import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import Input from '../../components/Input';
import { components } from '../../generated/apiTypes';
import { ReactComponent as Logo } from '../../images/components/Layout/logo-lg.svg';
import { selectApiError, setApiError } from '../../store/commonSlice';

interface IRegistrationForm {
  recoverPasswordRequest(data: components['schemas']['PasswordRecovery']): void;
}

const RestoreForm: React.FC<IRegistrationForm> = ({
  recoverPasswordRequest,
}) => {
  const [email, setEmail] = React.useState<string>('');
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [requestInProgress, setRequestInProgress] =
    React.useState<boolean>(false);
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      if (
        [
          'IDENTIFIER_NOT_FOUND',
          'IDENTIFIER_VERIFICATION_FAILED',
          'PasswordRecovery_login_Size',
        ].includes(apiError?.key as string)
      )
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [apiError.key]);

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (emailError) setEmailError(false);
    if (
      ['IDENTIFIER_NOT_FOUND', 'PasswordRecovery_login_Size'].includes(
        apiError?.key as string,
      )
    )
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setEmail(e.target.value);
  };

  const onRestoreClick = async () => {
    const emailIsCorrect = email;
    if (!emailIsCorrect) setEmailError(true);
    if (emailIsCorrect) {
      setRequestInProgress(true);
      try {
        await recoverPasswordRequest({ login: email });
      } catch (e) {
        //
      }
      setRequestInProgress(false);
    }
  };

  return (
    <>
      <div className="restore-page__logo-wrapper">
        <div className="restore-text-wrapper title">
          <Logo className="restore-page__logo" />
          Восстановление пароля
        </div>
      </div>
      <div className="restore-page__field">
        <Input
          placeholder="E-mail"
          value={email}
          onChange={onChangeEmail}
          error={
            emailError ||
            ['IDENTIFIER_NOT_FOUND', 'PasswordRecovery_login_Size'].includes(
              apiError?.key as string,
            )
          }
          onEnter={onRestoreClick}
        />
      </div>
      <Button
        className="restore-page__register-btn"
        onClick={onRestoreClick}
        disabled={requestInProgress}
      >
        Восстановить пароль
      </Button>
      {['IDENTIFIER_NOT_FOUND', 'IDENTIFIER_VERIFICATION_FAILED'].includes(
        apiError?.key as string,
      ) && (
        <div className="login-page__error-message restore-page__error-message normal-text">
          {apiError?.message}
        </div>
      )}
      {apiError.key === 'PasswordRecovery_login_Size' && (
        <div className="login-page__error-message restore-page__error-message normal-text">
          Aккаунт не найден
        </div>
      )}
    </>
  );
};

export default RestoreForm;
