import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import classNames from 'classnames';

import { ReactComponent as Menu } from '../../images/components/Layout/burger.svg';
import { ReactComponent as Logo } from '../../images/components/Layout/logo-lg.svg';
import { ReactComponent as Settings } from '../../images/components/Layout/settings.svg';
import { handleStorage } from '../../services/handleStorage';
import MobileMenu from './MobileMenu';
import Support from './Support';

import './style.scss';

interface ILayout extends RouteComponentProps {
  unauthorized?: boolean;
  error?: boolean;
  contentClassName?: string;
  headerClassName?: string;
  hideMobileMenu?: boolean;
}

const Layout: React.FC<ILayout> = ({
  history,
  location,
  children,
  unauthorized,
  error,
  contentClassName,
  headerClassName,
  hideMobileMenu,
}) => {
  const userName = handleStorage.getUserName();
  const role = handleStorage.getRole();
  const feedAuthority = handleStorage.getFeedAuthority();
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);
  const token = handleStorage.getToken();

  const redirectToAccount = () => {
    let path = '';
    if (role === 'ACTIVITY_PROVIDER') {
      path = '/provider/account';
    } else if (role === 'OPERATOR') {
      path = '/operator/account';
    }
    if (path) history.push(path);
  };

  const redirectToHome = () => {
    if (role === 'ACTIVITY_PROVIDER') history.push('/provider');
    else if (role === 'OPERATOR') history.push('/operator');
  };

  const redirectToAllBookings = () => {
    history.push(`/operator/bookings`);
  };

  const redirectToAllProviders = () => {
    history.push(`/operator/providers`);
  };

  const redirectToNewReviews = () => {
    history.push(`/operator/newreviews`);
  };

  const redirectToWebsite = () => {
    history.push(`/operator/website`);
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <MobileMenu className={openMenu ? 'open' : ''} closeMenu={closeMenu} />
      {!unauthorized && (
        <div
          className={classNames('layout__header-container', headerClassName)}
        >
          <div className="layout__header">
            {!hideMobileMenu && (
              <button className="layout__burger-menu" onClick={toggleMenu}>
                <Menu />
              </button>
            )}
            <button className="layout__logo" onClick={redirectToHome}>
              <Logo />
            </button>
            {role === 'OPERATOR' && (
              <div className="layout__header-content">
                <button
                  className={classNames(
                    'layout__header-menu-item button-text',
                    location.pathname === '/operator/bookings' && 'active',
                  )}
                  onClick={redirectToAllBookings}
                >
                  Бронирования
                </button>
                <button
                  className={classNames(
                    'layout__header-menu-item button-text',
                    location.pathname === '/operator/providers' && 'active',
                  )}
                  onClick={redirectToAllProviders}
                >
                  Поставщики
                </button>
                <button
                  className={classNames(
                    'layout__header-menu-item button-text',
                    location.pathname === '/operator/newreviews' && 'active',
                  )}
                  onClick={redirectToNewReviews}
                >
                  Новые отзывы
                </button>
                {feedAuthority === 'FEED' && (
                  <button
                    className={classNames(
                      'layout__header-menu-item button-text',
                      location.pathname === '/operator/website' && 'active',
                    )}
                    onClick={redirectToWebsite}
                  >
                    Сайт
                  </button>
                )}
              </div>
            )}
            <button
              className="layout__username button-text"
              onClick={redirectToAccount}
            >
              <div className="layout__username-name">{userName}</div>
              <div className="layout__settings-btn">
                <Settings className="layout__settings-icon" />
              </div>
            </button>
          </div>
        </div>
      )}
      <div
        className={classNames(
          'layout__content-container',
          { unauthorized },
          { error },
          contentClassName,
        )}
      >
        {children}
      </div>
      {role !== 'OPERATOR' && <Support />}
    </>
  );
};

export default withRouter(Layout);
