export const handleStorage = {
  setToken: (token: string) => {
    return localStorage.setItem('accessToken', token);
  },
  getToken: () => {
    return localStorage.getItem('accessToken');
  },
  removeToken: () => {
    localStorage.removeItem('accessToken');
  },
  setAuthorities: (authorities: ('FEED' | 'REFUND')[]) => {
    if (authorities.includes('FEED'))
      localStorage.setItem('feedAuthority', 'FEED');
    if (authorities.includes('REFUND'))
      localStorage.setItem('refundAuthority', 'REFUND');
  },
  getFeedAuthority: () => {
    return localStorage.getItem('feedAuthority');
  },
  getRefundAuthority: () => {
    return localStorage.getItem('refundAuthority');
  },
  removeFeedAuthority: () => {
    localStorage.removeItem('feedAuthority');
  },
  removeRefundAuthority: () => {
    localStorage.removeItem('refundAuthority');
  },
  setUsername: (name: string) => {
    return localStorage.setItem('username', name);
  },
  getUserName: () => {
    return localStorage.getItem('username');
  },
  removeUsername: () => {
    localStorage.removeItem('username');
  },
  setRole: (role: 'CUSTOMER' | 'ACTIVITY_PROVIDER' | 'OPERATOR') => {
    return localStorage.setItem('role', role);
  },
  getRole: () => {
    return localStorage.getItem('role');
  },
  removeRole: () => {
    localStorage.removeItem('role');
  },
};
