import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Divider from '../../../components/Divider/Divider';
import QrCode from '../QrCode/QrCode';

function Download(props: any) {
  const history = useHistory();
  const [modalActive, setModalActive] = useState(false);

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      history.push(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };

  const showQRModal = () => {
    window.scrollTo(0, 0);
    setModalActive(true);
  };

  return (
    <div className="download__container">
      <Divider className="divider__horizontal" />
      <div className="download__content">
        <div className="download__info">
          <div className="download__title">
            <h1>Скачивайте приложение adva</h1>
          </div>
          <div className="download__links-container">
            <div className="download__links">
              <button
                onClick={() =>
                  goTo(
                    'https://play.google.com/store/apps/details?id=com.numdes.a2',
                    true,
                  )
                }
                className="download__link-item-googleplay"
                type="button"
              />
              <button
                onClick={() =>
                  goTo(
                    'https://apps.apple.com/ru/app/adva-travel/id1600305963',
                    true,
                  )
                }
                className="download__link-item-appstore"
                type="button"
              />
              <button
                onClick={() => showQRModal()}
                className="download__link-item-qr"
                type="button"
              />
            </div>
          </div>
        </div>
        <div className="download__image">
          <div className="download__image-phone" />
        </div>
      </div>
      <QrCode modalActive={modalActive} setModalActive={setModalActive} />
    </div>
  );
}

export default Download;
