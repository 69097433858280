import * as React from 'react';

import { ReactComponent as StepOneIcon } from '../../../images/InfoProviders/steps-step1-number.svg';
import { ReactComponent as StepTwoIcon } from '../../../images/InfoProviders/steps-step2-number.svg';
import { ReactComponent as StepThreeIcon } from '../../../images/InfoProviders/steps-step3-number.svg';
import { ReactComponent as StepFourIcon } from '../../../images/InfoProviders/steps-step4-number.svg';

import './style.scss';

const Index: React.FC = () => {
  return (
    <div className="steps__container">
      <div className="steps__overlay" />
      <div className="steps__content-container">
        <div className="steps__main-img" />
        <div className="steps__overlay" />
        <div className="steps__shapes" />
        <div className="steps__title">Как работает ADVA</div>
        <div className="steps__blocks">
          <div className="steps__block step1-block">
            <StepOneIcon className="steps__number" />
            <div className="steps__step1-img" />
            <div className="steps__block-text step1-text">
              Заполните информацию об услуге в личном кабинете на сайте
            </div>
          </div>
          <div className="steps__block step2-block">
            <StepTwoIcon className="steps__number" />
            <div className="steps__step2-img" />
            <div className="steps__block-text step2-text">
              Примите заявку на услугу через личный кабинет или чат-бот
              в&nbsp;Telegram
            </div>
          </div>
          <div className="steps__block step3-block">
            <StepThreeIcon className="steps__number" />
            <div className="steps__step3-img" />
            <div className="steps__block-text step3-text">
              Проведите занятие
            </div>
          </div>
          <div className="steps__block step4-block">
            <StepFourIcon className="steps__number" />
            <div className="steps__step4-img" />
            <div className="steps__block-text step4-text">
              Получите оплату от клиента (клиент платит вам напрямую)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
