import * as React from 'react';
import { ValueType } from 'react-select';

import Dropdown from '../../../../../components/Dropdown';
import Switch from '../../../../../components/Switch';
import { components } from '../../../../../generated/apiTypes';

import './style.scss';

interface IDiscounts {
  view?: boolean;
  serviceType: 'EXPERT' | 'ACTIVITY' | 'RENTAL' | 'TRANSFER' | 'GENERIC';
  discountsSummationEnabled?: boolean;
  discounts?: components['schemas']['DiscountView'][];
  onChangeDiscountSummation?(val: boolean): void;
  onChangeDiscount?(value: number, index: number): void;
}

const discountsOptions: { value: string; label: string }[] = [
  { value: '0', label: '0' },
  { value: '5', label: '5%' },
  { value: '10', label: '10%' },
  { value: '15', label: '15%' },
  { value: '20', label: '20%' },
  { value: '25', label: '25%' },
  { value: '30', label: '30%' },
  { value: '35', label: '35%' },
  { value: '40', label: '40%' },
  { value: '45', label: '45%' },
  { value: '50', label: '50%' },
];

const Discounts: React.FC<IDiscounts> = ({
  view,
  serviceType,
  discountsSummationEnabled,
  discounts,
  onChangeDiscountSummation,
  onChangeDiscount,
}) => {
  const [timeDiscountSelectValue, setTimeDiscountSelectValue] = React.useState<
    ValueType<any, false> | undefined
  >(undefined);
  const [peopleDiscountSelectValue, setPeopleDiscountSelectValue] =
    React.useState<ValueType<any, false> | undefined>(undefined);

  React.useEffect(() => {
    const timeDiscount = discountsOptions?.find(
      item =>
        item?.value ===
        discounts
          ?.find(item => item.kind === 'THREE_OR_MORE_HOURS')
          ?.percentage.toString(),
    );
    setTimeDiscountSelectValue(timeDiscount);
  }, [
    discounts?.find(item => item.kind === 'THREE_OR_MORE_HOURS')?.percentage,
  ]);

  React.useEffect(() => {
    const peopleDiscount = discountsOptions?.find(
      item =>
        item?.value ===
        discounts
          ?.find(item => item.kind === 'TWO_OR_MORE_PEOPLE')
          ?.percentage.toString(),
    );
    setPeopleDiscountSelectValue(peopleDiscount);
  }, [discounts?.find(item => item.kind === 'TWO_OR_MORE_PEOPLE')?.percentage]);

  const onChangeTimeDiscount = (value: ValueType<any, false>) => {
    const index = discounts?.findIndex(
      item => item.kind === 'THREE_OR_MORE_HOURS',
    );
    if (
      onChangeDiscount &&
      index !== undefined &&
      parseInt(value.value) !== undefined
    ) {
      onChangeDiscount(parseInt(value.value), index);
    }
    setTimeDiscountSelectValue(value);
  };

  const onChangePeopleDiscount = (value: ValueType<any, false>) => {
    const index = discounts?.findIndex(
      item => item.kind === 'TWO_OR_MORE_PEOPLE',
    );
    if (
      onChangeDiscount &&
      index !== undefined &&
      parseInt(value.value) !== undefined
    ) {
      onChangeDiscount(parseInt(value.value), index);
    }
    setPeopleDiscountSelectValue(value);
  };

  return (
    <div className="discounts__container">
      <div className="prices__title header2">Скидки (Если есть)</div>
      {serviceType === 'EXPERT' && (
        <div className="prices__row">
          <div className="prices__row-title discounts__row-title header2">
            Бронирование <br className="discounts__new-line" />
            от 3х часов
          </div>
          <div className="discounts__discount-select">
            <Dropdown
              placeholder=""
              options={discountsOptions}
              menuWidth={'100%'}
              value={timeDiscountSelectValue}
              isSearchable={false}
              onChange={onChangeTimeDiscount}
              isDisabled={view}
            />
          </div>
        </div>
      )}
      <div className="prices__row">
        <div className="prices__row-title discounts__row-title header2">
          Группа <br className="discounts__new-line" />
          от 2х человек
        </div>
        <div className="discounts__discount-select">
          <Dropdown
            placeholder=""
            options={discountsOptions}
            menuWidth={'100%'}
            value={peopleDiscountSelectValue}
            isSearchable={false}
            onChange={onChangePeopleDiscount}
            isDisabled={view}
          />
        </div>
      </div>
      {serviceType === 'EXPERT' && (
        <div className="discounts__sum-container">
          <div className="discounts__sum-title header2">
            Суммировать <br className="discounts__new-line" />
            скидки
          </div>
          {onChangeDiscountSummation ? (
            <Switch
              checked={discountsSummationEnabled as boolean}
              handleChange={onChangeDiscountSummation}
            />
          ) : (
            <div className="view-info__with-icon-item label-text">
              {discountsSummationEnabled
                ? 'Суммировать скидки'
                : 'Не суммировать скидки'}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Discounts;
