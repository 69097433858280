import * as React from 'react';

import classNames from 'classnames';

import './style.scss';

interface IPopupMenuLayout {
  className?: string;
  closeMenu(): void;
  controlRef?: React.RefObject<any>;
}

const PopupMenuLayout: React.FC<IPopupMenuLayout> = ({
  children,
  className,
  closeMenu,
  controlRef,
}) => {
  const menuRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        !menuRef?.current?.contains(event.target) &&
        !controlRef?.current?.contains(event.target)
      ) {
        closeMenu();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className={classNames('popup-menu', className)} ref={menuRef}>
      {children}
    </div>
  );
};

export default PopupMenuLayout;
