import * as React from 'react';
import Switch from 'react-switch';

interface ISwitch {
  checked: boolean;
  handleChange?(checked: boolean, id?: number, name?: string): void;
  tagId?: number;
  name?: string;
}

const Index: React.FC<ISwitch> = ({ checked, handleChange, tagId, name }) => {
  const onChangeHandler = (checked: boolean) => {
    if (tagId !== undefined && name && handleChange)
      handleChange(checked, tagId, name);
    else if (handleChange) handleChange(checked);
  };
  return (
    <Switch
      onChange={onChangeHandler}
      checked={checked}
      height={28}
      width={44}
      handleDiameter={24}
      activeBoxShadow="0px -1px 6px rgba(52, 55, 67, 0.12)"
      boxShadow="0px -1px 6px rgba(52, 55, 67, 0.12)"
      onColor="#00BFB4"
      offColor="#DEE1E6"
      uncheckedIcon={false}
      checkedIcon={false}
    />
  );
};

export default Index;
