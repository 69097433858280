import * as React from 'react';
import ru from 'react-phone-number-input/locale/ru.json';
import { components, OptionProps } from 'react-select';

const Option: React.FC<OptionProps<any, any>> = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      {data.code !== 'Int' ? (
        <>
          <span
            style={{ width: '26px', display: 'inline-block' }}
          >{`${data.country}`}</span>{' '}
          <span
            style={{ width: '46px', display: 'inline-block' }}
          >{`${data.code}`}</span>{' '}
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
          {`${ru[data.country]}`}
        </>
      ) : (
        <>
          <span
            style={{ width: '76px', display: 'inline-block' }}
          >{`${data.code}`}</span>{' '}
          {`${ru['ZZ']}`}
        </>
      )}
    </components.Option>
  );
};

export default Option;
