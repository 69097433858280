import * as React from 'react';
import { Range } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TimePickerValue } from 'react-time-picker';

import classNames from 'classnames';
import { addDays, format, startOfWeek } from 'date-fns';
import en from 'date-fns/locale/en-GB';
import update from 'immutability-helper';

import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import WeekdaysPicker from '../../components/WeekdaysPicker';
import { components } from '../../generated/apiTypes';
import { ReactComponent as DeleteIcon } from '../../images/EditSlot/icn-delete.svg';
import { ReactComponent as AddIcon } from '../../images/Services/icn-add.svg';
import {
  createSlotConfiguration,
  deleteSlotConfiguration,
  updateSlotConfiguration,
} from '../../services/apiRequests';
import { selectApiError, setApiError } from '../../store/commonSlice';
import SlotsTable from './SlotsTable';
import TimeSlot from './TimeSlot';
import { ISlot } from './types';
import { slotsList, sortByDate } from './utils';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const SlotView: React.FC<ISlot> = ({
  newSlot,
  redirectToSlots,
  startDate,
  endDate,
  weekdaysProp,
  timesProp,
  match,
  type,
}) => {
  const [selectionState, setSelectionState] = React.useState<Range[]>([
    {
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
    },
  ]);
  const [weekdays, setWeekdays] = React.useState<number[]>(weekdaysProp);
  const [times, setTimes] = React.useState<TimePickerValue[]>(timesProp);
  const [slots, setSlots] = React.useState<Date[]>([]);
  const [noSlotsError, setNoSlotsError] = React.useState<boolean>(false);
  const [noWeekdaysError, setNoWeekdaysError] = React.useState<boolean>(false);
  const [noTimesError, setNoTimesError] = React.useState<boolean>(false);
  const [saveRequestInProgress, setSaveRequestInProgress] =
    React.useState<boolean>(false);
  const [deleteRequestInProgress, setDeleteRequestInProgress] =
    React.useState<boolean>(false);
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();

  const serviceId = match.params.service_id;
  const slotId = match.params.slot_id;

  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!ref?.current?.contains(event.target)) {
        onTimePickersBlur();
      }
      return true;
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (apiError.isError)
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [times]);

  React.useEffect(() => {
    redrawTable();
  }, [selectionState[0].startDate, selectionState[0].endDate, weekdays, times]);

  const setTimeByIndex = (value: TimePickerValue, index: number) => {
    const newTimes = update(times, { [index]: { $set: value } });
    setTimes(newTimes);
    if (noTimesError) setNoTimesError(false);
  };

  const onTimePickersBlur = () => {
    const newTimes = [...times];
    setTimes(newTimes.sort(sortByDate()));
  };

  const deleteTimeByIndex = (index: number) => {
    if (apiError.key === 'SlotConfigurationUpdate_times_Size') {
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    }
    const newTimes = update(times, { $splice: [[index, 1]] });
    setTimes(newTimes);
  };

  const onAddTimeClick = () => {
    const newTimes = update(times, { $push: [''] });
    setTimes(newTimes);
    if (noTimesError) setNoTimesError(false);
  };

  const setSelection = (range: Range[]) => {
    setSelectionState(range);
  };

  const redrawTable = () => {
    const list: Date[] = slotsList(
      selectionState[0].startDate as Date,
      selectionState[0].endDate as Date,
      times,
      weekdays,
    );
    setSlots(list);
    if (noSlotsError && list?.length > 0) setNoSlotsError(false);
  };

  const onSaveSlotConfiguration = async () => {
    onTimePickersBlur();
    const configuration: components['schemas']['SlotConfigurationView'] = {};
    configuration.startDate = format(
      selectionState[0]?.startDate as Date,
      'yyyy-MM-dd',
    );
    configuration.endDate = format(
      selectionState[0]?.endDate as Date,
      'yyyy-MM-dd',
    );
    configuration.times = [];
    for (const time of times) {
      if (time) configuration.times.push(time as any);
    }
    const firstDOW = startOfWeek(new Date(), { weekStartsOn: 1 });
    const weekDaysList = Array.from(Array(7))?.map((e, i) =>
      format(addDays(firstDOW, i), 'EEEE', { locale: en }),
    );
    const days: (
      | 'MONDAY'
      | 'TUESDAY'
      | 'WEDNESDAY'
      | 'THURSDAY'
      | 'FRIDAY'
      | 'SATURDAY'
      | 'SUNDAY'
    )[] = [];
    if (configuration.startDate !== configuration.endDate) {
      for (const [index, weekDay] of weekDaysList.entries()) {
        if (weekdays.includes(index)) {
          days.push(weekDay.toUpperCase() as any);
        }
      }
    }
    configuration.daysOfWeek = days;
    if (slots?.length > 0) {
      try {
        setSaveRequestInProgress(true);
        if (newSlot) {
          await createSlotConfiguration(serviceId, configuration);
        } else {
          await updateSlotConfiguration(slotId, configuration);
        }
        redirectToSlots();
      } catch (e) {
        //
      }
      setSaveRequestInProgress(false);
    } else {
      if (
        weekdays.length < 1 &&
        selectionState[0].startDate?.toDateString() !==
          selectionState[0].endDate?.toDateString()
      )
        setNoWeekdaysError(true);
      if (times.length < 1 && ['EXPERT', 'ACTIVITY'].includes(type as string))
        setNoTimesError(true);
      else if (weekdays.length > 0) setNoSlotsError(true);
    }
  };

  const onDeleteSlotConfiguration = async () => {
    setDeleteRequestInProgress(true);
    await deleteSlotConfiguration(slotId);
    setDeleteRequestInProgress(false);
    redirectToSlots();
  };

  const onWeekdaysChange = (weekdays: number[]) => {
    setWeekdays(weekdays);
    if (noWeekdaysError) setNoWeekdaysError(false);
  };

  return (
    <>
      <div className="edit-service__title-row page__title title">
        {newSlot
          ? 'Информация о расписании'
          : 'Редактирование информации о расписании'}
      </div>
      <div className="edit-slot__date-picker">
        <div className="edit-slot__label label-text">Дата/период</div>
        <div>
          <DatePicker selection={selectionState} setSelection={setSelection} />
        </div>
      </div>
      {selectionState[0].startDate?.toDateString() !==
        selectionState[0].endDate?.toDateString() && (
        <div className="edit-slot__weekdays-picker">
          <div
            className={classNames(
              'edit-slot__label label-text',
              noWeekdaysError && 'error',
            )}
          >
            Каждый
          </div>
          <WeekdaysPicker
            weekdays={weekdays}
            setWeekdays={onWeekdaysChange}
            disabled={
              selectionState[0].startDate?.toDateString() ===
              selectionState[0].endDate?.toDateString()
            }
            error={noWeekdaysError}
          />
        </div>
      )}
      {!['RENTAL', 'TRANSFER', 'PRIMITIVE'].includes(type as string) && (
        <div className="edit-slot__time-pickers-wrapper">
          <div
            className={classNames(
              'edit-slot__label label-text time-label',
              noTimesError && 'error',
            )}
          >
            {type === 'ACTIVITY' ? 'Время начала' : 'Время'}
          </div>
          <div className="edit-slot__time-pickers" ref={ref}>
            {times?.map((item, index) => (
              <TimeSlot
                key={index}
                time={item}
                setTime={setTimeByIndex}
                index={index}
                deleteTime={deleteTimeByIndex}
                onBlur={redrawTable}
                error={apiError.key === 'SlotConfigurationUpdate_times_Size'}
              />
            ))}
          </div>
          <Button
            className="edit-slot__add-time-btn"
            mode="secondary"
            onClick={onAddTimeClick}
          >
            <AddIcon className="services__add-btn-icon" />
            Добавить
          </Button>
        </div>
      )}
      {type === 'EXPERT' && (
        <div className="edit-slot__hint-message label-text">
          1 занятие - 1&nbsp;час (60&nbsp;минут). Один клиент может
          забронировать несколько часов подряд. Например, если вы работаете
          с&nbsp;10:00 до&nbsp;14:00 выбирайте 10:00, 11:00, 12:00, 13:00
        </div>
      )}
      <div className="edit-slot__bottom-row-xs">
        <div className="edit-slot__buttons">
          <Button
            className="edit-slot__btn"
            onClick={onSaveSlotConfiguration}
            disabled={saveRequestInProgress}
          >
            Сохранить
          </Button>
          <Button
            className="edit-slot__btn"
            mode="secondary"
            onClick={redirectToSlots}
          >
            Отменить
          </Button>
        </div>
        {noWeekdaysError && (
          <div className="edit-slot__errors-xs">Не выбраны дни работы</div>
        )}
        {noTimesError && (
          <div className="edit-slot__errors-xs">
            {type === 'EXPERT'
              ? 'Не выбрано время старта занятий'
              : 'Не выбрано время старта тура'}
          </div>
        )}
        {noSlotsError && (
          <div className="edit-slot__errors-xs">Выберите время</div>
        )}
        {[
          'SLOT_CONFIGURATION_OVERLAP',
          'SLOT_CONFIGURATION_RANGE_TOO_LONG',
          'SLOT_CONFIGURATION_TOO_MANY',
          'SlotConfigurationUpdate_times_Size',
        ].includes(apiError.key as string) && (
          <div className="edit-slot__errors-xs">{apiError?.message}</div>
        )}
        {!newSlot && (
          <button
            className="edit-slot__delete-row button-text"
            onClick={onDeleteSlotConfiguration}
            disabled={deleteRequestInProgress}
          >
            <DeleteIcon />
            Удалить информацию
          </button>
        )}
      </div>
      <div className="edit-slot__bottom-row">
        <div className="edit-slot__buttons">
          <Button
            className="edit-slot__btn"
            onClick={onSaveSlotConfiguration}
            disabled={saveRequestInProgress}
          >
            Сохранить
          </Button>
          <Button
            className="edit-slot__btn"
            mode="secondary"
            onClick={redirectToSlots}
          >
            Отменить
          </Button>
        </div>
        {!newSlot && (
          <button
            className="edit-slot__delete-row button-text"
            onClick={onDeleteSlotConfiguration}
            disabled={deleteRequestInProgress}
          >
            <DeleteIcon />
            Удалить информацию
          </button>
        )}
      </div>
      {noWeekdaysError && (
        <div className="edit-slot__errors">Не выбраны дни работы</div>
      )}
      {noTimesError && (
        <div className="edit-slot__errors">
          {type === 'EXPERT'
            ? 'Не выбрано время старта занятий'
            : 'Не выбрано время старта тура'}
        </div>
      )}
      {noSlotsError && <div className="edit-slot__errors">Выберите время</div>}
      {[
        'SLOT_CONFIGURATION_OVERLAP',
        'SLOT_CONFIGURATION_RANGE_TOO_LONG',
        'SLOT_CONFIGURATION_TOO_MANY',
        'SlotConfigurationUpdate_times_Size',
      ].includes(apiError.key as string) && (
        <div className="edit-slot__errors">{apiError?.message}</div>
      )}
      {slots.length > 0 && (
        <>
          <div className="edit-slot__slots-list-title header1">
            Сводная таблица расписания
          </div>
          <SlotsTable
            slots={slots}
            type={type}
            loadingByPage={false}
            hasMore={false}
          />
        </>
      )}
    </>
  );
};

export default withRouter(SlotView);
