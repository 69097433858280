import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { addDays, format, startOfWeek } from 'date-fns';
import en from 'date-fns/locale/en-GB';

import ApiErrorUI from '../../components/ApiErrorUI';
import Layout from '../../components/Layout';
import ReturnLink from '../../components/ReturnLink';
import { components } from '../../generated/apiTypes';
import { getActivityById, getSlotById } from '../../services/apiRequests';
import { selectApiError, setApiError } from '../../store/commonSlice';
import SlotView from './SlotView';
import { IRouterProps } from './types';

import './style.scss';

const EditSlot: React.FC<RouteComponentProps<IRouterProps>> = ({
  history,
  location,
  match,
}) => {
  const [slot, setSlot] =
    React.useState<components['schemas']['SlotConfigurationView']>();
  const [service, setService] =
    React.useState<components['schemas']['ActivityDetails']>();
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();
  const initDate = new Date();
  const initWeekDays: any[] = [];

  const getSlot = async () => {
    const response = await getSlotById(match.params.slot_id);
    if (response?.data) {
      setSlot(response.data);
    }
  };

  const getService = async () => {
    const response = await getActivityById(match.params.service_id);
    {
      if (response.data) {
        setService(response.data);
        if (match.params.slot_id === 'new') {
          if (
            ['RENTAL', 'TRANSFER', 'PRIMITIVE'].includes(
              response.data.summary?.category?.type as string,
            )
          ) {
            setSlot({
              startDate: format(initDate, 'yyyy-MM-dd'),
              endDate: format(initDate, 'yyyy-MM-dd'),
              times: ['00:00'],
              daysOfWeek: [
                'MONDAY',
                'TUESDAY',
                'WEDNESDAY',
                'THURSDAY',
                'FRIDAY',
                'SATURDAY',
                'SUNDAY',
              ],
            });
          } else {
            setSlot({
              startDate: format(initDate, 'yyyy-MM-dd'),
              endDate: format(initDate, 'yyyy-MM-dd'),
              times: [],
              daysOfWeek: initWeekDays,
            });
          }
        }
      }
    }
  };

  React.useEffect(() => {
    if (match.params.slot_id !== 'new') {
      getSlot();
      getService();
    } else {
      getService();
    }
    return () => {
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    };
  }, []);

  const redirectToSlots = () => {
    const path = location.pathname.split('/slot')[0];
    history.push({
      pathname: path,
      state: { tabIx: 2 },
    });
  };

  const weekdays: number[] = [];
  const firstDOW = startOfWeek(new Date(), { weekStartsOn: 1 });
  const weekDaysList = Array.from(Array(7))?.map((e, i) =>
    format(addDays(firstDOW, i), 'EEEE', { locale: en }),
  );
  for (const [index, weekDay] of weekDaysList?.entries()) {
    if (slot?.daysOfWeek?.includes(weekDay.toUpperCase() as any)) {
      weekdays.push(index);
    }
  }

  const errorToShow =
    apiError?.isError &&
    !(
      apiError?.key === 'SLOT_CONFIGURATION_OVERLAP' ||
      apiError?.key === 'SLOT_CONFIGURATION_RANGE_TOO_LONG' ||
      apiError?.key === 'SLOT_CONFIGURATION_TOO_MANY' ||
      apiError?.key === 'SlotConfigurationUpdate_times_Size'
    );

  return (
    <Layout hideMobileMenu>
      {errorToShow ? (
        <ApiErrorUI />
      ) : (
        slot && (
          <>
            <ReturnLink
              linkName={'К карточке услуги'}
              onClickHandler={redirectToSlots}
            />
            <SlotView
              redirectToSlots={redirectToSlots}
              newSlot={match.params.slot_id === 'new'}
              startDate={new Date(slot?.startDate as string)}
              endDate={new Date(slot?.endDate as string)}
              weekdaysProp={weekdays}
              timesProp={slot?.times as string[]}
              type={service?.summary?.category?.type}
            />
          </>
        )
      )}
    </Layout>
  );
};

export default withRouter(EditSlot);
