import * as React from 'react';

function ArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={13}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.858L9.142 11 0 20.142 1.858 22l11-11-11-11L0 1.858z"
        fill="#343743"
      />
    </svg>
  );
}

export default ArrowIcon;
