import * as React from 'react';

function BurgerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.975 5.975h16M3.975 11.975h16M3.975 17.975h16"
        stroke="#92A4B5"
        strokeWidth={2}
        strokeLinecap="square"
      />
    </svg>
  );
}

export default BurgerIcon;
