import * as React from 'react';

function ArrowNavigationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={4}
      height={7}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 .5l-.705.705L2.585 3.5.295 5.795 1 6.5l3-3-3-3z"
        fill="#ACAFBF"
      />
    </svg>
  );
}

export default ArrowNavigationIcon;
