/* eslint-disable */
import * as React from 'react';
import './dividerStyle.scss';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  children?: any;
}

function Divider(props: Props) {
  const { className, width, height, children } = props;
  if (width || height) {
    return (
      <div
        className={className ? className : 'divider'}
        style={{ width: width, height: height }}
      >
        {children}
      </div>
    );
  }
  return <div className={className ? className : 'divider'}>{children}</div>;
}

export default Divider;
