import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { handleStorage } from '../../services/handleStorage';
import { selectApiError } from '../../store/commonSlice';

import './style.scss';

const ApiErrorUI: React.FC<RouteComponentProps> = ({ history }) => {
  const apiError = useSelector(selectApiError);
  const role = handleStorage.getRole();

  if (role === 'OPERATOR' && location.pathname.startsWith('/provider')) {
    history.push('/operator');
  }
  if (
    role === 'ACTIVITY_PROVIDER' &&
    location.pathname.startsWith('/operator')
  ) {
    history.push('/provider');
  }

  const redirect = () => {
    if (role === 'OPERATOR') history.push('/operator');
    else if (role === 'ACTIVITY_PROVIDER') history.push('/provider');
  };

  return (
    <div className="error-page__content">
      <div className="title error-page__title">Произошла ошибка</div>
      <div className="error-page__codes">
        <div className="error-page__code normal-text">
          {apiError?.status && `STATUS_CODE_${apiError?.status}`}
        </div>
        <div className="error-page__code normal-text">
          {apiError?.key && `KEY_${apiError?.key}`}
        </div>
        <div className=" error-page__code normal-text">
          {apiError?.timeStamp && `TIME_STAMP_${apiError?.timeStamp}`}
        </div>
      </div>
      <div className="error-page__info normal-text">
        Пожалуйста, напишите нам на provider.support@adva.travel в какой момент
        она возникла и, по возможности, приложите скриншот данного экрана
      </div>
      <button
        className="error-page__link button-text underlined"
        onClick={redirect}
      >
        На главную
      </button>
    </div>
  );
};

export default withRouter(ApiErrorUI);
