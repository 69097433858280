import * as React from 'react';
import Dropzone from 'react-dropzone';

import imageCompression from 'browser-image-compression';
import classNames from 'classnames';
import update from 'immutability-helper';

import { ReactComponent as CloseIcon } from '../../../../../images/EditService/icn-close.svg';
import { ServiceInfo } from '../../types';

import './style.scss';

const compressionOptions = {
  maxSizeMB: 1,
};

const maxVideoSizeMB = 10;

interface IImagesArea {
  infoState: ServiceInfo;
  setInfoState(info: ServiceInfo): void;
  error?: boolean;
  setError?(err: boolean): void;
  resetError?(): void;
  type: 'PHOTOVIDEO' | 'PHOTO' | 'AUDIO';
  maxFilesQty: number;
}

const ImagesArea: React.FC<IImagesArea> = ({
  infoState,
  setInfoState,
  error,
  setError,
  type,
  maxFilesQty,
  resetError,
}) => {
  const onDropFile = (files: File[]) => {
    switch (type) {
      case 'PHOTOVIDEO':
        {
          if (infoState.images.length < maxFilesQty) {
            for (const file of files) {
              saveImageVideo(file).then(() => {
                //
              });
            }
          }
        }
        break;
      case 'PHOTO':
        {
          saveExpertPhoto(files[0]).then(() => {
            //
          });
        }
        break;
      case 'AUDIO':
        {
          saveExpertAudio(files[0]).then(() => {
            //
          });
        }
        break;
      default:
        break;
    }
  };

  const saveImageVideo = async (file: File) => {
    const reader = new FileReader();
    try {
      let compressedFile: File | undefined = undefined;
      if (file && file?.type.match('image/*')) {
        const newState = update(infoState, {
          images: {
            $push: [
              {
                view: { pictureUrl: `/file-loader.gif`, id: file.lastModified },
                file: undefined,
              },
            ],
          },
        });
        if (error && resetError) resetError();
        setInfoState(newState);
        compressedFile = await imageCompression(file, compressionOptions);
        if (compressedFile) reader.readAsDataURL(compressedFile);
      } else if (file && file?.type.match('video/*')) {
        if (file.size < maxVideoSizeMB * 1024 * 1024) {
          const newState = update(infoState, {
            images: {
              $push: [
                {
                  view: {
                    pictureUrl: `/file-loader.gif`,
                    id: file.lastModified,
                  },
                  file: undefined,
                },
              ],
            },
          });
          if (error && resetError) resetError();
          setInfoState(newState);
          reader.readAsDataURL(file);
        } else if (setError) setError(true);
      }
      reader.onload = () => {
        if (reader.result) {
          const fileIx = infoState.images.findIndex(
            item => item.view.id === file.lastModified,
          );
          if (fileIx > -1) {
            const newState = update(infoState, {
              images: {
                [fileIx]: {
                  $set: {
                    view: file?.type.match('image.*')
                      ? { pictureUrl: reader.result as string }
                      : { videoUrl: reader.result as string },
                    file: file?.type.match('image.*') ? compressedFile : file,
                  },
                },
              },
            });
            if (error && resetError) resetError();
            setInfoState(newState);
          } else {
            const newState = update(infoState, {
              images: {
                $push: [
                  {
                    view: file?.type.match('image.*')
                      ? { pictureUrl: reader.result as string }
                      : { videoUrl: reader.result as string },
                    file: file?.type.match('image.*') ? compressedFile : file,
                  },
                ],
              },
            });
            if (error && resetError) resetError();
            setInfoState(newState);
          }
        }
      };
    } catch (error) {
      //
    }
  };

  const saveExpertPhoto = async (file: File) => {
    const reader = new FileReader();
    let compressedFile: File | undefined;
    try {
      if (file && file?.type.match('image.*')) {
        if (infoState?.expert) {
          setInfoState({
            ...infoState,
            expert: {
              ...infoState.expert,
              photo: {
                view: {
                  url: `/file-loader.gif`,
                },
                file: undefined,
              },
            },
          });
        } else {
          setInfoState({
            ...infoState,
            expert: {
              firstName: '',
              lastName: '',
              photo: {
                view: { url: `/file-loader.gif` },
                file: undefined,
              },
            },
          });
        }
        compressedFile = await imageCompression(file, compressionOptions);
        if (compressedFile) reader.readAsDataURL(compressedFile);
      }
      reader.onload = () => {
        if (reader.result) {
          if (infoState?.expert) {
            setInfoState({
              ...infoState,
              expert: {
                ...infoState.expert,
                photo: {
                  view: {
                    url: reader.result as string,
                  },
                  file: compressedFile,
                },
              },
            });
          }
        }
      };
    } catch (error) {
      //
    }
  };

  const saveExpertAudio = async (file: File) => {
    const reader = new FileReader();
    try {
      if (file && file?.type.match('audio/*')) {
        if (file.size < maxVideoSizeMB * 1024 * 1024) {
          if (infoState?.expert) {
            setInfoState({
              ...infoState,
              expert: {
                ...infoState.expert,
                voice: {
                  view: { url: `/file-loader.gif` },
                  file: undefined,
                },
              },
            });
          } else {
            setInfoState({
              ...infoState,
              expert: {
                firstName: '',
                lastName: '',
                voice: {
                  view: { url: `/file-loader.gif` },
                  file: undefined,
                },
              },
            });
          }
          reader.readAsDataURL(file);
        } else if (setError) setError(true);
      }
      reader.onload = () => {
        if (reader.result) {
          if (error && resetError) resetError();
          if (infoState?.expert) {
            setInfoState({
              ...infoState,
              expert: {
                ...infoState.expert,
                voice: {
                  view: { url: reader.result as string },
                  file: file,
                },
              },
            });
          }
        }
      };
    } catch (error) {
      //
    }
  };

  const deleteImage = (ix: number) => {
    const newState = update(infoState, { images: { $splice: [[ix, 1]] } });
    setInfoState(newState);
  };

  const deleteExpertPhoto = () => {
    const newState = update(infoState, {
      expert: { photo: { $set: undefined } },
    });
    setInfoState(newState);
  };

  const deleteExpertAudio = () => {
    const newState = update(infoState, {
      expert: { voice: { $set: undefined } },
    });
    setInfoState(newState);
  };

  const placeholderByType = () => {
    switch (type) {
      case 'PHOTOVIDEO':
        return 'фотографий/видео';
      case 'PHOTO':
        return 'фотографии';
      case 'AUDIO':
        return 'аудиозаписи';
      default:
        return 'файла';
    }
  };

  return (
    <div
      className={classNames(
        'images-area__container',
        maxFilesQty === 1 && 'single',
        type.toLowerCase(),
      )}
    >
      <Dropzone onDrop={onDropFile}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            className={classNames(
              'images-area__dropzone normal-text',
              isDragActive && 'dragging',
              { error },
            )}
            {...getRootProps()}
          >
            <span className="images-area__dropzone-button underlined">
              Нажмите здесь
            </span>{' '}
            для загрузки {placeholderByType()} или&nbsp;перетащите файлы
            в&nbsp;эту область (Не&nbsp;более&nbsp;
            {maxFilesQty}
            {type === 'AUDIO' && ' файла размером до 10 Mбайт'}
            {type === 'PHOTOVIDEO' &&
              ' файлов, минимум 1 фотография, видео не более 10 Mбайт'}
            )
            <input
              type="file"
              {...getInputProps({
                accept:
                  type === 'PHOTOVIDEO'
                    ? 'image/*, video/*'
                    : type === 'PHOTO'
                    ? 'image/*'
                    : 'audio/*',
                multiple: false,
              })}
            />
          </div>
        )}
      </Dropzone>
      {type === 'PHOTOVIDEO' && infoState.images?.length > 0 && (
        <div className="images-area__photos">
          {infoState.images?.map((image, index) => {
            return (
              <div key={index} className="images-area__photos-item-wrapper">
                {image?.view?.pictureUrl && (
                  <img
                    src={image?.view?.pictureUrl}
                    alt=""
                    className="images-area__photos-item"
                    key={index}
                  />
                )}
                {image?.view?.videoUrl && (
                  <video
                    key={index}
                    src={image?.view?.videoUrl}
                    className="images-area__photos-item"
                  />
                )}
                <button
                  onClick={() => {
                    deleteImage(index);
                  }}
                >
                  <CloseIcon className="images-area__photos-item-close-icon" />
                </button>
              </div>
            );
          })}
        </div>
      )}
      {type === 'PHOTO' && infoState.expert?.photo?.view?.url && (
        <div className="images-area__photos">
          <div className="images-area__photos-item-wrapper">
            <img
              src={infoState.expert?.photo?.view?.url}
              alt=""
              className="images-area__photos-item"
            />
            <button onClick={deleteExpertPhoto}>
              <CloseIcon className="images-area__photos-item-close-icon" />
            </button>
          </div>
        </div>
      )}
      {type === 'AUDIO' && infoState.expert?.voice?.view?.url && (
        <div className="images-area__photos">
          <div className="images-area__photos-item-wrapper">
            <div className="images-area__photos-item sound-item" />
            <button onClick={deleteExpertAudio}>
              <CloseIcon className="images-area__photos-item-close-icon" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImagesArea;
