import { StylesConfig } from 'react-select';

const handleStyles: StylesConfig<any, any> = {
  control: (provided, state) => {
    return {
      ...provided,
      position: 'unset',
      border: 'none',
      fontWeight: 'normal',
      transition: 'all 0.25s ease',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      minHeight: '26px',
    };
  },
  menuList: provided => {
    return {
      ...provided,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
      zIndex: 200,
      borderRadius: '8px',
    };
  },
  option: (provided, state: { isSelected: boolean }) => {
    const color = state.isSelected ? '#fff' : '#393C48';
    const backgroundColor = state.isSelected ? '#00BFB4' : '#fff';
    const hoverBackgroundColor = state.isSelected ? '#05C4B9' : '#CEECEB';
    const activeBackgroundColor = state.isSelected ? '#00A196' : '#BEE2E1';
    return {
      ...provided,
      color,
      backgroundColor,
      height: 44,
      verticalAlign: 'middle',
      transition: 'all 0.25s ease',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '26px',
      cursor: 'pointer',
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: '9px 12px',
      '&:hover': {
        backgroundColor: hoverBackgroundColor,
      },
      '&:active': {
        backgroundColor: activeBackgroundColor,
      },
      '&:not(:last-child)': {
        borderBottom: '2px solid #EAEDF2',
      },
    };
  },
  menu: (provided, state) => {
    const menuWidth = state.selectProps.menuWidth;
    return {
      ...provided,
      width: menuWidth,
      position: 'absolute',
      top: '38px',
      left: '-13px',
      marginTop: 4,
      marginBottom: 0,
      borderRadius: 8,
      boxShadow: '0px 1px 6px rgba(52, 55, 67, 0.12)',
      backgroundColor: '#fff',
      zIndex: 200,
    };
  },
  indicatorsContainer: provided => {
    return {
      ...provided,
      fontSize: 0,
      height: '26px',
    };
  },
  valueContainer: provided => {
    return {
      ...provided,
      padding: 0,
      lineHeight: '26px',
    };
  },
  input: provided => {
    return {
      ...provided,
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '26px',
      color: '#343743',
    };
  },
  placeholder: provided => {
    return {
      ...provided,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '26px',
      marginLeft: 0,
      position: 'unset',
      transform: 'none',
      color: '#92A4B5',
    };
  },
  singleValue: provided => {
    return {
      ...provided,
      marginLeft: 0,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '26px',
      color: '#343743',
      position: 'unset',
      transform: 'none',
      marginRight: '12px',
    };
  },
};

export default handleStyles;
