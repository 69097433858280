import * as React from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import Input from '../../components/Input';
import IntlPhoneInput from '../../components/PhoneInput';
import { components } from '../../generated/apiTypes';
import { ReactComponent as Logo } from '../../images/components/Layout/logo-lg.svg';
import { selectApiError, setApiError } from '../../store/commonSlice';

interface IRegistrationForm {
  createProvider(data: components['schemas']['AccountRegistration']): void;
}

const RegistrationForm: React.FC<IRegistrationForm> = ({ createProvider }) => {
  const [name, setName] = React.useState<string>('');
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [phone, setPhone] = React.useState<string>('');
  const [phoneError, setPhoneError] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = React.useState<string>('');
  const [passwordError, setPasswordError] = React.useState<boolean>(false);
  const [passwordRepeatError, setPasswordRepeatError] =
    React.useState<boolean>(false);
  const [passwordsEqualError, setPasswordsEqualError] =
    React.useState<boolean>(false);
  const [requestInProgress, setRequestInProgress] =
    React.useState<boolean>(false);
  const phoneRef = React.useRef<HTMLDivElement>(null);
  const [phoneFieldWidth, setPhoneFieldWidth] = React.useState<number>(0);
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      if (
        [
          'IDENTIFIER_ALREADY_EXISTS',
          'AccountRegistration_password_Pattern',
          'AccountRegistration_name_NotBlank',
          'AccountRegistration_name_Size',
          'AccountRegistration_password_Size',
          'ACCOUNT_PASSWORD_WEAK',
          'AccountRegistration_login_Size',
          'AccountRegistration_email_Pattern',
          'AccountRegistration_password_NotBlank',
          'AccountRegistration_email_NotBlank',
        ].includes(apiError?.key as string)
      )
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [apiError.key]);

  React.useEffect(() => {
    if (phoneRef?.current?.clientWidth)
      setPhoneFieldWidth(phoneRef?.current?.clientWidth);
  }, [phoneRef?.current]);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (nameError) setNameError(false);
    if (
      [
        'AccountRegistration_name_NotBlank',
        'AccountRegistration_name_Size',
      ].includes(apiError?.key as string)
    )
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setName(e.target.value);
  };

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (emailError) setEmailError(false);
    if (
      [
        'IDENTIFIER_ALREADY_EXISTS',
        'AccountRegistration_email_Size',
        'AccountRegistration_email_Pattern',
        'AccountRegistration_email_NotBlank',
      ].includes(apiError.key as string)
    )
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setEmail(e.target.value);
  };

  const onChangePhone = (val: any) => {
    if (phoneError) setPhoneError(false);
    setPhone(val);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (passwordError) setPasswordError(false);
    if (passwordsEqualError) setPasswordsEqualError(false);
    if (
      [
        'AccountRegistration_password_Pattern',
        'AccountRegistration_password_Size',
        'ACCOUNT_PASSWORD_WEAK',
        'AccountRegistration_password_NotBlank',
      ].includes(apiError?.key as string)
    )
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
        }),
      );
    setPassword(e.target.value);
  };

  const onChangePasswordRepeat = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (passwordRepeatError) setPasswordRepeatError(false);
    if (passwordsEqualError) setPasswordsEqualError(false);
    setPasswordRepeat(e.target.value);
  };

  const onRegisterClick = async () => {
    const phoneIsCorrect = phone && isValidPhoneNumber(phone);
    if (!phoneIsCorrect) setPhoneError(true);
    if (!name) setNameError(true);
    const emailIsCorrect = email;
    if (!emailIsCorrect) setEmailError(true);
    if (!password) setPasswordError(true);
    if (!passwordRepeat) setPasswordRepeatError(true);
    const passwordsEqual = password === passwordRepeat;
    if (!passwordsEqual) {
      setPasswordsEqualError(true);
    }
    if (
      phoneIsCorrect &&
      name &&
      emailIsCorrect &&
      password &&
      passwordRepeat &&
      passwordsEqual
    ) {
      setPasswordError(false);
      setPasswordRepeatError(false);
      const phoneNumber = parseInt(phone);
      setRequestInProgress(true);
      try {
        await createProvider({
          name: name,
          email: email,
          phone: phoneNumber.toString(),
          password: password,
        });
      } catch (e) {
        //
      }
      setRequestInProgress(false);
    }
  };

  return (
    <>
      <div className="registration-page__logo-wrapper">
        <div className="registration-text-wrapper title">
          <Logo className="registration-page__logo" />
          Регистрация
        </div>
      </div>
      <div className="registration-page__field">
        <Input
          placeholder="Название организации / ФИО"
          value={name}
          onChange={onChangeName}
          error={
            nameError ||
            apiError?.key === 'AccountRegistration_name_NotBlank' ||
            apiError?.key === 'AccountRegistration_name_Size'
          }
        />
      </div>
      <div className="registration-page__field">
        <Input
          placeholder="E-mail"
          value={email}
          onChange={onChangeEmail}
          error={
            emailError ||
            apiError.key === 'IDENTIFIER_ALREADY_EXISTS' ||
            apiError.key === 'AccountRegistration_email_Size' ||
            apiError.key === 'AccountRegistration_email_Pattern' ||
            apiError.key === 'AccountRegistration_email_NotBlank'
          }
        />
      </div>
      <div className="registration-page__field" ref={phoneRef}>
        <IntlPhoneInput
          placeholder="Номер телефона"
          onChange={onChangePhone}
          error={phoneError}
          value={phone}
          menuWidth={phoneFieldWidth}
        />
      </div>
      <div className="registration-page__separator" />
      <div className="registration-page__field">
        <Input
          placeholder="Пароль"
          value={password}
          onChange={onChangePassword}
          error={
            passwordError ||
            passwordsEqualError ||
            apiError.key === 'AccountRegistration_password_Pattern' ||
            apiError?.key === 'AccountRegistration_password_Size' ||
            apiError?.key === 'ACCOUNT_PASSWORD_WEAK' ||
            apiError?.key === 'AccountRegistration_password_NotBlank'
          }
          type="password"
        />
      </div>
      <div className="registration-page__field">
        <Input
          placeholder="Повторить пароль"
          value={passwordRepeat}
          onChange={onChangePasswordRepeat}
          error={passwordRepeatError || passwordsEqualError}
          type="password"
          onEnter={onRegisterClick}
        />
        <div className="login-page__post-message">
          {
            'Пароль должен быть от 8 символов и содержать только латинские буквы и цифры'
          }
        </div>
      </div>
      <Button
        className="registration-page__register-btn"
        onClick={onRegisterClick}
        disabled={requestInProgress}
      >
        Зарегистрироваться
      </Button>
      {[
        'IDENTIFIER_ALREADY_EXISTS',
        'AccountRegistration_password_Pattern',
        'AccountRegistration_name_NotBlank',
        'AccountRegistration_name_Size',
        'AccountRegistration_password_Size',
        'ACCOUNT_PASSWORD_WEAK',
        'AccountRegistration_email_Size',
        'AccountRegistration_email_Pattern',
        'AccountRegistration_email_NotBlank',
        'AccountRegistration_password_NotBlank',
      ].includes(apiError?.key as string) && (
        <div className="login-page__error-message normal-text">
          {apiError?.message}
        </div>
      )}
      {passwordsEqualError && (
        <div className="login-page__error-message normal-text">
          Введенные пароли не совпадают
        </div>
      )}
      <div className="registration-page__policy button-text">
        Нажимая “Зарегистрироваться”, я соглашаюсь с{' '}
        <a
          className="login-page__policy-link button-text underlined"
          href="/Политика_обработки_персональных_данных.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          политикой обработки персональных данных
        </a>
        , даю{' '}
        <a
          className="login-page__policy-link button-text underlined"
          href="/Согласие_на_обработку_персональных_данных.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          согласие на обработку персональных данных
        </a>
        , а&nbsp;также принимаю{' '}
        <a
          className="login-page__policy-link button-text underlined"
          href="/Пользовательское_соглашение.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          пользовательское соглашение
        </a>{' '}
        сервиса ADVA
      </div>
    </>
  );
};

export default RegistrationForm;
