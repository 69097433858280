import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ReactComponent as ReviewIcon } from '../../../images/Services/icn-review.svg';

import './style.scss';

interface IProvider extends RouteComponentProps {
  id: string;
  name: string;
  reviewsAvg: string;
  newReviews: number;
}

const Index: React.FC<IProvider> = ({
  id,
  name,
  newReviews,
  reviewsAvg,
  history,
}) => {
  const onProviderClick = () => {
    history.push(`/operator/provider=${id}`);
  };

  return (
    <button className="provider__container" onClick={onProviderClick}>
      <div className="provider__name-row header2">
        <div className="provider__name-row-name">{name}</div>
      </div>

      <div className="provider__details-row">
        <div className="provider__details-column">
          {reviewsAvg && (
            <>
              <ReviewIcon />
              <span className="provider__details-number normal-text">
                {reviewsAvg}
              </span>
              {newReviews > 0 && (
                <span className="provider__details-new-number normal-text">{`+${newReviews}`}</span>
              )}
            </>
          )}
        </div>
      </div>
    </button>
  );
};

export default withRouter(Index);
