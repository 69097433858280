import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Divider from '../../../components/Divider/Divider';
import { components } from '../../../generated/apiTypes';
import { getFeedSubcategories } from '../../../services/apiRequests';
import declOfNum from '../../../utils/declOfNum';
import QrCode from '../QrCode/QrCode';

const CardList = () => {
  const history = useHistory();

  const [modalActive, setModalActive] = useState(false);

  const [maxResults, setMaxResults] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = React.useState<
    components['schemas']['SubcategoryView'][] | undefined
  >([]);

  const getAllCategories = async () => {
    const response = await getFeedSubcategories(maxResults * page, false);
    if (response && response.status === 200) {
      setData(response.data.results);
    }
  };

  const reserve = () => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      window
        .open(
          'https://play.google.com/store/apps/details?id=com.numdes.a2',
          '_blank',
        )
        ?.focus();
      return;
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window
        .open(
          'https://apps.apple.com/ru/app/adva-travel/id1600305963',
          '_blank',
        )
        ?.focus();
      return;
    }

    showQRModal();
  };

  const showQRModal = () => {
    window.scrollTo(0, 0);
    setModalActive(true);
  };

  const goTo = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <div className="cardlist__container">
      <Divider className="divider__horizontal" />
      <div className="cardlist__content">
        <div className="cardlist__title">
          <h2 className="block__title-light">Что есть в ADVA?</h2>
        </div>
        <div className="cardlist__cards">
          {data?.map(subcategory => (
            <button
              key={subcategory.id}
              className="cardlist__single-card"
              style={{
                background: `url(${subcategory.feedPicture?.url})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              onClick={() => goTo(`/subcategories/article/${subcategory.id}`)}
            >
              <div className="cardlist__single-card-footer">
                <p className="cardlist__single-card-title">
                  {subcategory.name}
                </p>
                <p className="cardlist__single-card-text">
                  {subcategory.activitiesCount +
                    ' ' +
                    declOfNum(
                      subcategory?.activitiesCount
                        ? subcategory?.activitiesCount
                        : 0,
                      ['предложение', 'предложения', 'предложений'],
                    )}
                </p>
              </div>
            </button>
          ))}
        </div>
        <div className="cardlist__wrapper-button">
          <div className="cardlist__button-container">
            <button className="cardlist__button" onClick={() => reserve()}>
              <p className="cardlist__button-text">
                Забронировать в приложении
              </p>
            </button>
          </div>
          <Divider className="divider__horizontal" />
        </div>
      </div>
      <QrCode modalActive={modalActive} setModalActive={setModalActive} />
    </div>
  );
};

export default CardList;
