import * as React from 'react';

import classNames from 'classnames';
import { addDays, format, startOfWeek } from 'date-fns';
import ru from 'date-fns/locale/ru';
import update from 'immutability-helper';

import MenuItem from './MenuItem';

interface IMenu {
  weekdays: number[];
  setWeekdays(weekdays: number[]): void;
}

const Menu: React.FC<IMenu> = ({ weekdays, setWeekdays }) => {
  const firstDOW = startOfWeek(new Date(), { weekStartsOn: 1 });
  const weekDaysList = Array.from(Array(7))?.map((e, i) =>
    format(addDays(firstDOW, i), 'EEEE', { locale: ru }),
  );

  const setCheckedValue = (value: boolean, index: number) => {
    let newWeekdays;
    if (value) newWeekdays = update(weekdays, { $push: [index] });
    else {
      const ixToDelete = weekdays.findIndex(val => val === index);
      newWeekdays = update(weekdays, { $splice: [[ixToDelete, 1]] });
    }
    setWeekdays(newWeekdays.sort());
  };

  return (
    <>
      {weekDaysList?.map((item, index) => (
        <div
          className={classNames(
            'weekdays-picker__menu-item normal-text',
            weekdays.includes(index) && 'checked',
          )}
        >
          <MenuItem
            index={index}
            checkedValue={weekdays.includes(index)}
            label={item}
            setCheckedValue={setCheckedValue}
          />
        </div>
      ))}
    </>
  );
};

export default Menu;
