import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ValueType } from 'react-select';

import Button from '../../../../components/Button';
import Dropdown from '../../../../components/Dropdown';
import ReturnLink from '../../../../components/ReturnLink';
import Switch from '../../../../components/Switch';
import { components } from '../../../../generated/apiTypes';
import {
  activityConfigurationRequest,
  getSubCategories,
  getSubLocations,
} from '../../../../services/apiRequests';
import { handleStorage } from '../../../../services/handleStorage';
import { ServiceInfo } from '../types';
import {
  getGroupSizeOptions,
  getPriceTypeOptions,
} from './GenericService/options';

import './GenericService/styles.scss';

interface IRouterProps {
  provider_id?: string;
  service_id?: string;
}

interface IStep1 extends RouteComponentProps<IRouterProps> {
  info: ServiceInfo;
  setInfo(info: ServiceInfo): void;
  newService?: boolean;
  resortOptions: { value: string; label: string }[];
  categoryOptions: { value: string; label: string }[];
  goToStep(step: 'FIRST' | 'SECOND'): void;
  categorySelectValue: ValueType<any, false> | undefined;
  categoryError: boolean;
  onChangeCategory(value: ValueType<any, false>): void;
  setCategoryError(val: boolean): void;
  onCancelClick(): void;
  setChildrenAllowance(checked: boolean): void;
  setActivityConfiguration(
    val: components['schemas']['ActivityConfiguration'],
  ): void;
}

const Step1: React.FC<IStep1> = ({
  newService,
  goToStep,
  info,
  setInfo,
  resortOptions,
  categoryOptions,
  categorySelectValue,
  categoryError,
  setCategoryError,
  onChangeCategory,
  history,
  match,
  setChildrenAllowance,
  setActivityConfiguration,
  onCancelClick,
}) => {
  const role = handleStorage.getRole();
  const provider_id = match.params.provider_id;
  const [subCategoriesOptions, setSubCategoriesOptions] = React.useState<
    { value: string; label: string }[]
  >([]);
  const [subLocationOptions, setSubLocationOptions] = React.useState<
    { value: string; label: string }[]
  >([]);
  const [priceTypeOptions, setPriceTypeOptions] = React.useState<
    { value: string; label: string; disabled: boolean }[]
  >(getPriceTypeOptions());
  const [subCategorySelectValue, setSubCategorySelectValue] = React.useState<
    ValueType<any, false> | undefined
  >(undefined);
  const [subLocationSelectValue, setSubLocationSelectValue] = React.useState<
    ValueType<any, false> | undefined
  >(undefined);
  const [resortSelectValue, setResortSelectValue] = React.useState<
    ValueType<any, false> | undefined
  >(undefined);
  const [groupSizeSelectValue, setGroupSizeSelectValue] = React.useState<
    ValueType<any, false> | undefined
  >(undefined);
  const [priceTypeSelectValue, setPriceTypeSelectValue] = React.useState<
    ValueType<any, false> | undefined
  >(undefined);
  const [subCategoryError, setSubCategoryError] =
    React.useState<boolean>(false);
  const [resortError, setResortError] = React.useState<boolean>(false);
  const [subLocationError, setSubLocationError] =
    React.useState<boolean>(false);
  const [groupSizeError, setGroupSizeError] = React.useState<boolean>(false);
  const [priceTypeError, setPriceTypeError] = React.useState<boolean>(false);
  const [isErrors, setIsErrors] = React.useState<boolean>(false);

  const getSubCategoriesOptions = async () => {
    const response = await getSubCategories(categorySelectValue?.value);
    if (response?.data) {
      const options = [];
      for (const item of response.data) {
        options.push({
          label: item?.name as string,
          value: (item?.id as number).toString(),
          tags: item.tags,
        });
      }
      setSubCategoriesOptions(options);
    }
  };

  const getSubLocationOptions = async () => {
    const response = await getSubLocations(resortSelectValue?.value);
    if (response?.data) {
      const options = [];
      for (const item of response.data) {
        options.push({
          label: item?.name as string,
          value: (item?.id as number).toString(),
          // disabled: true,
        });
      }
      setSubLocationOptions(options);
    }
  };

  const getActivityConfiguration = async () => {
    const response = await activityConfigurationRequest({
      subcategory: { id: parseInt(info.subCategories[0]?.value, 10) },
      priceType: info.summary?.priceType as
        | 'PER_PERSON'
        | 'PER_PERSON_PER_HOUR'
        | 'PER_GROUP',
    });
    if (response?.data) {
      setActivityConfiguration(response?.data);
    }
  };

  React.useEffect(() => {
    setSubCategorySelectValue('');
    if (categorySelectValue?.value) getSubCategoriesOptions();
  }, [categorySelectValue?.value]);

  React.useEffect(() => {
    setSubLocationSelectValue('');
    if (resortSelectValue?.value) getSubLocationOptions();
  }, [resortSelectValue?.value]);

  React.useEffect(() => {
    const subCategory = subCategoriesOptions?.find(
      item => item?.value === info?.subCategories[0]?.value,
    );
    if (subCategory?.value) setSubCategorySelectValue(subCategory);
  }, [info?.subCategories[0]?.value, subCategoriesOptions]);

  React.useEffect(() => {
    const resort = resortOptions?.find(
      item => item.value === info?.resort?.value,
    );
    if (resort) setResortSelectValue(resort);
  }, [info?.resort]);

  React.useEffect(() => {
    const subLocation = subLocationOptions?.find(
      item => item.value === info?.subLocations[0]?.value,
    );
    if (subLocation) setSubLocationSelectValue(subLocation);
  }, [info?.subLocations[0]?.value, subLocationOptions]);

  React.useEffect(() => {
    const size = getGroupSizeOptions()?.find(
      item => item.value === info?.summary?.groupType,
    );
    if (size?.value === 'GROUP') {
      const newOptions = getPriceTypeOptions();
      for (const item of newOptions) {
        if (item.value !== 'PER_PERSON') item.disabled = true;
      }
      setPriceTypeOptions(newOptions);
    }
    if (size) setGroupSizeSelectValue(size);
  }, [info?.summary?.groupType]);

  React.useEffect(() => {
    const type = getPriceTypeOptions()?.find(
      item => item.value === info?.summary?.priceType,
    );
    if (type) setPriceTypeSelectValue(type);
  }, [info?.summary?.priceType]);

  const onChangeSubCategory = (value: ValueType<any, false>) => {
    if (subCategoryError) setSubCategoryError(false);
    setSubCategorySelectValue(value);
    setInfo({
      ...info,
      subCategories: [value],
    });
  };

  const onChangeResort = (value: ValueType<any, false>) => {
    if (resortError) setResortError(false);
    setResortSelectValue(value);
    setInfo({
      ...info,
      resort: value,
      subLocations: [],
    });
  };

  const onChangeSubLocation = (value: ValueType<any, false>) => {
    if (subLocationError) setSubLocationError(false);
    setSubLocationSelectValue(value);
    setInfo({
      ...info,
      subLocations: [value],
    });
  };

  const onChangeGroupSize = (value: ValueType<any, false>) => {
    if (groupSizeError) setGroupSizeError(false);
    setGroupSizeSelectValue(value);
    setInfo({ ...info, summary: { ...info.summary, groupType: value.value } });
    if (value.value === 'GROUP') {
      const newOptions = getPriceTypeOptions();
      for (const item of newOptions) {
        if (item.value !== 'PER_PERSON') item.disabled = true;
      }
      setPriceTypeOptions(newOptions);
    } else setPriceTypeOptions(getPriceTypeOptions());
    setPriceTypeSelectValue('');
  };

  const onChangePriceType = (value: ValueType<any, false>) => {
    if (priceTypeError) setPriceTypeError(false);
    setPriceTypeSelectValue(value);
    setInfo({ ...info, summary: { ...info.summary, priceType: value.value } });
  };

  const validateFields = (): boolean => {
    let validateResult = true;
    if (
      categorySelectValue?.type !== 'PRIMITIVE' &&
      !subCategorySelectValue?.value
    ) {
      validateResult = false;
      setSubCategoryError(true);
    }
    if (!resortSelectValue?.value) {
      validateResult = false;
      setResortError(true);
    }
    if (subLocationOptions?.length > 0 && !subLocationSelectValue?.value) {
      validateResult = false;
      setSubLocationError(true);
    }

    if (
      categorySelectValue?.type !== 'PRIMITIVE' &&
      !groupSizeSelectValue?.value
    ) {
      validateResult = false;
      setGroupSizeError(true);
    }

    if (
      categorySelectValue?.type !== 'PRIMITIVE' &&
      !priceTypeSelectValue?.value
    ) {
      validateResult = false;
      setPriceTypeError(true);
    }
    return validateResult;
  };

  const goToNextStep = () => {
    if (isErrors) setIsErrors(false);
    if (
      categorySelectValue?.type === 'GENERIC' &&
      info.summary?.priceType &&
      info.subCategories[0]?.value
    )
      getActivityConfiguration();
    if (!categorySelectValue?.value) setCategoryError(true);
    else if (!['GENERIC', 'PRIMITIVE'].includes(categorySelectValue?.type))
      goToStep('SECOND');
    else if (validateFields()) goToStep('SECOND');
    else setIsErrors(true);
  };

  const redirectToHome = () => {
    if (role === 'ACTIVITY_PROVIDER') history.push('/provider');
    else if (role === 'OPERATOR')
      history.push(`/operator/provider=${provider_id}`);
  };

  return (
    <>
      {!newService ? (
        <ReturnLink
          linkName="К карточке услуги"
          onClickHandler={onCancelClick}
        />
      ) : (
        <ReturnLink
          linkName={
            role === 'ACTIVITY_PROVIDER' ? 'К списку услуг' : 'К поставщику'
          }
          onClickHandler={redirectToHome}
        />
      )}
      <div className="edit-service__title-row page__title title">
        {!newService ? 'Редактирование информации' : 'Создание новой услуги'}
      </div>
      <div className="edit-service__content">
        <>
          <div className="edit-info__item">
            {(newService || (!newService && categorySelectValue?.type)) && (
              <>
                <div className="view-info__item-title edit-service__category-title header1">
                  Категория
                </div>
                <div className="edit-service__select">
                  <Dropdown
                    placeholder="Выберите..."
                    options={categoryOptions}
                    menuWidth={'100%'}
                    value={categorySelectValue}
                    isSearchable={false}
                    onChange={onChangeCategory}
                    error={categoryError}
                  />
                </div>
              </>
            )}
            {categorySelectValue?.type === 'GENERIC' && (
              <>
                <div className="view-info__item-title edit-service__category-title step1__header2 header2">
                  Подкатегория
                </div>
                <div className="edit-service__select">
                  <Dropdown
                    placeholder="Выберите..."
                    options={subCategoriesOptions}
                    menuWidth={'100%'}
                    value={subCategorySelectValue}
                    isSearchable={false}
                    onChange={onChangeSubCategory}
                    error={subCategoryError}
                  />
                </div>{' '}
              </>
            )}
          </div>
          {['GENERIC', 'PRIMITIVE'].includes(categorySelectValue?.type) && (
            <>
              <div className="edit-info__item">
                <div className="view-info__item-title edit-service__category-title header1">
                  Локация
                </div>
                <div className="edit-service__select">
                  <Dropdown
                    placeholder="Выберите..."
                    options={resortOptions}
                    menuWidth={'100%'}
                    value={resortSelectValue}
                    isSearchable={false}
                    onChange={onChangeResort}
                    error={resortError}
                  />
                </div>
                {subLocationOptions.length > 0 && (
                  <>
                    <div className="view-info__item-title edit-service__category-title step1__header2 header2">
                      Место
                    </div>
                    <div className="edit-service__select">
                      <Dropdown
                        placeholder="Выберите..."
                        options={subLocationOptions}
                        menuWidth={'100%'}
                        value={subLocationSelectValue}
                        isSearchable={false}
                        onChange={onChangeSubLocation}
                        error={subLocationError}
                      />
                    </div>
                  </>
                )}
              </div>
              {categorySelectValue?.type !== 'PRIMITIVE' && (
                <div className="edit-info__item">
                  <div className="view-info__item-title edit-service__category-title header1">
                    Форма проведения
                  </div>
                  <div className="edit-service__select">
                    <Dropdown
                      placeholder="Выберите..."
                      options={getGroupSizeOptions()}
                      menuWidth={'100%'}
                      value={groupSizeSelectValue}
                      isSearchable={false}
                      onChange={onChangeGroupSize}
                      error={groupSizeError}
                    />
                  </div>
                </div>
              )}
              <div className="edit-info__item">
                <div className="view-info__item-title edit-service__children-title header1">
                  Дети (до 12 лет)
                </div>
                <div className="edit-service__children">
                  <div className="edit-service__child-option-wrapper">
                    <div className="edit-service__child-option normal-text">
                      Доступно для детей
                    </div>
                  </div>
                  <div className="edit-service__child-switch">
                    <Switch
                      checked={info.childrenAllowed}
                      handleChange={setChildrenAllowance}
                    />
                  </div>
                </div>
              </div>
              {categorySelectValue?.type !== 'PRIMITIVE' && (
                <div className="edit-info__item">
                  <div className="view-info__item-title edit-service__category-title header1">
                    Указать стоимость
                  </div>
                  <div className="edit-service__select">
                    <Dropdown
                      placeholder="Выберите..."
                      options={priceTypeOptions}
                      menuWidth={'100%'}
                      value={priceTypeSelectValue}
                      isSearchable={false}
                      onChange={onChangePriceType}
                      isOptionDisabled={option => (option as any)?.disabled}
                      error={priceTypeError}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
        <Button
          className="step1__next-btn"
          onClick={goToNextStep}
          mode="secondary"
          disabled={!newService && !categorySelectValue?.type}
        >
          Следующий шаг
        </Button>
        {isErrors && (
          <div className="login-page__error-message normal-text">
            Заполните все поля
          </div>
        )}
      </div>
    </>
  );
};

export default withRouter(Step1);
