import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import ApiErrorUI from '../../components/ApiErrorUI';
import Button from '../../components/Button';
import Layout from '../../components/Layout';
import PaginationRow from '../../components/PaginationRow';
import SearchInput from '../../components/SearchInput';
import { components } from '../../generated/apiTypes';
import { ReactComponent as AddIcon } from '../../images/Services/icn-add.svg';
import {
  getActivitiesByProviderId,
  getConnectedBotByProviderId,
  getMyActivities,
  getProviderById,
} from '../../services/apiRequests';
import { handleStorage } from '../../services/handleStorage';
import { selectApiError, setApiError } from '../../store/commonSlice';
import Service from './Service';

import './style.scss';
import '../style.scss';

interface IRouterProps {
  id: string;
}

const Index: React.FC<RouteComponentProps<IRouterProps>> = ({
  match,
  history,
}) => {
  const { pathname } = useLocation();
  const [searchInputValue, setSearchInputValue] = React.useState<string>('');
  const [tabIndex, setTabIndex] = React.useState(0);
  const [data, setData] =
    React.useState<components['schemas']['ActivitySummary'][]>();
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [maxResults, setMaxResults] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(0);
  const [provider, setProvider] =
    React.useState<components['schemas']['ActivityProviderView']>();
  const [telegramAccountName, setTelegramAccountName] =
    React.useState<string>();
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();
  const role = handleStorage.getRole();
  const token = handleStorage.getToken();
  const id = match.params.id;

  const getActivities = async () => {
    let group:
      | 'ALL'
      | 'ACTIVITY_VERIFICATION_PENDING'
      | 'RESERVATION_CREATED'
      | 'REVIEW_MODERATION_PENDING' = 'ALL';
    if (tabIndex === 1) group = 'RESERVATION_CREATED';
    else if (tabIndex === 2) group = 'ACTIVITY_VERIFICATION_PENDING';
    else if (tabIndex === 3) group = 'REVIEW_MODERATION_PENDING';
    const response = await getMyActivities(
      group,
      maxResults * page,
      searchInputValue,
    );
    if (response.data) {
      setData(response.data.results);
      setMaxResults(response.data.maxResults as number);
      setTotalPages(response.data.totalPages as number);
    }
  };

  const getProvider = async () => {
    const response = await getProviderById(id);
    if (response.data) {
      setProvider(response.data);
    }
  };

  const getBotStatus = async () => {
    const response = await getConnectedBotByProviderId(id);
    if (response?.data) {
      setTelegramAccountName(response.data?.name);
    } else {
      setTelegramAccountName('');
    }
  };

  const getActivitiesById = async () => {
    let group:
      | 'ALL'
      | 'ACTIVITY_VERIFICATION_PENDING'
      | 'RESERVATION_CREATED'
      | 'REVIEW_MODERATION_PENDING' = 'ALL';
    if (tabIndex === 1) group = 'RESERVATION_CREATED';
    else if (tabIndex === 2) group = 'ACTIVITY_VERIFICATION_PENDING';
    else if (tabIndex === 3) group = 'REVIEW_MODERATION_PENDING';

    const response = await getActivitiesByProviderId(
      id,
      group,
      maxResults * page,
      searchInputValue,
    );
    if (response.data) {
      setData(response.data.results);
      setMaxResults(response.data.maxResults as number);
      setTotalPages(response.data.totalPages as number);
    }
  };
  console.log('PATHNAME', pathname);
  React.useEffect(() => {
    if (
      pathname.includes('/provider') &&
      !pathname.includes('/operator') &&
      role === 'OPERATOR'
    ) {
      history.push('/operator');
    }
    if (!token) {
      history.push('/login');
    }
  }, []);

  React.useEffect(() => {
    if (role === 'OPERATOR' && id) {
      getProvider();
      getBotStatus();
    }
  }, []);

  React.useEffect(() => {
    setData(undefined);
    setTotalPages(0);
    if (role === 'ACTIVITY_PROVIDER') {
      getActivities();
    } else if (role === 'OPERATOR' && id) {
      getActivitiesById();
    }
    return () => {
      dispatch(
        setApiError({ isError: false, status: undefined, message: undefined }),
      );
    };
  }, [page]);

  React.useEffect(() => {
    setPage(0);
    if (page === 0) {
      setData(undefined);
      setTotalPages(0);
      if (role === 'ACTIVITY_PROVIDER') {
        getActivities();
      } else if (role === 'OPERATOR' && id) {
        getActivitiesById();
      }
    }
  }, [tabIndex, searchInputValue]);

  const onChangeSearchInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  const clearSearchInputValue = () => {
    setSearchInputValue('');
  };

  const redirectToCreateService = () => {
    if (role === 'ACTIVITY_PROVIDER') {
      history.push('/provider/create-service');
    } else {
      const path = `${history.location.pathname}/create-service`;
      history.push({ pathname: path });
    }
  };

  if (!token) {
    return null;
  }

  return (
    <Layout contentClassName="services__content">
      {apiError?.isError ? (
        <ApiErrorUI />
      ) : (
        <>
          <div>
            {role === 'OPERATOR' && (
              <>
                <div className="page__title title">
                  {`${provider?.account?.name ? provider?.account?.name : ''}${
                    provider?.supervisor ? ' (Супервайзер)' : ''
                  }`}
                </div>
                <div className="services__provider-info">
                  <div>
                    <div className="services__provider-info-item-title header1">
                      E-mail
                    </div>
                    <div className="services__provider-info-item normal-text">
                      {provider?.account?.email}
                    </div>
                  </div>
                  <div>
                    <div className="services__provider-info-item-title header1">
                      Номер телефона
                    </div>
                    <div className="services__provider-info-item normal-text">
                      {provider?.account?.phone !== undefined
                        ? `+${provider?.account?.phone}`
                        : ''}
                    </div>
                  </div>
                  <div>
                    <div className="services__provider-info-item-title header1">
                      Телеграм
                    </div>
                    <div className="services__provider-info-item normal-text">
                      {telegramAccountName !== undefined &&
                        (telegramAccountName ? (
                          <>
                            {'Подключены оповещения для телеграма'}{' '}
                            <span className="bold-item">
                              {telegramAccountName}
                            </span>
                          </>
                        ) : (
                          'Оповещения не подключены'
                        ))}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="page__title title">Список услуг</div>
            <div className="services__controls-row">
              <div className="services__search-input-wrapper">
                <SearchInput
                  onInput={onChangeSearchInputValue}
                  value={searchInputValue}
                  placeholder="Название услуги"
                  clearInputValue={clearSearchInputValue}
                />
              </div>
              <Button
                className="services__add-btn"
                onClick={redirectToCreateService}
              >
                <AddIcon className="services__add-btn-icon" />
                Добавить услугу
              </Button>
            </div>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={index => setTabIndex(index)}
              className="page__tabs"
            >
              <TabList className="page__tab-list">
                <Tab className="page__tab button-text">Все</Tab>
                <Tab className="page__tab button-text">
                  Новые&nbsp;бронирования
                </Tab>
                <Tab className="page__tab button-text">На&nbsp;модерации</Tab>
                {role === 'OPERATOR' && (
                  <Tab className="page__tab button-text">Новые&nbsp;отзывы</Tab>
                )}
              </TabList>
              <TabPanel>
                {data &&
                  data?.length > 0 &&
                  data?.map(item => (
                    <Service
                      key={item?.id?.toString() as string}
                      id={item?.id?.toString() as string}
                      type={item.category?.type}
                      name={
                        item.category?.type === 'EXPERT'
                          ? `${item?.expertFirstName} ${item?.expertLastName}. ${item?.category?.name}`
                          : (item?.name as string)
                      }
                      bookings={
                        item?.statistics?.numberOfReservations as number
                      }
                      numberOfWaitingForApprovalReservations={
                        item?.statistics
                          ?.numberOfWaitingForApprovalReservations as number
                      }
                      rating={item?.statistics?.rating?.toFixed(1) as string}
                      newReviews={0}
                      status={item.activeness as any}
                    />
                  ))}
                {data && data?.length === 0 && !searchInputValue && (
                  <div className="services__empty_message normal-text">
                    Не создано ни одной услуги
                  </div>
                )}
                {data && data?.length === 0 && searchInputValue && (
                  <div className="services__empty_message normal-text">
                    Не найдены услуги соответствующие данному запросу
                  </div>
                )}
                <PaginationRow
                  totalPages={totalPages}
                  setPage={setPage}
                  page={page}
                />
              </TabPanel>
              <TabPanel>
                {data &&
                  data?.length > 0 &&
                  data?.map(item => (
                    <Service
                      key={item?.id?.toString() as string}
                      id={item?.id?.toString() as string}
                      type={item.category?.type}
                      name={
                        item.category?.type === 'EXPERT'
                          ? `${item?.expertFirstName} ${item?.expertLastName}. ${item?.category?.name}`
                          : (item?.name as string)
                      }
                      bookings={
                        item?.statistics?.numberOfReservations as number
                      }
                      numberOfWaitingForApprovalReservations={
                        item?.statistics
                          ?.numberOfWaitingForApprovalReservations as number
                      }
                      rating={item?.statistics?.rating?.toFixed(1) as string}
                      newReviews={0}
                      status={item.activeness as any}
                    />
                  ))}
                {data && data?.length === 0 && (
                  <div className="services__empty_message normal-text">
                    Не найдены услуги соответствующие данному запросу
                  </div>
                )}
                <PaginationRow
                  totalPages={totalPages}
                  setPage={setPage}
                  page={page}
                />
              </TabPanel>
              {role === 'OPERATOR' && (
                <TabPanel>
                  {data &&
                    data?.length > 0 &&
                    data?.map(item => (
                      <Service
                        key={item?.id?.toString() as string}
                        id={item?.id?.toString() as string}
                        type={item.category?.type}
                        name={
                          item.category?.type === 'EXPERT'
                            ? `${item?.expertFirstName} ${item?.expertLastName}. ${item?.category?.name}`
                            : (item?.name as string)
                        }
                        bookings={
                          item?.statistics?.numberOfReservations as number
                        }
                        numberOfWaitingForApprovalReservations={
                          item?.statistics
                            ?.numberOfWaitingForApprovalReservations as number
                        }
                        rating={item?.statistics?.rating?.toFixed(1) as string}
                        newReviews={0}
                        status={item.activeness as any}
                      />
                    ))}
                  {data && data?.length === 0 && (
                    <div className="services__empty_message normal-text">
                      Не найдены услуги соответствующие данному запросу
                    </div>
                  )}
                  <PaginationRow
                    totalPages={totalPages}
                    setPage={setPage}
                    page={page}
                  />
                </TabPanel>
              )}
              <TabPanel>
                {data &&
                  data?.length > 0 &&
                  data?.map(item => (
                    <Service
                      key={item?.id?.toString() as string}
                      id={item?.id?.toString() as string}
                      type={item.category?.type}
                      name={
                        item.category?.type === 'EXPERT'
                          ? `${item?.expertFirstName} ${item?.expertLastName}. ${item?.category?.name}`
                          : (item?.name as string)
                      }
                      bookings={
                        item?.statistics?.numberOfReservations as number
                      }
                      numberOfWaitingForApprovalReservations={
                        item?.statistics
                          ?.numberOfWaitingForApprovalReservations as number
                      }
                      rating={item?.statistics?.rating?.toFixed(1) as string}
                      newReviews={0}
                      status={item.activeness as any}
                    />
                  ))}
                {data && data?.length === 0 && (
                  <div className="services__empty_message normal-text">
                    Не найдены услуги соответствующие данному запросу
                  </div>
                )}
                <PaginationRow
                  totalPages={totalPages}
                  setPage={setPage}
                  page={page}
                />
              </TabPanel>
            </Tabs>
          </div>
          <div className="services__add-btn-wrapper-xs">
            <Button
              className="services__add-btn-xs"
              onClick={redirectToCreateService}
            >
              <AddIcon className="services__add-btn-icon" />
              Добавить услугу
            </Button>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Index;
