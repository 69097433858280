import * as React from 'react';

import classNames from 'classnames';
import { addDays, format, startOfWeek } from 'date-fns';
import ru from 'date-fns/locale/ru';

import { ReactComponent as ArrowIcon } from '../../images/EditService/arrow-dropdown.svg';
import PopupMenuLayout from '../PopupMenuLayout';
import Menu from './Menu';

import './style.scss';

interface IWeekdaysPicker {
  weekdays: number[];
  setWeekdays(weekdays: number[]): void;
  disabled: boolean;
  error?: boolean;
}

const WeekdaysPicker: React.FC<IWeekdaysPicker> = ({
  weekdays,
  setWeekdays,
  disabled,
  error,
}) => {
  const controlRef = React.useRef(null);
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const firstDOW = startOfWeek(new Date(), { weekStartsOn: 1 });
  const shortWeekDays = weekdays?.map(e =>
    format(addDays(firstDOW, e), 'EEEEEE', { locale: ru }),
  );
  return (
    <div className={classNames('weekdays-picker__container')}>
      <button
        className={classNames('weekdays-picker__control-btn')}
        onClick={() => setActiveMenu(!activeMenu)}
        disabled={disabled}
      >
        <div
          className={classNames('weekdays-picker__control', { error })}
          ref={controlRef}
        >
          {shortWeekDays?.length > 0 ? (
            <span
              className={classNames(
                'weekdays-picker__control-value normal-text',
              )}
            >
              {shortWeekDays?.map((item, index, { length }) => {
                if (index !== length - 1) return `${item}, `;
                else return item;
              })}
            </span>
          ) : (
            <span className="weekdays-picker__control-value placeholder normal-text">
              Выберите...
            </span>
          )}
          <ArrowIcon className="weekdays-picker__arrow-icon" />
        </div>
      </button>
      {activeMenu && (
        <PopupMenuLayout
          className="weekdays-picker__menu-container"
          closeMenu={() => setActiveMenu(false)}
          controlRef={controlRef}
        >
          <Menu weekdays={weekdays} setWeekdays={setWeekdays} />
        </PopupMenuLayout>
      )}
    </div>
  );
};

export default WeekdaysPicker;
