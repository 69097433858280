import * as React from 'react';

function QrCodeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={386}
      height={386}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M386 0H0v386h386V0z" fill="#fff" />
      <path d="M28.593 14.296H14.296v14.297h14.297V14.296z" fill="#02AF9E" />
      <path
        d="M42.889 14.296H28.592v14.297H42.89V14.296zM57.185 14.296H42.89v14.297h14.296V14.296z"
        fill="#02AF9E"
      />
      <path d="M71.482 14.296H57.185v14.297h14.297V14.296z" fill="#02AF9E" />
      <path
        d="M85.778 14.296H71.48v14.297h14.297V14.296zM100.074 14.296H85.778v14.297h14.296V14.296zM114.37 14.296h-14.296v14.297h14.296V14.296zM142.963 14.296h-14.296v14.297h14.296V14.296zM157.259 14.296h-14.296v14.297h14.296V14.296zM171.556 14.296h-14.297v14.297h14.297V14.296zM185.852 14.296h-14.296v14.297h14.296V14.296zM228.741 14.296h-14.297v14.297h14.297V14.296zM257.333 14.296h-14.296v14.297h14.296V14.296zM285.926 14.296H271.63v14.297h14.296V14.296zM300.222 14.296h-14.296v14.297h14.296V14.296zM314.518 14.296h-14.296v14.297h14.296V14.296zM328.815 14.296h-14.296v14.297h14.296V14.296zM343.111 14.296h-14.296v14.297h14.296V14.296zM357.407 14.296h-14.296v14.297h14.296V14.296zM371.704 14.296h-14.297v14.297h14.297V14.296zM28.593 28.593H14.296v14.296h14.297V28.592z"
        fill="#02AF9E"
      />
      <path
        d="M114.37 28.593h-14.296v14.296h14.296V28.592zM185.852 28.593h-14.296v14.296h14.296V28.592zM200.148 28.593h-14.296v14.296h14.296V28.592zM257.333 28.593h-14.296v14.296h14.296V28.592zM285.926 28.593H271.63v14.296h14.296V28.592zM371.704 28.593h-14.297v14.296h14.297V28.592zM28.593 42.889H14.296v14.296h14.297V42.89zM57.185 42.889H42.89v14.296h14.296V42.89z"
        fill="#02AF9E"
      />
      <path d="M71.482 42.889H57.185v14.296h14.297V42.89z" fill="#02AF9E" />
      <path
        d="M85.778 42.889H71.48v14.296h14.297V42.89zM114.37 42.889h-14.296v14.296h14.296V42.89zM157.259 42.889h-14.296v14.296h14.296V42.89zM185.852 42.889h-14.296v14.296h14.296V42.89zM214.445 42.889h-14.297v14.296h14.297V42.89z"
        fill="#02AF9E"
      />
      <path
        d="M228.741 42.889h-14.297v14.296h14.297V42.89zM285.926 42.889H271.63v14.296h14.296V42.89zM314.518 42.889h-14.296v14.296h14.296V42.89zM328.815 42.889h-14.296v14.296h14.296V42.89zM343.111 42.889h-14.296v14.296h14.296V42.89zM371.704 42.889h-14.297v14.296h14.297V42.89zM28.593 57.185H14.296v14.297h14.297V57.185zM57.185 57.185H42.89v14.297h14.296V57.185z"
        fill="#02AF9E"
      />
      <path d="M71.482 57.185H57.185v14.297h14.297V57.185z" fill="#02AF9E" />
      <path
        d="M85.778 57.185H71.48v14.297h14.297V57.185zM114.37 57.185h-14.296v14.297h14.296V57.185zM171.556 57.185h-14.297v14.297h14.297V57.185zM185.852 57.185h-14.296v14.297h14.296V57.185zM214.445 57.185h-14.297v14.297h14.297V57.185zM243.037 57.185h-14.296v14.297h14.296V57.185zM257.333 57.185h-14.296v14.297h14.296V57.185zM285.926 57.185H271.63v14.297h14.296V57.185zM314.518 57.185h-14.296v14.297h14.296V57.185zM328.815 57.185h-14.296v14.297h14.296V57.185zM343.111 57.185h-14.296v14.297h14.296V57.185zM371.704 57.185h-14.297v14.297h14.297V57.185zM28.593 71.481H14.296v14.297h14.297V71.48zM57.185 71.481H42.89v14.297h14.296V71.48z"
        fill="#02AF9E"
      />
      <path d="M71.482 71.481H57.185v14.297h14.297V71.48z" fill="#02AF9E" />
      <path
        d="M85.778 71.481H71.48v14.297h14.297V71.48zM114.37 71.481h-14.296v14.297h14.296V71.48zM171.556 71.481h-14.297v14.297h14.297V71.48zM185.852 71.481h-14.296v14.297h14.296V71.48zM200.148 71.481h-14.296v14.297h14.296V71.48zM228.741 71.481h-14.297v14.297h14.297V71.48zM257.333 71.481h-14.296v14.297h14.296V71.48zM285.926 71.481H271.63v14.297h14.296V71.48zM314.518 71.481h-14.296v14.297h14.296V71.48zM328.815 71.481h-14.296v14.297h14.296V71.48zM343.111 71.481h-14.296v14.297h14.296V71.48zM371.704 71.481h-14.297v14.297h14.297V71.48zM28.593 85.778H14.296v14.296h14.297V85.778zM114.37 85.778h-14.296v14.296h14.296V85.778zM157.259 85.778h-14.296v14.296h14.296V85.778zM185.852 85.778h-14.296v14.296h14.296V85.778zM214.445 85.778h-14.297v14.296h14.297V85.778zM257.333 85.778h-14.296v14.296h14.296V85.778zM285.926 85.778H271.63v14.296h14.296V85.778zM371.704 85.778h-14.297v14.296h14.297V85.778z"
        fill="#02AF9E"
      />
      <path d="M28.593 100.074H14.296v14.296h14.297v-14.296z" fill="#02AF9E" />
      <path
        d="M42.889 100.074H28.592v14.296H42.89v-14.296zM57.185 100.074H42.89v14.296h14.296v-14.296z"
        fill="#02AF9E"
      />
      <path d="M71.482 100.074H57.185v14.296h14.297v-14.296z" fill="#02AF9E" />
      <path
        d="M85.778 100.074H71.48v14.296h14.297v-14.296zM100.074 100.074H85.778v14.296h14.296v-14.296zM114.37 100.074h-14.296v14.296h14.296v-14.296zM142.963 100.074h-14.296v14.296h14.296v-14.296zM171.556 100.074h-14.297v14.296h14.297v-14.296zM200.148 100.074h-14.296v14.296h14.296v-14.296zM228.741 100.074h-14.297v14.296h14.297v-14.296zM257.333 100.074h-14.296v14.296h14.296v-14.296zM285.926 100.074H271.63v14.296h14.296v-14.296zM300.222 100.074h-14.296v14.296h14.296v-14.296zM314.518 100.074h-14.296v14.296h14.296v-14.296zM328.815 100.074h-14.296v14.296h14.296v-14.296zM343.111 100.074h-14.296v14.296h14.296v-14.296zM357.407 100.074h-14.296v14.296h14.296v-14.296zM371.704 100.074h-14.297v14.296h14.297v-14.296zM142.963 114.37h-14.296v14.297h14.296V114.37zM157.259 114.37h-14.296v14.297h14.296V114.37zM200.148 114.37h-14.296v14.297h14.296V114.37zM214.445 114.37h-14.297v14.297h14.297V114.37z"
        fill="#02AF9E"
      />
      <path
        d="M228.741 114.37h-14.297v14.297h14.297V114.37zM257.333 114.37h-14.296v14.297h14.296V114.37zM28.593 128.667H14.296v14.296h14.297v-14.296z"
        fill="#02AF9E"
      />
      <path
        d="M42.889 128.667H28.592v14.296H42.89v-14.296zM71.482 128.667H57.185v14.296h14.297v-14.296z"
        fill="#02AF9E"
      />
      <path
        d="M85.778 128.667H71.48v14.296h14.297v-14.296zM114.37 128.667h-14.296v14.296h14.296v-14.296zM157.259 128.667h-14.296v14.296h14.296v-14.296zM200.148 128.667h-14.296v14.296h14.296v-14.296zM228.741 128.667h-14.297v14.296h14.297v-14.296zM243.037 128.667h-14.296v14.296h14.296v-14.296zM285.926 128.667H271.63v14.296h14.296v-14.296zM371.704 128.667h-14.297v14.296h14.297v-14.296zM28.593 142.963H14.296v14.296h14.297v-14.296zM100.074 142.963H85.778v14.296h14.296v-14.296zM128.667 142.963H114.37v14.296h14.297v-14.296zM142.963 142.963h-14.296v14.296h14.296v-14.296zM171.556 142.963h-14.297v14.296h14.297v-14.296zM228.741 142.963h-14.297v14.296h14.297v-14.296zM243.037 142.963h-14.296v14.296h14.296v-14.296zM300.222 142.963h-14.296v14.296h14.296v-14.296zM314.518 142.963h-14.296v14.296h14.296v-14.296zM328.815 142.963h-14.296v14.296h14.296v-14.296zM343.111 142.963h-14.296v14.296h14.296v-14.296zM357.407 142.963h-14.296v14.296h14.296v-14.296zM42.889 157.259H28.592v14.297H42.89v-14.297zM71.482 157.259H57.185v14.297h14.297v-14.297z"
        fill="#02AF9E"
      />
      <path
        d="M85.778 157.259H71.48v14.297h14.297v-14.297zM100.074 157.259H85.778v14.297h14.296v-14.297zM114.37 157.259h-14.296v14.297h14.296v-14.297zM142.963 157.259h-14.296v14.297h14.296v-14.297zM157.259 157.259h-14.296v14.297h14.296v-14.297zM200.148 157.259h-14.296v14.297h14.296v-14.297zM214.445 157.259h-14.297v14.297h14.297v-14.297z"
        fill="#02AF9E"
      />
      <path
        d="M228.741 157.259h-14.297v14.297h14.297v-14.297zM243.037 157.259h-14.296v14.297h14.296v-14.297zM285.926 157.259H271.63v14.297h14.296v-14.297zM328.815 157.259h-14.296v14.297h14.296v-14.297zM371.704 157.259h-14.297v14.297h14.297v-14.297zM28.593 171.556H14.296v14.296h14.297v-14.296zM85.778 171.556H71.48v14.296h14.297v-14.296zM128.667 171.556H114.37v14.296h14.297v-14.296zM142.963 171.556h-14.296v14.296h14.296v-14.296zM228.741 171.556h-14.297v14.296h14.297v-14.296zM243.037 171.556h-14.296v14.296h14.296v-14.296zM285.926 171.556H271.63v14.296h14.296v-14.296zM300.222 171.556h-14.296v14.296h14.296v-14.296zM314.518 171.556h-14.296v14.296h14.296v-14.296zM328.815 171.556h-14.296v14.296h14.296v-14.296zM343.111 171.556h-14.296v14.296h14.296v-14.296zM357.407 171.556h-14.296v14.296h14.296v-14.296zM371.704 171.556h-14.297v14.296h14.297v-14.296zM28.593 185.852H14.296v14.296h14.297v-14.296zM57.185 185.852H42.89v14.296h14.296v-14.296zM85.778 185.852H71.48v14.296h14.297v-14.296zM114.37 185.852h-14.296v14.296h14.296v-14.296zM128.667 185.852H114.37v14.296h14.297v-14.296zM157.259 185.852h-14.296v14.296h14.296v-14.296zM171.556 185.852h-14.297v14.296h14.297v-14.296zM257.333 185.852h-14.296v14.296h14.296v-14.296zM285.926 185.852H271.63v14.296h14.296v-14.296zM300.222 185.852h-14.296v14.296h14.296v-14.296zM371.704 185.852h-14.297v14.296h14.297v-14.296zM28.593 200.148H14.296v14.297h14.297v-14.297z"
        fill="#02AF9E"
      />
      <path
        d="M42.889 200.148H28.592v14.297H42.89v-14.297zM142.963 200.148h-14.296v14.297h14.296v-14.297zM171.556 200.148h-14.297v14.297h14.297v-14.297zM200.148 200.148h-14.296v14.297h14.296v-14.297zM214.445 200.148h-14.297v14.297h14.297v-14.297zM243.037 200.148h-14.296v14.297h14.296v-14.297zM314.518 200.148h-14.296v14.297h14.296v-14.297zM357.407 200.148h-14.296v14.297h14.296v-14.297z"
        fill="#02AF9E"
      />
      <path d="M28.593 214.444H14.296v14.297h14.297v-14.297z" fill="#02AF9E" />
      <path
        d="M42.889 214.444H28.592v14.297H42.89v-14.297zM71.482 214.444H57.185v14.297h14.297v-14.297zM114.37 214.444h-14.296v14.297h14.296v-14.297zM128.667 214.444H114.37v14.297h14.297v-14.297zM142.963 214.444h-14.296v14.297h14.296v-14.297zM171.556 214.444h-14.297v14.297h14.297v-14.297zM243.037 214.444h-14.296v14.297h14.296v-14.297zM271.63 214.444h-14.297v14.297h14.297v-14.297zM314.518 214.444h-14.296v14.297h14.296v-14.297zM328.815 214.444h-14.296v14.297h14.296v-14.297zM343.111 214.444h-14.296v14.297h14.296v-14.297zM357.407 214.444h-14.296v14.297h14.296v-14.297zM371.704 214.444h-14.297v14.297h14.297v-14.297zM28.593 228.741H14.296v14.296h14.297v-14.296zM57.185 228.741H42.89v14.296h14.296v-14.296zM142.963 228.741h-14.296v14.296h14.296v-14.296zM157.259 228.741h-14.296v14.296h14.296v-14.296zM171.556 228.741h-14.297v14.296h14.297v-14.296zM200.148 228.741h-14.296v14.296h14.296v-14.296zM243.037 228.741h-14.296v14.296h14.296v-14.296zM257.333 228.741h-14.296v14.296h14.296v-14.296zM285.926 228.741H271.63v14.296h14.296v-14.296zM300.222 228.741h-14.296v14.296h14.296v-14.296zM343.111 228.741h-14.296v14.296h14.296v-14.296zM371.704 228.741h-14.297v14.296h14.297v-14.296zM28.593 243.037H14.296v14.296h14.297v-14.296zM71.482 243.037H57.185v14.296h14.297v-14.296zM100.074 243.037H85.778v14.296h14.296v-14.296zM114.37 243.037h-14.296v14.296h14.296v-14.296zM142.963 243.037h-14.296v14.296h14.296v-14.296zM157.259 243.037h-14.296v14.296h14.296v-14.296zM185.852 243.037h-14.296v14.296h14.296v-14.296zM200.148 243.037h-14.296v14.296h14.296v-14.296zM214.445 243.037h-14.297v14.296h14.297v-14.296zM257.333 243.037h-14.296v14.296h14.296v-14.296z"
        fill="#02AF9E"
      />
      <path
        d="M271.63 243.037h-14.297v14.296h14.297v-14.296zM285.926 243.037H271.63v14.296h14.296v-14.296zM300.222 243.037h-14.296v14.296h14.296v-14.296zM314.518 243.037h-14.296v14.296h14.296v-14.296zM343.111 243.037h-14.296v14.296h14.296v-14.296zM357.407 243.037h-14.296v14.296h14.296v-14.296zM142.963 257.333h-14.296v14.297h14.296v-14.297zM157.259 257.333h-14.296v14.297h14.296v-14.297zM171.556 257.333h-14.297v14.297h14.297v-14.297zM185.852 257.333h-14.296v14.297h14.296v-14.297zM228.741 257.333h-14.297v14.297h14.297v-14.297zM257.333 257.333h-14.296v14.297h14.296v-14.297zM314.518 257.333h-14.296v14.297h14.296v-14.297zM357.407 257.333h-14.296v14.297h14.296v-14.297zM28.593 271.63H14.296v14.296h14.297V271.63z"
        fill="#02AF9E"
      />
      <path
        d="M42.889 271.63H28.592v14.296H42.89V271.63zM57.185 271.63H42.89v14.296h14.296V271.63z"
        fill="#02AF9E"
      />
      <path d="M71.482 271.63H57.185v14.296h14.297V271.63z" fill="#02AF9E" />
      <path
        d="M85.778 271.63H71.48v14.296h14.297V271.63zM100.074 271.63H85.778v14.296h14.296V271.63zM114.37 271.63h-14.296v14.296h14.296V271.63zM157.259 271.63h-14.296v14.296h14.296V271.63zM171.556 271.63h-14.297v14.296h14.297V271.63zM200.148 271.63h-14.296v14.296h14.296V271.63zM214.445 271.63h-14.297v14.296h14.297V271.63z"
        fill="#02AF9E"
      />
      <path
        d="M228.741 271.63h-14.297v14.296h14.297V271.63zM257.333 271.63h-14.296v14.296h14.296V271.63zM285.926 271.63H271.63v14.296h14.296V271.63zM314.518 271.63h-14.296v14.296h14.296V271.63zM328.815 271.63h-14.296v14.296h14.296V271.63zM371.704 271.63h-14.297v14.296h14.297V271.63zM28.593 285.926H14.296v14.296h14.297v-14.296zM114.37 285.926h-14.296v14.296h14.296v-14.296zM157.259 285.926h-14.296v14.296h14.296v-14.296zM185.852 285.926h-14.296v14.296h14.296v-14.296zM214.445 285.926h-14.297v14.296h14.297v-14.296z"
        fill="#02AF9E"
      />
      <path
        d="M228.741 285.926h-14.297v14.296h14.297v-14.296zM243.037 285.926h-14.296v14.296h14.296v-14.296zM257.333 285.926h-14.296v14.296h14.296v-14.296zM314.518 285.926h-14.296v14.296h14.296v-14.296zM371.704 285.926h-14.297v14.296h14.297v-14.296zM28.593 300.222H14.296v14.296h14.297v-14.296zM57.185 300.222H42.89v14.296h14.296v-14.296z"
        fill="#02AF9E"
      />
      <path d="M71.482 300.222H57.185v14.296h14.297v-14.296z" fill="#02AF9E" />
      <path
        d="M85.778 300.222H71.48v14.296h14.297v-14.296zM114.37 300.222h-14.296v14.296h14.296v-14.296zM142.963 300.222h-14.296v14.296h14.296v-14.296zM185.852 300.222h-14.296v14.296h14.296v-14.296zM200.148 300.222h-14.296v14.296h14.296v-14.296zM243.037 300.222h-14.296v14.296h14.296v-14.296zM257.333 300.222h-14.296v14.296h14.296v-14.296z"
        fill="#02AF9E"
      />
      <path
        d="M271.63 300.222h-14.297v14.296h14.297v-14.296zM285.926 300.222H271.63v14.296h14.296v-14.296zM300.222 300.222h-14.296v14.296h14.296v-14.296zM314.518 300.222h-14.296v14.296h14.296v-14.296zM328.815 300.222h-14.296v14.296h14.296v-14.296zM28.593 314.519H14.296v14.296h14.297v-14.296zM57.185 314.519H42.89v14.296h14.296v-14.296z"
        fill="#02AF9E"
      />
      <path d="M71.482 314.519H57.185v14.296h14.297v-14.296z" fill="#02AF9E" />
      <path
        d="M85.778 314.519H71.48v14.296h14.297v-14.296zM114.37 314.519h-14.296v14.296h14.296v-14.296zM142.963 314.519h-14.296v14.296h14.296v-14.296zM157.259 314.519h-14.296v14.296h14.296v-14.296zM200.148 314.519h-14.296v14.296h14.296v-14.296zM214.445 314.519h-14.297v14.296h14.297v-14.296zM271.63 314.519h-14.297v14.296h14.297v-14.296zM285.926 314.519H271.63v14.296h14.296v-14.296zM328.815 314.519h-14.296v14.296h14.296v-14.296zM357.407 314.519h-14.296v14.296h14.296v-14.296zM371.704 314.519h-14.297v14.296h14.297v-14.296zM28.593 328.815H14.296v14.296h14.297v-14.296zM57.185 328.815H42.89v14.296h14.296v-14.296z"
        fill="#02AF9E"
      />
      <path d="M71.482 328.815H57.185v14.296h14.297v-14.296z" fill="#02AF9E" />
      <path
        d="M85.778 328.815H71.48v14.296h14.297v-14.296zM114.37 328.815h-14.296v14.296h14.296v-14.296zM157.259 328.815h-14.296v14.296h14.296v-14.296zM185.852 328.815h-14.296v14.296h14.296v-14.296zM200.148 328.815h-14.296v14.296h14.296v-14.296zM228.741 328.815h-14.297v14.296h14.297v-14.296zM257.333 328.815h-14.296v14.296h14.296v-14.296z"
        fill="#02AF9E"
      />
      <path
        d="M271.63 328.815h-14.297v14.296h14.297v-14.296zM300.222 328.815h-14.296v14.296h14.296v-14.296zM314.518 328.815h-14.296v14.296h14.296v-14.296zM343.111 328.815h-14.296v14.296h14.296v-14.296zM357.407 328.815h-14.296v14.296h14.296v-14.296zM371.704 328.815h-14.297v14.296h14.297v-14.296zM28.593 343.111H14.296v14.296h14.297v-14.296zM114.37 343.111h-14.296v14.296h14.296v-14.296zM142.963 343.111h-14.296v14.296h14.296v-14.296zM171.556 343.111h-14.297v14.296h14.297v-14.296zM185.852 343.111h-14.296v14.296h14.296v-14.296zM200.148 343.111h-14.296v14.296h14.296v-14.296zM271.63 343.111h-14.297v14.296h14.297v-14.296zM300.222 343.111h-14.296v14.296h14.296v-14.296zM314.518 343.111h-14.296v14.296h14.296v-14.296zM343.111 343.111h-14.296v14.296h14.296v-14.296zM357.407 343.111h-14.296v14.296h14.296v-14.296zM371.704 343.111h-14.297v14.296h14.297v-14.296zM28.593 357.407H14.296v14.297h14.297v-14.297z"
        fill="#02AF9E"
      />
      <path
        d="M42.889 357.407H28.592v14.297H42.89v-14.297zM57.185 357.407H42.89v14.297h14.296v-14.297z"
        fill="#02AF9E"
      />
      <path d="M71.482 357.407H57.185v14.297h14.297v-14.297z" fill="#02AF9E" />
      <path
        d="M85.778 357.407H71.48v14.297h14.297v-14.297zM100.074 357.407H85.778v14.297h14.296v-14.297zM114.37 357.407h-14.296v14.297h14.296v-14.297zM142.963 357.407h-14.296v14.297h14.296v-14.297zM257.333 357.407h-14.296v14.297h14.296v-14.297zM285.926 357.407H271.63v14.297h14.296v-14.297zM328.815 357.407h-14.296v14.297h14.296v-14.297zM371.704 357.407h-14.297v14.297h14.297v-14.297z"
        fill="#02AF9E"
      />
    </svg>
  );
}

export default QrCodeIcon;
