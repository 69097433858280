import * as React from 'react';

import classNames from 'classnames';

import './style.scss';

interface IEditableDiv extends React.HTMLAttributes<HTMLDivElement> {
  value: string;
  saveValue?(value: string): void;
  singleLine?: boolean;
  divClassName?: string;
  error?: boolean;
  resetError?(): void;
  disabled?: boolean;
}

const EditableDiv: React.FC<IEditableDiv> = ({
  value,
  saveValue,
  singleLine,
  divClassName,
  error,
  resetError,
  disabled,
  ...props
}) => {
  const divRef = React.useRef<HTMLDivElement>(null);

  const onBlurHandler = async () => {
    if (divRef?.current && saveValue) {
      saveValue(divRef.current.innerText);
    }
  };

  const onInputHandler = () => {
    if (resetError) resetError();
  };

  return (
    <div
      className={classNames(
        'editable-div normal-text',
        singleLine && 'single-line',
        divClassName,
        { error },
        { disabled },
      )}
      ref={divRef}
      contentEditable={!disabled}
      onBlur={onBlurHandler}
      onInput={onInputHandler}
      {...props}
    >
      {value}
    </div>
  );
};

export default EditableDiv;
