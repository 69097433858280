import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import classNames from 'classnames';

import { components } from '../../../../generated/apiTypes';
import { ReactComponent as ApprovedStatusIcon } from '../../../../images/Booking/icn-status-approved.svg';
import { ReactComponent as CanceledStatusIcon } from '../../../../images/Booking/icn-status-canceled.svg';
import { ReactComponent as CompletedStatusIcon } from '../../../../images/Booking/icn-status-completed.svg';
import { ReactComponent as CreatedStatusIcon } from '../../../../images/Booking/icn-status-created.svg';
import { ReactComponent as ReviewStatusIcon } from '../../../../images/Booking/icn-status-review.svg';
import { ReactComponent as ArrowIcon } from '../../../../images/Service/BookingsList/icn-arrow.svg';
import { ReactComponent as StarIcon } from '../../../../images/Service/Reviews/icn-star.svg';
import { ReactComponent as FilledStarIcon } from '../../../../images/Service/Reviews/icn-star-filled.svg';
import { handleStorage } from '../../../../services/handleStorage';

import './style.scss';

interface IBooking extends RouteComponentProps {
  id: string;
  serialNumber: string;
  duration: number | undefined;
  time: string;
  date: string;
  amount: number;
  amountAdults: number;
  amountChildren: number;
  allDayReservation: boolean;
  resort: string;
  subcategory?: string;
  cost?: components['schemas']['Cost'];
  review?: components['schemas']['ReviewView'];
  customerStatus:
    | 'CREATED'
    | 'APPROVED'
    | 'REJECTED'
    | 'CANCELED_BY_CUSTOMER'
    | 'CANCELED_BY_PROVIDER'
    | 'REVIEW'
    | 'NOT_PROVIDED'
    | 'COMPLETED'
    | undefined;
  providerStatus:
    | 'CREATED'
    | 'APPROVED'
    | 'REJECTED'
    | 'CANCELED_BY_CUSTOMER'
    | 'CANCELED_BY_PROVIDER'
    | 'REVIEW'
    | 'NOT_PROVIDED'
    | 'COMPLETED'
    | undefined;
  type:
    | 'EXPERT'
    | 'RENTAL'
    | 'ACTIVITY'
    | 'TRANSFER'
    | 'GENERIC'
    | 'PRIMITIVE'
    | undefined;
}

const Index: React.FC<IBooking> = ({
  id,
  serialNumber,
  duration,
  time,
  amount,
  amountAdults,
  amountChildren,
  customerStatus,
  providerStatus,
  history,
  resort,
  date,
  review,
  cost,
  subcategory,
  type,
  allDayReservation,
}) => {
  const role = handleStorage.getRole();

  const onBookingClick = () => {
    let path = '';
    if (role === 'ACTIVITY_PROVIDER') {
      path = `/provider/booking=${id}`;
    } else if (role === 'OPERATOR') {
      path = `/operator/booking=${id}`;
    }
    if (path) history.push(path);
  };

  const getProviderStatus = (
    status:
      | 'CREATED'
      | 'APPROVED'
      | 'REJECTED'
      | 'CANCELED_BY_CUSTOMER'
      | 'CANCELED_BY_PROVIDER'
      | 'REVIEW'
      | 'NOT_PROVIDED'
      | 'COMPLETED'
      | undefined,
  ): React.ReactElement => {
    switch (status) {
      case 'CREATED':
        return (
          <>
            <CreatedStatusIcon />
            Ожидает подтверждения
          </>
        );
      case 'APPROVED':
        return (
          <>
            <ApprovedStatusIcon />
            Подтверждено
          </>
        );
      case 'REJECTED':
        return (
          <>
            <CanceledStatusIcon />
            Отклонено
          </>
        );
      case 'CANCELED_BY_CUSTOMER':
        return (
          <>
            <CanceledStatusIcon />
            Отменено клиентом
          </>
        );
      case 'CANCELED_BY_PROVIDER':
        return (
          <>
            <CanceledStatusIcon />
            Отменено
          </>
        );
      case 'REVIEW':
        return (
          <>
            <ReviewStatusIcon />
            Ожидает отзыв поставщика
          </>
        );
      case 'NOT_PROVIDED':
        return (
          <>
            <CanceledStatusIcon />
            Поставщик: Услуга не оказана
          </>
        );
      case 'COMPLETED':
        return (
          <>
            <CompletedStatusIcon />
            Поставщик: Завершено
          </>
        );
      case undefined:
        return <></>;
    }
  };

  const getCustomerStatus = (
    status:
      | 'CREATED'
      | 'APPROVED'
      | 'REJECTED'
      | 'CANCELED_BY_CUSTOMER'
      | 'CANCELED_BY_PROVIDER'
      | 'REVIEW'
      | 'NOT_PROVIDED'
      | 'COMPLETED'
      | undefined,
  ): React.ReactElement => {
    switch (status) {
      case 'CREATED':
        return (
          <>
            <CreatedStatusIcon />
            Ожидает подтверждения
          </>
        );
      case 'APPROVED':
        return (
          <>
            <ApprovedStatusIcon />
            Подтверждено
          </>
        );
      case 'REJECTED':
        return (
          <>
            <CanceledStatusIcon />
            Отклонено
          </>
        );
      case 'CANCELED_BY_CUSTOMER':
        return (
          <>
            <CanceledStatusIcon />
            Отменено клиентом
          </>
        );
      case 'CANCELED_BY_PROVIDER':
        return (
          <>
            <CanceledStatusIcon />
            Отменено поставщиком
          </>
        );
      case 'REVIEW':
        return (
          <>
            <ReviewStatusIcon />
            Ожидает отзыв клиента
          </>
        );
      case 'NOT_PROVIDED':
        return (
          <>
            <CanceledStatusIcon />
            Клиент: Услуга не оказана
          </>
        );
      case 'COMPLETED':
        return (
          <>
            <CompletedStatusIcon />
            Клиент: Завершено
          </>
        );
      case undefined:
        return <></>;
    }
  };

  return (
    <button
      className="booking__container"
      type="button"
      onClick={onBookingClick}
    >
      <div className="booking__id-row header2">
        {serialNumber}
        <div className="booking__status-container">
          <div className="booking__statuses">
            {role === 'OPERATOR' && customerStatus !== providerStatus && (
              <div
                className={classNames(
                  'booking__status customer-status label-text',
                  customerStatus?.toString().toLowerCase(),
                )}
              >
                {getCustomerStatus(customerStatus)}
              </div>
            )}
            <div
              className={classNames(
                'booking__status label-text',
                providerStatus?.toString().toLowerCase(),
              )}
            >
              {getProviderStatus(providerStatus)}
            </div>
          </div>
          <ArrowIcon />
        </div>
      </div>
      <div className="booking__stars-row">
        {review?.rating && (
          <div className="booking__details-container-row">
            <div className="review__stars">
              {review?.rating >= 1 ? <FilledStarIcon /> : <StarIcon />}
              {review?.rating / 2 >= 1 ? <FilledStarIcon /> : <StarIcon />}
              {review?.rating / 3 >= 1 ? <FilledStarIcon /> : <StarIcon />}
              {review?.rating / 4 >= 1 ? <FilledStarIcon /> : <StarIcon />}
              {review?.rating / 5 >= 1 ? <FilledStarIcon /> : <StarIcon />}
            </div>
          </div>
        )}
      </div>
      <div className="booking__details-container">
        <div className="booking__details-container-row">
          <div className="booking__details normal-text">
            {`${resort} •`}&nbsp;
          </div>
          <div className="booking__details normal-text">{`${date}`}&nbsp;</div>
          {type !== 'RENTAL' && (
            <div className="booking__details normal-text">{` • ${time}`}</div>
          )}
        </div>
        <div className="booking__details-container-row">
          {duration && (
            <div className="booking__details normal-text">
              {allDayReservation
                ? `${duration}+ ч (весь день) •`
                : `${duration} ч •`}
              &nbsp;
            </div>
          )}
          {type !== 'RENTAL' && (
            <div className="booking__details normal-text">
              {amountAdults && amountAdults > 0 ? `${amountAdults} взр.` : ''}
              {amountChildren > 0 && amountAdults > 0 ? ' ' : ''}
              {amountChildren && amountChildren > 0
                ? `${amountChildren} реб.`
                : ''}
              &nbsp;
            </div>
          )}
          {subcategory && (
            <div className="booking__details normal-text">
              {`• ${subcategory}`}&nbsp;
            </div>
          )}
          {cost?.paymentToProvider && (
            <div className="booking__details normal-text">
              {`• ${cost?.paymentToProvider} руб`}
            </div>
          )}
        </div>
      </div>
      <div className="booking__details-container-xs">
        <div className="booking__details-xs-row">
          <div className="booking__details normal-text">{`${resort}`}</div>
        </div>
        <div className="booking__details-xs-row">
          <div className="booking__details normal-text">{`${date}`}&nbsp;</div>
          {type !== 'RENTAL' && type !== 'PRIMITIVE' && (
            <div className="booking__details normal-text">{` • ${time}`}</div>
          )}
        </div>
        <div className="booking__details-xs-row">
          {duration && (
            <div className="booking__details normal-text">
              {allDayReservation
                ? `${duration}+ ч (весь день) •`
                : `${duration} ч •`}
              &nbsp;
            </div>
          )}
          {type !== 'RENTAL' && (
            <div className="booking__details normal-text">
              {amountAdults && amountAdults > 0 ? `${amountAdults} взр.` : ''}
              {amountChildren > 0 && amountAdults > 0 ? ' ' : ''}
              {amountChildren && amountChildren > 0
                ? `${amountChildren} реб.`
                : ''}
              &nbsp;
            </div>
          )}
          {subcategory && (
            <div className="booking__details normal-text">
              {`• ${subcategory}`}&nbsp;
            </div>
          )}
        </div>
        {cost?.paymentToProvider && (
          <div className="booking__details-xs-row">
            <div className="booking__details normal-text">{`${cost?.paymentToProvider} руб`}</div>
          </div>
        )}
      </div>
      <div className="booking__statuses-xs">
        {role === 'OPERATOR' && customerStatus !== providerStatus && (
          <div
            className={classNames(
              'booking__status booking__statuses-xs-status customer-status label-text',
              customerStatus?.toString().toLowerCase(),
            )}
          >
            {getCustomerStatus(customerStatus)}
          </div>
        )}
        <div
          className={classNames(
            'booking__status booking__statuses-xs-status label-text',
            providerStatus?.toString().toLowerCase(),
          )}
        >
          {getProviderStatus(providerStatus)}
        </div>
      </div>
    </button>
  );
};

export default withRouter(Index);
