import * as React from 'react';

import classNames from 'classnames';

import Button from '../../components/Button';
import { components } from '../../generated/apiTypes';
import { ReactComponent as ChangePasswordIcon } from '../../images/Account/icn-change-password.svg';
import { ReactComponent as ExitIcon } from '../../images/Account/icn-exit.svg';
import {
  disconnectBot,
  getConnectedBot,
  requestBotConnection,
} from '../../services/apiRequests';
import { handleStorage } from '../../services/handleStorage';
import CountDownTimer from './CountDownTimer';
import { accountState } from './types';

import './style.scss';

interface IAccountView {
  accountData: components['schemas']['AccountView'];
  setAccountState(state: accountState): void;
  serviceChargePercentage: number | undefined;
  supervisor?: boolean;
  logout(): void;
}

const AccountView: React.FC<IAccountView> = ({
  accountData,
  setAccountState,
  logout,
  serviceChargePercentage,
  supervisor,
}) => {
  const [telegramState, setTelegramState] = React.useState<
    'INITIAL' | 'CONNECTING' | 'CONNECTED'
  >();
  const [telegramAccountName, setTelegramAccountName] =
    React.useState<string>();
  const [telegramConnectionLink, setTelegramConnectionLink] =
    React.useState<string>();
  const [reInitTimer, setReInitTimer] = React.useState<boolean>(false);
  const role = handleStorage.getRole();
  const timerId = React.useRef<ReturnType<typeof setTimeout>>();

  const getBotStatus = async () => {
    const response = await getConnectedBot();
    if (response?.data) {
      setTelegramAccountName(response.data?.name);
      setTelegramState('CONNECTED');
      if (timerId.current) clearInterval(timerId.current);
    } else {
      setTelegramState('INITIAL');
    }
  };

  const getBotStatusContinuously = async () => {
    const response = await getConnectedBot();
    if (response?.data) {
      setTelegramAccountName(response.data?.name);
      setTelegramState('CONNECTED');
      if (timerId.current) clearInterval(timerId.current);
    }
  };

  const connectBot = async () => {
    const response = await requestBotConnection();
    if (response?.data) {
      setReInitTimer(!reInitTimer);
      setTelegramConnectionLink(response.data?.url);
      setTelegramState('CONNECTING');
      timerId.current = setInterval(async () => {
        getBotStatusContinuously();
      }, 1000);
    }
  };

  const disconnectTelegramBot = async () => {
    await disconnectBot();
    getBotStatus();
  };

  React.useEffect(() => {
    if (role === 'ACTIVITY_PROVIDER') getBotStatus();
    return () => {
      if (timerId.current) clearInterval(timerId.current);
    };
  }, []);

  const connectTelegram = () => {
    connectBot();
  };

  return (
    <>
      {role === 'ACTIVITY_PROVIDER' && (
        <div className="page__title account-view__title title">
          Данные профиля
        </div>
      )}
      {role === 'ACTIVITY_PROVIDER' && (
        <div className="account-view__data">
          {accountData?.name && (
            <>
              <div className="account-view__field-title header1">
                Название организации / ФИО
              </div>
              <div className="account-view__field-value normal-text">
                {accountData.name}
              </div>
            </>
          )}
          {accountData?.email && (
            <>
              <div className="account-view__field-title header1">E-mail</div>
              <div className="account-view__field-value normal-text">
                {accountData.email}
              </div>
            </>
          )}
          {accountData?.phone && (
            <>
              <div className="account-view__field-title header1">
                Номер телефона
              </div>
              <div className="account-view__field-value normal-text">{`+${accountData.phone}`}</div>
            </>
          )}
          <Button
            mode="secondary"
            className="account-view__edit-btn"
            onClick={() => setAccountState('EDIT')}
          >
            Редактировать
          </Button>
          {telegramState && (
            <>
              <div className="page__title title">Оповещения</div>
              {telegramState !== 'CONNECTED' && (
                <div className="account-view__telegram-panel">
                  <div className="account-view__field-title header1">
                    {supervisor
                      ? 'Подключение телеграм бота супервайзера'
                      : 'Подключите телеграм для быстрых уведомлений о бронированиях'}
                  </div>
                  {telegramState === 'INITIAL' && (
                    <div className="account-view__telegram-value normal-text">
                      Для подключения оповещений у вас должен быть телеграм
                      аккаунт
                    </div>
                  )}
                  {telegramState === 'CONNECTING' && (
                    <div className="account-view__telegram-value normal-text">
                      Откройте данную ссылку на устройстве, на котором
                      установлен телеграм
                    </div>
                  )}
                  {telegramState === 'INITIAL' && (
                    <Button
                      className="account-view__telegram-btn"
                      onClick={connectTelegram}
                    >
                      Подключить телеграм
                    </Button>
                  )}
                  {telegramState === 'CONNECTING' && (
                    <>
                      <a
                        className="login-page__policy-link button-text underlined"
                        href={telegramConnectionLink}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {telegramConnectionLink}
                      </a>
                      <div className="account-view__telegram-timer">
                        <CountDownTimer
                          deadlineInMin={10}
                          onTimerTriggered={() => {
                            setTelegramState('INITIAL');
                            setTelegramConnectionLink(undefined);
                            if (timerId.current) clearInterval(timerId.current);
                          }}
                          reInit={reInitTimer}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
              {telegramState === 'CONNECTED' && (
                <div className="account-view__telegram-panel connected">
                  <div className="account-view__field-title">
                    <span className="account-view__field-value normal-text">
                      {supervisor
                        ? 'Подключен бот супервайзера для телеграма'
                        : 'Подключены оповещения для телеграма'}
                    </span>{' '}
                    <span className="account-view__field-value bold-value normal-text">
                      {telegramAccountName}
                    </span>
                  </div>
                  <Button
                    className="account-view__telegram-btn disconnect"
                    mode={'secondary'}
                    onClick={disconnectTelegramBot}
                  >
                    Отключить оповещения для телеграма
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {role === 'ACTIVITY_PROVIDER' && serviceChargePercentage && (
        <div className="account-view__security">
          <div className="page__title title account-view__security-title">
            Комиссия сервиса
          </div>
          <div className="account-view__service-fee header1">{`${serviceChargePercentage}%`}</div>
        </div>
      )}
      <div
        className={classNames(
          'account-view__security',
          role === 'OPERATOR' && 'operator',
        )}
      >
        <div className="page__title title account-view__security-title">
          Безопасность
        </div>
        {role === 'ACTIVITY_PROVIDER' && (
          <>
            <div className="account-view__security-item">
              <a
                className="login-page__policy-link account-view__policy-link button-text underlined"
                href="/Пользовательское_соглашение.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Пользовательское соглашение
              </a>
            </div>
            <button
              className="account-view__security-item button-text"
              onClick={() => setAccountState('CHANGE_PASSWORD')}
            >
              <ChangePasswordIcon />
              Изменить пароль
            </button>
          </>
        )}
        <button
          className="account-view__security-item exit-item button-text"
          onClick={logout}
        >
          <ExitIcon />
          Выйти
        </button>
      </div>
    </>
  );
};

export default AccountView;
