import * as React from 'react';
import ReactPlayer from 'react-player';

interface IVideo {
  sourceVideo: string;
  setAutoplay(val: boolean): void;
}

const Video: React.FC<IVideo> = ({ sourceVideo, setAutoplay }) => {
  return (
    <>
      <ReactPlayer
        url={sourceVideo}
        controls={true}
        width="100%"
        height="100%"
        onPlay={() => setAutoplay(false)}
        onEnded={() => setAutoplay(true)}
      />
    </>
  );
};

export default Video;
