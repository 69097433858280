import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Layout from '../../components/Layout';
import { handleStorage } from '../../services/handleStorage';

const NotFoundPage: React.FC<RouteComponentProps> = ({ history }) => {
  const role = handleStorage.getRole();

  if (role === 'OPERATOR' && location.pathname.startsWith('/provider')) {
    history.push('/operator');
  }
  if (
    role === 'ACTIVITY_PROVIDER' &&
    location.pathname.startsWith('/operator')
  ) {
    history.push('/provider');
  }

  const redirect = () => {
    if (role === 'OPERATOR') history.push('/operator');
    else if (role === 'ACTIVITY_PROVIDER') history.push('/provider');
    else history.push('/');
  };

  return (
    <Layout unauthorized={!handleStorage.getToken()}>
      <div className="error-page__content">
        <div className="title error-page__title">Страница не найдена</div>
        <div className="error-page__info normal-text">
          Если вы считаете, что возникла ошибка, пожалуйста, напишите нам на
          provider.support@adva.travel
        </div>
        <button
          className="error-page__link button-text underlined"
          onClick={redirect}
        >
          На главную
        </button>
      </div>
    </Layout>
  );
};

export default withRouter(NotFoundPage);
