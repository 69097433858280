import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ReactComponent as IconPlus } from '../../../images/InfoProviders/advantages-icon-plus.svg';
import { handleStorage } from '../../../services/handleStorage';

import './style.scss';

const Index: React.FC<RouteComponentProps> = ({ history }) => {
  const role = handleStorage.getRole();

  const redirectToRegister = () => {
    if (role === 'ACTIVITY_PROVIDER') history.push('/provider');
    else if (role === 'OPERATOR') history.push('/operator');
    else history.push('/register');
  };

  return (
    <div className="register-provider__container">
      <div className="register-provider__content-container">
        <IconPlus className="register-provider__plus-icon" />
        <div className="register-provider__text">
          ХОЧУ В КОМАНДУ <br /> ADVA
        </div>
        <button className="register-provider__btn" onClick={redirectToRegister}>
          Зарегистрироваться
        </button>
        <div className="register-provider__resorts">
          <div className="register-provider__resorts-line before-line" />
          <span>&nbsp;#Красная&nbsp;поляна</span>
          <div className="register-provider__resorts-line" />
          <span>#Алтай</span>
          <div className="register-provider__resorts-line" />
          <span>#Шерегеш</span>
          <div className="register-provider__resorts-line" />
          <span>#Ленинградская&nbsp;область</span>
          <div className="register-provider__resorts-line" />
          <span>#Томск</span>
          <div className="register-provider__resorts-line edge" />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Index);
