/* eslint-disable */
import React from 'react';
import CardList from './CardList/CardList';
import Download from './Download/Download';
import Locations from './Locations/Locations';
import './style.scss';
import UserCategory from './UserCategory/UserCategory';
import Usefull from './Usefull/Usefull';
import MainBlock from './MainBlock/MainBlock';
import Nums from './Nums/Nums';
import LandingLayout from '../../components/LandingLayout/LandingLayout';

const MainPage = () => {
  return (
    <div className="root">
      <LandingLayout>
        <MainBlock />
        <Nums />
        <CardList />
        <Locations />
        <UserCategory />
        <Download />
        <Usefull />
      </LandingLayout>
    </div>
  );
};

export default MainPage;
