import * as React from 'react';
import { ValueType } from 'react-select';

import { components } from '../../../../generated/apiTypes';
import { getCategories } from '../../../../services/apiRequests';
import WinterService from '../EditInfo/WinterService';
import { ServiceInfo } from '../types';
import GenericService from './GenericService';
import PrimitiveService from './PrimitiveService';
import Step1 from './step1';

interface IEditInfo {
  info: ServiceInfo;
  resortOptions: { value: string; label: string }[];
  onSaveClick(id?: string): void;
  onCancelClick(): void;
  newService?: boolean;
}

const Index: React.FC<IEditInfo> = ({
  newService,
  onCancelClick,
  info,
  resortOptions,
  onSaveClick,
}) => {
  const [step, setStep] = React.useState<'FIRST' | 'SECOND'>('FIRST');
  const [infoState, setInfoState] = React.useState<ServiceInfo>(info);
  const [categoryOptions, setCategoryOptions] = React.useState<
    {
      value: string;
      label: string;
      type: 'ACTIVITY' | 'EXPERT' | 'RENTAL' | 'TRANSFER' | 'GENERIC';
    }[]
  >([]);
  const [categoryError, setCategoryError] = React.useState<boolean>(false);
  const [categorySelectValue, setCategorySelectValue] = React.useState<
    ValueType<any, false> | undefined
  >(undefined);
  const [configuration, setConfiguration] =
    React.useState<components['schemas']['ActivityConfiguration']>();

  const getCategoryOptions = async () => {
    const response = await getCategories();
    if (response?.data) {
      const options = [];
      for (const item of response.data) {
        options.push({
          value: (item?.id as number).toString(),
          label: item?.name as string,
          type: item?.type as
            | 'ACTIVITY'
            | 'EXPERT'
            | 'RENTAL'
            | 'TRANSFER'
            | 'GENERIC',
        });
      }
      setCategoryOptions(options);
    }
  };

  React.useEffect(() => {
    getCategoryOptions();
  }, []);

  React.useEffect(() => {
    const category = categoryOptions?.find(
      item => item?.value === infoState?.category?.value,
    );
    if (category?.value) setCategorySelectValue(category);
  }, [categoryOptions]);

  const onChangeCategory = (value: ValueType<any, false>) => {
    if (categoryError) setCategoryError(false);
    setCategorySelectValue(value);
    setInfoState({
      ...infoState,
      category: value,
      subCategories: [],
    });
  };

  const setChildrenAllowance = async (checked: boolean) => {
    setInfoState(infoState => ({ ...infoState, childrenAllowed: checked }));
  };

  return step === 'FIRST' ? (
    <Step1
      info={infoState}
      setInfo={setInfoState}
      newService={newService}
      resortOptions={resortOptions}
      goToStep={setStep}
      categoryError={categoryError}
      setCategoryError={setCategoryError}
      categoryOptions={categoryOptions}
      categorySelectValue={categorySelectValue}
      onChangeCategory={onChangeCategory}
      setChildrenAllowance={setChildrenAllowance}
      setActivityConfiguration={setConfiguration}
      onCancelClick={onCancelClick}
    />
  ) : (
    <>
      {' '}
      {['ACTIVITY', 'EXPERT', 'RENTAL', 'TRANSFER'].includes(
        categorySelectValue?.type,
      ) && (
        <WinterService
          newService={newService}
          onCancelClick={onCancelClick}
          onSaveClick={onSaveClick}
          resortOptions={resortOptions}
          info={infoState}
          goToStep={setStep}
        />
      )}
      {categorySelectValue?.type === 'GENERIC' && (
        <GenericService
          newService={newService}
          onCancelClick={onCancelClick}
          infoState={infoState}
          setInfoState={setInfoState}
          goToStep={setStep}
          onSaveClick={onSaveClick}
          configuration={configuration}
        />
      )}
      {categorySelectValue?.type === 'PRIMITIVE' && (
        <PrimitiveService
          newService={newService}
          onCancelClick={onCancelClick}
          infoState={infoState}
          setInfoState={setInfoState}
          goToStep={setStep}
          onSaveClick={onSaveClick}
        />
      )}
    </>
  );
};

export default Index;
