import * as React from 'react';

import classNames from 'classnames';

import './style.scss';

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
  onEnter?(): void;
  onBlur?(): void;
  containerClassName?: string;
}

const Input: React.FC<IInput> = ({
  label,
  error,
  onEnter,
  onBlur,
  children,
  containerClassName,
  ...props
}) => {
  const ref = React.useRef<HTMLInputElement | null>(null);
  const [isFocus, setIsFocus] = React.useState<boolean>();

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (['Enter', 'Escape'].includes(e.key)) {
      ref?.current?.blur();
      if (['Enter'].includes(e.key) && onEnter) {
        onEnter();
      }
    }
    return false;
  };

  const onFocusHandler = () => {
    setIsFocus(true);
  };

  const onBlurHandler = () => {
    setIsFocus(false);
    if (onBlur) onBlur();
  };

  return (
    <div>
      {label && <span className="input-label label-text">{label}</span>}
      <div
        className={classNames(
          'input__container',
          containerClassName,
          { error },
          props.disabled && 'disabled',
          isFocus && 'focus',
        )}
      >
        <input
          ref={ref}
          className={classNames('input normal-text', { error })}
          {...props}
          onKeyDown={onKeyDownHandler}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
        />
        {children}
      </div>
    </div>
  );
};

export default Input;
