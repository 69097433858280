/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
  BrowserRouter,
} from 'react-router-dom';

import { handleStorage } from './services/handleStorage';

import './App.css';

import updateAuthToken from './utils/updateAuthToken';
import NotFoundPage from './pages/NotFoundPage';
import Login from './pages/Login';
import Registration from './pages/Registration';
import Restore from './pages/Restore';
import MainPage from './pages/MainPage/MainPage';
import UsefullCategories from './pages/Articles/UsefullCategories/UsefullCategories';
import LocationArticle from './pages/Articles/LocationArticle/LocationArticle';
import LocationCategories from './pages/Articles/LocationCategories/LocationCategories';
import InfoCustomers from './pages/InfoCustomers';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Account from './pages/Account';
import Booking from './pages/Booking';
import CreateService from './pages/CreateService';
import EditSlot from './pages/EditSlot';
import InfoProviders from './pages/InfoProviders';
import Service from './pages/Service';
import Services from './pages/Services';
import AllProviders from './pages/AllProviders';
import EditWebsiteItem from './pages/WebsiteAdminPanel/EditWebsiteItem';
import AllBookings from './pages/AllBookings';
import WebsiteAdminPanel from './pages/WebsiteAdminPanel';
import UsefullArticle from './pages/Articles/UsefullArticle/UsefullArticle';
import LocationsUsefullArticle from './pages/Articles/UsefullArticle/UsefullArticle';
import LocationsArticle from './pages/Articles/LocationArticle/LocationArticle';
import ServicesCategories from './pages/Articles/ServicesCategories/ServicesCategories';
import ServiceArticle from './pages/Articles/ServiceArticle/ServiceArticle';
import { useLocation } from 'react-router-dom';

const App: React.FC<RouteComponentProps> = ({ history, location }) => {
  const locationPathname = location.pathname;
  const feedAuthority = handleStorage.getFeedAuthority();
  const role = handleStorage.getRole();
  const token = handleStorage.getToken();
  const [showHeaderAndFooter, setShowHeaderAndFooter] = useState(false);

  useEffect(() => {
    if (
      role === 'OPERATOR' &&
      (location.pathname.startsWith('/provider/') ||
        location.pathname === '/provider')
    ) {
      history.push('/operator');
    }
    if (
      role === 'ACTIVITY_PROVIDER' &&
      location.pathname.startsWith('/operator')
    ) {
      history.push('/provider');
    }
    if (
      !['ACTIVITY_PROVIDER', 'OPERATOR'].includes(role as string) &&
      (location.pathname.startsWith('/operator') ||
        location.pathname.startsWith('/provider/') ||
        location.pathname === '/provider')
    ) {
      handleStorage.removeToken();
      handleStorage.removeUsername();
      handleStorage.removeRole();
      handleStorage.removeFeedAuthority();
      handleStorage.removeRefundAuthority();
      updateAuthToken(false);
      history.push('/login');
    }
    if (
      (location.pathname === '/login' ||
        location.pathname === '/register' ||
        location.pathname === '/restore') &&
      ['ACTIVITY_PROVIDER', 'OPERATOR'].includes(role as string) &&
      token
    ) {
      if (role === 'OPERATOR') {
        history.push('/operator');
      } else {
        history.push('/provider');
      }
    }

    if (
      location.pathname !== '/' &&
      !location.pathname.includes('new') &&
      !location.pathname.includes('blog') &&
      !location.pathname.includes('blog/article') &&
      !location.pathname.includes('locations')
    ) {
      setShowHeaderAndFooter(false);
    } else {
      setShowHeaderAndFooter(true);
    }
  }, [location.pathname]);

  return (
    <BrowserRouter>
      {/* {showHeaderAndFooter ? <Header /> : null} */}
      <Switch>
        <Route exact path="/" component={MainPage} />
        {/* <Route exact path="/new" component={MainPage} /> */}
        <Route exact path="/locations" component={LocationCategories} />
        <Route
          exact
          path="/locations/article/:id"
          component={LocationArticle}
        />
        <Route exact path="/blogs" component={UsefullCategories} />
        <Route exact path="/blogs/article/:id" component={UsefullArticle} />
        <Route exact path="/subcategories" component={ServicesCategories} />
        <Route
          exact
          path="/subcategories/article/:id"
          component={ServiceArticle}
        />
        <Route exact path="/locations" component={LocationCategories} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Registration} />
        <Route exact path="/restore" component={Restore} />
        <Route exact path="/providerinfo" component={InfoProviders} />
        <Route exact path="/provider" component={Services} />
        <Route
          exact
          path="/provider/create-service"
          component={CreateService}
        />
        <Route exact path="/provider/account" component={Account} />
        <Route exact path="/provider/service=:service_id" component={Service} />
        <Route exact path="/provider/booking=:id" component={Booking} />
        <Route path="/activity/">
          <Redirect to={'/'} />
        </Route>
        <Route
          exact
          path="/provider/service=:service_id/slot=:slot_id"
          component={EditSlot}
        />
        <Route exact path="/operator">
          <Redirect to="/operator/providers" />
        </Route>
        <Route exact path="/operator/account" component={Account} />
        <Route exact path="/operator/bookings" component={AllBookings} />
        <Route exact path="/operator/newreviews" component={AllBookings} />
        <Route
          exact
          path="/operator/website/location=:item_id"
          render={props =>
            feedAuthority === 'FEED' ? (
              <EditWebsiteItem {...props} type="LOCATION" />
            ) : (
              <NotFoundPage />
            )
          }
        />
        <Route
          exact
          path="/operator/website/feedpost=:item_id"
          render={props =>
            feedAuthority === 'FEED' ? (
              <EditWebsiteItem {...props} type="POST" />
            ) : (
              <NotFoundPage />
            )
          }
        />
        <Route
          exact
          path="/operator/website/feedpost/new"
          render={props =>
            feedAuthority === 'FEED' ? (
              <EditWebsiteItem {...props} type="POST" newItem={true} />
            ) : (
              <NotFoundPage />
            )
          }
        />
        <Route
          exact
          path="/operator/website/subcategory=:item_id"
          render={props =>
            feedAuthority === 'FEED' ? (
              <EditWebsiteItem {...props} type="SUBCATEGORY" />
            ) : (
              <NotFoundPage />
            )
          }
        />
        <Route
          exact
          path="/operator/website"
          render={props =>
            feedAuthority === 'FEED' ? (
              <WebsiteAdminPanel {...props} />
            ) : (
              <NotFoundPage />
            )
          }
        />
        <Route exact path="/operator/providers" component={AllProviders} />
        <Route exact path="/operator/booking=:id" component={Booking} />
        <Route exact path="/operator/provider=:id" component={Services} />
        <Route
          exact
          path="/operator/provider=:provider_id/create-service"
          component={CreateService}
        />
        <Route
          exact
          path="/operator/provider=:provider_id/service=:service_id"
          component={Service}
        />
        <Route
          exact
          path="/operator/provider=:provider_id/service=:service_id/slot=:slot_id"
          component={EditSlot}
        />
        {!token &&
          (locationPathname.includes('provider') ||
            locationPathname.includes('operator')) && <Redirect to="/login" />}
        <Route path="*" component={NotFoundPage} />
      </Switch>
      {/* {showHeaderAndFooter ? <Footer /> : null} */}
    </BrowserRouter>
  );
};

export default withRouter(App);
