import React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ApiErrorUI from '../../components/ApiErrorUI';
import Button from '../../components/Button';
import Layout from '../../components/Layout';
import { components } from '../../generated/apiTypes';
import { ReactComponent as Logo } from '../../images/components/Layout/logo-lg.svg';
import { signInWithPassword } from '../../services/apiRequests';
import { handleStorage } from '../../services/handleStorage';
import { setApiError } from '../../store/commonSlice';
import { RootState } from '../../store/store';
import { ICommonSlice } from '../../store/types';
import updateAuthToken from '../../utils/updateAuthToken';
import FormField from './FormField';

import './style.scss';

interface LoginPropsType extends RouteComponentProps {
  apiError: ICommonSlice['apiError'];
  setApiError: typeof setApiError;
}

interface DispatchProps {
  setApiError: typeof setApiError;
}

class Login extends React.Component<LoginPropsType> {
  state = {
    password: '',
    email: '',
    emailError: false,
    passwordError: false,
    requestInProgress: false,
  };

  componentWillUnmount(): void {
    const { apiError, setApiError } = this.props;
    if (apiError.isError)
      setApiError({
        isError: false,
        status: undefined,
        key: undefined,
        message: undefined,
        timeStamp: undefined,
      });
  }

  tryToLogin = async () => {
    const { password, email } = this.state;
    const { history, setApiError } = this.props;
    const validateEmail = !!email;
    const validatePassword = !!password;
    if (!validateEmail) this.setState({ emailError: true });
    if (!validatePassword) this.setState({ passwordError: true });
    if (validateEmail && validatePassword) {
      this.setState({ requestInProgress: true });
      try {
        const formData: components['schemas']['PasswordSignIn'] = {
          login: email,
          password: password,
        };
        const {
          data: { token: token, account: account },
        } = await signInWithPassword(formData);
        if (token) {
          handleStorage.setToken(token);
          updateAuthToken(token);
        }
        if (account?.authorities)
          handleStorage.setAuthorities(account?.authorities);
        if (account?.name) handleStorage.setUsername(account?.name);
        if (account?.role) {
          handleStorage.setRole(account?.role);
          if (account.role === 'ACTIVITY_PROVIDER') history.push('/provider');
          if (account.role === 'OPERATOR') history.push('/operator');
          if (account.role === 'CUSTOMER') {
            setApiError({
              isError: true,
              status: undefined,
              message: 'Вход под учетной записью клиента невозможен',
              key: 'CUSTOMER_ACCOUNT',
              timeStamp: new Date().toISOString(),
            });
          }
        }
      } catch (err) {
        //
      }
      this.setState({ requestInProgress: false });
    }
  };

  submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    this.tryToLogin();
  };

  onChangeHandler = (
    e: React.FormEvent<HTMLInputElement>,
    formValue: 'password' | 'email',
  ) => {
    const { emailError, passwordError } = this.state;
    const { apiError, setApiError } = this.props;
    if (emailError && formValue === 'email')
      this.setState({ emailError: false });
    if (
      [
        'IDENTIFIER_NOT_FOUND',
        'PasswordSignIn_login_Size',
        'PasswordSignIn_login_NotBlank',
      ].includes(apiError.key as string) &&
      formValue === 'email'
    )
      setApiError({
        isError: false,
        status: undefined,
        message: undefined,
        key: undefined,
      });
    if (passwordError && formValue === 'password')
      this.setState({ passwordError: false });
    if (
      [
        'ACCOUNT_PASSWORD_INCORRECT',
        'PasswordSignIn_password_Size',
        'PasswordSignIn_password_NotBlank',
      ].includes(apiError.key as string) &&
      formValue === 'password'
    )
      setApiError({
        isError: false,
        status: undefined,
        message: undefined,
        key: undefined,
      });
    if (apiError)
      setApiError({
        isError: false,
        status: undefined,
        key: undefined,
        message: undefined,
        timeStamp: undefined,
      });
    this.setState({
      [formValue]: e.currentTarget.value,
    });
  };

  render() {
    const { emailError, passwordError, requestInProgress } = this.state;
    const { apiError, history } = this.props;
    const errorToShow =
      apiError?.isError &&
      ![
        'ACCOUNT_PASSWORD_INCORRECT',
        'IDENTIFIER_NOT_FOUND',
        'PasswordSignIn_login_Size',
        'PasswordSignIn_password_Size',
        'PasswordSignIn_password_NotBlank',
        'PasswordSignIn_login_NotBlank',
        'CUSTOMER_ACCOUNT',
      ].includes(apiError.key as string);
    return (
      <Layout unauthorized error={errorToShow}>
        {errorToShow ? (
          <ApiErrorUI />
        ) : (
          <div className="login-page__container">
            <form className="login-page__content" onSubmit={this.submitHandler}>
              <div className="login-page__logo-wrapper">
                <div className="logo-text-wrapper title">
                  <Logo className="login-page__logo" />
                  Вход
                </div>
              </div>
              <FormField
                onChangeHandler={this.onChangeHandler}
                formValue="email"
                placeholder="E-mail"
                error={
                  emailError ||
                  apiError.key === 'IDENTIFIER_NOT_FOUND' ||
                  apiError.key === 'PasswordSignIn_login_Size' ||
                  apiError.key === 'PasswordSignIn_login_NotBlank'
                }
              />
              <FormField
                onChangeHandler={this.onChangeHandler}
                formValue="password"
                type="password"
                placeholder="Пароль"
                onEnter={this.tryToLogin}
                error={
                  passwordError ||
                  apiError.key === 'ACCOUNT_PASSWORD_INCORRECT' ||
                  apiError.key === 'PasswordSignIn_password_Size' ||
                  apiError.key === 'PasswordSignIn_password_NotBlank'
                }
              />
              <div className="login-page-button-wrapper">
                <Button
                  type="submit"
                  className="login-page-button"
                  disabled={requestInProgress}
                >
                  Войти
                </Button>
              </div>
              {[
                'ACCOUNT_PASSWORD_INCORRECT',
                'IDENTIFIER_NOT_FOUND',
                'PasswordSignIn_login_NotBlank',
                'PasswordSignIn_password_NotBlank',
                'CUSTOMER_ACCOUNT',
              ].includes(apiError.key as string) && (
                <div className="login-page__error-message normal-text">
                  {apiError?.message}
                </div>
              )}
              {apiError.key === 'PasswordSignIn_password_Size' && (
                <div className="login-page__error-message normal-text">
                  Неверный пароль
                </div>
              )}
              {apiError.key === 'PasswordSignIn_login_Size' && (
                <div className="login-page__error-message normal-text">
                  Аккаунт не найден
                </div>
              )}
              <button
                className="login-page__restore-password-btn button-text underlined"
                type="button"
                onClick={() => {
                  history.push('/restore');
                }}
              >
                Восстановление пароля
              </button>
              <div className="login-page__create-account button-text">
                Нет аккаунта?{' '}
                <button
                  className="login-page__create-account-btn button-text underlined"
                  onClick={() => {
                    history.push('/register');
                  }}
                >
                  Создайте аккаунт
                </button>
              </div>
            </form>
          </div>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  apiError: state.common.apiError,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, null> = {
  setApiError,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
