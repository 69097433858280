import * as React from 'react';

import classNames from 'classnames';

import { components } from '../../../../../generated/apiTypes';
import Price from './Price';

import './style.scss';

interface IPrices {
  view?: boolean;
  label: string;
  prices?: components['schemas']['PriceView'][];
  customerType: 'ADULT' | 'CHILD' | 'GROUP';
  onChangePrice?(price: number, index: number): void;
  genericType?: boolean;
  priceType?: 'PER_PERSON' | 'PER_PERSON_PER_HOUR' | 'PER_GROUP';
}

const Prices: React.FC<IPrices> = ({
  view,
  label,
  prices,
  customerType,
  onChangePrice,
  genericType,
  priceType,
}) => {
  const getLabelByTariff = (tariff: 'STANDARD' | 'SPECIAL' | 'ALL_DAY') => {
    switch (tariff) {
      case 'STANDARD':
        return 'Стандарт';
      case 'SPECIAL':
        return 'Спецтариф';
      case 'ALL_DAY':
        return 'Весь день';
    }
  };
  return prices?.filter(item => item.customerType === customerType)?.length &&
    prices?.filter(item => item.customerType === customerType)?.length > 0 ? (
    <div className="prices__container">
      <div className="prices__title header2">{label}</div>
      <div className={classNames('prices__wrapper', genericType && 'generic')}>
        <div className="prices__row">
          <div className="prices__row-title header2">Будни</div>
          {prices
            ?.filter(item => item.dayType === 'WORKDAY')
            ?.filter(item => item.customerType === customerType)
            ?.map((value, index, { length }) => (
              <Price
                arrayIx={prices?.findIndex(item => item === value)}
                key={index}
                price={value.value.toString()}
                paymentToProvider={value.paymentToProvider}
                label={length > 1 ? getLabelByTariff(value.tariff) : ''}
                disabled={view}
                onChangePrice={onChangePrice}
                serviceFee={value.serviceChargePercentage}
                genericType={genericType}
                priceType={priceType}
              />
            ))}
        </div>
        <div className="prices__row">
          <div className="prices__row-title header2">Выходные и праздники</div>
          {prices
            ?.filter(item => item.dayType === 'DAY_OFF')
            ?.filter(item => item.customerType === customerType)
            ?.map((value, index, { length }) => (
              <Price
                arrayIx={prices?.findIndex(item => item === value)}
                key={index}
                price={value.value.toString()}
                paymentToProvider={value.paymentToProvider}
                label={length > 1 ? getLabelByTariff(value.tariff) : ''}
                disabled={view}
                onChangePrice={onChangePrice}
                serviceFee={value.serviceChargePercentage}
                genericType={genericType}
                priceType={priceType}
              />
            ))}
        </div>
      </div>
      <div className="price__post-label">
        <span className="price__sup">*</span>выплачивается клиентом на руки
        после комиссии сервиса {prices[0].serviceChargePercentage}% (мин.
        комиссия 100&nbsp;рублей)
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Prices;
