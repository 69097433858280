import * as React from 'react';

import classNames from 'classnames';

import RateRadio from '../../../../../components/RadioTabs';
import Switch from '../../../../../components/Switch';
import { components } from '../../../../../generated/apiTypes';

import './style.scss';

interface IRates {
  view?: boolean;
  subcategoryTariffs?: components['schemas']['SubcategoryTariffView'][];
  allDayTariffEnabled?: boolean;
  onChangeAllDayTariff?(val: boolean): void;
  onChangeSubcategoryTariff?(
    subcategoryTariff: components['schemas']['SubcategoryTariffView'],
    index: number,
  ): void;
}

const Rates: React.FC<IRates> = ({
  view,
  subcategoryTariffs,
  allDayTariffEnabled,
  onChangeAllDayTariff,
  onChangeSubcategoryTariff,
}) => {
  return (
    <div className="rates__container">
      <div className="rates__title header2">Тарифы</div>
      <div className={classNames('rates__content-container', { view })}>
        {subcategoryTariffs?.map((tariff, index) => (
          <div key={index} className="rates__rate">
            <RateRadio
              index={index}
              rates={['STANDARD', 'SPECIAL']}
              view={view}
              value={tariff}
              onChangeSubcategoryTariff={onChangeSubcategoryTariff}
            />
          </div>
        ))}
      </div>
      {!view && onChangeAllDayTariff && (
        <div className="edit-service__children">
          <div className="edit-service__all-day-option-wrapper">
            <div className="edit-service__child-option all-day-option header2">
              Возможность бронирования на весь день
            </div>
          </div>
          <div className="edit-service__child-switch">
            <Switch
              checked={allDayTariffEnabled as boolean}
              handleChange={onChangeAllDayTariff}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Rates;
