import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import classNames from 'classnames';

import ApiErrorUI from '../../components/ApiErrorUI';
import Layout from '../../components/Layout';
import { components } from '../../generated/apiTypes';
import {
  recoverPassword,
  setNewPassword,
  signInWithCode,
} from '../../services/apiRequests';
import { handleStorage } from '../../services/handleStorage';
import { selectApiError, setApiError } from '../../store/commonSlice';
import Confirmation from '../Registration/ConfirmationCode';
import ChangePasswordForm from './ChangePasswordForm';
import RestoreForm from './RestoreForm';

import './style.scss';

const Restore: React.FC<RouteComponentProps> = ({ history }) => {
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();
  const [mode, setMode] = React.useState<
    'RESTORE' | 'CONFIRM' | 'PASSWORD_CHANGE'
  >('RESTORE');
  const [authorized, setAuthorized] = React.useState<boolean>(false);
  const [recoveryData, setRecoveryData] =
    React.useState<components['schemas']['PasswordRecovery']>();
  const [email, setEmail] = React.useState<string>();

  React.useEffect(() => {
    return () => {
      if (apiError.isError)
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [apiError.isError]);

  const onCompleteCode = async (code: string) => {
    try {
      const {
        data: { token: token, account: account },
      } = await signInWithCode({
        login: recoveryData?.login as string,
        code: code,
      });
      if (token) {
        handleStorage.setToken(token);
      }
      if (account?.name) handleStorage.setUsername(account?.name);
      if (account?.role) {
        handleStorage.setRole(account?.role);
      }
      setAuthorized(true);
      setMode('PASSWORD_CHANGE');
    } catch (e) {
      //
    }
  };

  const changePassword = async (password: string) => {
    try {
      await setNewPassword({ password: password });
      history.push('/provider');
    } catch (e) {
      //
    }
  };

  const recoverPasswordRequest = async (
    data: components['schemas']['PasswordRecovery'],
  ) => {
    setRecoveryData(data);
    try {
      await recoverPassword(data);
      setEmail(data.login);
      setMode('CONFIRM');
    } catch (e) {
      //
    }
  };

  const resendCodeRequest = async () => {
    if (recoveryData) {
      try {
        await recoverPassword(recoveryData);
        setMode('CONFIRM');
      } catch (e) {
        //
      }
    }
  };

  const errorToShow =
    apiError?.isError &&
    ![
      'IDENTIFIER_NOT_FOUND',
      'IDENTIFIER_VERIFICATION_FAILED',
      'PasswordUpdate_password_Pattern',
      'PasswordUpdate_password_Size',
      'PasswordUpdate_oldPassword_Size',
      'ACCOUNT_PASSWORD_WEAK',
      'PasswordRecovery_login_Size',
    ].includes(apiError?.key as string);

  return (
    <Layout unauthorized error={errorToShow}>
      {errorToShow ? (
        <ApiErrorUI />
      ) : (
        <div
          className={classNames(
            'restore-page__container',
            mode === 'CONFIRM' && 'confirm-container',
            mode === 'PASSWORD_CHANGE' && 'password-change-container',
          )}
        >
          <div
            className={classNames(
              'restore-page__content',
              mode === 'CONFIRM' && 'confirm-content',
              mode === 'PASSWORD_CHANGE' && 'password-change-content',
            )}
          >
            {mode === 'RESTORE' && (
              <RestoreForm recoverPasswordRequest={recoverPasswordRequest} />
            )}
            {mode === 'CONFIRM' && (
              <Confirmation
                onComplete={onCompleteCode}
                resendCodeRequest={resendCodeRequest}
                email={email}
              />
            )}
            {mode === 'PASSWORD_CHANGE' && (
              <ChangePasswordForm changePassword={changePassword} />
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default withRouter(Restore);
