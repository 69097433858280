import * as React from 'react';

function HumanIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={60}
      height={56}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.055 17.636l-1.664-.943a3.32 3.32 0 00-4.366.998l-3.216 4.574c-.319.472-.54.957-.68 1.47-.304 1.15-.069 2.287.68 3.202.748.928 1.802 1.4 2.994 1.33l2.648-.138"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M21.368 22.861l4.075 2.149c.333.18.568.457.68.832.11.36.069.72-.112 1.053l-.014.014a1.38 1.38 0 01-1.857.582l-8.137-4.283-3.119 7.444.07 7.43c0 .318-.042.596-.153.887l-2.648 7.152a1.855 1.855 0 01-1.441 1.22 1.9 1.9 0 01-1.802-.568 2.636 2.636 0 01-.416-.61c-.346-.665-.402-1.359-.152-2.052l1.996-5.919c.124-.374.166-.706.124-1.08l-.942-8.429a2.45 2.45 0 01.11-1.095l3.092-8.982a2.4 2.4 0 011.247-1.428l1.248-.61c.818-.416 1.677-.443 2.523-.097l.845.36 4.145 5.49c.18.235.374.402.638.54z"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.716 28.656l7.444 4.532c.388.236.596.624.596 1.068l-.028 9.814c0 1.053-.86 1.927-1.927 1.927h-.055c-.624.013-1.165-.222-1.608-.666a2.14 2.14 0 01-.624-1.622l.194-5.614a1.214 1.214 0 00-.624-1.122l-4.158-2.37M23.558 27.186l-8.511 18.908M24.972 24.65l.97-2.024M18.637 15.584a4.325 4.325 0 100-8.65 4.325 4.325 0 000 8.65zM36.49 11.772l-1.663-.929a3.314 3.314 0 00-4.352.985l-3.216 4.588a4.75 4.75 0 00-.693 1.47c-.305 1.15-.055 2.272.693 3.201.749.929 1.802 1.386 2.994 1.33l2.648-.152M53.097 14.752l2.828 18.478M52.487 11.037l-.29-1.441M44.087 9.72a4.325 4.325 0 100-8.65 4.325 4.325 0 000 8.65z"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.085 26.16l.042 5.78c.014.32-.042.583-.153.888l-2.647 7.139a1.878 1.878 0 01-1.428 1.233c-.68.139-1.33-.069-1.802-.582a2.823 2.823 0 01-.43-.61 2.578 2.578 0 01-.152-2.051l2.01-5.92c.124-.36.152-.692.11-1.08l-.942-8.428c-.042-.375 0-.721.125-1.081l3.077-8.983a2.38 2.38 0 011.261-1.428l1.248-.623a2.946 2.946 0 012.509-.083l.86.346 5.128 1.608a.806.806 0 00.444.014l5.184-1.234a1.786 1.786 0 012.162 1.303l.014.028a1.79 1.79 0 01-1.04 2.107l-5.904 2.523a.862.862 0 01-.458.069l-5.309-.79-2.356 5.267"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.526 21.448l7.084 2.758c.416.152.596.61.596 1.067l-.028 9.814c0 1.054-.86 1.913-1.927 1.927h-.055c-.624.014-1.164-.222-1.608-.665-.43-.458-.596-.998-.624-1.622l-.25-5.988a.813.813 0 00-.498-.721l-9.37-4.2M58.032 34.727l-26.185 8.65-5.877 4.255-19.365 2.066-2.717 4.324M52.75 45.22l-21.208 3.23-3.216 5.129"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HumanIcon;
