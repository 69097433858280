import * as React from 'react';

import Checkbox from '../Checkbox';

const capitalizeFirstLetter = ([first, ...rest]: string) => {
  return [first.toUpperCase(), ...rest].join('');
};

interface IMenuItem {
  checkedValue: boolean;
  setCheckedValue(value: boolean, index: number): void;
  index: number;
  label: string;
}

const MenuItem: React.FC<IMenuItem> = ({
  checkedValue,
  setCheckedValue,
  index,
  label,
}) => {
  return (
    <Checkbox
      checkedValue={checkedValue}
      setCheckedValue={setCheckedValue}
      index={index}
      id={`weekday-${index}`}
      label={capitalizeFirstLetter(label)}
    />
  );
};

export default MenuItem;
