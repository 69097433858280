import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ApiErrorUI from '../../components/ApiErrorUI';
import Layout from '../../components/Layout';
import ReturnLink from '../../components/ReturnLink';
import { getLocations } from '../../services/apiRequests';
import { handleStorage } from '../../services/handleStorage';
import { selectApiError, setApiError } from '../../store/commonSlice';
import EditInfo from '../Service/Info/EditInfo/index';
import { ServiceInfo } from '../Service/Info/types';

const initInfo: ServiceInfo = {
  title: '',
  instructorName: '',
  instructorSurname: '',
  images: [],
  childrenAllowed: false,
  resort: undefined,
  subLocations: [],
  category: undefined,
  subCategories: [],
  description: '',
  location: undefined,
  duration: undefined,
  peopleAmount: undefined,
  costFrom: '',
  cost: '',
  details: '',
  activeness: 'VERIFICATION',
  visibility: 'VISIBLE',
  verification: 'PENDING',
  tags: [],
  routePoints: [],
};

interface IRouterProps {
  provider_id?: string;
}

const Index: React.FC<RouteComponentProps<IRouterProps>> = ({
  history,
  match,
}) => {
  const token = handleStorage.getToken();
  const [resortOptions, setResortOptions] = React.useState<
    { value: string; label: string }[]
  >([]);
  const apiError = useSelector(selectApiError);
  const role = handleStorage.getRole();
  const provider_id = match.params.provider_id;
  const dispatch = useDispatch();

  const getOptions = async () => {
    const response = await getLocations();
    if (response?.data) {
      const resortOptions = [];
      for (const item of response.data) {
        resortOptions.push({
          value: (item?.id as number).toString(),
          label: item?.name as string,
          location: [
            item?.position?.latitude as number,
            item?.position?.longitude as number,
          ],
        });
      }
      setResortOptions(resortOptions);
    }
  };

  useEffect(() => {
    getOptions();
    return () => {
      dispatch(
        setApiError({ isError: false, status: undefined, message: undefined }),
      );
    };
  }, []);

  useEffect(() => {
    if (!token) {
      history.push('/login');
    }
  }, []);

  const redirectToHome = () => {
    if (role === 'ACTIVITY_PROVIDER') history.push('/provider');
    else if (role === 'OPERATOR')
      history.push(`/operator/provider=${provider_id}`);
  };

  const redirectService = (id: string) => {
    history.push({ pathname: `/provider/service=${id}`, state: { tabIx: 2 } });
  };

  const redirectCreateSlot = (id: string) => {
    history.push({ pathname: `/provider/service=${id}/slot=new` });
  };

  const errorToShow =
    apiError?.isError &&
    !(
      apiError?.key?.includes('NotBlank') ||
      apiError?.key?.includes('Size') ||
      apiError?.key?.includes('Max') ||
      apiError?.key?.includes('Range')
    );

  if (!token) {
    return null;
  }

  return (
    <Layout hideMobileMenu={true}>
      {errorToShow ? (
        <ApiErrorUI />
      ) : (
        <EditInfo
          info={initInfo}
          onSaveClick={redirectCreateSlot}
          onCancelClick={redirectToHome}
          resortOptions={resortOptions}
          newService={true}
        />
      )}
    </Layout>
  );
};

export default withRouter(Index);
