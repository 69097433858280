import * as React from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ValueType } from 'react-select';
import TimePicker, { TimePickerValue } from 'react-time-picker';

import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';

import Button from '../../../../components/Button';
import Dropdown from '../../../../components/Dropdown';
import EditableDiv from '../../../../components/EditableDiv';
import Input from '../../../../components/Input';
import IntlPhoneInput from '../../../../components/PhoneInput';
import ReturnLink from '../../../../components/ReturnLink';
import Switch from '../../../../components/Switch';
import { components } from '../../../../generated/apiTypes';
import { ReactComponent as LocationIcon } from '../../../../images/EditService/icn-location.svg';
import { ReactComponent as RubIcon } from '../../../../images/EditService/icn-rub.svg';
import {
  createActivity,
  createActivityByOperator,
  createExpert,
  createExpertByOperator,
  createRental,
  createRentalByOperator,
  createTransfer,
  createTransferByOperator,
  getCategories,
  previewPricing,
  previewPricingByOperator,
  updateActivity,
  updateExpert,
  updateRental,
  updateTransfer,
  uploadAttachment,
} from '../../../../services/apiRequests';
import { handleStorage } from '../../../../services/handleStorage';
import { selectApiError, setApiError } from '../../../../store/commonSlice';
import Map from '../Map';
import { ServiceInfo } from '../types';
import Discounts from './Discounts';
import ImagesArea from './ImagesArea';
import Prices from './Prices';
import Rates from './Rates';
import SubCategories from './SubCategories';

import './style.scss';

interface IRouterProps {
  provider_id?: string;
  service_id?: string;
}

interface IEditInfo extends RouteComponentProps<IRouterProps> {
  info: ServiceInfo;
  resortOptions: { value: string; label: string }[];
  onSaveClick(id?: string): void;
  onCancelClick(): void;
  newService?: boolean;
  goToStep(step: 'FIRST' | 'SECOND'): void;
}

const EditInfo: React.FC<IEditInfo> = ({
  info,
  resortOptions,
  onSaveClick,
  onCancelClick,
  newService,
  match,
  goToStep,
}) => {
  const [infoState, setInfoState] = React.useState<ServiceInfo>(info);
  const [locationAddress, setLocationAddress] = React.useState<string>('');
  const [resortSelectValue, setResortSelectValue] = React.useState<
    ValueType<any, false> | undefined
  >(undefined);
  const [phone, setPhone] = React.useState<string | undefined>(
    `+${info?.phone}` || '',
  );
  const [phoneError, setPhoneError] = React.useState<boolean>(false);
  const service_id = match.params.service_id;
  const provider_id = match.params.provider_id as string;
  const [imagesError, setImagesError] = React.useState<boolean>(false);
  const [resortError, setResortError] = React.useState<boolean>(false);
  const [subCategoryError, setSubCategoryError] =
    React.useState<boolean>(false);
  const [subLocationError, setSubLocationError] =
    React.useState<boolean>(false);
  const [instructorFirstNameError, setInstructorFirstNameError] =
    React.useState<boolean>(false);
  const [instructorLastNameError, setInstructorLastNameError] =
    React.useState<boolean>(false);
  const [titleError, setTitleError] = React.useState<boolean>(false);
  const [descError, setDescError] = React.useState<boolean>(false);
  const [costError, setCostError] = React.useState<boolean>(false);
  const [costFromError, setCostFromError] = React.useState<boolean>(false);
  const [openingHoursError, setOpeningHoursError] =
    React.useState<boolean>(false);
  const [closingHoursError, setClosingHoursError] =
    React.useState<boolean>(false);
  const [isErrors, setIsErrors] = React.useState<boolean>(false);
  const [requestInProgress, setRequestInProgress] =
    React.useState<boolean>(false);
  const phoneRef = React.useRef<HTMLDivElement>(null);
  const [phoneFieldWidth, setPhoneFieldWidth] = React.useState<number>(0);
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();
  const [isSubLocationsOptions, setIsSubLocationsOptions] =
    React.useState<boolean>(false);
  const role = handleStorage.getRole();

  const goToPrevStep = () => {
    goToStep('FIRST');
  };

  const getPricingPreview = async () => {
    const subcategories: components['schemas']['SubcategoryView'][] = [];
    for (const subcategory of infoState?.subCategories?.filter(
      item => item.value !== '',
    )) {
      subcategories.push({ id: parseInt(subcategory.value, 10) });
    }
    if (infoState.category?.value) {
      let response:
        | AxiosResponse<components['schemas']['PricingView']>
        | undefined = undefined;
      if (role === 'ACTIVITY_PROVIDER') {
        response = await previewPricing({
          subcategories,
          category: { id: parseInt(infoState.category?.value as string, 10) },
          childrenAllowed: infoState.childrenAllowed,
          pricing: infoState.pricing || {},
        });
      } else {
        response = await previewPricingByOperator(
          {
            subcategories,
            category: { id: parseInt(infoState.category?.value as string, 10) },
            childrenAllowed: infoState.childrenAllowed,
            pricing: infoState.pricing || {},
          },
          provider_id,
        );
      }
      if (response?.data) {
        setInfoState(infoState => ({
          ...infoState,
          pricing: {
            ...infoState.pricing,
            prices: response?.data.prices,
            discountsSummationEnabled: response?.data.discountsSummationEnabled,
            discounts: response?.data.discounts,
          },
        }));
        if (
          infoState.pricing?.allDayTariffEnabled !==
          response.data.allDayTariffEnabled
        ) {
          setInfoState(infoState => ({
            ...infoState,
            pricing: {
              ...infoState.pricing,
              allDayTariffEnabled: response?.data.allDayTariffEnabled,
            },
          }));
        }
        if (
          infoState.pricing?.subcategoryTariffs?.length !==
          response.data.subcategoryTariffs?.length
        ) {
          setInfoState(infoState => ({
            ...infoState,
            pricing: {
              ...infoState.pricing,
              subcategoryTariffs: response?.data.subcategoryTariffs,
            },
          }));
        }
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (
        apiError?.key?.includes('NotBlank') ||
        apiError?.key?.includes('Size') ||
        apiError?.key?.includes('Max')
      )
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [apiError.key]);

  React.useEffect(() => {
    const resort = resortOptions?.find(
      item => item?.value === infoState?.resort?.value,
    );
    setResortSelectValue(resort);
  }, [resortOptions]);

  React.useEffect(() => {
    if (phoneRef?.current?.clientWidth)
      setPhoneFieldWidth(phoneRef?.current?.clientWidth);
  }, [phoneRef?.current]);

  React.useEffect(() => {
    if (
      infoState.category?.type !== 'ACTIVITY' &&
      infoState.subCategories[0]?.value
    )
      getPricingPreview();
    else if (infoState.category?.type === 'ACTIVITY') getPricingPreview();
  }, [
    infoState.category,
    infoState.childrenAllowed,
    infoState.subCategories,
    infoState.pricing?.allDayTariffEnabled,
    infoState.pricing?.subcategoryTariffs,
  ]);

  React.useEffect(() => {
    if (infoState.pricing === null) {
      getPricingPreview();
    }
  }, [infoState.pricing]);

  const setChildrenAllowance = async (checked: boolean) => {
    setInfoState(infoState => ({ ...infoState, childrenAllowed: checked }));
  };

  const setDeliveryAllowance = async (checked: boolean) => {
    setInfoState(infoState => ({ ...infoState, deliveryAvailable: checked }));
  };

  const setDescription = (desc: string) => {
    if (descError) setDescError(false);
    if (apiError?.key?.includes('description'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState({ ...infoState, description: desc });
  };

  const setTitle = (title: string) => {
    if (titleError) setTitleError(false);
    if (apiError?.key?.includes('name'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState({ ...infoState, title: title });
  };

  const setInstructorName = (name: string) => {
    if (instructorFirstNameError) setInstructorFirstNameError(false);
    if (apiError?.key?.includes('expertFirstName'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState({ ...infoState, instructorName: name });
  };

  const setInstructorSurname = (name: string) => {
    if (instructorLastNameError) setInstructorLastNameError(false);
    if (apiError?.key?.includes('expertLastName'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState({ ...infoState, instructorSurname: name });
  };

  const setLocation = (location: number[]) => {
    setInfoState(infoState => ({ ...infoState, location: location }));
  };

  const setCost = (cost: string) => {
    if (costError) setCostError(false);
    if (apiError?.key?.includes('prices'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState({ ...infoState, cost: cost });
  };

  const setDetails = (details: string) => {
    if (apiError?.key?.includes('additionalInfo'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState({ ...infoState, details: details });
  };

  const onChangeCostFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (costFromError) setCostFromError(false);
    if (apiError?.key?.includes('minimumPrice'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    const newValue: number | string = parseInt(e.target.value, 10) || '';
    setInfoState({ ...infoState, costFrom: newValue.toString() });
  };

  const onChangeSubCategory = (
    index: number,
    value: { value: string; label: string },
  ) => {
    if (subCategoryError) setSubCategoryError(false);
    const info = update(infoState, {
      subCategories: { [index]: { $set: value } },
    });
    setInfoState(info);
  };

  const addSubCategory = () => {
    const info = update(infoState, {
      subCategories: { $push: [{ label: '', value: '' }] },
    });
    setInfoState(info);
  };

  const deleteSubCategory = (index: number) => {
    const info = update(infoState, {
      subCategories: { $splice: [[index, 1]] },
    });
    setInfoState(info);
  };

  const onChangeSubLocation = (
    index: number,
    value: { value: string; label: string },
  ) => {
    if (subLocationError) setSubLocationError(false);
    const info = update(infoState, {
      subLocations: { [index]: { $set: value } },
    });
    setInfoState(info);
  };

  const addSubLocation = () => {
    const info = update(infoState, {
      subLocations: { $push: [{ label: '', value: '' }] },
    });
    setInfoState(info);
  };

  const deleteSubLocation = (index: number) => {
    const info = update(infoState, {
      subLocations: { $splice: [[index, 1]] },
    });
    setInfoState(info);
  };

  const onChangeResort = (value: ValueType<any, false>) => {
    if (resortError) setResortError(false);
    setInfoState({
      ...infoState,
      resort: value,
      subLocations: [],
      location: undefined,
    });
    setResortSelectValue(value);
  };

  const onChangePhone = (val: any) => {
    if (phoneError) setPhoneError(false);
    setPhone(val);
    const phoneNumber = parseInt(val);
    setInfoState({ ...infoState, phone: phoneNumber.toString() });
  };

  const onChangePeopleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (apiError?.key?.includes('numberOfPeople'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    const newValue: number | string = parseInt(e.target.value, 10) || '';
    setInfoState({ ...infoState, peopleAmount: newValue.toString() });
  };

  const onChangeDurationHours = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (apiError?.key?.includes('durationInMinutes'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    const newValue: number | string = parseInt(e.target.value, 10) || '';
    setInfoState({
      ...infoState,
      duration: {
        ...infoState.duration,
        hours: newValue.toString(),
      },
    });
  };

  const onChangeDurationHDays = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (apiError?.key?.includes('durationInMinutes'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    const newValue: number | string = parseInt(e.target.value, 10) || '';
    setInfoState({
      ...infoState,
      duration: {
        ...infoState.duration,
        days: newValue.toString(),
      },
    });
  };

  const saveOpeningTime = (value: TimePickerValue) => {
    if (openingHoursError) setOpeningHoursError(false);
    setInfoState({ ...infoState, openingTime: value });
  };

  const saveClosingTime = (value: TimePickerValue) => {
    if (closingHoursError) setClosingHoursError(false);
    setInfoState({ ...infoState, closingTime: value });
  };

  const onChangeAllDayTariff = (val: boolean) => {
    const info = update(infoState, {
      pricing: { allDayTariffEnabled: { $set: val } },
    });
    setInfoState(info);
  };

  const onChangeDiscountSummation = (val: boolean) => {
    const info = update(infoState, {
      pricing: { discountsSummationEnabled: { $set: val } },
    });
    setInfoState(info);
  };

  const onChangeSubcategoryTariff = (
    subcategoryTariff: components['schemas']['SubcategoryTariffView'],
    index: number,
  ) => {
    const info = update(infoState, {
      pricing: { subcategoryTariffs: { [index]: { $set: subcategoryTariff } } },
    });
    setInfoState(info);
  };

  const onChangeDiscount = (value: number, index: number) => {
    const info = update(infoState, {
      pricing: { discounts: { [index]: { percentage: { $set: value } } } },
    });
    setInfoState(info);
  };

  const onChangePrice = (price: number, index: number) => {
    const info = update(infoState, {
      pricing: { prices: { [index]: { value: { $set: price } } } },
    });
    setInfoState(info);
  };

  const infoStateToActivity = (
    uploadedImages: components['schemas']['ActivityPictureView'][],
  ): components['schemas']['ActivityActivityUpdate'] => {
    const location = infoState?.location || infoState.resort?.location;
    const sublocations: components['schemas']['SublocationView'][] = [];
    for (const sublocation of infoState?.subLocations?.filter(
      item => item.value !== '',
    )) {
      sublocations.push({ id: parseInt(sublocation.value, 10) });
    }
    return {
      sublocations,
      location: { id: parseInt(infoState.resort?.value as string, 10) },
      category: { id: parseInt(infoState.category?.value as string, 10) },
      pictures: uploadedImages,
      description: infoState.description,
      additionalInfo: infoState.details,
      phone: infoState.phone as string,
      position: {
        longitude: location?.length && location[1],
        latitude: location?.length && location[0],
      },
      pricing: infoState.pricing as components['schemas']['PricingView'],
      childrenAllowed: infoState.childrenAllowed,
      name: infoState.title,
      numberOfPeople: parseInt(infoState.peopleAmount as string, 10) || 1,
      durationInMinutes:
        (parseInt(infoState.duration?.days as string, 10) || 0) * 1440 +
        (parseInt(infoState.duration?.hours as string, 10) || 1) * 60,
    };
  };

  const infoStateToExpert = (
    uploadedImages: components['schemas']['ActivityPictureView'][],
  ): components['schemas']['ExpertActivityUpdate'] => {
    const location = infoState?.location || infoState.resort?.location;
    const subcategories: components['schemas']['SubcategoryView'][] = [];
    for (const subcategory of infoState?.subCategories?.filter(
      item => item.value !== '',
    )) {
      subcategories.push({ id: parseInt(subcategory.value, 10) });
    }
    const sublocations: components['schemas']['SublocationView'][] = [];
    for (const sublocation of infoState?.subLocations?.filter(
      item => item.value !== '',
    )) {
      sublocations.push({ id: parseInt(sublocation.value, 10) });
    }
    return {
      subcategories,
      sublocations,
      location: { id: parseInt(infoState.resort?.value as string, 10) },
      category: { id: parseInt(infoState.category?.value as string, 10) },
      pictures: uploadedImages,
      description: infoState.description,
      pricing: infoState.pricing as components['schemas']['PricingView'],
      additionalInfo: infoState.details,
      phone: infoState.phone as string,
      position: {
        longitude: location?.length && location[1],
        latitude: location?.length && location[0],
      },
      childrenAllowed: infoState.childrenAllowed,
      expertFirstName: infoState.instructorName as string,
      expertLastName: infoState.instructorSurname as string,
      numberOfPeople: parseInt(infoState.peopleAmount as string, 10) || 1,
    };
  };

  const infoStateToRental = (
    uploadedImages: components['schemas']['ActivityPictureView'][],
  ): components['schemas']['RentalActivityUpdate'] => {
    const location = infoState?.location || infoState.resort?.location;
    const sublocations: components['schemas']['SublocationView'][] = [];
    for (const sublocation of infoState?.subLocations?.filter(
      item => item.value !== '',
    )) {
      sublocations.push({ id: parseInt(sublocation.value, 10) });
    }
    return {
      sublocations,
      location: { id: parseInt(infoState.resort?.value as string, 10) },
      category: { id: parseInt(infoState.category?.value as string, 10) },
      pictures: uploadedImages,
      description: infoState.description,
      prices: infoState.cost,
      additionalInfo: infoState.details,
      phone: infoState.phone as string,
      position: {
        longitude: location?.length && location[1],
        latitude: location?.length && location[0],
      },
      childrenAllowed: infoState.childrenAllowed,
      minimumPrice: parseInt(infoState.costFrom, 10) || 0,
      name: infoState.title,
      openFrom: infoState.openingTime as string,
      openUntil: infoState.closingTime as string,
      deliveryAvailable: infoState.deliveryAvailable as boolean,
    };
  };

  const infoStateToTransfer = (
    uploadedImages: components['schemas']['ActivityPictureView'][],
  ): components['schemas']['TransferActivityUpdate'] => {
    return {
      location: { id: parseInt(infoState.resort?.value as string, 10) },
      category: { id: parseInt(infoState.category?.value as string, 10) },
      pictures: uploadedImages,
      description: infoState.description,
      prices: infoState.cost,
      additionalInfo: infoState.details,
      phone: infoState.phone as string,
      childrenAllowed: infoState.childrenAllowed,
      minimumPrice: parseInt(infoState.costFrom, 10) || 0,
      name: infoState.title,
      openFrom: infoState.openingTime as string,
      openUntil: infoState.closingTime as string,
    };
  };

  const validateFields = (): boolean => {
    let validateResult = true;
    if (!(infoState?.images?.length > 0)) {
      validateResult = false;
      setImagesError(true);
    }
    if (infoState?.category?.type === 'EXPERT') {
      if (
        !(
          infoState?.subCategories?.filter(item => item.value !== '').length > 0
        )
      ) {
        validateResult = false;
        setSubCategoryError(true);
      }
      if (!infoState?.instructorName) {
        validateResult = false;
        setInstructorFirstNameError(true);
      }
      if (!infoState?.instructorSurname) {
        validateResult = false;
        setInstructorLastNameError(true);
      }
    } else {
      if (!infoState?.title) {
        validateResult = false;
        setTitleError(true);
      }
      if (
        ['RENTAL', 'TRANSFER'].includes(infoState?.category?.type as string)
      ) {
        if (!infoState?.openingTime) {
          validateResult = false;
          setOpeningHoursError(true);
        }
        if (!infoState?.closingTime) {
          validateResult = false;
          setClosingHoursError(true);
        }
      }
      if (!infoState?.resort?.value) {
        validateResult = false;
        setResortError(true);
      } else if (
        isSubLocationsOptions &&
        infoState?.category?.type !== 'TRANSFER' &&
        !(infoState?.subLocations?.filter(item => item.value !== '').length > 0)
      ) {
        validateResult = false;
        setSubLocationError(true);
      }
    }
    if (!(phone && isValidPhoneNumber(phone))) {
      validateResult = false;
      setPhoneError(true);
    }
    if (!infoState?.description) {
      validateResult = false;
      setDescError(true);
    }
    if (['RENTAL', 'TRANSFER'].includes(infoState?.category?.type as string)) {
      if (!infoState?.cost) {
        validateResult = false;
        setCostError(true);
      }
      if (!infoState?.costFrom) {
        validateResult = false;
        setCostFromError(true);
      }
    }
    return validateResult;
  };

  const saveServiceInfo = async () => {
    setIsErrors(false);
    if (validateFields()) {
      setRequestInProgress(true);
      const uploadedImages: components['schemas']['ActivityPictureView'][] = [];
      const existingImages: components['schemas']['ActivityPictureView'][] = [];
      for (const image of infoState?.images) {
        if (image?.file) {
          const formData = new FormData();
          formData.append('file', image?.file as Blob);
          const response = await uploadAttachment(formData);
          if (response?.data) {
            uploadedImages.push(response.data);
          }
        } else {
          existingImages.push(image.view);
        }
      }
      let createdServiceId: string | undefined = undefined;
      let response:
        | AxiosResponse<components['schemas']['ActivityDetails']>
        | undefined = undefined;
      try {
        if (newService) {
          if (infoState?.category?.type === 'ACTIVITY') {
            if (role === 'ACTIVITY_PROVIDER') {
              response = await createActivity(
                infoStateToActivity(uploadedImages),
              );
            } else {
              response = await createActivityByOperator(
                infoStateToActivity(uploadedImages),
                provider_id,
              );
            }
          } else if (infoState?.category?.type === 'EXPERT') {
            if (role === 'ACTIVITY_PROVIDER') {
              response = await createExpert(infoStateToExpert(uploadedImages));
            } else {
              response = await createExpertByOperator(
                infoStateToExpert(uploadedImages),
                provider_id,
              );
            }
          } else if (infoState?.category?.type === 'RENTAL') {
            if (role === 'ACTIVITY_PROVIDER') {
              response = await createRental(infoStateToRental(uploadedImages));
            } else {
              response = await createRentalByOperator(
                infoStateToRental(uploadedImages),
                provider_id,
              );
            }
          } else if (infoState?.category?.type === 'TRANSFER') {
            if (role === 'ACTIVITY_PROVIDER') {
              response = await createTransfer(
                infoStateToTransfer(uploadedImages),
              );
            } else {
              response = await createTransferByOperator(
                infoStateToTransfer(uploadedImages),
                provider_id,
              );
            }
          }
          createdServiceId = response?.data.id?.toString();
        } else {
          if (infoState?.category?.type === 'ACTIVITY') {
            await updateActivity(
              infoStateToActivity([...existingImages, ...uploadedImages]),
              service_id as string,
            );
          } else if (infoState?.category?.type === 'EXPERT') {
            await updateExpert(
              infoStateToExpert([...existingImages, ...uploadedImages]),
              service_id as string,
            );
          } else if (infoState?.category?.type === 'RENTAL') {
            await updateRental(
              infoStateToRental([...existingImages, ...uploadedImages]),
              service_id as string,
            );
          } else if (infoState?.category?.type === 'TRANSFER') {
            await updateTransfer(
              infoStateToTransfer([...existingImages, ...uploadedImages]),
              service_id as string,
            );
          }
        }
        if (createdServiceId) onSaveClick(createdServiceId);
        else onSaveClick();
      } catch (e) {
        //
      }
      setRequestInProgress(false);
    } else {
      setIsErrors(true);
    }
  };

  const resetTitleError = () => {
    if (titleError) setTitleError(false);
    if (apiError?.key?.includes('name'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const resetExpertFirstName = () => {
    if (instructorFirstNameError) setInstructorFirstNameError(false);
    if (apiError?.key?.includes('expertFirstName'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const resetExpertLastName = () => {
    if (instructorLastNameError) setInstructorLastNameError(false);
    if (apiError?.key?.includes('expertLastName'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const resetDescriptionError = () => {
    if (descError) setDescError(false);
    if (apiError?.key?.includes('description'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const resetPricesError = () => {
    if (costError) setCostError(false);
    if (apiError?.key?.includes('prices'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const resetAdditionalInfoError = () => {
    if (apiError?.key?.includes('additionalInfo'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  return (
    <>
      <ReturnLink linkName="Назад" onClickHandler={goToPrevStep} />
      <div className="edit-service__title-row page__title title">
        {!newService ? 'Редактирование информации' : 'Создание новой услуги'}
      </div>
      <div className="edit-service__instruction-link">
        <a
          className="login-page__policy-link account-view__policy-link button-text underlined"
          href="/Инструкция_по_заполнению_информации_об_услуге_для_инструкторов.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Инструкция по заполнению информации об услуге.pdf
        </a>
      </div>
      <div className="view-info__item-title edit-service__images-title header1">
        Фотоматериалы
      </div>
      <ImagesArea
        infoState={infoState}
        setInfoState={setInfoState}
        error={imagesError}
        setError={setImagesError}
        maxFilesQty={7}
        type={'PHOTOVIDEO'}
      />
      <div className="edit-service__content">
        {infoState.category?.type === 'EXPERT' && (
          <div
            className={classNames(
              'view-info__item-title edit-service__category-title header2',
              'edit-service__sub-category-title',
              subCategoryError && 'error',
            )}
          >
            Подкатегории
          </div>
        )}
      </div>
      <div className="edit-info__item">
        {infoState.category?.type === 'EXPERT' && (
          <SubCategories
            type="SUBCATEGORIES"
            serviceType={infoState.category?.type}
            categoryId={infoState.category.value}
            subCategories={infoState?.subCategories}
            changeSubCategory={onChangeSubCategory}
            addSubCategory={addSubCategory}
            deleteSubCategory={deleteSubCategory}
            setIsSubLocationsOptions={setIsSubLocationsOptions}
            isSubLocationsOptions={isSubLocationsOptions}
          />
        )}
      </div>
      {infoState.category?.value && (
        <div className="edit-service__content">
          <div className="view-info__item-title edit-service__category-title header1">
            Локация
          </div>
          <div className="edit-service__select">
            <Dropdown
              placeholder="Выберите..."
              options={resortOptions}
              menuWidth={'100%'}
              value={resortSelectValue}
              isSearchable={false}
              onChange={onChangeResort}
              error={resortError}
            />
          </div>
          {infoState.resort?.value &&
            isSubLocationsOptions &&
            infoState.category?.type !== 'TRANSFER' && (
              <div
                className={classNames(
                  'view-info__item-title edit-service__category-title header2',
                  'edit-service__sub-category-title',
                  subLocationError && 'error',
                )}
              >
                Курорт
              </div>
            )}
        </div>
      )}
      <div className="edit-info__item">
        {infoState.resort?.value && infoState.category?.type !== 'TRANSFER' && (
          <SubCategories
            type="SUBLOCATIONS"
            serviceType={
              infoState.category?.type as 'EXPERT' | 'RENTAL' | 'ACTIVITY'
            }
            categoryId={
              (resortSelectValue as { value: string; label: string })?.value
            }
            subCategories={infoState?.subLocations}
            changeSubCategory={onChangeSubLocation}
            addSubCategory={addSubLocation}
            deleteSubCategory={deleteSubLocation}
            setIsSubLocationsOptions={setIsSubLocationsOptions}
            isSubLocationsOptions={isSubLocationsOptions}
          />
        )}
      </div>
      <div className="edit-service__content">
        {infoState.category?.type && infoState.category?.type !== 'EXPERT' && (
          <>
            <div className="view-info__item-title edit-service__category-title header1">
              {infoState.category?.type === 'RENTAL' && 'Название проката'}
              {infoState.category?.type === 'ACTIVITY' && 'Название тура'}
              {infoState.category?.type === 'TRANSFER' &&
                'Название организации / ИП'}
            </div>
            <div className="edit-info__item">
              <div className="edit-service__title-input">
                <EditableDiv
                  value={infoState?.title as string}
                  saveValue={setTitle}
                  singleLine={true}
                  error={titleError || apiError?.key?.includes('name')}
                  resetError={resetTitleError}
                  data-placeholder={
                    (infoState.category?.type === 'RENTAL' &&
                      'Название проката') ||
                    (infoState.category?.type === 'ACTIVITY' &&
                      'Название тура') ||
                    (infoState.category?.type === 'TRANSFER' &&
                      'Название организации / ИП')
                  }
                />
              </div>
            </div>
          </>
        )}
        {infoState.category?.type === 'EXPERT' && (
          <>
            <div className="view-info__item-title edit-service__category-title header1">
              ИФ инструктора
            </div>
            <div className="edit-info__item">
              <div className="edit-service__title-input instructor-name">
                <EditableDiv
                  value={infoState?.instructorName as string}
                  saveValue={setInstructorName}
                  singleLine={true}
                  data-placeholder={'Имя'}
                  error={
                    instructorFirstNameError ||
                    apiError?.key?.includes('expertFirstName')
                  }
                  resetError={resetExpertFirstName}
                />
              </div>
              <div className="edit-service__title-input instructor-surname">
                <EditableDiv
                  value={infoState?.instructorSurname as string}
                  saveValue={setInstructorSurname}
                  singleLine={true}
                  data-placeholder={'Фамилия'}
                  error={
                    instructorLastNameError ||
                    apiError?.key?.includes('expertLastName')
                  }
                  resetError={resetExpertLastName}
                />
              </div>
            </div>
            <div className="view-info__item-title edit-service__desc-title header1">
              Макс. кол-во участников на одном занятии
            </div>
            <div className="edit-service__amount max-amount">
              <Input
                value={infoState?.peopleAmount}
                onChange={onChangePeopleAmount}
                placeholder={'1'}
                error={apiError?.key?.includes('numberOfPeople')}
              />
            </div>
          </>
        )}
        <div className="view-info__item-title edit-service__children-title header1">
          Дети
        </div>
        <div className="edit-service__children">
          <div className="edit-service__child-option-wrapper">
            <div className="edit-service__child-option normal-text">
              Доступно для детей
            </div>
          </div>
          <div className="edit-service__child-switch">
            <Switch
              checked={infoState.childrenAllowed}
              handleChange={setChildrenAllowance}
            />
          </div>
        </div>
        <div className="view-info__item-title edit-service__desc-title header1">
          Описание
        </div>
        <EditableDiv
          value={infoState.description}
          saveValue={setDescription}
          data-placeholder={
            infoState.category?.type !== 'TRANSFER'
              ? 'Описание'
              : 'Пожалуйста, укажите возможные маршруты, по которым осуществляется трансфер, и другую основную информацию (например, перевозите ли вы инвентарь, есть ли у вас детское кресло)'
          }
          error={descError || apiError?.key?.includes('description')}
          resetError={resetDescriptionError}
        />
        <div className="view-info__item-title edit-service__desc-title header1">
          Номер телефона
        </div>
        <div className="edit-service__title-input" ref={phoneRef}>
          <IntlPhoneInput
            placeholder="Номер телефона"
            onChange={onChangePhone}
            error={phoneError}
            value={phone}
            menuWidth={phoneFieldWidth}
          />
        </div>
      </div>
      {infoState.resort?.location && infoState.category?.type !== 'TRANSFER' && (
        <>
          <div className="view-info__item-title edit-service__desc-title edit-service__location-title header1">
            {infoState.category?.type === 'EXPERT'
              ? 'Место встречи'
              : 'Местоположение'}
          </div>
          <Map
            mode="EDIT"
            coordinates={
              infoState.location
                ? infoState.location
                : infoState.resort?.location
            }
            setAddress={setLocationAddress}
            setNewLocation={setLocation}
          />
          <div className="view-info__item location-item full-width normal-text">
            <LocationIcon />
            {locationAddress}
          </div>
        </>
      )}
      {['RENTAL', 'TRANSFER'].includes(infoState.category?.type as string) && (
        <>
          <div className="view-info__item-title edit-service__desc-title edit-service__hours-title header1">
            Часы работы
          </div>
          <div className="edit-service__duration">
            <div>
              <div className="edit-info__time-label label-text">С</div>
              <div
                className={classNames(
                  'time-slot__container  edit-info__time-container normal-text',
                  openingHoursError && 'error',
                )}
              >
                <TimePicker
                  value={infoState?.openingTime as string}
                  onChange={saveOpeningTime}
                  disableClock={true}
                  clearIcon={null}
                  className="time-slot__picker"
                  hourPlaceholder={'––'}
                  minutePlaceholder={'––'}
                  format={'HH:mm'}
                />
              </div>
            </div>
            <div>
              <div className="edit-info__time-label label-text">До</div>
              <div
                className={classNames(
                  'time-slot__container  edit-info__time-container normal-text',
                  closingHoursError && 'error',
                )}
              >
                <TimePicker
                  value={infoState?.closingTime as string}
                  onChange={saveClosingTime}
                  disableClock={true}
                  clearIcon={null}
                  className="time-slot__picker"
                  hourPlaceholder={'––'}
                  minutePlaceholder={'––'}
                  format={'HH:mm'}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="edit-service__content">
        {infoState.category?.type === 'RENTAL' && (
          <>
            <div className="view-info__item-title edit-service__desc-title header1">
              Доставка
            </div>
            <div className="edit-service__children">
              <div className="edit-service__delivery-option-wrapper">
                <div className="edit-service__child-option normal-text">
                  Есть доставка снаряжения
                </div>
              </div>
              <div className="edit-service__child-switch">
                <Switch
                  checked={infoState.deliveryAvailable as boolean}
                  handleChange={setDeliveryAllowance}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {infoState.category?.type === 'ACTIVITY' && (
        <>
          <div className="view-info__item-title edit-service__desc-title edit-service__amount-title header1">
            Количество человек
          </div>
          <div className="edit-service__amount">
            <Input
              value={infoState?.peopleAmount}
              onChange={onChangePeopleAmount}
              placeholder={'1'}
              error={apiError?.key?.includes('numberOfPeople')}
            />
          </div>
          <div className="view-info__item-title edit-service__duration-title header1">
            Длительность
          </div>
          <div className="edit-service__duration">
            <div className="edit-service__amount">
              <Input
                value={infoState.duration?.days}
                onChange={onChangeDurationHDays}
                label="Дней"
                placeholder={'0'}
                error={apiError?.key?.includes('durationInMinutes')}
              />
            </div>
            <div className="edit-service__amount">
              <Input
                value={infoState.duration?.hours}
                onChange={onChangeDurationHours}
                label="Часов"
                placeholder={'1'}
                error={apiError?.key?.includes('durationInMinutes')}
              />
            </div>
          </div>
        </>
      )}
      {infoState.category?.type === 'ACTIVITY' && (
        <>
          <div className="view-info__item-title edit-service__desc-title detailed-cost-title header1">
            Стоимость
          </div>
          <Prices
            label="Взрослые (с 12 лет)"
            prices={infoState.pricing?.prices}
            customerType={'ADULT'}
            onChangePrice={onChangePrice}
          />
          <Prices
            label="Дети (до 12 лет)"
            prices={infoState.pricing?.prices}
            customerType={'CHILD'}
            onChangePrice={onChangePrice}
          />
          <Discounts
            serviceType={infoState.category?.type}
            discountsSummationEnabled={
              infoState.pricing?.discountsSummationEnabled
            }
            discounts={infoState.pricing?.discounts}
            onChangeDiscountSummation={onChangeDiscountSummation}
            onChangeDiscount={onChangeDiscount}
          />
        </>
      )}
      {infoState.category?.type === 'EXPERT' &&
        infoState.subCategories?.length > 0 && (
          <>
            <div className="view-info__item-title edit-service__desc-title detailed-cost-title header1">
              <>Стоимость (за 1&nbsp;час)</>
            </div>
            <Rates
              subcategoryTariffs={infoState.pricing?.subcategoryTariffs}
              allDayTariffEnabled={infoState.pricing?.allDayTariffEnabled}
              onChangeAllDayTariff={onChangeAllDayTariff}
              onChangeSubcategoryTariff={onChangeSubcategoryTariff}
            />
            <Prices
              label="Взрослые (с 12 лет)"
              prices={infoState.pricing?.prices}
              customerType={'ADULT'}
              onChangePrice={onChangePrice}
            />
            <Prices
              label="Дети (до 12 лет)"
              prices={infoState.pricing?.prices}
              customerType={'CHILD'}
              onChangePrice={onChangePrice}
            />
            <Discounts
              serviceType={infoState.category?.type}
              discountsSummationEnabled={
                infoState.pricing?.discountsSummationEnabled
              }
              discounts={infoState.pricing?.discounts}
              onChangeDiscountSummation={onChangeDiscountSummation}
              onChangeDiscount={onChangeDiscount}
            />
          </>
        )}
      <div className="edit-service__content">
        {['RENTAL', 'TRANSFER'].includes(
          infoState.category?.type as string,
        ) && (
          <>
            <div className="view-info__item-title edit-service__desc-title header1">
              {infoState.category?.type === 'RENTAL'
                ? 'Стоимость аренды снаряжения'
                : 'Стоимость трансфера'}
            </div>
            <div className="edit-service__cost-from label-text">
              от
              <Input
                value={infoState.costFrom}
                onChange={onChangeCostFrom}
                containerClassName="edit-service__cost-from-input"
                placeholder={'0'}
                error={costFromError || apiError?.key?.includes('minimumPrice')}
              >
                <RubIcon className="edit-service__rub-icon" />
              </Input>
            </div>
          </>
        )}
        {['RENTAL', 'TRANSFER'].includes(
          infoState.category?.type as string,
        ) && (
          <>
            <div className="view-info__item-title edit-service__desc-title header1">
              Стоимость
            </div>
            <EditableDiv
              value={infoState.cost}
              saveValue={setCost}
              data-placeholder={'Дополнительная информация о стоимости'}
              error={costError || apiError?.key?.includes('prices')}
              resetError={resetPricesError}
            />{' '}
          </>
        )}
        <div className="view-info__item-title edit-service__desc-title header1">
          Дополнительная информация
        </div>
        <EditableDiv
          value={infoState.details}
          saveValue={setDetails}
          data-placeholder={'Необязательное поле'}
          error={apiError?.key?.includes('additionalInfo')}
          resetError={resetAdditionalInfoError}
        />
        <div className="edit-service__buttons">
          <Button
            className="edit-service__save-button"
            onClick={saveServiceInfo}
            disabled={requestInProgress}
          >
            Сохранить
          </Button>
          <Button
            className="edit-service__cancel-button"
            onClick={onCancelClick}
            mode="secondary"
          >
            Отменить
          </Button>
        </div>
        {apiError?.key?.includes('NotBlank') ||
        apiError?.key?.includes('Size') ||
        apiError?.key?.includes('Max') ? (
          <div className="login-page__error-message normal-text">
            {apiError?.message}
          </div>
        ) : isErrors ? (
          <div className="login-page__error-message normal-text">
            Заполните все поля
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default withRouter(EditInfo);
