import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import BurgerIcon from '../../images/LandingIcons/BurgerIcon';
import Divider from '../Divider/Divider';
import MainMenuModal from './modal/MainMenuModal';

function Header() {
  const history = useHistory();

  const [showMenu, setShowMenu] = useState(false);

  const goTo = (path: string) => {
    history.push(path);
  };

  return (
    <div className="navigation__container">
      <Divider className="divider__horizontal">
        <Link to="/" className="navigation__logo" />
      </Divider>
      <div className="navigation__content">
        <div className="navigation__content-375">
          <button
            className="navigation__content-375-burger"
            onClick={() => setShowMenu(!showMenu)}
          >
            <BurgerIcon />
          </button>
          <div className="navigation__content-375-logo">
            <Link to="/" className="navigation__logo" />
          </div>
        </div>
        <div className="navigation__links">
          <div className="header__link">
            <Link to="/" className="navigation__item-link">
              Главная
            </Link>
          </div>
          <div className="header__link">
            <Link to="/subcategories" className="navigation__item-link">
              Что есть в ADVA?
            </Link>
          </div>
          <div className="header__link">
            <Link to="/locations" className="navigation__item-link">
              Локации
            </Link>
          </div>
          <div className="header__link">
            <Link to="/blogs" className="navigation__item-link">
              Полезное для путешествий
            </Link>
          </div>
        </div>
        <div className="navigation__wrapper-button">
          <button
            className="navigation__button"
            onClick={() => goTo('/providerinfo')}
          >
            <p className="header__button-text">Разместить услугу</p>
          </button>
        </div>
      </div>
      {showMenu && <MainMenuModal handleClose={() => setShowMenu(false)} />}
    </div>
  );
}

export default Header;
