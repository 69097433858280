import React from 'react';
import { useHistory } from 'react-router-dom';

import Divider from '../../Divider/Divider';

function MainMenuModal(props: any) {
  const history = useHistory();

  const goTo = (path: string, blank?: boolean) => {
    props.handleClose();
    if (!blank) {
      history.push(path);
      return;
    }

    window.open(path, '_blank')?.focus();
  };

  return (
    <div className="header__modal">
      <div className="header__modal-content">
        <div>
          <div className="header__modal-menu-links">
            <button
              className="header__modal-menu-links-text"
              onClick={() => goTo('/')}
            >
              <p>Главная страница</p>
            </button>
            {/* <div className="header__modal-divider" /> */}
            <button
              className="header__modal-menu-links-text"
              onClick={() => goTo('/subcategories')}
            >
              <p>Что есть в ADVA?</p>
            </button>
            <button
              className="header__modal-menu-links-text"
              onClick={() => goTo('/locations')}
            >
              <p>Локации</p>
            </button>
            <button
              className="header__modal-menu-links-text"
              onClick={() => goTo('/blogs')}
            >
              <p>Полезное для путешествий</p>
            </button>
          </div>
          <button
            className="header__modal-menu-button"
            onClick={() => goTo('/providerinfo')}
          >
            <p className="header__modal-menu-button-text">Разместить услугу</p>
          </button>
        </div>
        <div className="header__modal-menu-info">
          <div>
            <p className="header__modal-menu-info-label">По любым вопросам:</p>
            <p className="header__modal-menu-info-email">info@adva.travel</p>
          </div>
          <div className="header__modal-menu-info-social">
            <button
              type="button"
              className="telegram__link-button"
              onClick={() => goTo('https://t.me/advatravel', true)}
            />
            <Divider width="10px" />
            <button
              type="button"
              className="vk__link-button"
              onClick={() => goTo('https://vk.com/advaapp', true)}
            />
            <Divider width="10px" />
            <button
              type="button"
              className="r__link-button"
              onClick={() => goTo('https://rutube.ru/channel/24261482/', true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainMenuModal;
