/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Divider from '../../../components/Divider/Divider';
import altai from '../../MainPage/Locations/images/altai.png';
import krasnodar from '../../MainPage/Locations/images/krasnodar.png';
import leningrad from '../../MainPage/Locations/images/leningrad.png';
import NavigationIcon from '../../../images/LandingIcons/NavigationIcon';
import { Link, useHistory } from 'react-router-dom';
import { components } from '../../../generated/apiTypes';
import { getFeedLocations } from '../../../services/apiRequests';
import LandingLayout from '../../../components/LandingLayout/LandingLayout';
import ArrowNavigationIcon from '../../../images/LandingIcons/ArrowNavigationIcon';
import ArrowIconLeft from '../../../images/LandingIcons/ArrowIconLeft';

const LocationCategories = () => {
  const history = useHistory();

  const [maxResults, setMaxResults] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<
    components['schemas']['LocationView'][] | undefined
  >([]);

  const getAllPosts = async () => {
    const response = await getFeedLocations(maxResults * page, false);
    setData(response.data.results);
  };

  const goTo = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    getAllPosts();
    window.scrollTo(0, 0);
  }, []);

  return (
    <LandingLayout>
      <div className="locationsCategories__container">
        <Divider className="divider__horizontal" />
        <div className="locationsCategories__content">
          <div className="locationsCategories__navigation-links">
            <Link to="/">Главная</Link>
            <ArrowNavigationIcon />
            <p>Локации ADVA</p>
          </div>
          <div className="locationsCategories__navigation-links_1024">
            <ArrowIconLeft />
            <Link to="/">Главная</Link>
          </div>
          <h2 className="block__title-dark">Локации ADVA</h2>
          <div className="locationsCategories__cards-page">
            {data?.map(location => (
              <button
                key={location.id}
                className="locationsCategories__card-item"
                style={{
                  background: `url(${location.feedPicture?.url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                onClick={() => goTo(`/locations/article/${location.id}`)}
              >
                <div className="locationsCategories__cards-item_bottom">
                  <p className="locationsCategories__cards-item_title">
                    {location.name}
                  </p>
                </div>
              </button>
            ))}
          </div>
          <Divider height="20px" />
        </div>
        <Divider className="divider__horizontal" />
      </div>
    </LandingLayout>
  );
};

export default LocationCategories;
