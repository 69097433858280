import * as React from 'react';

import Button from '../../../../../components/Button';
import { ReactComponent as AddIcon } from '../../../../../images/Services/icn-add.svg';
import {
  getSubCategories,
  getSubLocations,
} from '../../../../../services/apiRequests';
import SubCategory from './SubCategory';

interface ISubCategories {
  type: 'SUBCATEGORIES' | 'SUBLOCATIONS';
  serviceType: 'EXPERT' | 'RENTAL' | 'ACTIVITY';
  categoryId: string;
  subCategories: { value: string; label: string }[];
  changeSubCategory(
    index: number,
    value: { value: string; label: string },
  ): void;
  addSubCategory(): void;
  deleteSubCategory(index: number): void;
  setIsSubLocationsOptions(val: boolean): void;
  isSubLocationsOptions: boolean;
}

const SubCategories: React.FC<ISubCategories> = ({
  subCategories,
  categoryId,
  changeSubCategory,
  addSubCategory,
  deleteSubCategory,
  type,
  serviceType,
  setIsSubLocationsOptions,
  isSubLocationsOptions,
}) => {
  const [subCategoryOptionsState, setSubCategoryOptionsState] = React.useState<
    { value: string; label: string }[]
  >([]);
  const [subCategoriesState, setSubCategoriesState] =
    React.useState<{ value: string; label: string }[]>(subCategories);
  const [subCategoryOptions, setSubCategoryOptions] = React.useState<
    { value: string; label: string }[]
  >([]);

  const getSubCategoryOptions = async () => {
    const response =
      type === 'SUBCATEGORIES'
        ? await getSubCategories(categoryId)
        : await getSubLocations(categoryId);
    if (response?.data) {
      const options = [];
      for (const item of response.data) {
        options.push({
          label: item?.name as string,
          value: (item?.id as number).toString(),
        });
      }
      setSubCategoryOptions(options);
      if (type === 'SUBLOCATIONS') {
        setIsSubLocationsOptions(options?.length > 0);
      }
    }
  };

  React.useEffect(() => {
    const options = subCategoryOptions?.filter(
      it => !subCategories?.some(item => item.value === it.value),
    );
    setSubCategoryOptionsState(options);
    setSubCategoriesState(subCategories);
  }, [subCategories, subCategoryOptions]);

  React.useEffect(() => {
    setSubCategoryOptionsState([]);
    setSubCategoryOptions([]);
    if (categoryId) getSubCategoryOptions();
  }, [categoryId]);

  const onAddSubCategory = () => {
    if (
      serviceType === 'EXPERT' &&
      subCategories.length < subCategoryOptions.length
    ) {
      addSubCategory();
    } else if (subCategories.length < 1) {
      addSubCategory();
    }
  };

  return (
    <div className="sub-categories__container">
      {subCategoriesState?.map((item, index) => (
        <SubCategory
          key={index}
          index={index}
          type={type}
          subCategoryOptions={subCategoryOptionsState}
          subCategoryFullOptions={subCategoryOptions}
          subCategoryId={item?.value}
          changeSubCategory={changeSubCategory}
          deleteSubCategory={deleteSubCategory}
        />
      ))}
      {(isSubLocationsOptions || type === 'SUBCATEGORIES') && (
        <Button
          className="sub-categories__add-btn edit-slot__add-time-btn"
          mode="secondary"
          onClick={onAddSubCategory}
          disabled={
            (serviceType === 'EXPERT' &&
              subCategories.length === subCategoryOptions.length) ||
            (serviceType !== 'EXPERT' && subCategories.length > 0)
          }
        >
          <AddIcon className="services__add-btn-icon" />
          Добавить
        </Button>
      )}
    </div>
  );
};

export default SubCategories;
