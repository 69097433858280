import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ValueType } from 'react-select';

import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import ApiErrorUI from '../../components/ApiErrorUI';
import Dropdown from '../../components/Dropdown';
import Layout from '../../components/Layout';
import PaginationRow from '../../components/PaginationRow';
import SearchInput from '../../components/SearchInput';
import { components } from '../../generated/apiTypes';
import { getAllReservations, getLocations } from '../../services/apiRequests';
import { selectApiError, setApiError } from '../../store/commonSlice';
import Booking from '../Service/components/Booking';

import './style.scss';

const Index: React.FC<RouteComponentProps> = ({ location }) => {
  const [searchInputValue, setSearchInputValue] = React.useState<string>('');
  const [data, setData] =
    React.useState<components['schemas']['ReservationView'][]>();
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [maxResults, setMaxResults] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(0);
  const [resortSelectValue, setResortSelectValue] = React.useState<
    ValueType<any, false> | undefined
  >({ value: '0', label: 'Все локации' });
  const [resortOptions, setResortOptions] = React.useState<
    { value: string; label: string }[]
  >([]);

  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();

  const getAllBookings = async () => {
    let group: 'ALL' | 'REVIEW_MODERATION_PENDING' = 'ALL';
    if (location.pathname === '/operator/newreviews')
      group = 'REVIEW_MODERATION_PENDING';
    const response = await getAllReservations(
      maxResults * page,
      group,
      searchInputValue,
      resortSelectValue?.value > 0 ? resortSelectValue?.value : [],
    );
    if (response.data) {
      setData(response.data?.results);
      setMaxResults(response.data.maxResults as number);
      setTotalPages(response.data.totalPages as number);
    }
  };

  React.useEffect(() => {
    getOptions();
    return () => {
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    };
  }, []);

  React.useEffect(() => {
    setData(undefined);
    setTotalPages(0);
    getAllBookings();
  }, [page]);

  React.useEffect(() => {
    setPage(0);
    if (page === 0) {
      setData(undefined);
      setTotalPages(0);
      getAllBookings();
    }
  }, [searchInputValue, resortSelectValue?.value, location.pathname]);

  const getOptions = async () => {
    const response = await getLocations();
    if (response?.data) {
      const resortOptions = [];
      resortOptions.push({
        value: '0',
        label: 'Все локации',
      });
      for (const item of response.data) {
        resortOptions.push({
          value: (item?.id as number).toString(),
          label: item?.name as string,
        });
      }
      setResortOptions(resortOptions);
    }
  };

  const onChangeResort = (value: ValueType<any, false>) => {
    setResortSelectValue(value);
  };

  const onChangeSearchInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  const clearSearchInputValue = () => {
    setSearchInputValue('');
  };

  return (
    <Layout>
      {apiError?.isError ? (
        <ApiErrorUI />
      ) : (
        <>
          <div className="page__title title">
            {location.pathname === '/operator/newreviews'
              ? 'Новые отзывы'
              : 'Бронирования'}
          </div>
          <div className="all-providers__controls-row">
            <div className="all-providers__search-input-wrapper">
              <SearchInput
                onInput={onChangeSearchInputValue}
                value={searchInputValue}
                placeholder="Номер бронирования"
                clearInputValue={clearSearchInputValue}
              />
            </div>
            <div className="all-providers__location-select">
              <Dropdown
                placeholder="Фильтр по локации"
                options={resortOptions}
                menuWidth={'100%'}
                value={resortSelectValue}
                isSearchable={false}
                onChange={onChangeResort}
              />
            </div>
          </div>
          <div className="all-bookings__bookings-container">
            {data &&
              (data?.length > 0 ? (
                data?.map(item => (
                  <Booking
                    id={(item.id as number).toString()}
                    serialNumber={item.serialNumber as string}
                    duration={item.durationInHours}
                    cost={item.cost}
                    review={item.review}
                    resort={`${item.activity?.location?.name as string}${
                      item?.sublocation?.name
                        ? `, ${item.sublocation.name}`
                        : ''
                    }`}
                    subcategory={item.subcategory?.name}
                    time={format(
                      new Date(`${new Date().toDateString()} ${item.time}`),
                      'HH:mm',
                      {
                        locale: ru,
                      },
                    )}
                    amount={item.numberOfPeople as number}
                    amountAdults={item.numberOfAdults as number}
                    amountChildren={item.numberOfChildren as number}
                    providerStatus={item.providerStatus}
                    customerStatus={item.customerStatus}
                    date={format(new Date(item.date as string), 'd MMMM yyyy', {
                      locale: ru,
                    })}
                    type={item?.activity?.category?.type}
                    allDayReservation={item?.allDayReservation as boolean}
                  />
                ))
              ) : (
                <div className="services__empty_message normal-text">
                  Список бронирований пуст
                </div>
              ))}
            <PaginationRow
              totalPages={totalPages}
              setPage={setPage}
              page={page}
            />
          </div>
        </>
      )}
    </Layout>
  );
};

export default withRouter(Index);
