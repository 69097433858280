import * as React from 'react';
import { ValueType } from 'react-select';

import classNames from 'classnames';

import Dropdown from '../../../../../components/Dropdown';
import { ReactComponent as CloseIcon } from '../../../../../images/EditSlot/icn-close.svg';

import './style.scss';

interface ISubCategory {
  type: 'SUBCATEGORIES' | 'SUBLOCATIONS';
  subCategoryOptions: { value: string; label: string }[];
  subCategoryFullOptions: { value: string; label: string }[];
  subCategoryId: string;
  index: number;
  changeSubCategory(
    index: number,
    value: { value: string; label: string },
  ): void;
  deleteSubCategory(index: number): void;
}

const SubCategory: React.FC<ISubCategory> = ({
  subCategoryOptions,
  subCategoryFullOptions,
  subCategoryId,
  index,
  changeSubCategory,
  deleteSubCategory,
  type,
}) => {
  const [subCategorySelectValue, setSubCategorySelectValue] = React.useState<
    ValueType<any, false> | undefined
  >(undefined);

  React.useEffect(() => {
    if (subCategoryId) {
      const subCategory = subCategoryFullOptions?.find(
        item => item?.value === subCategoryId,
      );
      setSubCategorySelectValue(subCategory);
    } else setSubCategorySelectValue(null);
  }, [subCategoryId, subCategoryFullOptions]);

  const onChangeSubCategory = (value: ValueType<any, false>) => {
    setSubCategorySelectValue(value);
    changeSubCategory(index, value);
  };

  const onDeleteSubCategory = () => {
    deleteSubCategory(index);
  };

  return (
    <div
      className={classNames(
        'sub-category__container',
        type.toString().toLowerCase(),
      )}
    >
      <Dropdown
        placeholder="Выберите..."
        options={subCategoryOptions}
        menuWidth={'100%'}
        value={subCategorySelectValue}
        isSearchable={false}
        onChange={onChangeSubCategory}
        withoutIndicator={true}
        padding="8px 36px 8px 12px"
      />
      <button
        className="sub-category__delete-btn"
        onClick={onDeleteSubCategory}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export default SubCategory;
