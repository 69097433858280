import * as React from 'react';

function HumanIcon3(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={55}
      height={55}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.224 13.988a6.376 6.376 0 100-12.753 6.376 6.376 0 000 12.753zM27.224 36.291a6.82 6.82 0 100-13.64 6.82 6.82 0 000 13.64zM27.32 16.303l-4.518.68c-.305.04-.582.082-.832.249-3.756 2.675-5.78 4.269-12.032 5.96M21.25 26.172c-2.967 1.29-7.333 1.705-11.187.666"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M1.65 25.604l4.823-3.354a2.218 2.218 0 012.939.346l.374.43c.762.873.79 2.148.056 3.05l-1.179 1.441c-1.136 1.4-2.772 2.065-4.56 1.858l-.666-.084c-1.011-.11-1.802-.707-2.217-1.622l-.084-.194a1.513 1.513 0 01.513-1.87z"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.744 26.2c1.872 3.881 4.187 6.598 6.89 9.204M3.313 29.277c2.703 5.989 7.305 11.478 11.962 15.595"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M19.156 31.87h-.138a2.077 2.077 0 00-2.19 1.76L14.859 46.52a1.986 1.986 0 00.43 1.608c.374.471.873.735 1.47.777l.221.027a2.076 2.076 0 002.218-1.774l1.885-12.905a2.03 2.03 0 00-.443-1.608 2.047 2.047 0 00-1.484-.777z"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.612 37.442c2.12.846 3.022 1.303 6.89 1.455M18.075 48.725v4.297M27.182 16.303l4.519.665c.291.056.568.083.818.264 3.756 2.661 5.794 4.269 12.046 5.96M33.24 26.172c2.966 1.276 7.333 1.705 11.2.666"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M52.84 25.59l-4.824-3.354a2.202 2.202 0 00-2.925.36l-.374.43a2.335 2.335 0 00-.07 3.05l1.179 1.441c1.137 1.386 2.786 2.052 4.574 1.844l.666-.07c.998-.125 1.802-.707 2.204-1.622l.097-.194a1.541 1.541 0 00-.527-1.885z"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.76 26.2c-1.886 3.867-4.187 6.598-6.89 9.204M51.19 29.277c-2.716 5.989-7.304 11.464-11.962 15.595"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M35.347 31.87h.124a2.078 2.078 0 012.19 1.76L39.63 46.52a1.99 1.99 0 01-.416 1.608 1.994 1.994 0 01-1.47.777l-.22.013a2.077 2.077 0 01-2.233-1.774L33.42 34.254a1.976 1.976 0 01.444-1.622 2.03 2.03 0 011.483-.762z"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.878 37.428c-2.107.86-3.008 1.303-6.89 1.47M36.414 48.712v4.31"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HumanIcon3;
