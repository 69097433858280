import * as React from 'react';

import classNames from 'classnames';

import { components } from '../../generated/apiTypes';

import './style.scss';

interface IRateRadio {
  rates: ('STANDARD' | 'SPECIAL' | 'POSITION' | 'HOTEL' | 'CUSTOM')[];
  index?: number;
  view?: boolean;
  value:
    | components['schemas']['SubcategoryTariffView']
    | ('POSITION' | 'HOTEL' | 'CUSTOM');
  onChangeSubcategoryTariff?(
    subcategoryTariff: components['schemas']['SubcategoryTariffView'],
    index: number,
  ): void;
  onChangeMeetingPoint?(value: 'POSITION' | 'HOTEL' | 'CUSTOM'): void;
}

const RateRadio: React.FC<IRateRadio> = ({
  rates,
  index,
  view,
  onChangeSubcategoryTariff,
  onChangeMeetingPoint,
  value,
}) => {
  const onChangeHandler = (
    val: 'STANDARD' | 'SPECIAL' | 'POSITION' | 'HOTEL' | 'CUSTOM',
  ) => {
    if (onChangeSubcategoryTariff && index !== undefined)
      onChangeSubcategoryTariff(
        {
          subcategory: (value as components['schemas']['SubcategoryTariffView'])
            .subcategory,
          tariff: val as 'STANDARD' | 'SPECIAL',
        },
        index,
      );
    if (onChangeMeetingPoint) {
      onChangeMeetingPoint(val as 'POSITION' | 'HOTEL' | 'CUSTOM');
    }
  };

  const getLabelByValue = (
    value: 'STANDARD' | 'SPECIAL' | 'POSITION' | 'HOTEL' | 'CUSTOM',
  ): JSX.Element => {
    switch (value) {
      case 'STANDARD':
        return <>Стандарт</>;
      case 'SPECIAL':
        return <>Спецтариф</>;
      case 'POSITION':
        return <>Точка&nbsp;на&nbsp;карте</>;
      case 'HOTEL':
        return <>От&nbsp;отеля</>;
      case 'CUSTOM':
        return <>По&nbsp;запросу</>;
      default:
        return <></>;
    }
  };

  return (
    <div className="rate-radio__container">
      {(value as components['schemas']['SubcategoryTariffView'])?.subcategory
        ?.name && (
        <div className="rate-radio__label header2">
          {
            (value as components['schemas']['SubcategoryTariffView'])
              .subcategory.name
          }
        </div>
      )}
      <div className="rate-radio__rates">
        {rates?.map((val, index) => (
          <button
            key={index}
            className={classNames(
              'rate-radio__rate button-text',
              val ===
                (value as components['schemas']['SubcategoryTariffView'])
                  ?.tariff && 'active',
              { view },
              val === (value as 'POSITION' | 'HOTEL' | 'CUSTOM') && 'active',
            )}
            onClick={() => onChangeHandler(val)}
            disabled={view}
          >
            {getLabelByValue(val)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default RateRadio;
