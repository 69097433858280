import * as React from 'react';

import EditableDiv from '../../../../../../components/EditableDiv';
import { ReactComponent as CloseIcon } from '../../../../../../images/EditSlot/icn-close.svg';

import './style.scss';

interface IStop {
  index: number;
  stop: {
    name: string;
    description: string;
    nameError?: boolean;
    descriptionError?: boolean;
  };
  deleteStop(index: number): void;
  setStop(
    index: number,
    stop: {
      name: string;
      description: string;
      nameError?: boolean;
      descriptionError?: boolean;
    },
  ): void;
}

const Index: React.FC<IStop> = ({ index, deleteStop, stop, setStop }) => {
  const onDeleteStop = () => {
    deleteStop(index);
  };

  const onChangeStop = (val: string) => {
    setStop(index, { ...stop, name: val, nameError: false });
  };

  const onChangeDesc = (val: string) => {
    setStop(index, { ...stop, description: val, descriptionError: false });
  };

  return (
    <div className="route__stop-container">
      <div className="route__first-row">
        <span className="header2">{`Точка маршрута #${index + 1}`}</span>
        <button className="route__delete-btn" onClick={onDeleteStop}>
          <CloseIcon />
        </button>
      </div>
      <div className="edit-service__title-input route__stop-name">
        <EditableDiv
          value={stop.name}
          saveValue={onChangeStop}
          singleLine={true}
          data-placeholder={'Название точки'}
          error={stop.nameError}
        />
      </div>
      <div className="edit-service__title-input route__stop-desc">
        <EditableDiv
          value={stop.description}
          saveValue={onChangeDesc}
          data-placeholder={'Краткое описание'}
          error={stop.descriptionError}
        />
      </div>
    </div>
  );
};

export default Index;
