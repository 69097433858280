import * as React from 'react';

function HumanIcon1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={54}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M44.903 35.657a4.325 4.325 0 100-8.65 4.325 4.325 0 000 8.65zM42.477 54.121l-2.772 5.905c-.222.471-.597.79-1.096.957a1.758 1.758 0 01-1.441-.167l-.374-.208c-.777-.457-1.11-1.4-.777-2.231l2.523-6.405-3.604-1.635 1.248-8.359-7 4.38c-.43.264-.915.32-1.387.167a1.601 1.601 0 01-1.04-.943l-.096-.235a1.667 1.667 0 01.693-2.08l10.604-6.293 1.247-.624a2.976 2.976 0 012.523-.083l.846.347 9.481 5.378a1.8 1.8 0 01.735 2.357l-.194.388a1.797 1.797 0 01-2.357.86l-7.443-3.342-1.276 5.323"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.342 47.384l7.084 2.759c.415.152.596.61.596 1.067l-.028 9.814c0 1.054-.86 1.927-1.927 1.927h-.055c-.624.014-1.165-.222-1.608-.665-.444-.458-.596-.998-.624-1.622l-.263-5.988a.823.823 0 00-.486-.721l-7.928-3.493M33.772 11.496l2.425 26.518L23.278 24.18M36.198 38.014L9.417 34.16"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M6.409 7.088c-8.733 9.69-5.573 23.551.222 27.946 2.495-9.454 3.52-10.702 14.748-12.767l.098-.097c.637-10.424 2.314-11.893 12.877-13.238l.152-.152C30.64.81 14.823-.785 6.41 7.088z"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HumanIcon1;
