import * as React from 'react';
import { components, SingleValueProps } from 'react-select';

const SingleValue: React.FC<SingleValueProps<any>> = props => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <>{`${data.country} ${data.code}`}</>
    </components.SingleValue>
  );
};

export default SingleValue;
