import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import classNames from 'classnames';

import ApiErrorUI from '../../components/ApiErrorUI';
import Layout from '../../components/Layout';
import { components } from '../../generated/apiTypes';
import { createProvider, signInWithCode } from '../../services/apiRequests';
import { handleStorage } from '../../services/handleStorage';
import { selectApiError, setApiError } from '../../store/commonSlice';
import Confirmation from './ConfirmationCode';
import RegistrationForm from './RegistrationForm';

import './style.scss';

const Registration: React.FC<RouteComponentProps> = ({ history }) => {
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();
  const [mode, setMode] = React.useState<'REGISTER' | 'CONFIRM'>('REGISTER');
  const [registerData, setRegisterData] =
    React.useState<components['schemas']['AccountRegistration']>();
  const [email, setEmail] = React.useState<string>();

  React.useEffect(() => {
    return () => {
      if (apiError.isError)
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [apiError.isError]);

  const onCompleteCode = async (code: string) => {
    try {
      const {
        data: { token: token, account: account },
      } = await signInWithCode({
        login: registerData?.email as string,
        code: code,
      });
      if (token) {
        handleStorage.setToken(token);
      }
      if (account?.name) handleStorage.setUsername(account?.name);
      if (account?.role) {
        handleStorage.setRole(account?.role);
        history.push('/provider');
      }
    } catch (e) {
      //
    }
  };

  const createProviderRequest = async (
    data: components['schemas']['AccountRegistration'],
  ) => {
    setRegisterData(data);
    try {
      await createProvider(data);
      setEmail(data.email);
      setMode('CONFIRM');
    } catch (e) {
      //
    }
  };

  const resendCodeRequest = async () => {
    if (registerData) {
      try {
        await createProvider(registerData);
        setMode('CONFIRM');
      } catch (e) {
        //
      }
    }
  };

  const errorToShow =
    apiError?.isError &&
    ![
      'IDENTIFIER_ALREADY_EXISTS',
      'AccountRegistration_password_Pattern',
      'AccountRegistration_name_NotBlank',
      'IDENTIFIER_VERIFICATION_FAILED',
      'AccountRegistration_name_Size',
      'AccountRegistration_password_Size',
      'ACCOUNT_PASSWORD_WEAK',
      'AccountRegistration_email_Size',
      'AccountRegistration_email_Pattern',
      'AccountRegistration_email_NotBlank',
      'AccountRegistration_password_NotBlank',
    ].includes(apiError?.key as string);

  return (
    <Layout unauthorized error={errorToShow}>
      {errorToShow ? (
        <ApiErrorUI />
      ) : (
        <div
          className={classNames(
            'registration-page__container',
            mode === 'CONFIRM' && 'confirm-container',
          )}
        >
          <div
            className={classNames(
              'registration-page__content',
              mode === 'CONFIRM' && 'confirm-content',
            )}
          >
            {mode === 'REGISTER' && (
              <RegistrationForm createProvider={createProviderRequest} />
            )}
            {mode === 'CONFIRM' && (
              <Confirmation
                onComplete={onCompleteCode}
                resendCodeRequest={resendCodeRequest}
                email={email}
              />
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default withRouter(Registration);
