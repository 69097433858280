import { TimePickerValue } from 'react-time-picker';

import { eachDayOfInterval, format, isValid } from 'date-fns';
import ru from 'date-fns/locale/ru';

export const sortByDate = () => {
  return (a: TimePickerValue, b: TimePickerValue) => {
    const date = new Date();
    if (a && b) {
      if (
        new Date(`${date.toDateString()} ${a}`) >
        new Date(`${date.toDateString()} ${b}`)
      )
        return 1;
      if (
        new Date(`${date.toDateString()} ${a}`) <
        new Date(`${date.toDateString()} ${b}`)
      )
        return -1;
    }
    return 0;
  };
};

export const slotsList = (
  startDate: Date,
  endDate: Date,
  times: TimePickerValue[],
  weekdays: number[],
): Date[] => {
  const displayedSlots: Date[] = [];
  const dates = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });
  const slotWeekdays =
    startDate?.toDateString() === endDate?.toDateString()
      ? [0, 1, 2, 3, 4, 5, 6]
      : weekdays;
  for (const date of dates) {
    for (const time of times) {
      if (
        slotWeekdays.includes(
          parseInt(format(date, 'i', { locale: ru, weekStartsOn: 1 }), 10) - 1,
        ) &&
        isValid(new Date(`${date.toDateString()} ${time}`)) &&
        time !== ''
      ) {
        displayedSlots.push(new Date(`${date.toDateString()} ${time}`));
      }
    }
  }
  return displayedSlots;
};
