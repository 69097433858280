import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AxiosResponse } from 'axios';

import Button from '../../../../../components/Button';
import EditableDiv from '../../../../../components/EditableDiv';
import Input from '../../../../../components/Input';
import IntlPhoneInput from '../../../../../components/PhoneInput';
import ReturnLink from '../../../../../components/ReturnLink';
import { components } from '../../../../../generated/apiTypes';
import { ReactComponent as LocationIcon } from '../../../../../images/EditService/icn-location.svg';
import { ReactComponent as RubIcon } from '../../../../../images/EditService/icn-rub.svg';
import {
  createPrimitive,
  createPrimitiveByOperator,
  updatePrimitive,
  uploadAttachment,
} from '../../../../../services/apiRequests';
import { handleStorage } from '../../../../../services/handleStorage';
import { selectApiError, setApiError } from '../../../../../store/commonSlice';
import Map from '../../Map';
import { ServiceInfo } from '../../types';
import ImagesArea from '../ImagesArea';

interface IRouterProps {
  provider_id?: string;
  service_id?: string;
}

interface IStep2 extends RouteComponentProps<IRouterProps> {
  infoState: ServiceInfo;
  setInfoState(info: ((info: ServiceInfo) => void) | ServiceInfo): void;
  newService?: boolean;
  onCancelClick(): void;
  onSaveClick(id?: string): void;
  goToStep(step: 'FIRST' | 'SECOND'): void;
}

const Index: React.FC<IStep2> = ({
  match,
  goToStep,
  newService,
  infoState,
  setInfoState,
  onCancelClick,
  onSaveClick,
}) => {
  const provider_id = match.params.provider_id as string;
  const service_id = match.params.service_id as string;
  const [titleError, setTitleError] = React.useState<boolean>(false);
  const [phone, setPhone] = React.useState<string | undefined>(
    `+${infoState?.phone}` || '',
  );
  const [phoneError, setPhoneError] = React.useState<boolean>(false);
  const phoneRef = React.useRef<HTMLDivElement>(null);
  const [phoneFieldWidth, setPhoneFieldWidth] = React.useState<number>(0);
  const [locationAddress, setLocationAddress] = React.useState<string>('');
  const [descError, setDescError] = React.useState<boolean>(false);
  const [imagesError, setImagesError] = React.useState<boolean>(false);
  const [requestInProgress, setRequestInProgress] =
    React.useState<boolean>(false);
  const [isErrors, setIsErrors] = React.useState<boolean>(false);
  const [costError, setCostError] = React.useState<boolean>(false);
  const [costFromError, setCostFromError] = React.useState<boolean>(false);

  const role = handleStorage.getRole();
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();

  const goToPrevStep = () => {
    goToStep('FIRST');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (phoneRef?.current?.clientWidth)
      setPhoneFieldWidth(phoneRef?.current?.clientWidth);
  }, [phoneRef?.current]);

  const setLocation = (location: number[]) => {
    setInfoState(infoState => ({ ...infoState, location: location }));
  };

  const setTitle = (title: string) => {
    if (titleError) setTitleError(false);
    if (apiError?.key?.includes('name'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState(infoState => ({ ...infoState, title: title }));
  };

  const resetTitleError = () => {
    if (titleError) setTitleError(false);
    if (apiError?.key?.includes('name'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const onChangePhone = (val: any) => {
    if (phoneError) setPhoneError(false);
    setPhone(val);
    const phoneNumber = parseInt(val);
    setInfoState(infoState => ({
      ...infoState,
      phone: phoneNumber.toString(),
    }));
  };

  const setDescription = (desc: string) => {
    if (descError) setDescError(false);
    if (apiError?.key?.includes('description'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState(infoState => ({ ...infoState, description: desc }));
  };

  const resetDescriptionError = () => {
    if (descError) setDescError(false);
    if (apiError?.key?.includes('description'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const resetImagesError = () => {
    setImagesError(false);
    setIsErrors(false);
  };

  const setDetails = (details: string) => {
    if (apiError?.key?.includes('additionalInfo'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState(infoState => ({ ...infoState, details: details }));
  };

  const resetAdditionalInfoError = () => {
    if (apiError?.key?.includes('additionalInfo'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const resetPricesError = () => {
    if (costError) setCostError(false);
    if (apiError?.key?.includes('prices'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const setCost = (cost: string) => {
    if (costError) setCostError(false);
    if (apiError?.key?.includes('prices'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState({ ...infoState, cost: cost });
  };

  const onChangeCostFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (costFromError) setCostFromError(false);
    if (apiError?.key?.includes('minimumPrice'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    const newValue: number | string = parseInt(e.target.value, 10) || '';
    setInfoState({ ...infoState, costFrom: newValue.toString() });
  };

  const validateFields = (): boolean => {
    let validateResult = true;
    if (
      !(infoState?.images?.length > 0) ||
      infoState.images.filter(
        item => item.view?.pictureUrl && item.view?.pictureUrl?.length > 0,
      )?.length === 0
    ) {
      validateResult = false;
      setImagesError(true);
    }
    if (!infoState?.title) {
      validateResult = false;
      setTitleError(true);
    }
    if (!infoState?.description) {
      validateResult = false;
      setDescError(true);
    }
    if (!infoState?.phone) {
      validateResult = false;
      setPhoneError(true);
    }
    if (!infoState?.phone) {
      validateResult = false;
      setPhoneError(true);
    }
    if (!infoState?.cost) {
      validateResult = false;
      setCostError(true);
    }
    return validateResult;
  };

  const infoStateToPrimitiveActivity = (
    uploadedImages: components['schemas']['ActivityPictureView'][],
  ): components['schemas']['PrimitiveActivityUpdate'] => {
    const location = infoState?.location || infoState.resort?.location;
    const sublocations: components['schemas']['SublocationView'][] = [];
    for (const sublocation of infoState?.subLocations?.filter(
      item => item.value !== '',
    )) {
      sublocations.push({ id: parseInt(sublocation.value, 10) });
    }
    return {
      sublocations,
      location: { id: parseInt(infoState.resort?.value as string, 10) },
      category: { id: parseInt(infoState.category?.value as string, 10) },
      pictures: uploadedImages,
      description: infoState.description,
      additionalInfo: infoState.details,
      phone: infoState.phone as string,
      position: {
        longitude: location?.length && location[1],
        latitude: location?.length && location[0],
      },
      minimumPrice: parseInt(infoState.costFrom, 10) || 0,
      prices: infoState.cost,
      childrenAllowed: infoState.childrenAllowed,
      name: infoState.title,
    };
  };

  const saveServiceInfo = async () => {
    setIsErrors(false);
    if (validateFields()) {
      setRequestInProgress(true);
      const uploadedImages: components['schemas']['ActivityPictureView'][] = [];
      const existingImages: components['schemas']['ActivityPictureView'][] = [];
      for (const image of infoState?.images) {
        if (image?.file) {
          const formData = new FormData();
          formData.append('file', image?.file as Blob);
          const response = await uploadAttachment(formData);
          if (response?.data) {
            uploadedImages.push(response.data);
          }
        } else {
          existingImages.push(image.view);
        }
      }
      let createdServiceId: string | undefined = undefined;
      let response:
        | AxiosResponse<components['schemas']['ActivityDetails']>
        | undefined = undefined;
      try {
        if (newService) {
          if (role === 'ACTIVITY_PROVIDER') {
            response = await createPrimitive(
              infoStateToPrimitiveActivity(uploadedImages),
            );
          } else {
            response = await createPrimitiveByOperator(
              infoStateToPrimitiveActivity(uploadedImages),
              provider_id,
            );
          }
          createdServiceId = response?.data.id?.toString();
        } else {
          await updatePrimitive(
            infoStateToPrimitiveActivity([
              ...existingImages,
              ...uploadedImages,
            ]),
            service_id as string,
          );
        }
        if (createdServiceId) onSaveClick(createdServiceId);
        else onSaveClick();
      } catch (e) {
        //
      }
      setRequestInProgress(false);
    } else {
      setIsErrors(true);
    }
  };

  return (
    <>
      <ReturnLink linkName="Назад" onClickHandler={goToPrevStep} />
      <div className="edit-service__title-row page__title title">
        {!newService ? 'Редактирование информации' : 'Создание новой услуги'}
      </div>
      <div className="edit-service__content">
        <div className="view-info__item-title edit-service__category-title header1">
          {'Название услуги'}
        </div>
        <div className="edit-info__item">
          <div className="edit-service__title-input">
            <EditableDiv
              value={infoState?.title as string}
              saveValue={setTitle}
              singleLine={true}
              error={
                titleError ||
                (apiError?.key?.includes('name') &&
                  !apiError?.key?.includes('RoutePointView'))
              }
              resetError={resetTitleError}
              data-placeholder={'Название услуги'}
            />
          </div>
        </div>
        <div className="view-info__item-title edit-service__desc-title header1">
          Номер телефона организатора
        </div>
        <div className="edit-service__title-input" ref={phoneRef}>
          <IntlPhoneInput
            placeholder="900 000 00 00"
            onChange={onChangePhone}
            error={phoneError}
            value={phone}
            menuWidth={phoneFieldWidth}
          />
        </div>
        <div className="view-info__item-title edit-service__desc-title header1">
          Описание
        </div>
        <EditableDiv
          value={infoState.description}
          saveValue={setDescription}
          data-placeholder={'Описание'}
          error={descError || apiError?.key?.includes('description')}
          resetError={resetDescriptionError}
        />
      </div>
      <div className="view-info__item-title edit-service__images-title header1">
        Фото и видео
      </div>
      <ImagesArea
        infoState={infoState}
        setInfoState={setInfoState}
        error={imagesError}
        setError={setImagesError}
        resetError={resetImagesError}
        maxFilesQty={7}
        type={'PHOTOVIDEO'}
      />
      <div className="edit-service__content">
        <div className="view-info__item-title edit-service__desc-title header1">
          Местоположение/Место встречи
        </div>
      </div>
      {infoState.resort?.location && (
        <>
          <Map
            mode="EDIT"
            coordinates={
              infoState.location
                ? infoState.location
                : infoState.resort?.location
            }
            setAddress={setLocationAddress}
            setNewLocation={setLocation}
          />
          <div className="view-info__item location-item full-width normal-text">
            <LocationIcon />
            {locationAddress}
          </div>
        </>
      )}
      <>
        <div className="view-info__item-title edit-service__desc-title header1">
          Стоимость за 1 взрослого человека за 1 день
        </div>
        <div className="edit-service__cost-from label-text">
          от
          <Input
            value={infoState.costFrom}
            onChange={onChangeCostFrom}
            containerClassName="edit-service__cost-from-input"
            placeholder={'0'}
            error={costFromError || apiError?.key?.includes('minimumPrice')}
          >
            <RubIcon className="edit-service__rub-icon" />
          </Input>
        </div>
      </>

      <div className="edit-service__content">
        <div className="view-info__item-title edit-service__desc-title header1">
          Стоимость
        </div>
        <EditableDiv
          value={infoState.cost}
          saveValue={setCost}
          data-placeholder={'Дополнительная информация о стоимости'}
          error={costError || apiError?.key?.includes('prices')}
          resetError={resetPricesError}
        />{' '}
      </div>
      <div className="edit-service__content">
        <div className="view-info__item-title edit-service__desc-title header1">
          Дополнительная информация
        </div>
        <EditableDiv
          value={infoState.details}
          saveValue={setDetails}
          data-placeholder={'Необязательное поле'}
          error={apiError?.key?.includes('additionalInfo')}
          resetError={resetAdditionalInfoError}
        />
        <div className="edit-service__buttons">
          <Button
            className="edit-service__save-button"
            onClick={saveServiceInfo}
            disabled={requestInProgress}
          >
            {requestInProgress && (
              <img className="edit-service__loader" src="/btn-loader.gif" />
            )}
            {requestInProgress ? 'Сохранение...' : 'Сохранить'}
          </Button>
          <Button
            className="edit-service__cancel-button"
            onClick={onCancelClick}
            mode="secondary"
          >
            Отменить
          </Button>
        </div>
        {apiError?.key?.includes('NotBlank') ||
        apiError?.key?.includes('Size') ||
        apiError?.key?.includes('Range') ||
        apiError?.key?.includes('Max') ? (
          <div className="login-page__error-message normal-text">
            {apiError?.key?.includes('durationInMinutes') &&
              'Содержимое составного поля будет переведено в минуты. '}
            {apiError?.message}
          </div>
        ) : phoneError ? (
          <div className="login-page__error-message normal-text">
            Введите корректное значение номера телефона
          </div>
        ) : imagesError &&
          infoState.images.filter(
            item => item.view?.pictureUrl && item.view?.pictureUrl?.length > 0,
          )?.length === 0 ? (
          <div className="login-page__error-message normal-text">
            Добавьте хотя бы 1 фотографию
          </div>
        ) : isErrors ? (
          <div className="login-page__error-message normal-text">
            Заполните все поля
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default withRouter(Index);
