import * as React from 'react';

import classNames from 'classnames';

import Button from '../../../components/Button';
import { components } from '../../../generated/apiTypes';
import { ReactComponent as ApproveIcon } from '../../../images/Booking/icn-approve.svg';
import { ReactComponent as DeclineIcon } from '../../../images/Booking/icn-decline.svg';
import { ReactComponent as LocationIcon } from '../../../images/EditService/icn-location.svg';
import { activityConfigurationRequest } from '../../../services/apiRequests';
import { handleStorage } from '../../../services/handleStorage';
import Map from '../Info/Map/index';
import MeetingPoint from './EditInfo/GenericService/MeetingPoint';
import {
  getFitnessLevelOptions,
  getGroupSizeOptions,
  getPriceTypeOptions,
} from './EditInfo/GenericService/options';
import Route from './EditInfo/GenericService/Route';
import Prices from './EditInfo/Prices';
import Slideshow from './Slideshow';
import { ServiceInfo } from './types';

import './style.scss';

interface IViewInfo {
  info: ServiceInfo;
  onEditClick(): void;
  onDeclineChangesClick(): void;
  verifyService(): void;
  approveRequestInProgress: boolean;
}

const ViewInfo: React.FC<IViewInfo> = ({
  info,
  onEditClick,
  onDeclineChangesClick,
  verifyService,
  approveRequestInProgress,
}) => {
  const role = handleStorage.getRole();
  const [configuration, setConfiguration] =
    React.useState<components['schemas']['ActivityConfiguration']>();
  const [locationAddress, setLocationAddress] = React.useState<string>('');

  const getActivityConfiguration = async () => {
    const response = await activityConfigurationRequest({
      subcategory: { id: parseInt(info.subCategories[0]?.value, 10) },
      priceType: info.summary?.priceType as
        | 'PER_PERSON'
        | 'PER_PERSON_PER_HOUR'
        | 'PER_GROUP',
      meetingPoint: info.summary?.meetingPoint,
    });
    if (response?.data) {
      setConfiguration(response?.data);
    }
  };

  React.useEffect(() => {
    if (info.category?.type === 'GENERIC') getActivityConfiguration();
  }, []);

  return (
    <>
      {role === 'OPERATOR' && info?.activeness === 'VERIFICATION' && (
        <div className="view-info__decline-changes-panel normal-text">
          Изменение информации
          <Button
            className="accent-button view-info__decline-changes-panel-btn view-info__approve-btn"
            onClick={verifyService}
            disabled={approveRequestInProgress}
          >
            <ApproveIcon className="booking-page__button-icon" />
            Подтвердить
          </Button>
          <Button
            className="accent-button view-info__decline-changes-panel-btn view-info__decline-btn"
            onClick={onDeclineChangesClick}
            mode={'secondary'}
          >
            <DeclineIcon className="booking-page__button-icon" />
            Отклонить
          </Button>
        </div>
      )}
      <div className="view-info__content">
        <div className="view-info__item-title header1">Фото и видео</div>
        <div>
          <Slideshow images={info?.images} />
        </div>
        {info?.title && (
          <>
            <div className="view-info__item-title header1">Название услуги</div>
            <div className="view-info__item normal-text">{info?.title}</div>
          </>
        )}
        <div className="view-info__item-title header1">Категория</div>
        <div className={classNames('view-info__item category-item with-icon')}>
          <div className="view-info__with-icon-item label-text">
            {info?.category?.label}
          </div>
        </div>
        {info.category?.type !== 'PRIMITIVE' && (
          <>
            <div className="view-info__item-title header2">Подкатегория</div>
            <div className="view-info__subcategories">
              <div
                className={classNames(
                  'view-info__item sub-category-item with-icon',
                )}
              >
                <div className="view-info__with-icon-item label-text">
                  {info?.subCategories[0]?.label}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="view-info__item-title header1">Локация</div>
        <div
          className={classNames('view-info__item category-item normal-text')}
        >
          {info?.resort?.label}
        </div>
        <div className="view-info__item-title header2">Место</div>
        <div className="view-info__subcategories">
          <div
            className={classNames(
              'view-info__item sub-category-item with-icon',
            )}
          >
            <div className="view-info__with-icon-item label-text">
              {info?.subLocations[0]?.label}
            </div>
          </div>
        </div>
        {info?.phone && (
          <>
            <div className="view-info__item-title header1">
              Номер телефона организатора
            </div>
            <div className="view-info__item normal-text">{`+${info?.phone}`}</div>
          </>
        )}
        <div className="view-info__item-title header1">Описание</div>
        <div className="view-info__item normal-text">{info?.description}</div>
        {configuration?.groupType && info.summary?.groupType && (
          <>
            <div className="view-info__item-title header1">
              Форма проведения
            </div>
            <div className="view-info__subcategories">
              <div
                className={classNames(
                  'view-info__item sub-category-item with-icon',
                )}
              >
                <div className="view-info__with-icon-item label-text">
                  {
                    getGroupSizeOptions().find(
                      item => item.value === info.summary?.groupType,
                    )?.label
                  }
                </div>
              </div>
            </div>
          </>
        )}
        <div className="view-info__item-title header1">Дети (до 12 лет)</div>
        <div className="view-info__item with-icon">
          <div className="view-info__with-icon-item label-text">
            {info.childrenAllowed ? 'Доступно для детей' : 'Без детей'}
          </div>
        </div>
        {configuration?.priceType && info.summary?.priceType && (
          <>
            <div className="view-info__item-title header1">
              Стоимость указана
            </div>
            <div className="view-info__subcategories">
              <div
                className={classNames(
                  'view-info__item sub-category-item with-icon',
                )}
              >
                <div className="view-info__with-icon-item label-text">
                  {
                    getPriceTypeOptions().find(
                      item => item.value === info.summary?.priceType,
                    )?.label
                  }
                </div>
              </div>
            </div>
          </>
        )}
        {configuration?.tags && info.tags && info.tags?.length > 0 && (
          <>
            <div className="view-info__item-title header1">Теги</div>
            <div className="view-info__subcategories">
              {info.tags.map((item, index) => (
                <div
                  key={index}
                  className="view-info__item sub-category-item with-icon"
                >
                  <div className="view-info__with-icon-item label-text">
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {configuration?.routePoints &&
          info.routePoints?.length !== undefined &&
          info.routePoints?.length > 0 && (
            <>
              <div className="view-info__item-title header1">Маршрут</div>
              <div className="view-info__item">
                <Route infoState={info} view />
              </div>
            </>
          )}
        {configuration?.expert && info?.expert && (
          <>
            <div className="view-info__item-title header1">ИФ гида</div>
            <div className="view-info__item normal-text">
              {info?.expert.firstName} {info?.expert.lastName}
            </div>
            {info?.expert?.photo && (
              <>
                <div className="view-info__item-title header1">
                  Фотография гида
                </div>
                <div>
                  <Slideshow images={[info?.expert.photo]} type="PHOTO" />
                </div>
              </>
            )}
            {info?.expert?.voice && (
              <>
                <div className="view-info__item-title header1">Голос гида</div>
                <div>
                  <Slideshow images={[info?.expert.voice]} type="AUDIO" />
                </div>
              </>
            )}
          </>
        )}
        {configuration?.numberOfPeople && (
          <>
            <div className="view-info__item-title header1">
              Макс. кол-во человек
            </div>
            <div className="view-info__item normal-text">{`${info?.peopleAmount} чел`}</div>
          </>
        )}
        {(configuration?.durationRange || configuration?.durationInMinutes) && (
          <div className="view-info__item-title header1">Длительность</div>
        )}
        {configuration?.durationRange &&
          info?.durationRange?.fromInMinutes &&
          info.durationRange.toInMinutes && (
            <div className="view-info__item normal-text">{`от ${
              info.durationRange?.fromInMinutes / 60
            } ч до ${info.durationRange.toInMinutes / 60} ч`}</div>
          )}
        {configuration?.durationInMinutes && (
          <div className="view-info__item normal-text">
            {info?.duration?.days &&
              parseInt(info?.duration?.days, 10) > 0 &&
              `${info?.duration?.days} д `}{' '}
            {`${info?.duration?.hours || 0} ч `}
          </div>
        )}
      </div>
      {configuration?.fitnessLevel && info.summary?.fitnessLevel && (
        <>
          <div className="view-info__item-title header1">
            Уровень подготовки
          </div>
          <div className="view-info__subcategories">
            <div
              className={classNames(
                'view-info__item sub-category-item with-icon',
              )}
            >
              <div className="view-info__with-icon-item label-text">
                {
                  getFitnessLevelOptions().find(
                    item => item.value === info.summary?.fitnessLevel,
                  )?.label
                }
              </div>
            </div>
          </div>
        </>
      )}
      {configuration?.meetingPoint && (
        <>
          <div className="view-info__item-title header1">
            Местоположение/Место встречи
          </div>
          <div className="meeting-point__item">
            <MeetingPoint infoState={info} view />
          </div>
        </>
      )}
      {info.resort?.location && info.category?.type === 'PRIMITIVE' && (
        <>
          <div className="view-info__item-title edit-service__location-title header1">
            Местоположение/Место встречи
          </div>
          <Map
            mode="VIEW"
            coordinates={info.location ? info.location : info.resort?.location}
            setAddress={setLocationAddress}
          />
          <div className="view-info__item location-item full-width normal-text">
            <LocationIcon />
            {locationAddress}
          </div>
        </>
      )}
      {configuration?.pricing && (
        <>
          <div className="view-info__item-title detailed-cost-title header1">
            <>Стоимость</>
          </div>
          <Prices
            label="Взрослые (с 12 лет)"
            prices={info.pricing?.prices}
            customerType={'ADULT'}
            genericType={true}
            priceType={info.summary?.priceType}
            view
          />
          <Prices
            label="Дети (до 12 лет)"
            prices={info.pricing?.prices}
            customerType={'CHILD'}
            genericType={true}
            priceType={info.summary?.priceType}
            view
          />
          <Prices
            label="За всю услугу"
            prices={info.pricing?.prices}
            customerType={'GROUP'}
            genericType={true}
            priceType={info.summary?.priceType}
            view
          />
        </>
      )}
      <div className="view-info__content">
        {info?.details && (
          <>
            <div className="view-info__item-title header1">
              Дополнительная информация
            </div>
            <div className="view-info__item normal-text">{info.details}</div>
          </>
        )}
        <Button className="view-info__edit-button" onClick={onEditClick}>
          Редактировать
        </Button>
      </div>
    </>
  );
};

export default ViewInfo;
