import { AxiosResponse } from 'axios';

import { api } from '../api';
import { components } from '../generated/apiTypes';

export const signInWithPassword = (
  formData: components['schemas']['PasswordSignIn'],
): Promise<AxiosResponse<components['schemas']['SessionView']>> => {
  return api.post(`/sessions/password`, formData);
};

export const signInWithCode = (
  data: components['schemas']['VerificationCodeSignIn'],
): Promise<AxiosResponse<components['schemas']['SessionView']>> => {
  return api.post('/sessions/code', data);
};

export const getMyActivityProvider = (): Promise<
  AxiosResponse<components['schemas']['ActivityProviderView']>
> => {
  return api.get(`/activity-providers/me`);
};

export const getAccount = (): Promise<
  AxiosResponse<components['schemas']['AccountView']>
> => {
  return api.get(`/accounts/me`);
};

export const setAccount = (
  account: components['schemas']['AccountUpdate'],
): Promise<AxiosResponse<components['schemas']['AccountView']>> => {
  return api.put(`/accounts/me`, account);
};

export const setNewPassword = (
  password: components['schemas']['PasswordUpdate'],
): Promise<AxiosResponse<components['schemas']['AccountView']>> => {
  return api.put(`/accounts/me/password`, password);
};

export const recoverPassword = (
  data: components['schemas']['PasswordRecovery'],
): Promise<AxiosResponse> => {
  return api.post(`/accounts/password/recovery`, data);
};

export const getMyActivities = (
  group:
    | 'ALL'
    | 'ACTIVITY_VERIFICATION_PENDING'
    | 'RESERVATION_CREATED'
    | 'REVIEW_MODERATION_PENDING',
  offset: number,
  name?: string,
  locationsIds?: string[],
): Promise<
  AxiosResponse<components['schemas']['OffsetResultsActivitySummary']>
> => {
  return api.get(`/activities/mine`, {
    params: {
      offset: offset,
      group: group,
      name: name,
      locationsIds: locationsIds,
    },
  });
};

export const getActivityById = (
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.get(`/activities/${id}`);
};

export const getProviders = (
  group: 'ALL' | 'ACTIVITY_VERIFICATION_PENDING' | 'REVIEW_MODERATION_PENDING',
  offset: number,
  name?: string,
  locationsIds?: string[],
): Promise<
  AxiosResponse<components['schemas']['OffsetResultsActivityProviderView']>
> => {
  return api.get(`/activity-providers`, {
    params: {
      offset: offset,
      group: group,
      name: name,
      locationsIds: locationsIds,
    },
  });
};

export const getProviderById = (
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityProviderView']>> => {
  return api.get(`/activity-providers/${id}`);
};

export const createProvider = (
  data: components['schemas']['AccountRegistration'],
): Promise<AxiosResponse<components['schemas']['ActivityProviderView']>> => {
  return api.post(`/activity-providers`, data);
};

export const getActivitiesByProviderId = (
  id: string,
  group:
    | 'ALL'
    | 'ACTIVITY_VERIFICATION_PENDING'
    | 'RESERVATION_CREATED'
    | 'REVIEW_MODERATION_PENDING',
  offset: number,
  name?: string,
  locationsIds?: string[],
): Promise<
  AxiosResponse<components['schemas']['OffsetResultsActivitySummary']>
> => {
  return api.get(`/activity-providers/${id}/activities`, {
    params: {
      offset: offset,
      group: group,
      name: name,
      locationsIds: locationsIds,
    },
  });
};

export const getLocations = (): Promise<
  AxiosResponse<components['schemas']['LocationView'][]>
> => {
  return api.get(`/locations`);
};

export const getCategories = (): Promise<
  AxiosResponse<components['schemas']['CategoryView'][]>
> => {
  return api.get(`/categories`);
};

export const getSubCategories = (
  id: string,
): Promise<AxiosResponse<components['schemas']['SubcategoryView'][]>> => {
  return api.get(`/categories/${id}/subcategories`);
};

export const getSubLocations = (
  id: string,
): Promise<AxiosResponse<components['schemas']['SublocationView'][]>> => {
  return api.get(`/locations/${id}/sublocations`);
};

export const getSlotConfigurations = (
  id: string,
): Promise<AxiosResponse<components['schemas']['SlotConfigurationView'][]>> => {
  return api.get(`/activities/${id}/slot-configurations`);
};

export const createSlotConfiguration = (
  id: string,
  configuration: components['schemas']['SlotConfigurationView'],
): Promise<AxiosResponse<components['schemas']['SlotConfigurationView']>> => {
  return api.post(`/activities/${id}/slot-configurations`, configuration);
};

export const updateSlotConfiguration = (
  id: string,
  configuration: components['schemas']['SlotConfigurationView'],
): Promise<AxiosResponse<components['schemas']['SlotConfigurationView']>> => {
  return api.put(`/slot-configurations/${id}`, configuration);
};

export const deleteSlotConfiguration = (
  id: string,
): Promise<AxiosResponse<components['schemas']['SlotConfigurationView']>> => {
  return api.delete(`/slot-configurations/${id}`);
};

export const getSlots = (
  id: string,
  offset: number,
): Promise<AxiosResponse<components['schemas']['OffsetResultsSlotView']>> => {
  return api.get(`/activities/${id}/slots/all`, {
    params: { offset: offset, maxResults: 20 },
  });
};

export const getSlotById = (
  id: string,
): Promise<AxiosResponse<components['schemas']['SlotConfigurationView']>> => {
  return api.get(`/slot-configurations/${id}`);
};

export const uploadAttachment = (
  file: FormData,
): Promise<AxiosResponse<components['schemas']['AttachmentView']>> => {
  return api.post('/attachments', file);
};

export const createActivity = (
  data: components['schemas']['ActivityActivityUpdate'],
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post('/activities/activities', data);
};

export const createExpert = (
  data: components['schemas']['ExpertActivityUpdate'],
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post('/activities/experts', data);
};

export const createRental = (
  data: components['schemas']['RentalActivityUpdate'],
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post('/activities/rentals', data);
};

export const createTransfer = (
  data: components['schemas']['TransferActivityUpdate'],
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post('/activities/transfers', data);
};

export const createPrimitive = (
  data: components['schemas']['PrimitiveActivityUpdate'],
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post('/activities/primitives', data);
};

export const createGenericActivity = (
  data: components['schemas']['GenericActivityUpdate'],
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post('/activities', data);
};

export const createActivityByOperator = (
  data: components['schemas']['ActivityActivityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post(`/activity-providers/${id}/activities/activities`, data);
};

export const createExpertByOperator = (
  data: components['schemas']['ExpertActivityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post(`/activity-providers/${id}/activities/experts`, data);
};

export const createRentalByOperator = (
  data: components['schemas']['RentalActivityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post(`/activity-providers/${id}/activities/rentals`, data);
};

export const createPrimitiveByOperator = (
  data: components['schemas']['PrimitiveActivityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post(`/activity-providers/${id}/activities/primitives`, data);
};

export const createTransferByOperator = (
  data: components['schemas']['TransferActivityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post(`/activity-providers/${id}/activities/transfers`, data);
};

export const createGenericActivityByOperator = (
  data: components['schemas']['GenericActivityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post(`/activity-providers/${id}/activities`, data);
};

export const updateActivity = (
  data: components['schemas']['ActivityActivityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.put(`/activities/${id}/activities`, data);
};

export const updateExpert = (
  data: components['schemas']['ExpertActivityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.put(`/activities/${id}/experts`, data);
};

export const updateRental = (
  data: components['schemas']['RentalActivityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.put(`/activities/${id}/rentals`, data);
};

export const updatePrimitive = (
  data: components['schemas']['PrimitiveActivityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.put(`/activities/${id}/primitives`, data);
};

export const updateTransfer = (
  data: components['schemas']['TransferActivityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.put(`/activities/${id}/transfers`, data);
};

export const updateGenericActivity = (
  data: components['schemas']['GenericActivityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.put(`activities/${id}`, data);
};

export const updateVerification = (
  data: components['schemas']['VerificationUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.post(`/activities/${id}/verification`, data);
};

export const updateVisibility = (
  data: components['schemas']['VisibilityUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ActivityDetails']>> => {
  return api.put(`/activities/${id}/visibility`, data);
};

export const getAllReservations = (
  offset: number,
  group: 'ALL' | 'REVIEW_MODERATION_PENDING',
  serialNumber?: string,
  locationsIds?: string[],
): Promise<
  AxiosResponse<components['schemas']['OffsetResultsReservationView']>
> => {
  return api.get('/reservations', {
    params: {
      offset: offset,
      group: group,
      serialNumber: serialNumber,
      locationsIds: locationsIds,
    },
  });
};

export const getReservationsByActivityId = (
  id: string,
  group: 'CURRENT' | 'WAITING_FOR_REVIEW' | 'HISTORY' | 'WAITING_FOR_APPROVAL',
  offset: number,
): Promise<
  AxiosResponse<components['schemas']['OffsetResultsReservationView']>
> => {
  return api.get(`/activities/${id}/reservations`, {
    params: { group: group, offset: offset },
  });
};

export const updateReservationStatusByProvider = (
  data: components['schemas']['ProviderReservationStatusUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ReservationView']>> => {
  return api.post(`/reservations/${id}/status/provider`, data);
};

export const getReservationById = (
  id: string,
): Promise<AxiosResponse<components['schemas']['ReservationView']>> => {
  return api.get(`/reservations/${id}`);
};

export const previewPricing = (
  data: components['schemas']['PricingConfiguration'],
): Promise<AxiosResponse<components['schemas']['PricingView']>> => {
  return api.put(`/activities/pricing/preview`, data);
};

export const previewPricingByOperator = (
  data: components['schemas']['PricingConfiguration'],
  id: string,
): Promise<AxiosResponse<components['schemas']['PricingView']>> => {
  return api.put(`/activity-providers/${id}/activities/pricing/preview`, data);
};

export const getReviewsById = (
  id: string,
  offset: number,
): Promise<AxiosResponse<components['schemas']['OffsetResultsReviewView']>> => {
  return api.get(`/activities/${id}/reviews`, { params: { offset: offset } });
};

export const moderateReview = (
  data: components['schemas']['ReviewModerationUpdate'],
  id: string,
): Promise<AxiosResponse<components['schemas']['ReviewView']>> => {
  return api.post(`/reviews/${id}/moderation`, data);
};

export const getConnectedBot = (): Promise<
  AxiosResponse<components['schemas']['Chat']>
> => {
  return api.get('/activity-providers/me/bot');
};

export const getConnectedBotByProviderId = (
  id: string,
): Promise<AxiosResponse<components['schemas']['Chat']>> => {
  return api.get(`/activity-providers/${id}/bot`);
};

export const requestBotConnection = (): Promise<
  AxiosResponse<components['schemas']['ConnectionInfo']>
> => {
  return api.post('/activity-providers/me/bot');
};

export const disconnectBot = (): Promise<AxiosResponse> => {
  return api.delete('/activity-providers/me/bot');
};

export const getConnectedSupervisedProviderBotChart = (
  id: string,
): Promise<AxiosResponse<components['schemas']['Chat']>> => {
  return api.get(`/activities/${id}/bot`);
};

export const requestSupervisedProviderBotConnection = (
  id: string,
): Promise<AxiosResponse<components['schemas']['ConnectionInfo']>> => {
  return api.put(`/activities/${id}/bot`);
};

export const disconnectSupervisedProviderBot = (
  id: string,
): Promise<AxiosResponse> => {
  return api.delete(`/activities/${id}/bot`);
};

export const activityConfigurationRequest = (
  data: components['schemas']['ActivityConfigurationRequest'],
): Promise<AxiosResponse<components['schemas']['ActivityConfiguration']>> => {
  return api.put(`/activities/configuration`, data);
};

export const getFeedLocations = (
  offset: number,
  showAll: boolean,
): Promise<
  AxiosResponse<components['schemas']['OffsetResultsLocationView']>
> => {
  return api.get(`/feed/locations`, {
    params: { offset: offset, showAll: showAll },
  });
};

export const getFeedLocationById = (
  id: string,
): Promise<AxiosResponse<components['schemas']['LocationView']>> => {
  return api.get(`/feed/locations/${id}`);
};

export const updateFeedLocationById = (
  id: string,
  data: components['schemas']['FeedLocationUpdate'],
): Promise<AxiosResponse<components['schemas']['LocationView']>> => {
  return api.put(`/feed/locations/${id}`, data);
};

export const getFeedSubcategories = (
  offset: number,
  showAll: boolean,
): Promise<
  AxiosResponse<components['schemas']['OffsetResultsSubcategoryView']>
> => {
  return api.get(`/feed/subcategories`, {
    params: { offset: offset, showAll: showAll },
  });
};

export const getFeedSubcategoryById = (
  id: string,
): Promise<AxiosResponse<components['schemas']['SubcategoryView']>> => {
  return api.get(`/feed/subcategories/${id}`);
};

export const updateFeedSubcategoryById = (
  id: string,
  data: components['schemas']['FeedSubcategoryUpdate'],
): Promise<AxiosResponse<components['schemas']['SubcategoryView']>> => {
  return api.put(`/feed/subcategories/${id}`, data);
};

export const getFeedPosts = (
  offset: number,
  showAll: boolean,
): Promise<
  AxiosResponse<components['schemas']['OffsetResultsFeedPostView']>
> => {
  return api.get(`/feed/posts`, {
    params: { offset: offset, showAll: showAll },
  });
};

export const getFeedPostById = (
  id: string,
): Promise<AxiosResponse<components['schemas']['FeedPostView']>> => {
  return api.get(`/feed/posts/${id}`);
};

export const updateFeedPostById = (
  id: string,
  data: components['schemas']['FeedPostUpdate'],
): Promise<AxiosResponse<components['schemas']['FeedPostView']>> => {
  return api.put(`/feed/posts/${id}`, data);
};

export const createFeedPost = (
  data: components['schemas']['FeedPostUpdate'],
): Promise<AxiosResponse<components['schemas']['FeedPostView']>> => {
  return api.post(`/feed/posts`, data);
};

export const refundByReservationId = (id: string): Promise<AxiosResponse> => {
  return api.post(`/reservations/${id}/refund`);
};
