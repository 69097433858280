import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { addDays, format, startOfWeek } from 'date-fns';
import en from 'date-fns/locale/en-GB';
import ru from 'date-fns/locale/ru';

import { ReactComponent as CalendarIcon } from '../../../../images/components/DatePicker/calendar.svg';
import { ReactComponent as TimeIcon } from '../../../../images/EditSlot/icn-time.svg';
import { ReactComponent as ArrowIcon } from '../../../../images/Service/BookingsList/icn-arrow.svg';
import { handleStorage } from '../../../../services/handleStorage';

import './style.scss';

interface ISlot extends RouteComponentProps {
  id: number;
  startDate: Date;
  endDate: Date;
  weekdays:
    | (
        | 'MONDAY'
        | 'TUESDAY'
        | 'WEDNESDAY'
        | 'THURSDAY'
        | 'FRIDAY'
        | 'SATURDAY'
        | 'SUNDAY'
      )[]
    | undefined;
  times: string[] | undefined;
  type:
    | 'ACTIVITY'
    | 'EXPERT'
    | 'RENTAL'
    | 'TRANSFER'
    | 'GENERIC'
    | 'PRIMITIVE'
    | undefined;
}

const Slot: React.FC<ISlot> = ({
  id,
  startDate,
  endDate,
  weekdays,
  times,
  history,
  location,
  type,
}) => {
  const role = handleStorage.getRole();
  const onSlotClick = () => {
    const path = `${history.location.pathname}/slot=${id}`;
    history.push({
      state: { tabIx: 2 },
    });
    history.push({
      pathname: path,
      state: {
        tabIx: (location.state as { tabIx: number }).tabIx,
      },
    });
  };
  const firstDOW = startOfWeek(new Date(), { weekStartsOn: 1 });
  const shortWeekDays: string[] = [];
  const weekDaysList = Array.from(Array(7))?.map((e, i) =>
    format(addDays(firstDOW, i), 'EEEE', { locale: en }),
  );
  for (const [index, weekDay] of weekDaysList?.entries()) {
    if (weekdays?.includes(weekDay.toUpperCase() as any)) {
      shortWeekDays.push(
        format(addDays(firstDOW, index), 'EEEEEE', { locale: ru }),
      );
    }
  }
  return (
    <button key={id} className="slot__container" onClick={onSlotClick}>
      <div key={id} className="slot__content">
        <div className="slot__first-row-wrapper">
          <div className="slot__first-row">
            <CalendarIcon />
            <div className="slot__first-row-dates button-text">{`${format(
              startDate,
              'dd MMMM yyyy',
              {
                locale: ru,
              },
            )} ${
              endDate?.toDateString() !== startDate?.toDateString()
                ? ` -  ${format(endDate, 'dd MMMM yyyy', {
                    locale: ru,
                  })}`
                : ''
            }`}</div>
            <div className="slot__first-row-dates-xs button-text">{`${format(
              startDate,
              'dd MMM yyyy',
              {
                locale: ru,
              },
            )} ${
              endDate?.toDateString() !== startDate?.toDateString()
                ? ` -  ${format(endDate, 'dd MMM yyyy', {
                    locale: ru,
                  })}`
                : ''
            }`}</div>
            {endDate?.toDateString() !== startDate?.toDateString() && (
              <div className="slot__first-row-weekdays-wrapper">
                <div className="slot__first-row-weekdays-label label-text">
                  Каждый:
                </div>
                <div className="slot__first-row-weekdays normal-text">
                  {shortWeekDays?.map((item, index, { length }) => {
                    if (index !== length - 1)
                      return <span key={index}>{`${item}, `}</span>;
                    else return <span key={index}>{item}</span>;
                  })}
                </div>
              </div>
            )}
          </div>
          {role === 'ACTIVITY_PROVIDER' && (
            <div className="slot__first-row-icon">
              <ArrowIcon />
            </div>
          )}
        </div>
        {!['RENTAL', 'TRANSFER', 'PRIMITIVE'].includes(type as string) && (
          <div className="slot__second-row">
            {times?.map((item, index) => (
              <div
                key={index}
                className="time-slot__container slot__second-row-time label-text"
              >
                <TimeIcon />
                {format(
                  new Date(`${new Date().toDateString()} ${item}`),
                  'HH:mm',
                  {
                    locale: ru,
                  },
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </button>
  );
};

export default withRouter(Slot);
