import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactCodeInput from 'react-verification-code-input';

import classNames from 'classnames';

import { ReactComponent as Logo } from '../../images/components/Layout/logo-lg.svg';
import { selectApiError, setApiError } from '../../store/commonSlice';
import DeadlineTimer from './DeadlineTimer';

interface IConfirmation {
  onComplete(code: string): void;
  resendCodeRequest(): void;
  email: string | undefined;
}

const Confirmation: React.FC<IConfirmation> = ({
  onComplete,
  resendCodeRequest,
  email,
}) => {
  const [readyToResendCode, setReadyToResendCode] =
    React.useState<boolean>(false);
  const [reInitTimer, setReInitTimer] = React.useState<boolean>(false);
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      if (['IDENTIFIER_VERIFICATION_FAILED'].includes(apiError?.key as string))
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [apiError.key]);

  const resendCode = () => {
    if (readyToResendCode) {
      if (apiError.key === 'IDENTIFIER_VERIFICATION_FAILED')
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
          }),
        );
      resendCodeRequest();
      setReInitTimer(!reInitTimer);
      setReadyToResendCode(false);
    }
  };

  const onCodeChange = () => {
    if (apiError.key === 'IDENTIFIER_VERIFICATION_FAILED')
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
        }),
      );
  };

  return (
    <>
      <div className="registration-page__logo-wrapper">
        <div className="registration-text-wrapper title">
          <Logo className="registration-page__logo" />
          Введите код подтверждения
        </div>
      </div>
      <div className="confirmation-page__hint normal-text">
        Мы отправили код подтверждения
        <br />
        на ваш e-mail {email && <span className="bold-text">{email}</span>}
      </div>
      <div
        className={classNames(
          'confirmation-page__code',
          apiError.key === 'IDENTIFIER_VERIFICATION_FAILED' && 'error',
        )}
      >
        <ReactCodeInput
          fields={5}
          type="number"
          fieldHeight={44}
          fieldWidth={44}
          autoFocus={true}
          onComplete={onComplete}
          onChange={onCodeChange}
          placeholder={['-', '-', '-', '-', '-']}
        />
      </div>
      <div className="confirmation-page__time-row label-text">
        {
          <DeadlineTimer
            deadlineInMin={10}
            setReadyToResendCode={setReadyToResendCode}
            reInit={reInitTimer}
          />
        }
      </div>
      <button
        className="confirmation-page__repeat-btn button-text underlined"
        onClick={resendCode}
        disabled={!readyToResendCode}
      >
        Получить код повторно
      </button>
    </>
  );
};

export default Confirmation;
