import { StylesConfig } from 'react-select';

const handleStyles: StylesConfig<any, any> = {
  control: (provided, state) => {
    const backgroundColor = state.isDisabled ? '#fff' : '#EAEDF2';
    const border = state.selectProps.error
      ? '1px solid #D63854'
      : '1px solid #EAEDF2';
    const padding = state.selectProps.padding
      ? state.selectProps.padding
      : '8px 12px';
    return {
      ...provided,
      border,
      padding,
      backgroundColor,
      fontWeight: 'normal',
      transition: 'all 0.25s ease',
      boxShadow: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      '&:hover': {
        border: state.selectProps.error
          ? '1px solid #D63854'
          : '1px solid E2E6ED',
        backgroundColor: '#E2E6ED',
      },
      '&:active': {
        backgroundColor: '#DCE1EA',
      },
    };
  },
  menuList: provided => {
    return {
      ...provided,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
    };
  },
  option: (provided, state: { isSelected: boolean; isDisabled: boolean }) => {
    const color = state.isSelected
      ? '#fff'
      : state.isDisabled
      ? '#AAAEBD'
      : '#393C48';
    const backgroundColor = state.isSelected ? '#00BFB4' : '#fff';
    const hoverBackgroundColor = state.isSelected
      ? '#05C4B9'
      : state.isDisabled
      ? 'none'
      : '#CEECEB';
    const activeBackgroundColor = state.isSelected
      ? '#00A196'
      : state.isDisabled
      ? 'none'
      : '#BEE2E1';
    const cursor = state.isDisabled ? 'default' : 'pointer';
    return {
      ...provided,
      color,
      backgroundColor,
      cursor,
      height: 44,
      verticalAlign: 'middle',
      transition: 'all 0.25s ease',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '26px',
      padding: '9px 12px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '&:hover': {
        backgroundColor: hoverBackgroundColor,
      },
      '&:active': {
        backgroundColor: activeBackgroundColor,
      },
      '&:disabled': {
        backgroundColor: 'pink',
      },
      '&:first-child': {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      },
      '&:last-child': {
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      },
      '&:not(:last-child)': {
        borderBottom: '2px solid #EAEDF2',
      },
    };
  },
  menu: (provided, state) => {
    const menuWidth = state.selectProps.menuWidth;
    return {
      ...provided,
      width: menuWidth,
      marginTop: 4,
      marginBottom: 0,
      borderRadius: 8,
      boxShadow: '0px 1px 6px rgba(52, 55, 67, 0.12)',
      backgroundColor: '#fff',
      zIndex: 10,
    };
  },
  indicatorsContainer: (provided, state) => {
    const fillColor = state.isDisabled ? '#92A4B5' : '#343743';
    return {
      ...provided,
      fontSize: 0,
      height: '26px',
      '& svg path': {
        fill: fillColor,
      },
    };
  },
  clearIndicator: provided => {
    return {
      ...provided,
      padding: '0 8px',
    };
  },
  valueContainer: provided => {
    return {
      ...provided,
      padding: 0,
      lineHeight: '26px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      flexWrap: 'nowrap',
    };
  },
  input: provided => {
    return {
      ...provided,
      color: '#343743',
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '26px',
    };
  },
  placeholder: provided => {
    return {
      ...provided,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '26px',
      marginLeft: 0,
      position: 'unset',
      transform: 'none',
      color: '#92A4B5',
    };
  },
  singleValue: (provided, state) => {
    const color = state.isDisabled ? '#92A4B5' : '#343743';
    return {
      ...provided,
      color,
      marginLeft: 0,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '26px',
      position: 'unset',
      transform: 'none',
      marginRight: '12px',
    };
  },
};

export default handleStyles;
