import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import classNames from 'classnames';

import ApiErrorUI from '../../components/ApiErrorUI';
import Layout from '../../components/Layout';
import { selectApiError, setApiError } from '../../store/commonSlice';
import Locations from './Locations';
import Posts from './Posts';
import SubCategories from './SubCategories';

const WebsiteAdminPanel: React.FC<RouteComponentProps> = ({ location }) => {
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = React.useState<number>(
    (location.state as { tabIx: number })?.tabIx || 0,
  );

  React.useEffect(() => {
    return () => {
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    };
  }, []);

  return (
    <Layout contentClassName={classNames(tabIndex === 0 && 'website__content')}>
      {apiError?.isError ? (
        <ApiErrorUI />
      ) : (
        <>
          <div className="page__title title">
            {'Статьи на сайт adva.travel'}
          </div>
          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList className={classNames('bookings__menu-tab-list')}>
              <Tab className="bookings__menu-tab button-text">Полезное</Tab>
              <Tab className="bookings__menu-tab button-text">Локации</Tab>
              <Tab className="bookings__menu-tab button-text">Подкатегории</Tab>
            </TabList>
            <TabPanel>
              <Posts />
            </TabPanel>
            <TabPanel>
              <Locations />
            </TabPanel>
            <TabPanel>
              <SubCategories />
            </TabPanel>
          </Tabs>
        </>
      )}
    </Layout>
  );
};

export default withRouter(WebsiteAdminPanel);
