import * as React from 'react';

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.333 3.165l23.502 23.502-2.168 2.168L3.165 5.333l2.168-2.168z"
        fill="#343743"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.835 5.333L5.333 28.835l-2.168-2.168L26.667 3.165l2.168 2.168z"
        fill="#343743"
      />
    </svg>
  );
}

export default CloseIcon;
