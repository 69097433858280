import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import classNames from 'classnames';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import ApiErrorUI from '../../components/ApiErrorUI';
import Button from '../../components/Button';
import EditableDiv from '../../components/EditableDiv';
import Layout from '../../components/Layout';
import ReturnLink from '../../components/ReturnLink';
import { components } from '../../generated/apiTypes';
import { ReactComponent as CloseIcon } from '../../images/Service/icn-close.svg';
import {
  getActivityById,
  getLocations,
  updateVerification,
  updateVisibility,
} from '../../services/apiRequests';
import { handleStorage } from '../../services/handleStorage';
import { selectApiError, setApiError } from '../../store/commonSlice';
import BookingsLists from './BookingsLists';
import PopupMenu from './components/PopupMenu';
import ViewInfo from './Info';
import EditInfo from './Info/EditInfo/index';
import GenericServiceView from './Info/GenericServiceView';
import { ServiceInfo } from './Info/types';
import Notifications from './Notifications';
import Reviews from './Reviews';
import Slots from './Slots';

import './style.scss';

const info: ServiceInfo = {
  title: '',
  instructorName: '',
  instructorSurname: '',
  images: [],
  childrenAllowed: false,
  resort: undefined,
  subLocations: [],
  category: undefined,
  subCategories: [],
  description: '',
  location: undefined, // [52.953678, 87.956018],
  duration: undefined,
  peopleAmount: undefined,
  costFrom: '',
  cost: '',
  details: '',
  activeness: 'VERIFICATION',
  visibility: 'VISIBLE',
  verification: 'PENDING',
};

interface IRouterProps {
  provider_id?: string;
  service_id: string;
}

const Index: React.FC<RouteComponentProps<IRouterProps>> = ({
  location,
  history,
  match,
}) => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [serviceState, setServiceState] = React.useState<
    'VIEW' | 'EDIT' | 'DECLINE_CHANGES' | 'BLOCKING'
  >('VIEW');
  const [blockingDetails, setBlockingDetails] = React.useState<string>('');
  const [infoState, setInfoState] = React.useState<ServiceInfo>(info);
  const [resortOptions, setResortOptions] = React.useState<
    { value: string; label: string }[]
  >([]);
  const apiError = useSelector(selectApiError);
  const service_id = match.params.service_id;
  const provider_id = match.params.provider_id;
  const role = handleStorage.getRole();
  const dispatch = useDispatch();
  const [messageShown, setMessageShown] = React.useState<boolean>(true);
  const [visibilityRequestInProgress, setVisibilityRequestInProgress] =
    React.useState<boolean>(false);
  const [deleteRequestInProgress, setDeleteRequestInProgress] =
    React.useState<boolean>(false);
  const [approveRequestInProgress, setApproveRequestInProgress] =
    React.useState<boolean>(false);
  const [declineRequestInProgress, setDeclineRequestInProgress] =
    React.useState<boolean>(false);
  const [blockRequestInProgress, setBlockRequestInProgress] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    return () => {
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    };
  }, []);

  const getActivity = async () => {
    const response = await getActivityById(service_id);
    if (response.data) {
      const subCategories: {
        value: string;
        label: string;
        tags?: components['schemas']['TagView'][];
      }[] = [];
      const images: {
        view: components['schemas']['ActivityPictureView'];
        file?: File;
      }[] = [];
      response.data?.subcategories?.forEach(item =>
        subCategories.push({
          label: item?.name as string,
          value: item?.id?.toString() as string,
          tags: item.tags,
        }),
      );
      response.data?.pictures?.forEach(item =>
        images.push({
          view: item,
        }),
      );
      const subLocations: { value: string; label: string }[] = [];
      response.data?.sublocations?.forEach(item =>
        subLocations.push({
          label: item?.name as string,
          value: item?.id?.toString() as string,
        }),
      );
      const tags: { id?: number; name?: string }[] = [];
      response.data?.tags?.forEach(item =>
        tags.push({
          id: item?.id as number,
          name: item?.name as string,
        }),
      );
      const routePoints: { name: string; description?: string }[] = [];
      response.data?.routePoints?.forEach(item =>
        routePoints.push({
          name: item?.name as string,
          description: item?.description as string,
        }),
      );
      setInfoState({
        ...infoState,
        subCategories,
        subLocations,
        images,
        tags,
        routePoints,
        description: response.data?.description as string,
        details: response.data?.additionalInfo as string,
        costFrom: (response.data?.summary?.minimumPrice as number).toString(),
        cost: response.data?.prices as string,
        pricing: response.data?.pricing,
        resort: {
          label: response.data?.summary?.location?.name as string,
          value: response.data?.summary?.location?.id?.toString() as string,
          location: [
            response.data?.summary?.location?.position?.latitude as number,
            response.data?.summary?.location?.position?.longitude as number,
          ],
        },
        childrenAllowed: response.data?.summary?.childrenAllowed as boolean,
        category: {
          label: response.data?.summary?.category?.name as string,
          value: response.data?.summary?.category?.id?.toString() as string,
          type: response.data?.summary?.category?.type as
            | 'ACTIVITY'
            | 'EXPERT'
            | 'RENTAL',
        },
        title:
          response.data?.summary?.category?.type === 'EXPERT'
            ? `${response.data?.summary?.expertFirstName} ${response.data?.summary?.expertLastName}. ${response.data?.summary?.category?.name}`
            : (response.data?.summary?.name as string),
        location:
          response.data?.summary?.position?.latitude &&
          response.data?.summary?.position?.longitude
            ? [
                response.data?.summary?.position?.latitude as number,
                response.data?.summary?.position?.longitude as number,
              ]
            : undefined,
        instructorName: response.data?.summary?.expertFirstName as string,
        instructorSurname: response.data?.summary?.expertLastName as string,
        phone: response.data?.summary?.phone,
        deliveryAvailable: response.data?.summary?.deliveryAvailable,
        openingTime: response.data?.summary?.openFrom
          ? format(
              new Date(
                `${new Date().toDateString()} ${
                  response.data?.summary?.openFrom
                }`,
              ),
              'HH:mm',
              {
                locale: ru,
              },
            )
          : '',
        closingTime: response.data?.summary?.openUntil
          ? format(
              new Date(
                `${new Date().toDateString()} ${
                  response.data?.summary?.openUntil
                }`,
              ),
              'HH:mm',
              {
                locale: ru,
              },
            )
          : '',
        peopleAmount: Math.floor(
          response.data?.summary?.numberOfPeople as number,
        ).toString(),
        duration: {
          days:
            response.data?.summary?.durationInMinutes !== null
              ? Math.floor(
                  (response.data?.summary?.durationInMinutes as number) / 1440,
                ).toString()
              : undefined,
          hours:
            response.data?.summary?.durationInMinutes !== null
              ? Math.floor(
                  (-Math.floor(
                    (response.data?.summary?.durationInMinutes as number) /
                      1440,
                  ) *
                    1440 +
                    (response.data?.summary?.durationInMinutes as number)) /
                    60,
                ).toString()
              : undefined,
        },
        activeness: response.data?.summary?.activeness as any,
        visibility: response.data?.summary?.visibility as any,
        verification: response.data?.summary?.verification as any,
        operatorComment: response.data?.operatorComment,
        summary: response.data?.summary,
        expert: response.data?.expert && {
          firstName: response.data?.expert?.firstName as string,
          lastName: response.data?.expert?.lastName as string,
          photo: response.data.expert?.photo && {
            view: response.data.expert
              ?.photo as components['schemas']['AttachmentView'],
          },
          voice: response.data.expert?.voice && {
            view: response.data.expert
              ?.voice as components['schemas']['AttachmentView'],
          },
        },
        durationRange: response.data.durationRange,
      });
    }
  };

  const getOptions = async () => {
    const response = await getLocations();
    if (response?.data) {
      const resortOptions = [];
      for (const item of response.data) {
        resortOptions.push({
          value: (item?.id as number).toString(),
          label: item?.name as string,
          location: [
            item?.position?.latitude as number,
            item?.position?.longitude as number,
          ],
        });
      }
      setResortOptions(resortOptions);
    }
  };

  React.useEffect(() => {
    const tabIx = (location.state as { tabIx: number })?.tabIx;
    if (tabIx !== undefined) {
      setTabIndex(tabIx);
    }
    history.replace({ state: {} });
    getActivity();
    getOptions();
    return () => {
      dispatch(
        setApiError({ isError: false, status: undefined, message: undefined }),
      );
    };
  }, []);

  const onBlockServiceClick = async () => {
    if (infoState?.verification === 'BLOCKED') {
      setBlockRequestInProgress(true);
      await updateVerification({ status: 'VERIFIED' }, service_id);
      await getActivity();
      setBlockRequestInProgress(false);
    } else setServiceState('BLOCKING');
  };

  const onEditServiceClick = () => {
    setServiceState('EDIT');
  };

  const onDeclineChangesClick = () => {
    setServiceState('DECLINE_CHANGES');
  };

  const blockService = async () => {
    setBlockRequestInProgress(true);
    await updateVerification(
      { status: 'BLOCKED', comment: blockingDetails },
      service_id,
    );
    await getActivity();
    setBlockRequestInProgress(false);
    setServiceState('VIEW');
    setBlockingDetails('');
  };

  const declineChanges = async () => {
    setDeclineRequestInProgress(true);
    await updateVerification(
      { status: 'REJECTED', comment: blockingDetails },
      service_id,
    );
    await getActivity();
    setDeclineRequestInProgress(false);
    setServiceState('VIEW');
    setBlockingDetails('');
  };

  const cancelBlockingService = () => {
    setServiceState('VIEW');
    setBlockingDetails('');
  };

  const onSaveChangesClick = async () => {
    await getActivity();
    setServiceState('VIEW');
    window.scrollTo(0, 0);
  };

  const onCancelChangesClick = () => {
    setServiceState('VIEW');
  };

  const redirectToHome = () => {
    if (role === 'ACTIVITY_PROVIDER') history.push('/provider');
    else if (role === 'OPERATOR')
      history.push(`/operator/provider=${provider_id}`);
  };

  const verifyService = async () => {
    setApproveRequestInProgress(true);
    await updateVerification({ status: 'VERIFIED' }, service_id);
    await getActivity();
    setApproveRequestInProgress(false);
  };

  const deleteService = async () => {
    setDeleteRequestInProgress(true);
    try {
      await updateVisibility({ visibility: 'DELETED' }, service_id);
      history.push('/provider');
    } catch (e) {
      //
    }
    setDeleteRequestInProgress(false);
  };

  const toggleVisibility = async () => {
    const visibility =
      infoState?.activeness === 'ACTIVE' ? 'HIDDEN' : 'VISIBLE';
    setVisibilityRequestInProgress(true);
    try {
      await updateVisibility({ visibility: visibility }, service_id);
      await getActivity();
    } catch (e) {
      //
    }
    setVisibilityRequestInProgress(false);
  };

  const getStatus = (): string => {
    switch (infoState?.activeness) {
      case 'ACTIVE':
        return 'Активна';
      case 'INACTIVE':
        return 'Неактивна';
      case 'BLOCKED':
        return 'Заблокирована';
      case 'REJECTED':
        return 'Отклонена';
      case 'VERIFICATION':
        return 'На модерации';
    }
  };

  const errorToShow =
    apiError?.isError &&
    !(
      apiError?.key?.includes('NotBlank') ||
      apiError?.key?.includes('Size') ||
      apiError?.key?.includes('Max') ||
      apiError?.key?.includes('Range')
    );

  return (
    <Layout hideMobileMenu>
      {errorToShow ? (
        <ApiErrorUI />
      ) : (
        <>
          {serviceState === 'VIEW' && infoState?.title && (
            <>
              <ReturnLink
                linkName={
                  role === 'ACTIVITY_PROVIDER'
                    ? 'К списку услуг'
                    : 'К поставщику'
                }
                onClickHandler={redirectToHome}
              />
              <div className="bookings__top-row">
                <div className="bookings__top-row-title-wrapper">
                  <div className="page__title title bookings__top-row-title">
                    {infoState.title}
                  </div>
                  <div
                    className={classNames(
                      'service__status label-text',
                      infoState?.activeness.toLowerCase(),
                    )}
                  >
                    {getStatus()}
                  </div>
                </div>
                <PopupMenu
                  onBlockClick={onBlockServiceClick}
                  onDeleteService={deleteService}
                  toggleVisibility={toggleVisibility}
                  activeness={infoState?.activeness}
                  verification={infoState?.verification}
                  visibilityRequestInProgress={visibilityRequestInProgress}
                  deleteRequestInProgress={deleteRequestInProgress}
                  blockRequestInProgress={blockRequestInProgress}
                />
              </div>
              {infoState?.activeness === 'VERIFICATION' &&
                messageShown &&
                role === 'ACTIVITY_PROVIDER' && (
                  <div className="service__message">
                    <div className="service__message-title header2">
                      Ваша услуга проходит модерацию
                    </div>
                    <div className="service__message-text normal-text">
                      В случае успешного прохождения модерации услуга станет
                      доступна для пользователей приложения ADVA. Пока услуга
                      находится на рассмотрении, вы можете заполнить информацию
                      о расписании работы
                    </div>
                    <button
                      className="service__message-icon"
                      onClick={() => setMessageShown(false)}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                )}
              {['REJECTED', 'BLOCKED'].includes(infoState?.activeness) &&
                messageShown &&
                role === 'ACTIVITY_PROVIDER' && (
                  <div
                    className={classNames(
                      'service__message',
                      infoState?.activeness === 'REJECTED'
                        ? 'rejected'
                        : 'blocked',
                    )}
                  >
                    <div className="service__message-title header2">
                      {'Ваша услуга'}
                      {infoState?.activeness === 'REJECTED'
                        ? ' отклонена '
                        : ' заблокирована '}
                      {'модератором'}
                    </div>
                    <div className="service__message-text normal-text">
                      {'Причины'}
                      {infoState?.activeness === 'REJECTED'
                        ? ' отклонения '
                        : ' блокировки '}
                      {'услуги:'} <br />
                      {infoState?.operatorComment
                        ? infoState?.operatorComment
                        : 'Не указаны'}
                      <br />
                      Вы можете отредактировать описание и повторно отправить
                      услугу на модерацию
                    </div>
                    <button
                      className="service__message-icon"
                      onClick={() => setMessageShown(false)}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                )}
              <Tabs
                selectedIndex={tabIndex}
                onSelect={index => setTabIndex(index)}
              >
                <TabList
                  className={classNames(
                    'bookings__menu-tab-list',
                    infoState?.activeness === 'VERIFICATION' &&
                      messageShown &&
                      role === 'ACTIVITY_PROVIDER' &&
                      'message-shown',
                  )}
                >
                  <Tab className="bookings__menu-tab button-text">
                    Бронирования
                  </Tab>
                  <Tab className="bookings__menu-tab button-text">
                    Информация
                  </Tab>
                  <Tab className="bookings__menu-tab button-text">
                    Расписание
                  </Tab>
                  <Tab className="bookings__menu-tab button-text">Отзывы</Tab>
                  {infoState.summary?.provider?.supervisor && (
                    <Tab className="bookings__menu-tab button-text">
                      Оповещения
                    </Tab>
                  )}
                </TabList>
                <TabPanel>
                  <BookingsLists />
                </TabPanel>
                <TabPanel>
                  {['ACTIVITY', 'EXPERT', 'RENTAL', 'TRANSFER'].includes(
                    infoState.category?.type as string,
                  ) ? (
                    <ViewInfo
                      info={infoState}
                      onDeclineChangesClick={onDeclineChangesClick}
                      onEditClick={onEditServiceClick}
                      verifyService={verifyService}
                      approveRequestInProgress={approveRequestInProgress}
                    />
                  ) : (
                    <GenericServiceView
                      info={infoState}
                      onDeclineChangesClick={onDeclineChangesClick}
                      onEditClick={onEditServiceClick}
                      verifyService={verifyService}
                      approveRequestInProgress={approveRequestInProgress}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  <Slots type={infoState.category?.type} />
                </TabPanel>
                <TabPanel>
                  <Reviews
                    rating={infoState.summary?.statistics?.rating}
                    numberOfReviews={
                      infoState.summary?.statistics?.numberOfReviews
                    }
                  />
                </TabPanel>
                {infoState.summary?.provider?.supervisor && (
                  <TabPanel>
                    <Notifications />
                  </TabPanel>
                )}
              </Tabs>
            </>
          )}
          {serviceState === 'BLOCKING' && (
            <>
              <div className="edit-service__return-link-xs">
                <ReturnLink
                  linkName={''}
                  onClickHandler={() => setServiceState('VIEW')}
                />
              </div>
              <div className="edit-service__content">
                <div className="page__title title">Блокировка услуги</div>
                <div className="service__blocking-message-container">
                  <EditableDiv
                    divClassName="service__blocking-message"
                    value={blockingDetails}
                    saveValue={setBlockingDetails}
                    data-placeholder={`Напишите причины блокировки заявки и способы устранения этих причин`}
                  />
                </div>
                <div className="service__blocking-buttons">
                  <Button
                    onClick={blockService}
                    className="service__blocking-btn"
                    disabled={blockRequestInProgress}
                  >
                    Отправить и заблокировать услугу
                  </Button>
                  <Button
                    onClick={cancelBlockingService}
                    className="service__blocking-cancel-btn"
                    mode="secondary"
                  >
                    Отменить
                  </Button>
                </div>
              </div>
            </>
          )}
          {serviceState === 'DECLINE_CHANGES' && (
            <>
              <div className="edit-service__return-link-xs">
                <ReturnLink
                  linkName={''}
                  onClickHandler={() => setServiceState('VIEW')}
                />
              </div>
              <div className="edit-service__content">
                <div className="page__title title">
                  Отклонение заявки на изменение данных
                </div>
                <div className="service__blocking-message-container">
                  <EditableDiv
                    divClassName="service__blocking-message"
                    value={blockingDetails}
                    saveValue={setBlockingDetails}
                    data-placeholder={`Напишите причины отклонения заявки и способы устранения этих причин`}
                  />
                </div>
                <div className="service__blocking-buttons">
                  <Button
                    onClick={declineChanges}
                    className="service__blocking-btn"
                    disabled={declineRequestInProgress}
                  >
                    Отправить и отклонить заявку
                  </Button>
                  <Button
                    onClick={cancelBlockingService}
                    className="service__blocking-cancel-btn"
                    mode="secondary"
                  >
                    Отменить
                  </Button>
                </div>
              </div>
            </>
          )}
          {serviceState === 'EDIT' && (
            <EditInfo
              info={infoState}
              onSaveClick={onSaveChangesClick}
              onCancelClick={onCancelChangesClick}
              resortOptions={resortOptions}
            />
          )}{' '}
        </>
      )}
    </Layout>
  );
};

export default withRouter(Index);
