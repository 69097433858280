import * as React from 'react';
import { Range } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import ApiErrorUI from '../../components/ApiErrorUI';
import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import EditableDiv from '../../components/EditableDiv';
import ImageArea, { Image } from '../../components/ImagesArea';
import Layout from '../../components/Layout';
import Switch from '../../components/Switch';
import { components } from '../../generated/apiTypes';
import {
  createFeedPost,
  getFeedLocationById,
  getFeedPostById,
  getFeedSubcategoryById,
  updateFeedLocationById,
  updateFeedPostById,
  updateFeedSubcategoryById,
  uploadAttachment,
} from '../../services/apiRequests';
import { selectApiError, setApiError } from '../../store/commonSlice';

interface IRouterProps {
  item_id?: string;
}

interface IEditWebsiteItemProps extends RouteComponentProps<IRouterProps> {
  type: 'POST' | 'LOCATION' | 'SUBCATEGORY';
  newItem?: boolean;
}

type WebsiteItem = {
  id?: number;
  name?: string;
  hidden?: boolean;
  description?: string;
  date?: Date;
};

const EditWebsiteItem: React.FC<IEditWebsiteItemProps> = ({
  type,
  newItem,
  match,
  history,
}) => {
  const item_id = match.params.item_id;
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();
  const [image, setImage] = React.useState<Image>();
  const [data, setData] = React.useState<WebsiteItem>({
    date: new Date(),
    hidden: true,
  });
  const [requestInProgress, setRequestInProgress] =
    React.useState<boolean>(false);
  const [selectionState, setSelectionState] = React.useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [descError, setDescError] = React.useState<boolean>(false);
  const [imageError, setImageError] = React.useState<boolean>(false);

  React.useEffect(() => {
    return () => {
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    };
  }, []);

  const getLocation = async () => {
    if (item_id) {
      const response = await getFeedLocationById(item_id);
      if (response.data) {
        setData({
          name: response.data.name,
          id: response.data.id,
          hidden: response.data.hiddenFromFeed,
          description: response.data.description,
        });
        setImage({
          view: response.data.feedPicture,
          file: undefined,
        });
      }
    }
  };

  const getSubcategory = async () => {
    if (item_id) {
      const response = await getFeedSubcategoryById(item_id);
      if (response.data) {
        setData({
          name: response.data.name,
          id: response.data.id,
          hidden: response.data.hiddenFromFeed,
          description: response.data.description,
        });
        setImage({
          view: response.data.feedPicture,
          file: undefined,
        });
      }
    }
  };

  const getPost = async () => {
    if (item_id) {
      const response = await getFeedPostById(item_id);
      if (response.data) {
        setData({
          name: response.data.title,
          id: response.data.id,
          hidden: response.data.hidden,
          description: response.data.content,
          date: new Date(response.data.publicationDate as string),
        });
        setImage({
          view: response.data.picture,
          file: undefined,
        });
      }
    }
  };

  React.useEffect(() => {
    switch (type) {
      case 'LOCATION':
        getLocation();
        return;
      case 'SUBCATEGORY':
        getSubcategory();
        return;
      case 'POST':
        getPost();
        return;
      default:
        return;
    }
  }, []);

  React.useEffect(() => {
    if (data?.date) {
      setSelectionState([
        {
          startDate: data?.date,
          endDate: data?.date,
          key: 'selection',
        },
      ]);
    }
  }, [data?.date]);

  const getTitle = () => {
    if (newItem) {
      switch (type) {
        case 'LOCATION':
          return 'Добавление локации';
        case 'POST':
          return 'Добавление статьи в полезное';
        case 'SUBCATEGORY':
          return 'Добавление подкатегории';
        default:
          return '';
      }
    } else {
      switch (type) {
        case 'LOCATION':
          return 'Редактирование локации';
        case 'POST':
          return 'Редактирование статьи';
        case 'SUBCATEGORY':
          return 'Редактирование подкатегории';
        default:
          return '';
      }
    }
  };

  const setSelection = (range: Range[]) => {
    const newRange: Range[] = [
      {
        startDate: range[0].startDate,
        endDate: range[0]?.startDate,
        key: range[0]?.key,
      },
    ];
    setSelectionState(newRange);
    setData(data => ({ ...data, date: newRange[0].startDate }));
  };

  const onNameChanged = (name: string) => {
    if (nameError) setNameError(false);
    if (apiError?.key?.includes('name'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setData(data => ({ ...data, name: name }));
  };

  const onDescChanged = (desc: string) => {
    if (descError) setDescError(false);
    if (apiError?.key?.includes('description'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setData(data => ({ ...data, description: desc }));
  };

  const onChangeTag = (value: boolean) => {
    setData(data => ({ ...data, hidden: !value }));
  };

  const resetImageError = () => {
    setImageError(false);
  };

  const updateWebsiteItem = async () => {
    let imageToUpdate: components['schemas']['AttachmentView'] = {};
    try {
      setRequestInProgress(true);
      if (image?.file) {
        const formData = new FormData();
        formData.append('file', image?.file as Blob);
        const response = await uploadAttachment(formData);
        if (response?.data) {
          imageToUpdate = response.data;
        }
      } else {
        imageToUpdate = image?.view as components['schemas']['AttachmentView'];
      }
      if (item_id) {
        switch (type) {
          case 'LOCATION': {
            await updateFeedLocationById(item_id, {
              description: data?.description as string,
              hiddenFromFeed: data?.hidden as boolean,
              feedPicture: imageToUpdate,
            });
            history.push({
              pathname: '/operator/website',
              state: { tabIx: 1 },
            });
            break;
          }
          case 'SUBCATEGORY': {
            await updateFeedSubcategoryById(item_id, {
              description: data?.description as string,
              hiddenFromFeed: data?.hidden as boolean,
              feedPicture: imageToUpdate,
            });
            history.push({
              pathname: '/operator/website',
              state: { tabIx: 2 },
            });
            break;
          }
          case 'POST': {
            await updateFeedPostById(item_id, {
              title: data?.name as string,
              content: data?.description as string,
              hidden: data?.hidden as boolean,
              picture: imageToUpdate,
              publicationDate: format(data?.date as Date, 'yyyy-MM-dd', {
                locale: ru,
              }),
            });
            history.push({
              pathname: '/operator/website',
              state: { tabIx: 0 },
            });
            break;
          }
          default:
            break;
        }
      } else {
        if (newItem && type === 'POST') {
          await createFeedPost({
            title: data?.name as string,
            content: data?.description as string,
            hidden: data?.hidden as boolean,
            picture: imageToUpdate,
            publicationDate: format(data?.date as Date, 'yyyy-MM-dd', {
              locale: ru,
            }),
          });
          history.push({
            pathname: '/operator/website',
            state: { tabIx: 0 },
          });
        }
      }
    } catch (err) {
      //
    }
    setRequestInProgress(false);
  };

  const validateFields = (): boolean => {
    let validateResult = true;
    if (!image?.view && !image?.file) {
      validateResult = false;
      setImageError(true);
    }
    if (!data?.description) {
      validateResult = false;
      setDescError(true);
    }
    if (!data?.name) {
      validateResult = false;
      setNameError(true);
    }
    return validateResult;
  };

  const saveItem = () => {
    if (validateFields()) {
      updateWebsiteItem();
    }
  };

  const errorToShow =
    apiError?.isError &&
    !(apiError?.key?.includes('NotBlank') || apiError?.key?.includes('Size'));

  return (
    <Layout>
      {errorToShow ? (
        <ApiErrorUI />
      ) : (
        <div>
          <div className="edit-service__title-row page__title title">
            {getTitle()}
          </div>
          <div className="edit-service__content">
            <div className="view-info__item-title edit-service__category-title header1">
              {'Заголовок'}
            </div>
            <div className="edit-info__item">
              <div className="edit-service__title-input">
                <EditableDiv
                  value={data?.name as string}
                  singleLine={true}
                  disabled={type !== 'POST'}
                  data-placeholder={'Заголовок'}
                  saveValue={onNameChanged}
                  error={nameError || apiError?.key?.includes('title')}
                />
              </div>
            </div>
            <div className="edit-service__children step1__tag">
              <div className="edit-service__child-option-wrapper">
                <div className="edit-service__child-option normal-text">
                  {'Показывать на сайте'}
                </div>
              </div>
              <div className="edit-service__child-switch">
                <Switch
                  checked={!data?.hidden}
                  name={''}
                  handleChange={onChangeTag}
                />
              </div>
            </div>
          </div>
          <div className="view-info__item-title edit-service__images-title header1">
            Фотоматериалы
          </div>
          <ImageArea
            image={image}
            setImage={setImage}
            error={imageError}
            setError={setImageError}
            resetError={resetImageError}
          />
          <div className="edit-service__content">
            <div className="view-info__item-title edit-service__category-title header1">
              {'Текст статьи'}
            </div>
          </div>
          <div className="edit-info__item">
            <div className="edit-service__title-input website_edit-item-post-text-input">
              <EditableDiv
                value={data?.description as string}
                singleLine={false}
                data-placeholder={'Текст статьи'}
                divClassName="website_edit-item-post-text"
                saveValue={onDescChanged}
                error={descError || apiError?.key?.includes('description')}
              />
            </div>
          </div>
          {type === 'POST' && (
            <div className="edit-slot__date-picker">
              <div className="edit-slot__label label-text">Дата публикации</div>
              <div>
                <DatePicker
                  selection={selectionState}
                  setSelection={setSelection}
                  inputClassName="website__edit-item-date-input"
                  singleDate={true}
                  beforeDays={true}
                />
              </div>
            </div>
          )}
          <div className="edit-service__content">
            <div className="website_edit-item-button-wrapper">
              <Button
                className="website_edit-item-button"
                onClick={saveItem}
                disabled={requestInProgress}
              >
                {requestInProgress && (
                  <img className="edit-service__loader" src="/btn-loader.gif" />
                )}
                {requestInProgress ? 'Сохранение...' : 'Сохранить'}
              </Button>
            </div>
            {imageError && (
              <div className="login-page__error-message normal-text">
                Добавьте фотографию
              </div>
            )}
            {apiError?.key?.includes('NotBlank') ||
            apiError?.key?.includes('Size') ||
            apiError?.key?.includes('Max') ? (
              <div className="login-page__error-message normal-text">
                {apiError?.message}
              </div>
            ) : nameError || descError ? (
              <div className="login-page__error-message normal-text">
                Заполните все поля
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default withRouter(EditWebsiteItem);
