import * as React from 'react';

import Footer from '../../components/Footer/Footer';
import Advantages from './Advantages';
import Introduction from './Introduction';
import Register from './Register';
import Steps from './Steps';
import Tools from './Tools';

const Index: React.FC = () => {
  return (
    <>
      <Introduction />
      <Tools />
      <Advantages />
      <Steps />
      <Register />
      <Footer />
    </>
  );
};

export default Index;
