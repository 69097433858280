/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Divider from '../../../components/Divider/Divider';
import ArrowIcon from '../../../images/LandingIcons/ArrowIcon';
// import './usefullStyle.scss';
import pic from './travelPic.png';
import ShareIcon from '../../../images/LandingIcons/ShareIcon';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { getFeedPosts } from '../../../services/apiRequests';
import { components } from '../../../generated/apiTypes';

function Usefull(props: any) {
  const history = useHistory();

  const [maxResults, setMaxResults] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = React.useState<
    components['schemas']['FeedPostView'][] | undefined
  >([]);

  const goTo = (path: string) => {
    history.push(path);
  };

  const getAllPosts = async () => {
    const response = await getFeedPosts(maxResults * page, false);
    setData(response.data.results);
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  return (
    <div className="usefull__container">
      <Divider className="divider__horizontal" />
      <div className="usefull__content">
        <div className="usefull__title">
          <h2 className="block__title-dark">
            Полезное для путешествий и новости
          </h2>
        </div>
        <div className="usefull__cards">
          {data?.map(post => (
            <button
              key={post.id}
              className="usefull__card-item"
              type="button"
              onClick={() => goTo(`/blogs/article/${post.id}`)}
            >
              <div className="usefull__picture-container">
                <div
                  className="usefull__picture"
                  style={{
                    background: `url(${post.picture?.url})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              </div>
              <div className="usefull__card-description">
                <div className="usefull__card-description-title_container">
                  <p className="usefull__card-description-title">
                    {post.title}
                  </p>
                </div>
                <div className="usefull__card-description-text_container">
                  <p className="usefull__card-description-text">
                    {post.content}
                  </p>
                </div>
              </div>
            </button>
          ))}
        </div>
        <div className="usefull__links">
          <div className="usefull__link">
            <Link to="/blogs">
              <p>Читать больше</p>
            </Link>
          </div>
          <div className="usefull__link-arrow">
            <ArrowIcon />
          </div>
        </div>
      </div>
      <Divider className="divider__horizontal" />
    </div>
  );
}

export default Usefull;
