import axios, { AxiosRequestConfig } from 'axios';

import { handleStorage } from './services/handleStorage';
import { setApiError } from './store/commonSlice';
import store from './store/store';
import updateAuthToken from './utils/updateAuthToken';

const config: AxiosRequestConfig = {
  baseURL:
    process.env.REACT_APP_ENVIRONMENT === 'prod'
      ? 'https://adva.travel/api'
      : 'https://dev.a2.numdes.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
};

export const api = axios.create(config);

const handleRequest = (request: AxiosRequestConfig) => {
  if (!request.headers?.common?.Authorization) {
    const token = handleStorage.getToken();
    if (!token) {
      return request;
    }
    updateAuthToken(token);
    request.headers.common.Authorization = `Bearer ${token}`;
  }
  return request;
};

const handleResponse = async (res: any) => {
  return res;
};

const handleError = async (error: any) => {
  if (error.response?.status) {
    switch (error.response?.status) {
      case 401: {
        handleStorage.removeToken();
        updateAuthToken(false);
        if (!window.location.href.includes('login')) {
          window.location.replace('/login');
        }
        break;
      }
      default:
        store.dispatch(
          setApiError({
            isError: true,
            status: error.response?.status,
            key: error.response.data[0]?.key,
            message: error.response?.data[0]?.message,
            timeStamp: new Date().toISOString(),
          }),
        );
    }
  } else {
    store.dispatch(
      setApiError({
        isError: true,
        message: error.message,
      }),
    );
  }
  return Promise.reject(error);
};

api.interceptors.request.use(handleRequest);

api.interceptors.response.use((res: any) => handleResponse(res), handleError);
