import React, { useEffect } from 'react';

import { ReactComponent as IconPlus } from '../../../images/InfoProviders/advantages-icon-plus.svg';

import './style.scss';

const Index: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="advantages__container">
      <div className="advantages__content-container">
        <div className="advantages__main-img" />
        <div className="advantages__message">
          Что вы получите присоединившись к&nbsp;ADVA
        </div>
        <IconPlus className="advantages__icon-plus" />
        <div className="advantages__blocks">
          <div className="advantages__blocks-column">
            <div className="advantages__block">
              <IconPlus className="advantages__block-plus" />
              Новых клиентов, привлекаемых через приложение ADVA
            </div>
            <div className="advantages__block">
              <IconPlus className="advantages__block-plus" />
              Личный кабинет с&nbsp;вашим расписанием и&nbsp;активными заказами
            </div>
          </div>
          <div className="advantages__blocks-column">
            <div className="advantages__block">
              <IconPlus className="advantages__block-plus" />
              Снижение затрат на&nbsp;продвижение ваших услуг
            </div>
            <div className="advantages__block">
              <IconPlus className="advantages__block-plus" />
              Получение оплаты напрямую от клиента
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
