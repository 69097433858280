import * as React from 'react';
import {
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import ru from 'react-phone-number-input/locale/ru.json';
import { ValueType } from 'react-select';

import classNames from 'classnames';

import CountrySelect from '../CountrySelect';
import { sortByName } from './sort';

import 'react-phone-number-input/style.css';
import './style.scss';

const IntlPhoneInput: React.FC<any> = ({
  error,
  value,
  setPhoneValue,
  menuWidth,
  ...props
}) => {
  const [country, setCountry] = React.useState<{
    label: string;
    value: string;
    country: string;
    code: string;
  }>({
    label: (value && parsePhoneNumber(value)?.country) || 'RU',
    value: (value && parsePhoneNumber(value)?.country) || 'RU',
    country: (value && parsePhoneNumber(value)?.country) || 'RU',
    code:
      (value &&
        parsePhoneNumber(value)?.countryCallingCode.toString() &&
        `+${parsePhoneNumber(value)?.countryCallingCode.toString()}`) ||
      '+7',
  });
  let options: {
    label: string;
    value: string;
    country: string;
    code: string;
  }[] = [];
  const restOptions: {
    label: string;
    value: string;
    country: string;
    code: string;
  }[] = [];
  getCountries().map(country => {
    if (['RU'].includes(country)) {
      options.push({
        label: `${country} +${getCountryCallingCode(country)} ${ru[country]}`,
        value: country,
        country: country,
        code: `+${getCountryCallingCode(country).toString()}`,
      });
    }
  });
  getCountries().map(country => {
    if (['AT'].includes(country)) {
      options.push({
        label: `${country} +${getCountryCallingCode(country)} ${ru[country]}`,
        value: country,
        country: country,
        code: `+${getCountryCallingCode(country).toString()}`,
      });
    }
  });
  getCountries().map(country => {
    if (['VA'].includes(country)) {
      restOptions.push({
        label: `${country} +${getCountryCallingCode(
          country,
        )} Папский престол (Государство - город Ватикан)`,
        value: country,
        country: country,
        code: `+${getCountryCallingCode(country).toString()}`,
      });
    } else if (!['RU', 'AT'].includes(country)) {
      restOptions.push({
        label: `${country} +${getCountryCallingCode(country)} ${ru[country]}`,
        value: country,
        country: country,
        code: `+${getCountryCallingCode(country).toString()}`,
      });
    }
  });
  restOptions.sort(sortByName);
  options = [...options, ...restOptions];
  options.push({
    label: `Int ${ru['ZZ']}`,
    value: '',
    country: '',
    code: 'Int',
  });

  const onChangeCountry = (value: ValueType<any, false>) => {
    setCountry(value);
  };

  return (
    <div className={classNames('phone-input__container', { error })}>
      <CountrySelect
        labels={ru}
        value={country}
        onChange={onChangeCountry}
        options={options}
        isSearchable={false}
        menuWidth={menuWidth || '343px'}
      />
      <PhoneInput
        international
        country={country.country}
        value={value}
        onChange={setPhoneValue}
        {...props}
      />
    </div>
  );
};

export default IntlPhoneInput;
