import * as React from 'react';
import TimePicker, { TimePickerValue } from 'react-time-picker';

import classNames from 'classnames';

import { ReactComponent as CloseIcon } from '../../images/EditSlot/icn-close.svg';

import './style.scss';

interface ITimeSlot {
  time: TimePickerValue;
  setTime(value: TimePickerValue, index: number): void;
  deleteTime(index: number): void;
  index: number;
  onBlur(): void;
  error?: boolean;
}

const TimeSlot: React.FC<ITimeSlot> = ({
  time,
  setTime,
  index,
  deleteTime,
  onBlur,
  error,
}) => {
  // const today = new Date().toDateString();
  // const customDate = new Date(`${today} 10:30 GMT+300`);
  // const dd = customDate.toISOString();
  // alert(new Date(dd));;
  const saveTime = () => {
    const elem = document
      .getElementsByClassName(`time-slot__picker index-${index}`)[0]
      .getElementsByClassName('react-time-picker__inputGroup__hour')[0];
    if ((elem as any).value) {
      let hours = (elem as any).value;
      if (hours?.length === 1) hours = `0${hours}`;
      setTime(`${hours}:00`, index);
    }
  };

  return (
    <div
      className={classNames('time-slot__container normal-text', { error })}
      onBlur={onBlur}
    >
      <TimePicker
        key={index}
        value={time}
        onChange={saveTime}
        disableClock={true}
        clearIcon={null}
        className={`time-slot__picker index-${index}`}
        hourPlaceholder={'––'}
        minutePlaceholder={'––'}
        format={'HH:00'}
      />
      <button
        className="time-slot__delete-time-btn"
        onClick={() => deleteTime(index)}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export default TimeSlot;
