import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import classNames from 'classnames';

import { ReactComponent as CloseMenu } from '../../images/components/Layout/menu-close.svg';
import { ReactComponent as EmailIcon } from '../../images/components/Layout/support-icn-email.svg';
import { ReactComponent as PhoneIcon } from '../../images/components/Layout/support-icn-phone.svg';
import { ReactComponent as SendIcon } from '../../images/components/Layout/support-icn-send.svg';
import { handleStorage } from '../../services/handleStorage';

interface IMobileMenu extends RouteComponentProps {
  className: string;
  closeMenu(): void;
}

const MobileMenu: React.FC<IMobileMenu> = ({
  className,
  closeMenu,
  history,
}) => {
  const role = handleStorage.getRole();
  const feedAuthority = handleStorage.getFeedAuthority();

  return (
    <>
      <div className={classNames('layout__menu-container', className)} />
      <div className="layout__menu">
        <button className="layout__menu-close" onClick={closeMenu}>
          <CloseMenu />
        </button>
        <div className="layout__menu-title title">Меню</div>
        {role === 'ACTIVITY_PROVIDER' && (
          <button
            className="layout__menu-item button-text"
            onClick={() => {
              history.push('/provider');
              closeMenu();
            }}
          >
            Список услуг
          </button>
        )}
        {role === 'OPERATOR' && (
          <>
            <button
              className="layout__menu-item button-text"
              onClick={() => {
                history.push('/operator/bookings');
                closeMenu();
              }}
            >
              Бронирования
            </button>
            <button
              className="layout__menu-item button-text"
              onClick={() => {
                history.push('/operator/providers');
                closeMenu();
              }}
            >
              Поставщики
            </button>
            <button
              className="layout__menu-item button-text"
              onClick={() => {
                history.push('/operator/newreviews');
                closeMenu();
              }}
            >
              Новые отзывы
            </button>
          </>
        )}
        {feedAuthority === 'FEED' && (
          <button
            className={classNames('layout__menu-item button-text')}
            onClick={() => {
              history.push('/operator/website');
              closeMenu();
            }}
          >
            Сайт
          </button>
        )}
        <button
          className={classNames(
            'layout__menu-item button-text',
            role === 'OPERATOR' && 'operator-account-item',
          )}
          onClick={() => {
            if (role === 'OPERATOR') {
              history.push('/operator/account');
            } else {
              history.push('/provider/account');
            }
            closeMenu();
          }}
        >
          Профиль
        </button>
        {role === 'ACTIVITY_PROVIDER' && (
          <div className="layout__menu-support">
            <div className="layout__support-menu-title header2">
              Контакты&nbsp;техподдержки
            </div>
            <div className="layout__support-menu-item normal-text">
              <SendIcon className="layout__support-menu-item-icon" />
              @adva_support
            </div>
            <div className="layout__support-menu-item normal-text">
              <EmailIcon className="layout__support-menu-item-icon" />
              provider.support@adva.travel
            </div>
            <div className="layout__support-menu-item normal-text">
              <PhoneIcon className="layout__support-menu-item-icon" />
              +7 (995) 631-89-11
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withRouter(MobileMenu);
