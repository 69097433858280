import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import classNames from 'classnames';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import ApiErrorUI from '../../components/ApiErrorUI';
import Button from '../../components/Button';
import EditableDiv from '../../components/EditableDiv';
import Input from '../../components/Input';
import Layout from '../../components/Layout';
import ReturnLink from '../../components/ReturnLink';
import { components } from '../../generated/apiTypes';
import { ReactComponent as ApproveIcon } from '../../images/Booking/icn-approve.svg';
import { ReactComponent as DeclineIcon } from '../../images/Booking/icn-decline.svg';
import { ReactComponent as ApprovedStatusIcon } from '../../images/Booking/icn-status-approved.svg';
import { ReactComponent as CanceledStatusIcon } from '../../images/Booking/icn-status-canceled.svg';
import { ReactComponent as CompletedStatusIcon } from '../../images/Booking/icn-status-completed.svg';
import { ReactComponent as CreatedStatusIcon } from '../../images/Booking/icn-status-created.svg';
import { ReactComponent as ReviewStatusIcon } from '../../images/Booking/icn-status-review.svg';
import { ReactComponent as StarIcon } from '../../images/Service/Reviews/icn-star.svg';
import { ReactComponent as FilledStarIcon } from '../../images/Service/Reviews/icn-star-filled.svg';
import {
  getAccount,
  getReservationById,
  moderateReview,
  refundByReservationId,
  signInWithPassword,
  updateReservationStatusByProvider,
} from '../../services/apiRequests';
import { handleStorage } from '../../services/handleStorage';
import { selectApiError, setApiError } from '../../store/commonSlice';

import './style.scss';

interface IRouterProps {
  id: string;
}

const Index: React.FC<RouteComponentProps<IRouterProps>> = ({
  history,
  match,
}) => {
  const role = handleStorage.getRole();
  const [state, setState] = React.useState<
    'VIEW' | 'CANCEL' | 'DECLINE' | 'NOT_PROVIDED'
  >('VIEW');
  const [cancelBookingDetails, setCancelBookingDetails] =
    React.useState<string>('');
  const [data, setData] =
    React.useState<components['schemas']['ReservationView']>();
  const [approveRequestInProgress, setApproveRequestInProgress] =
    React.useState<boolean>(false);
  const [rejectRequestInProgress, setRejectRequestInProgress] =
    React.useState<boolean>(false);
  const [approveReviewInProgress, setApproveReviewInProgress] =
    React.useState<boolean>(false);
  const [rejectReviewInProgress, setRejectReviewInProgress] =
    React.useState<boolean>(false);
  const [cancelRequestInProgress, setCancelRequestInProgress] =
    React.useState<boolean>(false);
  const [completeRequestInProgress, setCompleteRequestInProgress] =
    React.useState<boolean>(false);
  const [emptyCommentError, setEmptyCommentError] =
    React.useState<boolean>(false);
  const [notProvidedRequestInProgress, setNotProvidedRequestInProgress] =
    React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>('');
  const [passwordError, setPasswordError] = React.useState<boolean>(false);
  const apiError = useSelector(selectApiError);
  const refundAuthority = handleStorage.getRefundAuthority();
  const dispatch = useDispatch();
  const id = match.params.id;

  const getBooking = async () => {
    const response = await getReservationById(id);
    if (response.data) {
      setData(response.data);
    }
  };

  React.useEffect(() => {
    getBooking();
    return () => {
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    };
  }, []);

  const approveBooking = async () => {
    setApproveRequestInProgress(true);
    await updateReservationStatusByProvider({ status: 'APPROVED' }, id);
    setApproveRequestInProgress(false);
    history.push({
      pathname: `/provider/service=${data?.activity?.id}`,
      state: { tabIx: 0 },
    });
  };

  const completeBooking = async () => {
    setCompleteRequestInProgress(true);
    await updateReservationStatusByProvider({ status: 'COMPLETED' }, id);
    setCompleteRequestInProgress(false);
    history.push({
      pathname: `/provider/service=${data?.activity?.id}`,
      state: { tabIx: 0 },
    });
  };

  const rejectBooking = async () => {
    setRejectRequestInProgress(true);
    await updateReservationStatusByProvider(
      { status: 'REJECTED', comment: cancelBookingDetails },
      id,
    );
    setRejectRequestInProgress(false);
    setCancelBookingDetails('');
    redirectToBookings();
  };

  const cancelBooking = async () => {
    setCancelRequestInProgress(true);
    await updateReservationStatusByProvider(
      { status: 'CANCELED_BY_PROVIDER', comment: cancelBookingDetails },
      id,
    );
    setCancelRequestInProgress(false);
    setCancelBookingDetails('');
    redirectToBookings();
  };

  const setBookingNotProvided = async () => {
    if (!cancelBookingDetails) {
      setEmptyCommentError(true);
    } else {
      setNotProvidedRequestInProgress(true);
      await updateReservationStatusByProvider(
        { status: 'NOT_PROVIDED', comment: cancelBookingDetails },
        id,
      );
      setNotProvidedRequestInProgress(false);
      setCancelBookingDetails('');
      redirectToBookings();
    }
  };

  const approveReview = async () => {
    if (data?.review?.id) {
      setApproveReviewInProgress(true);
      await moderateReview({ status: 'APPROVED' }, data.review.id.toString());
      setApproveReviewInProgress(false);
      getBooking();
    }
  };

  const rejectReview = async () => {
    if (data?.review?.id) {
      setRejectReviewInProgress(true);
      await moderateReview({ status: 'REJECTED' }, data.review.id.toString());
      setRejectReviewInProgress(false);
      getBooking();
    }
  };

  const redirectToBookings = () => {
    if (role === 'ACTIVITY_PROVIDER') {
      history.push({
        pathname: `/provider/service=${data?.activity?.id}`,
        state: { tabIx: 0 },
      });
    } else if (role === 'OPERATOR') {
      history.push({
        pathname: `/operator/provider=${(
          data?.activity?.provider?.id as number
        ).toString()}/service=${data?.activity?.id}`,
        state: { tabIx: 0 },
      });
    }
  };

  const redirectToProvider = (id: string) => {
    history.push(`/operator/provider=${id}`);
  };

  const redirectToService = (id: string) => {
    history.push({
      pathname: `/operator/provider=${(
        data?.activity?.provider?.id as number
      ).toString()}/service=${id}`,
      state: { tabIx: 1 },
    });
  };

  const getProviderStatus = (
    status:
      | 'CREATED'
      | 'APPROVED'
      | 'REJECTED'
      | 'CANCELED_BY_CUSTOMER'
      | 'CANCELED_BY_PROVIDER'
      | 'REVIEW'
      | 'NOT_PROVIDED'
      | 'COMPLETED'
      | undefined,
  ): React.ReactElement => {
    switch (status) {
      case 'CREATED':
        return (
          <>
            <CreatedStatusIcon />
            Ожидает подтверждения
          </>
        );
      case 'APPROVED':
        return (
          <>
            <ApprovedStatusIcon />
            Подтверждено
          </>
        );
      case 'REJECTED':
        return (
          <>
            <CanceledStatusIcon />
            Отклонено
          </>
        );
      case 'CANCELED_BY_CUSTOMER':
        return (
          <>
            <CanceledStatusIcon />
            Отменено клиентом
          </>
        );
      case 'CANCELED_BY_PROVIDER':
        return (
          <>
            <CanceledStatusIcon />
            Отменено
          </>
        );
      case 'REVIEW':
        return (
          <>
            <ReviewStatusIcon />
            Ожидает отзыв поставщика
          </>
        );
      case 'NOT_PROVIDED':
        return (
          <>
            <CanceledStatusIcon />
            Поставщик: Услуга не оказана
          </>
        );
      case 'COMPLETED':
        return (
          <>
            <CompletedStatusIcon />
            Поставщик: Завершено
          </>
        );
      case undefined:
        return <></>;
    }
  };

  const getCustomerStatus = (
    status:
      | 'CREATED'
      | 'APPROVED'
      | 'REJECTED'
      | 'CANCELED_BY_CUSTOMER'
      | 'CANCELED_BY_PROVIDER'
      | 'REVIEW'
      | 'NOT_PROVIDED'
      | 'COMPLETED'
      | undefined,
  ): React.ReactElement => {
    switch (status) {
      case 'CREATED':
        return (
          <>
            <CreatedStatusIcon />
            Ожидает подтверждения
          </>
        );
      case 'APPROVED':
        return (
          <>
            <ApprovedStatusIcon />
            Подтверждено
          </>
        );
      case 'REJECTED':
        return (
          <>
            <CanceledStatusIcon />
            Отклонено
          </>
        );
      case 'CANCELED_BY_CUSTOMER':
        return (
          <>
            <CanceledStatusIcon />
            Отменено клиентом
          </>
        );
      case 'CANCELED_BY_PROVIDER':
        return (
          <>
            <CanceledStatusIcon />
            Отменено поставщиком
          </>
        );
      case 'REVIEW':
        return (
          <>
            <ReviewStatusIcon />
            Ожидает отзыв клиента
          </>
        );
      case 'NOT_PROVIDED':
        return (
          <>
            <CanceledStatusIcon />
            Клиент: Услуга не оказана
          </>
        );
      case 'COMPLETED':
        return (
          <>
            <CompletedStatusIcon />
            Клиент: Завершено
          </>
        );
      case undefined:
        return <></>;
    }
  };

  const cancelHandler = () => {
    setCancelBookingDetails('');
    setState('VIEW');
  };

  const cancelBookingHandler = () => {
    setState('CANCEL');
  };

  const bookingNotProviderHandler = () => {
    setState('NOT_PROVIDED');
  };

  const declineBookingHandler = () => {
    setState('DECLINE');
  };

  const onChangeCancelBookingDetails = (value: string) => {
    if (emptyCommentError) setEmptyCommentError(false);
    setCancelBookingDetails(value);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (passwordError) setPasswordError(false);
    setPassword(e.target.value);
  };

  const onRefundClick = async () => {
    try {
      const response = await getAccount();
      if (response.data?.email) {
        await signInWithPassword({
          login: response.data?.email,
          password: password,
        });
        await refundByReservationId(id);
        getBooking();
      }
    } catch (e) {
      //
    }
  };

  const errorToShow =
    apiError?.isError &&
    ![
      'ACCOUNT_PASSWORD_INCORRECT',
      'PasswordSignIn_password_Size',
      'PasswordSignIn_password_NotBlank',
    ].includes(apiError.key as string);

  return (
    <Layout hideMobileMenu={true}>
      {errorToShow ? (
        <ApiErrorUI />
      ) : (
        data && (
          <>
            {state === 'VIEW' && (
              <>
                <ReturnLink
                  linkName={'К карточке услуги'}
                  onClickHandler={redirectToBookings}
                />
                <div className="booking-page__title-row">
                  <div className="page__title title booking-page__title">
                    Бронирование {data?.serialNumber}
                  </div>
                  <div className="booking-page__statuses_wrapper">
                    {role === 'OPERATOR' &&
                      data?.customerStatus !== data?.providerStatus && (
                        <div
                          className={classNames(
                            'booking__status booking-page__status label-text',
                            data?.customerStatus?.toLowerCase(),
                          )}
                        >
                          {getCustomerStatus(data?.customerStatus)}
                        </div>
                      )}
                    <div
                      className={classNames(
                        'booking__status booking-page__status label-text',
                        data?.providerStatus?.toLowerCase(),
                      )}
                    >
                      {getProviderStatus(data?.providerStatus)}
                    </div>
                  </div>
                </div>
                <div className="booking-page__details">
                  {role === 'OPERATOR' && (
                    <>
                      <div className="booking-page__details-title header1">
                        Поставщик услуги
                      </div>
                      <button
                        className="booking-page__details-link"
                        onClick={() =>
                          redirectToProvider(
                            (data?.activity?.provider?.id as number).toString(),
                          )
                        }
                      >
                        <div className="booking-page__details-value button-text underlined">
                          {data?.activity?.provider?.account?.name}
                        </div>
                      </button>
                      <div className="booking-page__details-title header1">
                        Услуга
                      </div>
                      <button
                        className="booking-page__details-link"
                        onClick={() =>
                          redirectToService(
                            (data?.activity?.id as number).toString(),
                          )
                        }
                      >
                        <div className="booking-page__details-value button-text underlined">
                          {data?.activity?.category?.type === 'EXPERT'
                            ? `${data?.activity?.expertFirstName} ${data?.activity?.expertLastName}. ${data?.activity?.category?.name}`
                            : data?.activity?.name}
                        </div>
                      </button>
                    </>
                  )}
                  <div className="booking-page__details-title header1">
                    Имя клиента
                  </div>
                  <div className="booking-page__details-value normal-text">
                    {data?.customer?.name}
                  </div>
                  <div className="booking-page__details-title header1">
                    Номер телефона клиента
                  </div>
                  <div className="booking-page__details-value normal-text">
                    {`+${data?.customerPhone}`}
                  </div>
                  <div className="booking-page__details-title header1">
                    Курорт
                  </div>
                  <div className="booking-page__details-value normal-text">
                    {`${data?.activity?.location?.name}${
                      data.sublocation?.name ? `, ${data.sublocation.name}` : ''
                    }`}
                  </div>
                  <div className="booking-page__details-title header1">
                    Дата
                  </div>
                  <div className="booking-page__details-value normal-text">
                    {format(new Date(data?.date as string), 'dd MMMM yyyy', {
                      locale: ru,
                    })}
                  </div>
                  {data?.activity?.category?.type !== 'RENTAL' && (
                    <>
                      <div className="booking-page__details-title header1">
                        Время
                      </div>
                      <div className="booking-page__details-value normal-text">
                        {format(
                          new Date(
                            `${new Date().toDateString()} ${data?.time}`,
                          ),
                          'HH:mm',
                          {
                            locale: ru,
                          },
                        )}
                      </div>
                    </>
                  )}
                  {data?.activity?.category?.type === 'TRANSFER' && (
                    <>
                      <div className="booking-page__details-title header1">
                        Откуда
                      </div>
                      <div className="booking-page__details-value normal-text">
                        {data?.whereFrom}
                      </div>
                      <div className="booking-page__details-title header1">
                        Куда
                      </div>
                      <div className="booking-page__details-value normal-text">
                        {data?.whereTo}
                      </div>
                    </>
                  )}
                  {data?.numberOfPeople !== null &&
                    data?.activity?.category?.type !== 'RENTAL' && (
                      <>
                        <div className="booking-page__details-title header1">
                          {data?.activity?.category?.type !== 'TRANSFER'
                            ? 'Количество участников'
                            : 'Количество человек'}
                        </div>
                        <div className="booking-page__details-value normal-text">
                          {data?.numberOfAdults && data?.numberOfAdults > 0
                            ? `${data?.numberOfAdults} взр. `
                            : ''}
                          {data?.numberOfChildren && data?.numberOfChildren > 0
                            ? `${data?.numberOfChildren} реб.`
                            : ''}
                        </div>
                      </>
                    )}
                  {data?.durationInHours && (
                    <>
                      <div className="booking-page__details-title header1">
                        Длительность занятия
                      </div>

                      <div className="booking-page__details-value normal-text">
                        {data?.allDayReservation
                          ? `${data?.durationInHours}+ ч (весь день)`
                          : `${data?.durationInHours} ч`}
                      </div>
                    </>
                  )}
                  {data?.subcategory?.name && (
                    <>
                      <div className="booking-page__details-title header1">
                        Тип занятия
                      </div>
                      <div className="booking-page__details-value normal-text">
                        {data?.subcategory?.name}
                      </div>
                    </>
                  )}
                  {data?.cost?.costWithDiscountForProvider &&
                    data.cost.serviceChargePercentage &&
                    data?.cost.serviceChargeAmount &&
                    data.cost.paymentToProvider && (
                      <>
                        <div className="booking-page__details-title header1">
                          Стоимость
                        </div>
                        <div className="booking-page__cost-field full-cost header2">
                          Полная стоимость:{' '}
                          <span className="booking-page__cost-field-value header1">
                            {data?.cost.costWithDiscountForProvider}&nbsp;руб
                          </span>
                        </div>
                        <div className="booking-page__cost-field service-charge header2">
                          Автоматически взимаемая предоплата (комиссия{' '}
                          {data.cost.serviceChargePercentage}%):{' '}
                          <span className="booking-page__cost-field-value header1">
                            {data?.cost.serviceChargeAmount}&nbsp;руб
                          </span>
                          <br />
                          <span className="booking-page__cost-field-value header1">
                            {data.payment?.status === 'PAID' ? (
                              <span className="header2 paid"> (Оплачено)</span>
                            ) : data.payment?.status === 'REFUND' ? (
                              <span className="header2 refund">
                                {' '}
                                (Возврат&nbsp;средств)
                              </span>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                        <div className="booking-page__cost-field customer-payment header2">
                          Оплата клиентом на руки:{' '}
                          <span className="booking-page__cost-field-value header1">
                            {data.cost.paymentToProvider}&nbsp;руб
                          </span>
                        </div>
                        {refundAuthority === 'REFUND' &&
                          data.payment?.refundAvailable && (
                            <div className="booking-page__review-container refund-container">
                              <div className="booking-page__details-title header2">
                                Вернуть предоплату
                              </div>
                              <div className="booking-page__refund-form">
                                <Input
                                  placeholder="Пароль"
                                  value={password}
                                  onChange={onChangePassword}
                                  error={
                                    passwordError ||
                                    apiError.key ===
                                      'ACCOUNT_PASSWORD_INCORRECT' ||
                                    apiError.key ===
                                      'PasswordSignIn_password_Size' ||
                                    apiError.key ===
                                      'PasswordSignIn_password_NotBlank'
                                  }
                                  type="password"
                                  onEnter={onRefundClick}
                                  label="Пароль для подтверждения"
                                  autoComplete="off"
                                />
                                <div className="booking-page__refund-form-button-wrapper">
                                  <Button onClick={onRefundClick}>
                                    Вернуть предоплату
                                  </Button>
                                  {[
                                    'ACCOUNT_PASSWORD_INCORRECT',
                                    'PasswordSignIn_password_Size',
                                  ].includes(apiError.key as string) && (
                                    <div className="login-page__error-message normal-text">
                                      Неверный пароль
                                    </div>
                                  )}
                                  {[
                                    'PasswordSignIn_password_NotBlank',
                                  ].includes(apiError.key as string) && (
                                    <div className="login-page__error-message normal-text">
                                      Введите пароль
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  {data?.customerComment && (
                    <>
                      <div className="booking-page__details-title header1">
                        Комментарий к бронированию
                      </div>
                      <div className="booking-page__details-value normal-text">
                        {data?.customerComment}
                      </div>
                    </>
                  )}
                  {role !== 'OPERATOR' && data.providerStatus === 'CREATED' && (
                    <div className="booking-page__notification label-text">
                      Пожалуйста, свяжитесь с клиентом по телефону после
                      подтверждения бронирования
                    </div>
                  )}
                  {role === 'OPERATOR' && data?.providerComment && (
                    <>
                      <div className="booking-page__details-title header1">
                        Комментарий поставщика
                      </div>
                      <div className="booking-page__details-value normal-text">
                        {data?.providerComment}
                      </div>
                    </>
                  )}
                  {role === 'OPERATOR' && data?.review?.rating && (
                    <>
                      <div className="booking-page__details-title header1">
                        Отзыв клиента
                      </div>
                      <div className="booking-page__review-container">
                        <div className="review__stars">
                          {data?.review?.rating >= 1 ? (
                            <FilledStarIcon />
                          ) : (
                            <StarIcon />
                          )}
                          {data?.review?.rating / 2 >= 1 ? (
                            <FilledStarIcon />
                          ) : (
                            <StarIcon />
                          )}
                          {data?.review?.rating / 3 >= 1 ? (
                            <FilledStarIcon />
                          ) : (
                            <StarIcon />
                          )}
                          {data?.review?.rating / 4 >= 1 ? (
                            <FilledStarIcon />
                          ) : (
                            <StarIcon />
                          )}
                          {data?.review?.rating / 5 >= 1 ? (
                            <FilledStarIcon />
                          ) : (
                            <StarIcon />
                          )}
                        </div>
                        <div className="booking-page__review-comment normal-text">
                          {data.review?.comment}
                        </div>
                        {data.review?.moderation === 'PENDING' && (
                          <div className="booking-page__review-buttons">
                            <Button
                              className="accent-button booking-page__button approve-btn"
                              onClick={approveReview}
                              disabled={approveReviewInProgress}
                            >
                              <ApproveIcon className="booking-page__button-icon" />
                              Подтвердить
                            </Button>
                            <Button
                              className="accent-button booking-page__button"
                              mode={'secondary'}
                              onClick={rejectReview}
                              disabled={rejectReviewInProgress}
                            >
                              <DeclineIcon className="booking-page__button-icon" />
                              Отклонить
                            </Button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {role === 'OPERATOR' && data.createdAt && (
                    <>
                      <div className="booking-page__details-title header1">
                        Мета информация
                      </div>
                      <div className="booking-page__details-value normal-text">
                        <span className="header2">
                          {'Бронирование создано: '}
                        </span>
                        {format(
                          new Date(data.createdAt as string),
                          'dd MMMM yyyy HH:mm',
                          {
                            locale: ru,
                          },
                        )}{' '}
                        {`(${data?.activity?.location?.name})`}
                      </div>
                    </>
                  )}
                </div>
                {role === 'ACTIVITY_PROVIDER' && (
                  <div
                    className={classNames(
                      'booking-page__buttons',
                      data?.providerStatus === 'CREATED' && 'small-margin',
                    )}
                  >
                    {data?.providerStatus === 'CREATED' && (
                      <>
                        <Button
                          className="accent-button booking-page__button approve-btn"
                          onClick={approveBooking}
                          disabled={approveRequestInProgress}
                        >
                          <ApproveIcon className="booking-page__button-icon" />
                          Подтвердить
                        </Button>
                        <Button
                          className="accent-button booking-page__button"
                          mode={'secondary'}
                          onClick={declineBookingHandler}
                        >
                          <DeclineIcon className="booking-page__button-icon" />
                          Отклонить
                        </Button>
                      </>
                    )}
                    {data?.providerStatus === 'APPROVED' && (
                      <Button
                        className="accent-button booking-page__button"
                        mode={'secondary'}
                        onClick={cancelBookingHandler}
                      >
                        <DeclineIcon className="booking-page__button-icon" />
                        Отменить
                      </Button>
                    )}
                    {data?.providerStatus === 'REVIEW' && (
                      <>
                        <Button
                          className="accent-button booking-page__button booking-page__not-provided-button approve-btn"
                          onClick={completeBooking}
                          disabled={completeRequestInProgress}
                        >
                          <ApproveIcon className="booking-page__button-icon" />
                          Услуга оказана
                        </Button>
                        <Button
                          className="accent-button booking-page__button booking-page__not-provided-button"
                          mode={'secondary'}
                          onClick={bookingNotProviderHandler}
                        >
                          <DeclineIcon className="booking-page__button-icon" />
                          Услуга не оказана
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
            {state === 'CANCEL' && (
              <>
                <div className="edit-service__return-link-xs">
                  <ReturnLink
                    linkName={''}
                    onClickHandler={() => setState('VIEW')}
                  />
                </div>
                <div className="edit-service__content">
                  <div className="page__title title booking__comment-title">
                    Отмена бронирования
                  </div>
                  <div className="service__blocking-message-container">
                    <EditableDiv
                      divClassName="service__blocking-message"
                      value={cancelBookingDetails}
                      saveValue={setCancelBookingDetails}
                      data-placeholder={`Напишите причины отмены бронирования`}
                    />
                  </div>
                  <div className="service__blocking-buttons">
                    <Button
                      onClick={cancelBooking}
                      className="booking-page__cancel-booking-btn"
                      disabled={cancelRequestInProgress}
                    >
                      Отправить и отменить бронирование
                    </Button>
                    <Button
                      onClick={cancelHandler}
                      className="service__blocking-cancel-btn"
                      mode="secondary"
                    >
                      Отменить
                    </Button>
                  </div>
                </div>
              </>
            )}
            {state === 'DECLINE' && (
              <>
                <div className="edit-service__return-link-xs">
                  <ReturnLink
                    linkName={''}
                    onClickHandler={() => setState('VIEW')}
                  />
                </div>
                <div className="edit-service__content">
                  <div className="page__title title booking__comment-title">
                    Отклонение бронирования
                  </div>
                  <div className="service__blocking-message-container">
                    <EditableDiv
                      divClassName="service__blocking-message"
                      value={cancelBookingDetails}
                      saveValue={setCancelBookingDetails}
                      data-placeholder={`Напишите причины отклонения бронирования`}
                    />
                  </div>
                  <div className="service__blocking-buttons">
                    <Button
                      onClick={rejectBooking}
                      className="booking-page__cancel-booking-btn"
                      disabled={rejectRequestInProgress}
                    >
                      Отправить и отклонить бронирование
                    </Button>
                    <Button
                      onClick={cancelHandler}
                      className="service__blocking-cancel-btn"
                      mode="secondary"
                    >
                      Отменить
                    </Button>
                  </div>
                </div>
              </>
            )}
            {state === 'NOT_PROVIDED' && (
              <>
                <div className="edit-service__return-link-xs">
                  <ReturnLink
                    linkName={''}
                    onClickHandler={() => setState('VIEW')}
                  />
                </div>
                <div className="edit-service__content">
                  <div className="page__title title booking__comment-title">
                    Услуга не оказана
                  </div>
                  <div className="service__blocking-message-container">
                    <EditableDiv
                      divClassName="service__blocking-message"
                      value={cancelBookingDetails}
                      saveValue={onChangeCancelBookingDetails}
                      data-placeholder={`Напишите причины, по которым услуга не была оказана`}
                      error={emptyCommentError}
                      resetError={() => setEmptyCommentError(false)}
                    />
                    {emptyCommentError && (
                      <div className="login-page__error-message normal-text">
                        Заполните поле
                      </div>
                    )}
                  </div>
                  <div className="service__blocking-buttons">
                    <Button
                      onClick={setBookingNotProvided}
                      className="booking-page__cancel-booking-btn"
                      disabled={notProvidedRequestInProgress}
                    >
                      Отправить комментарий
                    </Button>
                    <Button
                      onClick={cancelHandler}
                      className="service__blocking-cancel-btn"
                      mode="secondary"
                    >
                      Отменить
                    </Button>
                  </div>
                </div>
              </>
            )}
          </>
        )
      )}
    </Layout>
  );
};

export default withRouter(Index);
