import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import update from 'immutability-helper';

import Button from '../../../../../../components/Button';
import { ReactComponent as StopIcon } from '../../../../../../images/Service/Info/icn-route-stop.svg';
import { ReactComponent as AddIcon } from '../../../../../../images/Services/icn-add.svg';
import {
  selectApiError,
  setApiError,
} from '../../../../../../store/commonSlice';
import { ServiceInfo } from '../../../types';
import Stop from './Stop';

import './style.scss';

interface IRoute {
  infoState: ServiceInfo;
  setInfoState?(info: ServiceInfo): void;
  view?: boolean;
}

const Index: React.FC<IRoute> = ({ infoState, setInfoState, view }) => {
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();

  const addStop = () => {
    if (setInfoState) {
      const arr = update(infoState.routePoints, {
        $push: [{ name: '', description: '' }],
      });
      setInfoState({ ...infoState, routePoints: arr });
    }
  };

  const deleteStop = (index: number) => {
    if (setInfoState) {
      const arr = update(infoState.routePoints, { $splice: [[index, 1]] });
      setInfoState({ ...infoState, routePoints: arr });
    }
  };

  const setStop = (
    index: number,
    value: {
      name: string;
      description: string;
      nameError?: boolean;
      descriptionError?: boolean;
    },
  ) => {
    if (apiError?.key?.includes('RoutePointView'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    if (setInfoState) {
      const arr = update(infoState.routePoints, { [index]: { $set: value } });
      setInfoState({ ...infoState, routePoints: arr });
    }
  };

  return (
    <>
      {view ? (
        <div className={classNames('route__container')}>
          {infoState.routePoints?.map((item, index) => (
            <div className="route__view-stop-container">
              <StopIcon className={'route__stop-icon'} />
              <div className="route__view-stop-name header2">{item.name}</div>
              <div className="route__view-stop-desc normal-text">
                {item.description}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div>
            {infoState.routePoints?.map((item, index) => (
              <Stop
                index={index}
                deleteStop={deleteStop}
                stop={
                  item as {
                    name: string;
                    description: string;
                    nameError?: boolean;
                    descriptionError?: boolean;
                  }
                }
                setStop={setStop}
              />
            ))}
          </div>
          <div>
            <Button
              className="route__add-stop-btn"
              mode="secondary"
              onClick={addStop}
              disabled={
                infoState.routePoints && infoState.routePoints?.length > 7
              }
            >
              <AddIcon className="services__add-btn-icon" />
              Добавить точку маршрута
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default Index;
