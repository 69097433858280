import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ReactComponent as Logo } from '../../../images/components/Layout/logo.svg';
import { ReactComponent as AccountIcon } from '../../../images/InfoProviders/introduction-account-btn.svg';
import { ReactComponent as AccountIconXs } from '../../../images/InfoProviders/introduction-account-btn-xs.svg';
import { ReactComponent as ADVA } from '../../../images/InfoProviders/introduction-ADVA.svg';
import { ReactComponent as Arrow } from '../../../images/InfoProviders/introduction-arrow-btn.svg';
import { ReactComponent as DownloadIcon } from '../../../images/InfoProviders/introduction-download-icon.svg';
import { ReactComponent as IconRuTube } from '../../../images/InfoProviders/introduction-icon-rutube.svg';
import { ReactComponent as IconTelegram } from '../../../images/InfoProviders/introduction-icon-telegram.svg';
import { ReactComponent as IconVk } from '../../../images/InfoProviders/introduction-icon-vk.svg';
import { handleStorage } from '../../../services/handleStorage';

import './style.scss';

const Index: React.FC<RouteComponentProps> = ({ history }) => {
  const role = handleStorage.getRole();

  const redirectToRegister = () => {
    if (role === 'ACTIVITY_PROVIDER') history.push('/provider');
    else if (role === 'OPERATOR') history.push('/operator');
    else history.push('/register');
  };

  const redirectToLogin = () => {
    if (role === 'ACTIVITY_PROVIDER') history.push('/provider');
    else if (role === 'OPERATOR') history.push('/operator');
    else history.push('/login');
  };

  return (
    <>
      <div className="introduction__main-img-xs-wrapper">
        <div className="introduction__main-img-xs" />
      </div>
      <div className="introduction__container">
        <div className="introduction__shapes-container" />
        <div className="introduction__content-container">
          <div>
            <div className="introduction__logo-container">
              <Logo className="introduction__logo" />
            </div>
            <div className="introduction__info-container">
              <div className="introduction__icons">
                <a
                  href="https://t.me/advaapp"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <IconTelegram className="introduction__icon" />
                </a>
                <a
                  href="https://vk.com/advaapp"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <IconVk className="introduction__icon" />
                </a>
                <a
                  href="https://rutube.ru/channel/24261482/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <IconRuTube className="introduction__icon" />
                </a>
              </div>
              <div className="introduction__text-container">
                <div className="introduction__info-adva-title-wrapper">
                  <span className="introduction__info-adva-title">ADVA</span>
                  <span className="introduction__info-adva-title-postfix">
                    Сервис
                  </span>
                </div>
                <div className="introduction__info">
                  помогаем зарабатывать больше организаторам активных туров
                </div>
                <button
                  className="introduction__register-btn"
                  onClick={redirectToRegister}
                >
                  Разместить услугу
                  <Arrow className="introduction__register-btn-arrow" />
                </button>
                <div className="introduction__download-link">
                  <a
                    href="/Инструкция_по_заполнению_информации_об_услуге_для_инструкторов.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <DownloadIcon />
                    Руководство инструктора (3.6 MB)
                  </a>
                </div>
                <div className="introduction__resorts">
                  <div className="introduction__resort">Алтай</div>
                  <div className="introduction__resort">
                    Краснодарский&nbsp;край
                  </div>
                  <div className="introduction__resort">
                    Ленинградская&nbsp;область
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="introduction__main-img-container">
            <div className="introduction__main-img" />
            <ADVA className="introduction__adva-img" />
            <button
              className="introduction__account-btn"
              onClick={redirectToLogin}
            >
              <AccountIcon className="introduction__account-btn-icon" />
              Личный кабинет
            </button>
          </div>
          <button
            className="introduction__account-btn-xs"
            onClick={redirectToLogin}
          >
            <AccountIconXs />
          </button>
        </div>
      </div>
    </>
  );
};

export default withRouter(Index);
