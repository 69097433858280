import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import PaginationRow from '../../../components/PaginationRow';
import { components } from '../../../generated/apiTypes';
import { getReservationsByActivityId } from '../../../services/apiRequests';
import { selectApiError, setApiError } from '../../../store/commonSlice';
import BookingsDateGroup from '../components/BookingsDateGroup';

import '../style.scss';

interface IRouterProps {
  provider_id?: string;
  service_id: string;
}

const Index: React.FC<RouteComponentProps<IRouterProps>> = ({ match }) => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [data, setData] =
    React.useState<
      { date: string; bookings: components['schemas']['ReservationView'][] }[]
    >();
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [maxResults, setMaxResults] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(0);
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();
  const service_id = match.params.service_id;

  const getBookingsById = async () => {
    let group:
      | 'CURRENT'
      | 'WAITING_FOR_REVIEW'
      | 'HISTORY'
      | 'WAITING_FOR_APPROVAL' = 'CURRENT';
    if (tabIndex === 1) group = 'WAITING_FOR_REVIEW';
    else if (tabIndex === 2) group = 'HISTORY';
    else if (tabIndex === 3) group = 'WAITING_FOR_APPROVAL';
    const response = await getReservationsByActivityId(
      service_id,
      group,
      maxResults * page,
    );
    if (response.data) {
      // this gives an object with dates as keys
      const groups = response.data.results?.reduce((groups, booking) => {
        const date = booking?.date;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!groups[date]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          groups[date] = [];
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        groups[date].push(booking);
        return groups;
      }, {});

      const groupArrays = Object.keys(groups as any).map(date => {
        return {
          date,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          bookings: groups[date],
        };
      });
      setData(groupArrays);
      setMaxResults(response.data.maxResults as number);
      setTotalPages(response.data.totalPages as number);
    }
  };

  React.useEffect(() => {
    setData(undefined);
    setTotalPages(0);
    getBookingsById();
    return () => {
      if (apiError.isError)
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [page]);

  React.useEffect(() => {
    setPage(0);
    if (page === 0) {
      setData(undefined);
      setTotalPages(0);
      getBookingsById();
    }
  }, [tabIndex]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList className="page__tab-list bookings-list__tab-list">
        <Tab className="page__tab button-text">Текущие</Tab>
        <Tab className="page__tab button-text">Ждут&nbsp;отзыва</Tab>
        <Tab className="page__tab button-text">История</Tab>
        <Tab className="page__tab button-text">Ждут&nbsp;подтверждения</Tab>
      </TabList>
      <TabPanel>
        {data &&
          (data.length > 0 ? (
            data.map(item => (
              <BookingsDateGroup date={item.date} bookings={item.bookings} />
            ))
          ) : (
            <div className="services__empty_message normal-text">
              Нет бронирований в данной категории
            </div>
          ))}
        <PaginationRow totalPages={totalPages} setPage={setPage} page={page} />
      </TabPanel>
      <TabPanel>
        {data &&
          (data.length > 0 ? (
            data.map(item => (
              <BookingsDateGroup date={item.date} bookings={item.bookings} />
            ))
          ) : (
            <div className="services__empty_message normal-text">
              Нет бронирований в данной категории
            </div>
          ))}
        <PaginationRow totalPages={totalPages} setPage={setPage} page={page} />
      </TabPanel>
      <TabPanel>
        {data &&
          (data.length > 0 ? (
            data.map(item => (
              <BookingsDateGroup date={item.date} bookings={item.bookings} />
            ))
          ) : (
            <div className="services__empty_message normal-text">
              Нет бронирований в данной категории
            </div>
          ))}
        <PaginationRow totalPages={totalPages} setPage={setPage} page={page} />
      </TabPanel>
      <TabPanel>
        {data &&
          (data.length > 0 ? (
            data.map(item => (
              <BookingsDateGroup date={item.date} bookings={item.bookings} />
            ))
          ) : (
            <div className="services__empty_message normal-text">
              Нет бронирований в данной категории
            </div>
          ))}
        <PaginationRow totalPages={totalPages} setPage={setPage} page={page} />
      </TabPanel>
    </Tabs>
  );
};

export default withRouter(Index);
