import * as React from 'react';
import Dropzone from 'react-dropzone';

import imageCompression from 'browser-image-compression';
import classNames from 'classnames';

import { components } from '../../generated/apiTypes';
import { ReactComponent as CloseIcon } from '../../images/EditService/icn-close.svg';

import './style.scss';

const compressionOptions = {
  maxSizeMB: 1,
};

export type Image = {
  view?: components['schemas']['AttachmentView'];
  file?: File;
};

interface IImageArea {
  image?: Image;
  setImage(image?: Image): void;
  error?: boolean;
  setError?(err: boolean): void;
  resetError?(): void;
}

const ImageArea: React.FC<IImageArea> = ({
  image,
  setImage,
  error,
  setError,
  resetError,
}) => {
  const onDropFile = (files: File[]) => {
    saveImage(files[0]).then(() => {
      //
    });
  };

  const saveImage = async (file: File) => {
    const reader = new FileReader();
    try {
      let compressedFile: File | undefined = undefined;
      if (file && file?.type.match('image/*')) {
        setImage({
          view: { url: `/file-loader.gif` },
          file: undefined,
        });
        if (error && resetError) resetError();
        compressedFile = await imageCompression(file, compressionOptions);
        if (compressedFile) reader.readAsDataURL(compressedFile);
      } else if (setError) setError(true);
      reader.onload = () => {
        if (reader.result) {
          setImage({
            view: { url: reader.result as string },
            file: file?.type.match('image.*') ? compressedFile : file,
          });
          if (error && resetError) resetError();
        }
      };
    } catch (error) {
      //
    }
  };

  const deleteImage = () => {
    setImage();
  };

  return (
    <div className={classNames('images-area__container')}>
      <Dropzone onDrop={onDropFile}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            className={classNames(
              'images-area__dropzone normal-text',
              isDragActive && 'dragging',
              { error },
            )}
            {...getRootProps()}
          >
            <span className="images-area__dropzone-button underlined">
              Нажмите здесь
            </span>{' '}
            для загрузки фотографий
            <br />
            или&nbsp;перетащите фотографии в&nbsp;эту область
            (Не&nbsp;более&nbsp;1)
            <input
              type="file"
              {...getInputProps({
                accept: 'image/*',
                multiple: false,
              })}
            />
          </div>
        )}
      </Dropzone>
      {image?.view?.url && (
        <div className="images-area__photos">
          <div className="images-area__photos-item-wrapper">
            <img
              src={image?.view.url}
              alt=""
              className="images-area__photos-item"
            />
            <button onClick={deleteImage}>
              <CloseIcon className="images-area__photos-item-close-icon" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageArea;
