import * as React from 'react';

import RadioTabs from '../../../../../../components/RadioTabs';
import { ReactComponent as LocationIcon } from '../../../../../../images/EditService/icn-location.svg';
import Map from '../../../Map';
import { ServiceInfo } from '../../../types';

import './style.scss';

interface IMeetingPoint {
  infoState: ServiceInfo;
  setInfoState?(info: ((info: ServiceInfo) => void) | ServiceInfo): void;
  view?: boolean;
}

const Index: React.FC<IMeetingPoint> = ({ infoState, setInfoState, view }) => {
  const [locationAddress, setLocationAddress] = React.useState<string>('');
  React.useEffect(() => {
    if (!infoState.summary?.meetingPoint && setInfoState) {
      setInfoState((infoState: ServiceInfo) => ({
        ...infoState,
        summary: { ...infoState.summary, meetingPoint: 'POSITION' },
      }));
    }
  }, []);

  const setLocation = (location: number[]) => {
    if (setInfoState) {
      setInfoState(infoState => ({ ...infoState, location: location }));
    }
  };

  const setPositionAddress = (address: string) => {
    setLocationAddress(address);
    if (setInfoState) {
      setInfoState(infoState => ({ ...infoState, positionAddress: address }));
    }
  };

  const onChangeHandler = (value: 'POSITION' | 'HOTEL' | 'CUSTOM') => {
    if (setInfoState) {
      setInfoState({
        ...infoState,
        summary: { ...infoState.summary, meetingPoint: value },
      });
    }
  };

  return (
    <>
      <div className="meeting-point__container">
        {infoState.summary?.meetingPoint && (
          <div className="meeting-point__tabs-container">
            <RadioTabs
              rates={['POSITION', 'CUSTOM', 'HOTEL']}
              view={view}
              value={infoState.summary?.meetingPoint}
              onChangeMeetingPoint={onChangeHandler}
            />
          </div>
        )}
      </div>
      {infoState.resort?.location &&
        infoState.summary?.meetingPoint === 'POSITION' && (
          <>
            <Map
              mode={view ? 'VIEW' : 'EDIT'}
              coordinates={
                infoState.location
                  ? infoState.location
                  : infoState.resort?.location
              }
              setAddress={setPositionAddress}
              setNewLocation={setLocation}
            />
            <div className="view-info__item location-item full-width normal-text">
              <LocationIcon />
              {infoState?.positionAddress || locationAddress}
            </div>
          </>
        )}
      {infoState.summary?.meetingPoint === 'CUSTOM' && (
        <div className="meeting-point__custom normal-text">
          Встреча/трансфер клиента из любой удобной точки
        </div>
      )}

      {infoState.summary?.meetingPoint === 'HOTEL' && (
        <div className="meeting-point__custom normal-text">
          Встреча/трансфер от отеля клиента
        </div>
      )}
    </>
  );
};

export default Index;
