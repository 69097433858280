import * as React from 'react';

import classNames from 'classnames';

import Button from '../../../components/Button';
import { ReactComponent as ApproveIcon } from '../../../images/Booking/icn-approve.svg';
import { ReactComponent as DeclineIcon } from '../../../images/Booking/icn-decline.svg';
import { ReactComponent as LocationIcon } from '../../../images/EditService/icn-location.svg';
import { handleStorage } from '../../../services/handleStorage';
import Discounts from './EditInfo/Discounts';
import Prices from './EditInfo/Prices';
import Rates from './EditInfo/Rates';
import Map from './Map';
import Slideshow from './Slideshow';
import { ServiceInfo } from './types';

import './style.scss';

interface IViewInfo {
  info: ServiceInfo;
  onEditClick(): void;
  onDeclineChangesClick(): void;
  verifyService(): void;
  approveRequestInProgress: boolean;
}

const ViewInfo: React.FC<IViewInfo> = ({
  info,
  onEditClick,
  onDeclineChangesClick,
  verifyService,
  approveRequestInProgress,
}) => {
  const [locationAddress, setLocationAddress] = React.useState<string>('');
  const role = handleStorage.getRole();

  return (
    <>
      {role === 'OPERATOR' && info?.activeness === 'VERIFICATION' && (
        <div className="view-info__decline-changes-panel normal-text">
          Изменение информации
          <Button
            className="accent-button view-info__decline-changes-panel-btn view-info__approve-btn"
            onClick={verifyService}
            disabled={approveRequestInProgress}
          >
            <ApproveIcon className="booking-page__button-icon" />
            Подтвердить
          </Button>
          <Button
            className="accent-button view-info__decline-changes-panel-btn view-info__decline-btn"
            onClick={onDeclineChangesClick}
            mode={'secondary'}
          >
            <DeclineIcon className="booking-page__button-icon" />
            Отклонить
          </Button>
        </div>
      )}
      <div className="view-info__content">
        <div className="view-info__item-title header1">Фотоматериалы</div>
        <div>
          <Slideshow images={info?.images} />
        </div>
        <div className="view-info__item-title header1">Категория</div>
        <div
          className={classNames(
            'view-info__item category-item with-icon',
            info?.category?.type !== 'EXPERT' && 'without-subcategory',
          )}
        >
          <div className="view-info__with-icon-item label-text">
            {info?.category?.label}
          </div>
        </div>
        {info?.category?.type === 'EXPERT' && (
          <>
            <div className="view-info__item-title header2">Подкатегории</div>
            <div className="view-info__subcategories">
              {info?.subCategories?.map(item => (
                <div className="view-info__item sub-category-item with-icon">
                  <div className="view-info__with-icon-item label-text">
                    {item?.label}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <div className="view-info__item-title header1">Локация</div>
        <div
          className={classNames(
            'view-info__item category-item normal-text',
            (info?.subLocations?.length === 0 ||
              info?.category?.type === 'TRANSFER') &&
              'without-subcategory',
          )}
        >
          {info?.resort?.label}
        </div>
        {info?.subLocations?.length > 0 && (
          <>
            <div className="view-info__item-title header2">Курорт</div>
            <div className="view-info__subcategories">
              {info?.subLocations?.map(item => (
                <div className="view-info__item sub-category-item with-icon">
                  <div className="view-info__with-icon-item label-text">
                    {item?.label}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {info?.category?.type !== 'EXPERT' && (
          <>
            <div className="view-info__item-title header1">
              {info.category?.type === 'RENTAL' && 'Название проката'}
              {info.category?.type === 'ACTIVITY' && 'Название тура'}
              {info.category?.type === 'TRANSFER' &&
                'Название организации / ИП'}
            </div>
            <div className="view-info__item normal-text">{info?.title}</div>
          </>
        )}
        {info?.category?.type === 'EXPERT' && (
          <>
            <div className="view-info__item-title header1">Имя инструктора</div>
            <div className="view-info__item normal-text">
              {info?.instructorName} {info?.instructorSurname}
            </div>
            <div className="view-info__item-title header1">
              Макс. кол-во участников на одном занятии
            </div>
            <div className="view-info__item normal-text">
              {info?.peopleAmount}
            </div>
          </>
        )}
        <div className="view-info__item-title header1">Дети</div>
        <div className="view-info__item with-icon">
          <div className="view-info__with-icon-item label-text">
            {info.childrenAllowed ? 'Доступно для детей' : 'Без детей'}
          </div>
        </div>
        <div className="view-info__item-title header1">Описание</div>
        <div className="view-info__item normal-text">{info?.description}</div>
        {info?.phone && (
          <>
            <div className="view-info__item-title header1">Номер телефона</div>
            <div className="view-info__item normal-text">{`+${info?.phone}`}</div>
          </>
        )}
      </div>
      {info?.category?.type !== 'TRANSFER' && (
        <>
          <div className="view-info__item-title header1">
            {info?.category?.type === 'EXPERT'
              ? 'Место встречи'
              : 'Местоположение'}
          </div>
          <Map
            mode="VIEW"
            coordinates={info?.location as number[]}
            setAddress={setLocationAddress}
          />
          <div className="view-info__item location-item full-width normal-text">
            <LocationIcon />
            {locationAddress}
          </div>
        </>
      )}
      <div className="view-info__content">
        {info?.category?.type === 'ACTIVITY' && (
          <>
            <div className="view-info__item-title header1">
              Количество человек
            </div>
            <div className="view-info__item normal-text">{`${
              info?.peopleAmount || 0
            } чел`}</div>
            <div className="view-info__item-title header1">Длительность</div>
            <div className="view-info__item normal-text">
              {info?.duration?.days &&
                parseInt(info?.duration?.days, 10) > 0 &&
                `${info?.duration?.days} д `}{' '}
              {`${info?.duration?.hours || 0} ч `}
            </div>
          </>
        )}
        {['RENTAL', 'TRANSFER'].includes(info.category?.type as string) &&
          info?.openingTime &&
          info?.closingTime && (
            <>
              <div className="view-info__item-title header1">Часы работы</div>
              <div className="view-info__item normal-text">{`с ${info?.openingTime} до ${info?.closingTime}`}</div>
            </>
          )}
        {info?.category?.type === 'RENTAL' && (
          <>
            <div className="view-info__item-title header1">Доставка</div>
            <div className="view-info__item with-icon">
              <div className="view-info__with-icon-item label-text">
                {info.deliveryAvailable
                  ? 'Есть доставка снаряжения'
                  : 'Нет доставки снаряжения'}
              </div>
            </div>
          </>
        )}
        {['RENTAL', 'TRANSFER'].includes(info.category?.type as string) && (
          <>
            <div className="view-info__item-title header1">
              {info.category?.type === 'RENTAL'
                ? 'Стоимость аренды снаряжения'
                : 'Стоимость трансфера'}
            </div>
            <div className="view-info__item normal-text">{`от ${info?.costFrom} руб`}</div>
            {info?.cost && (
              <>
                <div className="view-info__item-title header1">Стоимость</div>
                <div className="view-info__item normal-text">{info?.cost}</div>
              </>
            )}
          </>
        )}
      </div>
      {info.category?.type === 'ACTIVITY' && (
        <>
          <div className="view-info__item-title detailed-cost-title header1">
            <>Стоимость</>
          </div>
          <Prices
            label="Взрослые (с 12 лет)"
            prices={info.pricing?.prices}
            customerType={'ADULT'}
            view
          />
          <Prices
            label="Дети (до 12 лет)"
            prices={info.pricing?.prices}
            customerType={'CHILD'}
            view
          />
          <Discounts
            serviceType={info.category?.type}
            discountsSummationEnabled={info.pricing?.discountsSummationEnabled}
            discounts={info.pricing?.discounts}
            view
          />
        </>
      )}
      {info.category?.type === 'EXPERT' && (
        <>
          <div className="view-info__item-title detailed-cost-title header1">
            <>Стоимость (за 1&nbsp;час)</>
          </div>
          <Rates
            subcategoryTariffs={info.pricing?.subcategoryTariffs}
            allDayTariffEnabled={info.pricing?.allDayTariffEnabled}
            view
          />
          <Prices
            label="Взрослые (с 12 лет)"
            customerType={'ADULT'}
            prices={info.pricing?.prices}
            view
          />
          <Prices
            label="Дети (до 12 лет)"
            customerType={'CHILD'}
            prices={info.pricing?.prices}
            view
          />
          <Discounts
            serviceType={info.category?.type}
            discountsSummationEnabled={info.pricing?.discountsSummationEnabled}
            discounts={info.pricing?.discounts}
            view
          />
        </>
      )}
      <div className="view-info__content">
        {info?.details && (
          <>
            <div className="view-info__item-title header1">
              Дополнительная информация
            </div>
            <div className="view-info__item normal-text">{info.details}</div>
          </>
        )}
        <Button className="view-info__edit-button" onClick={onEditClick}>
          Редактировать
        </Button>
      </div>
    </>
  );
};

export default ViewInfo;
