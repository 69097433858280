import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { RootState } from './store';
import { ICommonSlice } from './types';

const commonInitialState = initialState as ICommonSlice;

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    apiError: commonInitialState.apiError,
    auth: commonInitialState.auth,
  },
  reducers: {
    setApiError: (state, action: PayloadAction<ICommonSlice['apiError']>) => {
      state.apiError = { ...state.apiError, ...action.payload };
    },
    setAuth: (state, action: PayloadAction<ICommonSlice['auth']>) => {
      state.auth = action.payload;
    },
    resetCommonSliceState: state => {
      state.auth = commonInitialState.auth;
      state.apiError = commonInitialState.apiError;
    },
  },
});

export const { setApiError, setAuth, resetCommonSliceState } =
  commonSlice.actions;

const selectSelf = (state: RootState) => state.common;

export const selectApiError = createSelector(
  selectSelf,
  common => common.apiError,
);
export const selectAuth = createSelector(selectSelf, common => common.auth);
export default commonSlice.reducer;
