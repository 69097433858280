import * as React from 'react';

import classNames from 'classnames';

import PopupMenuLayout from '../../components/PopupMenuLayout';
import { ReactComponent as SupportIcon } from '../../images/components/Layout/support.svg';
import { ReactComponent as EmailIcon } from '../../images/components/Layout/support-icn-email.svg';
import { ReactComponent as PhoneIcon } from '../../images/components/Layout/support-icn-phone.svg';
import { ReactComponent as SendIcon } from '../../images/components/Layout/support-icn-send.svg';

import './style.scss';

const Support: React.FC = () => {
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const controlRef = React.useRef<HTMLButtonElement>(null);

  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };

  const closeMenu = () => {
    setActiveMenu(false);
  };

  const onMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleMenu();
  };
  return (
    <div className="layout__support-container">
      <button
        className={classNames('layout__support-button')}
        type="button"
        ref={controlRef}
        onClick={onMenuClick}
      >
        <SupportIcon />
      </button>
      {activeMenu && (
        <PopupMenuLayout
          closeMenu={closeMenu}
          controlRef={controlRef}
          className="layout__support-menu"
        >
          <div className="layout__support-menu-title header2">
            Контакты&nbsp;техподдержки
          </div>
          <div className="layout__support-menu-item normal-text">
            <SendIcon className="layout__support-menu-item-icon" />
            @adva_support
          </div>
          <div className="layout__support-menu-item normal-text">
            <EmailIcon className="layout__support-menu-item-icon" />
            provider.support@adva.travel
          </div>{' '}
          <div className="layout__support-menu-item normal-text">
            <PhoneIcon className="layout__support-menu-item-icon" />
            +7 (995) 631-89-11
          </div>
        </PopupMenuLayout>
      )}
    </div>
  );
};

export default Support;
