import React, { useEffect } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ValueType } from 'react-select';

import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';

import Button from '../../../../../components/Button';
import Dropdown from '../../../../../components/Dropdown';
import EditableDiv from '../../../../../components/EditableDiv';
import Input from '../../../../../components/Input';
import IntlPhoneInput from '../../../../../components/PhoneInput';
import ReturnLink from '../../../../../components/ReturnLink';
import Switch from '../../../../../components/Switch';
import { components } from '../../../../../generated/apiTypes';
import {
  createGenericActivity,
  createGenericActivityByOperator,
  previewPricing,
  previewPricingByOperator,
  updateGenericActivity,
  uploadAttachment,
} from '../../../../../services/apiRequests';
import { handleStorage } from '../../../../../services/handleStorage';
import { selectApiError, setApiError } from '../../../../../store/commonSlice';
import { ServiceInfo } from '../../types';
import ImagesArea from '../ImagesArea';
import Prices from '../Prices';
import MeetingPoint from './MeetingPoint';
import { getDurationOptions, getFitnessLevelOptions } from './options';
import Route from './Route';

interface IRouterProps {
  provider_id?: string;
  service_id?: string;
}

interface IStep2 extends RouteComponentProps<IRouterProps> {
  infoState: ServiceInfo;
  setInfoState(info: ((info: ServiceInfo) => void) | ServiceInfo): void;
  newService?: boolean;
  onCancelClick(): void;
  onSaveClick(id?: string): void;
  goToStep(step: 'FIRST' | 'SECOND'): void;
  configuration?: components['schemas']['ActivityConfiguration'];
}

const Index: React.FC<IStep2> = ({
  newService,
  onCancelClick,
  onSaveClick,
  goToStep,
  infoState,
  setInfoState,
  match,
  configuration,
}) => {
  const provider_id = match.params.provider_id as string;
  const service_id = match.params.service_id as string;
  const [titleError, setTitleError] = React.useState<boolean>(false);
  const [phone, setPhone] = React.useState<string | undefined>(
    `+${infoState?.phone}` || '',
  );
  const [phoneError, setPhoneError] = React.useState<boolean>(false);
  const phoneRef = React.useRef<HTMLDivElement>(null);
  const [phoneFieldWidth, setPhoneFieldWidth] = React.useState<number>(0);
  const [descError, setDescError] = React.useState<boolean>(false);
  const [imagesError, setImagesError] = React.useState<boolean>(false);
  const [audioError, setAudioError] = React.useState<boolean>(false);
  const [instructorFirstNameError, setInstructorFirstNameError] =
    React.useState<boolean>(false);
  const [instructorLastNameError, setInstructorLastNameError] =
    React.useState<boolean>(false);
  const [fitnessLevelError, setFitnessLevelError] =
    React.useState<boolean>(false);
  const [requestInProgress, setRequestInProgress] =
    React.useState<boolean>(false);
  const [isErrors, setIsErrors] = React.useState<boolean>(false);
  const [rangeFromError, setRangeFromError] = React.useState<boolean>(false);
  const [rangeToError, setRangeToError] = React.useState<boolean>(false);
  const [durationInMinutesError, setDurationInMinutesError] =
    React.useState<boolean>(false);
  const [nameSizeRouteError, setNameSizeRouteError] =
    React.useState<boolean>(false);
  const [descriptionSizeRouteError, setDescriptionSizeRouteError] =
    React.useState<boolean>(false);
  const [nameEmptyRouteError, setNameEmptyRouteError] =
    React.useState<boolean>(false);
  const [durationFromOptions, setDurationFromOptions] = React.useState<
    { value: string; label: string; disabled: boolean }[]
  >(getDurationOptions());
  const [durationToOptions, setDurationToOptions] = React.useState<
    { value: string; label: string; disabled: boolean }[]
  >(getDurationOptions());
  const role = handleStorage.getRole();
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (phoneRef?.current?.clientWidth)
      setPhoneFieldWidth(phoneRef?.current?.clientWidth);
  }, [phoneRef?.current]);

  const getPricingPreview = async () => {
    const subcategories: components['schemas']['SubcategoryView'][] = [];
    for (const subcategory of infoState?.subCategories?.filter(
      item => item.value !== '',
    )) {
      subcategories.push({ id: parseInt(subcategory.value, 10) });
    }
    if (infoState.category?.value) {
      let response:
        | AxiosResponse<components['schemas']['PricingView']>
        | undefined = undefined;
      if (role === 'ACTIVITY_PROVIDER') {
        response = await previewPricing({
          subcategories,
          category: { id: parseInt(infoState.category?.value as string, 10) },
          childrenAllowed: infoState.childrenAllowed,
          pricing: infoState.pricing || {},
          priceType: infoState.summary?.priceType,
        });
      } else {
        response = await previewPricingByOperator(
          {
            subcategories,
            category: {
              id: parseInt(infoState.category?.value as string, 10),
            },
            childrenAllowed: infoState.childrenAllowed,
            pricing: infoState.pricing || {},
            priceType: infoState.summary?.priceType,
          },
          provider_id,
        );
      }
      if (response?.data) {
        setInfoState((infoState: ServiceInfo) => ({
          ...infoState,
          pricing: {
            ...infoState.pricing,
            prices: response?.data.prices,
            discountsSummationEnabled: response?.data.discountsSummationEnabled,
            discounts: response?.data.discounts,
          },
        }));
      }
    }
  };

  React.useEffect(() => {
    getPricingPreview();
  }, [infoState.childrenAllowed, infoState.summary?.priceType]);

  React.useEffect(() => {
    if (infoState.durationRange?.fromInMinutes) {
      const newOptions = getDurationOptions();
      for (const item of newOptions) {
        if (parseInt(item.value) < infoState.durationRange?.fromInMinutes / 60)
          item.disabled = true;
      }
      setDurationToOptions(newOptions);
    }
  }, [infoState.durationRange?.fromInMinutes]);

  React.useEffect(() => {
    if (infoState.durationRange?.toInMinutes) {
      const newOptions = getDurationOptions();
      for (const item of newOptions) {
        if (parseInt(item.value) > infoState.durationRange?.toInMinutes / 60)
          item.disabled = true;
      }
      setDurationFromOptions(newOptions);
    }
  }, [infoState.durationRange?.toInMinutes]);

  const goToPrevStep = () => {
    goToStep('FIRST');
  };

  const setTitle = (title: string) => {
    if (titleError) setTitleError(false);
    if (apiError?.key?.includes('name'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState(infoState => ({ ...infoState, title: title }));
  };

  const resetTitleError = () => {
    if (titleError) setTitleError(false);
    if (apiError?.key?.includes('name'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const onChangePhone = (val: any) => {
    if (phoneError) setPhoneError(false);
    setPhone(val);
    const phoneNumber = parseInt(val);
    setInfoState(infoState => ({
      ...infoState,
      phone: phoneNumber.toString(),
    }));
  };

  const setDescription = (desc: string) => {
    if (descError) setDescError(false);
    if (apiError?.key?.includes('description'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState(infoState => ({ ...infoState, description: desc }));
  };

  const resetDescriptionError = () => {
    if (descError) setDescError(false);
    if (apiError?.key?.includes('description'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const setInstructorName = (name: string) => {
    if (instructorFirstNameError) setInstructorFirstNameError(false);
    if (apiError?.key?.includes('Expert_firstName'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    if (
      infoState?.expert?.firstName !== undefined &&
      infoState?.expert?.lastName !== undefined
    ) {
      setInfoState(infoState => ({
        ...infoState,
        expert: {
          ...infoState.expert,
          firstName: name,
          lastName: infoState.expert?.lastName as string,
        },
      }));
    } else {
      setInfoState(infoState => ({
        ...infoState,
        expert: {
          firstName: name,
          lastName: '',
        },
      }));
    }
  };

  const setInstructorSurname = (name: string) => {
    if (instructorLastNameError) setInstructorLastNameError(false);
    if (apiError?.key?.includes('Expert_lastName'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    if (
      infoState?.expert?.firstName !== undefined &&
      infoState?.expert?.lastName !== undefined
    ) {
      setInfoState(infoState => ({
        ...infoState,
        expert: {
          ...infoState.expert,
          lastName: name,
          firstName: infoState.expert?.firstName as string,
        },
      }));
    } else {
      setInfoState(infoState => ({
        ...infoState,
        expert: {
          firstName: '',
          lastName: name,
        },
      }));
    }
  };

  const resetExpertFirstNameError = () => {
    if (instructorFirstNameError) setInstructorFirstNameError(false);
    if (apiError?.key?.includes('Expert_firstName'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const resetExpertLastNameError = () => {
    if (instructorLastNameError) setInstructorLastNameError(false);
    if (apiError?.key?.includes('Expert_lastName'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const resetImagesError = () => {
    setImagesError(false);
    setIsErrors(false);
  };

  const resetAudioError = () => {
    setAudioError(false);
  };

  const onChangePeopleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (apiError?.key?.includes('numberOfPeople'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    const newValue: number | string = parseInt(e.target.value, 10) || '';
    setInfoState(infoState => ({
      ...infoState,
      peopleAmount: newValue.toString(),
    }));
  };

  const onChangeTags = (value: boolean, id: number, name: string) => {
    if (value) {
      const arr = update(infoState.tags, { $push: [{ id: id, name: name }] });
      setInfoState(infoState => ({ ...infoState, tags: arr }));
    } else {
      const ix = infoState?.tags?.findIndex(it => it.id === id);
      if (ix !== undefined) {
        const arr = update(infoState.tags, { $splice: [[ix, 1]] });
        setInfoState(infoState => ({ ...infoState, tags: arr }));
      }
    }
  };

  const onChangeFitnessLevel = (value: ValueType<any, false>) => {
    if (fitnessLevelError) setFitnessLevelError(false);
    setInfoState(infoState => ({
      ...infoState,
      summary: { ...infoState.summary, fitnessLevel: value.value },
    }));
  };

  const setDetails = (details: string) => {
    if (apiError?.key?.includes('additionalInfo'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setInfoState(infoState => ({ ...infoState, details: details }));
  };

  const resetAdditionalInfoError = () => {
    if (apiError?.key?.includes('additionalInfo'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
  };

  const onChangePrice = (price: number, index: number) => {
    const info = update(infoState, {
      pricing: { prices: { [index]: { value: { $set: price } } } },
    });
    setInfoState(info);
  };

  const getFitnessLevelDesc = (): JSX.Element => {
    switch (infoState.summary?.fitnessLevel) {
      case 'BASIC':
        return (
          <>
            Подходит для всех вне зависимости от физической подготовки и
            возраста.
          </>
        );
      case 'INTERMEDIATE':
        return (
          <>Предполагает умеренную физическую нагрузку. Подходит новичкам.</>
        );
      case 'ADVANCED':
        return (
          <>
            Не требует специальных навыков, но необходима хорошая физическая
            форма. (Активности: экспедиции, продолжительные сплавы и т.д.)
          </>
        );
      case 'EXTREME':
        return (
          <>
            Необходим опыт походов, специальные навыки и&nbsp;отличная
            физическая форма. (Активности: восхождения, экстремальные сплавы и
            т.д.)
          </>
        );
      default:
        return <></>;
    }
  };

  const onChangeDurationHDays = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (durationInMinutesError) setDurationInMinutesError(false);
    if (apiError?.key?.includes('durationInMinutes'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    const newValue: number | string = e.target.value
      ? parseInt(e.target.value, 10)
      : '';
    setInfoState(infoState => ({
      ...infoState,
      duration: {
        ...infoState.duration,
        days: newValue.toString(),
      },
    }));
  };

  const onChangeDurationHours = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (durationInMinutesError) setDurationInMinutesError(false);
    if (apiError?.key?.includes('durationInMinutes'))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    const newValue: number | string = e.target.value
      ? parseInt(e.target.value, 10)
      : '';
    setInfoState(infoState => ({
      ...infoState,
      duration: {
        ...infoState.duration,
        hours: newValue.toString(),
      },
    }));
  };

  const onChangeDurationMin = (value: ValueType<any, false>) => {
    if (rangeFromError) setRangeFromError(false);
    if (infoState?.durationRange?.fromInMinutes) {
      const info = update(infoState, {
        durationRange: {
          fromInMinutes: { $set: parseInt(value.value, 10) * 60 },
        },
      });
      setInfoState(() => info);
    } else {
      const info = update(infoState, {
        durationRange: {
          $set: {
            fromInMinutes: parseInt(value.value, 10) * 60,
            toInMinutes: infoState.durationRange?.toInMinutes || 0,
          },
        },
      });
      setInfoState(() => info);
    }
  };

  const onChangeDurationMax = (value: ValueType<any, false>) => {
    if (rangeToError) setRangeToError(false);
    if (infoState?.durationRange?.toInMinutes) {
      const info = update(infoState, {
        durationRange: {
          toInMinutes: { $set: parseInt(value.value, 10) * 60 },
        },
      });
      setInfoState(() => info);
    } else {
      const info = update(infoState, {
        durationRange: {
          $set: {
            toInMinutes: parseInt(value.value, 10) * 60,
            fromInMinutes: infoState.durationRange?.fromInMinutes || 0,
          },
        },
      });
      setInfoState(() => info);
    }
  };

  const infoStateToGenericActivity = (
    uploadedImages: components['schemas']['ActivityPictureView'][],
    expertPhoto?: components['schemas']['AttachmentView'],
    expertAudio?: components['schemas']['AttachmentView'],
  ): components['schemas']['GenericActivityUpdate'] => {
    const location = infoState?.location || infoState.resort?.location;
    const subcategories: components['schemas']['SubcategoryView'][] = [];
    for (const subcategory of infoState?.subCategories?.filter(
      item => item.value !== '',
    )) {
      subcategories.push({ id: parseInt(subcategory.value, 10) });
    }
    const sublocations: components['schemas']['SublocationView'][] = [];
    for (const sublocation of infoState?.subLocations?.filter(
      item => item.value !== '',
    )) {
      sublocations.push({ id: parseInt(sublocation.value, 10) });
    }
    return {
      subcategories,
      sublocations,
      tags: infoState.tags,
      durationRange: infoState.durationRange,
      location: { id: parseInt(infoState.resort?.value as string, 10) },
      category: { id: parseInt(infoState.category?.value as string, 10) },
      pictures: uploadedImages,
      description: infoState.description,
      pricing: infoState.pricing as components['schemas']['PricingView'],
      additionalInfo: infoState.details,
      phone: infoState.phone as string,
      position: {
        longitude: location?.length && location[1],
        latitude: location?.length && location[0],
      },
      childrenAllowed: infoState.childrenAllowed,
      numberOfPeople: parseInt(infoState.peopleAmount as string, 10) || 1,
      priceType: infoState.summary?.priceType as
        | 'PER_PERSON'
        | 'PER_PERSON_PER_HOUR'
        | 'PER_GROUP',
      groupType: infoState.summary?.groupType as 'INDIVIDUAL' | 'GROUP',
      routePoints: infoState.routePoints,
      name: infoState.title,
      meetingPoint: infoState.summary?.meetingPoint,
      fitnessLevel: infoState.summary?.fitnessLevel,
      durationInMinutes:
        (infoState.duration?.days !== ('' || undefined)
          ? parseInt(infoState.duration?.days as string, 10)
          : 0) *
          1440 +
        (infoState.duration?.hours !== ('' || undefined)
          ? parseInt(infoState.duration?.hours as string, 10)
          : 1) *
          60,
      expert:
        infoState.expert?.firstName && infoState.expert?.lastName
          ? {
              firstName: infoState.expert?.firstName,
              lastName: infoState.expert?.lastName,
              photo: expertPhoto ? expertPhoto : infoState?.expert?.photo?.view,
              voice: expertAudio ? expertAudio : infoState?.expert?.voice?.view,
            }
          : undefined,
    };
  };

  const validateFields = (): boolean => {
    let validateResult = true;
    if (nameSizeRouteError) setNameSizeRouteError(false);
    if (descriptionSizeRouteError) setDescriptionSizeRouteError(false);
    if (nameEmptyRouteError) setNameEmptyRouteError(false);
    if (!infoState?.title) {
      validateResult = false;
      setTitleError(true);
    }
    if (!(phone && isValidPhoneNumber(phone))) {
      validateResult = false;
      setPhoneError(true);
    }
    if (!infoState?.description) {
      validateResult = false;
      setDescError(true);
    }
    if (
      !(infoState?.images?.length > 0) ||
      infoState.images.filter(
        item => item.view?.pictureUrl && item.view?.pictureUrl?.length > 0,
      )?.length === 0
    ) {
      validateResult = false;
      setImagesError(true);
    }
    if (configuration?.fitnessLevel && !infoState?.summary?.fitnessLevel) {
      validateResult = false;
      setFitnessLevelError(true);
    }
    if (
      configuration?.durationRange &&
      infoState.summary?.priceType === 'PER_PERSON_PER_HOUR'
    ) {
      if (!infoState.durationRange?.fromInMinutes) {
        validateResult = false;
        setRangeFromError(true);
      }
      if (!infoState.durationRange?.toInMinutes) {
        validateResult = false;
        setRangeToError(true);
      }
    }
    if (configuration?.durationInMinutes) {
      const durationInMinutes =
        (parseInt(infoState.duration?.days as string, 10) || 0) * 1440 +
        (parseInt(infoState.duration?.hours as string, 10) || 0) * 60;
      if (durationInMinutes > 144000) {
        validateResult = false;
        dispatch(
          setApiError({
            isError: true,
            status: undefined,
            message: 'Значение должно быть меньше или равно 144000',
            key: 'durationInMinutes_Max',
            timeStamp: undefined,
          }),
        );
      } else if (
        durationInMinutes === 0 &&
        (infoState.duration?.days !== undefined ||
          infoState.duration?.hours !== undefined)
      ) {
        validateResult = false;
        setDurationInMinutesError(true);
      }
    }
    if (configuration?.expert) {
      if (infoState.expert?.firstName && !infoState.expert?.lastName) {
        validateResult = false;
        setInstructorLastNameError(true);
      } else if (infoState.expert?.lastName && !infoState.expert?.firstName) {
        validateResult = false;
        setInstructorFirstNameError(true);
      } else if (
        (infoState.expert?.photo?.file ||
          infoState.expert?.photo?.view.url ||
          infoState.expert?.voice?.file ||
          infoState.expert?.voice?.view.url) &&
        !infoState.expert?.firstName
      ) {
        validateResult = false;
        setInstructorFirstNameError(true);
        setInstructorLastNameError(true);
      } else {
        if (instructorFirstNameError) setInstructorFirstNameError(false);
        if (instructorLastNameError) setInstructorLastNameError(false);
      }
    }
    if (configuration?.routePoints && infoState.routePoints) {
      let arr: {
        name?: string;
        description?: string;
        nameError?: boolean;
        descriptionError?: boolean;
      }[] = infoState.routePoints;
      for (const [index, point] of infoState.routePoints.entries()) {
        if (!point.name) {
          validateResult = false;
          setNameEmptyRouteError(true);
          arr = update(arr, {
            [index]: { nameError: { $set: true } },
          });
        }
        if (point.name && point.name?.length > 50) {
          validateResult = false;
          setNameSizeRouteError(true);
          arr = update(arr, {
            [index]: { nameError: { $set: true } },
          });
        }
        if (point.description && point.description?.length > 200) {
          validateResult = false;
          setDescriptionSizeRouteError(true);
          arr = update(arr, {
            [index]: { descriptionError: { $set: true } },
          });
        }
      }
      setInfoState(infoState => ({ ...infoState, routePoints: arr }));
    }
    return validateResult;
  };

  const saveServiceInfo = async () => {
    setIsErrors(false);
    if (validateFields()) {
      setRequestInProgress(true);
      const uploadedImages: components['schemas']['ActivityPictureView'][] = [];
      const existingImages: components['schemas']['ActivityPictureView'][] = [];
      let expertPhoto: components['schemas']['AttachmentView'] | undefined =
        undefined;
      let expertAudio: components['schemas']['AttachmentView'] | undefined =
        undefined;
      for (const image of infoState?.images) {
        if (image?.file) {
          const formData = new FormData();
          formData.append('file', image?.file as Blob);
          const response = await uploadAttachment(formData);
          if (response?.data) {
            uploadedImages.push(response.data);
          }
        } else {
          existingImages.push(image.view);
        }
      }
      if (infoState.expert?.photo?.file) {
        const formData = new FormData();
        formData.append('file', infoState.expert?.photo?.file as Blob);
        const response = await uploadAttachment(formData);
        if (response?.data) {
          expertPhoto = response.data;
        }
      }
      if (infoState.expert?.voice?.file) {
        const formData = new FormData();
        formData.append('file', infoState.expert?.voice?.file as Blob);
        const response = await uploadAttachment(formData);
        if (response?.data) {
          expertAudio = response.data;
        }
      }
      let createdServiceId: string | undefined = undefined;
      let response:
        | AxiosResponse<components['schemas']['ActivityDetails']>
        | undefined = undefined;
      try {
        if (newService) {
          if (role === 'ACTIVITY_PROVIDER') {
            response = await createGenericActivity(
              infoStateToGenericActivity(
                uploadedImages,
                expertPhoto,
                expertAudio,
              ),
            );
          } else {
            response = await createGenericActivityByOperator(
              infoStateToGenericActivity(
                uploadedImages,
                expertPhoto,
                expertAudio,
              ),
              provider_id,
            );
          }
          createdServiceId = response?.data.id?.toString();
        } else {
          await updateGenericActivity(
            infoStateToGenericActivity(
              [...existingImages, ...uploadedImages],
              expertPhoto,
              expertAudio,
            ),
            service_id as string,
          );
        }
        if (createdServiceId) onSaveClick(createdServiceId);
        else onSaveClick();
      } catch (e) {
        //
      }
      setRequestInProgress(false);
    } else {
      setIsErrors(true);
    }
  };

  return (
    <>
      <ReturnLink linkName="Назад" onClickHandler={goToPrevStep} />
      <div className="edit-service__title-row page__title title">
        {!newService ? 'Редактирование информации' : 'Создание новой услуги'}
      </div>
      {configuration && (
        <>
          <div className="edit-service__content">
            <div className="view-info__item-title edit-service__category-title header1">
              {'Название услуги'}
            </div>
            <div className="edit-info__item">
              <div className="edit-service__title-input">
                <EditableDiv
                  value={infoState?.title as string}
                  saveValue={setTitle}
                  singleLine={true}
                  error={
                    titleError ||
                    (apiError?.key?.includes('name') &&
                      !apiError?.key?.includes('RoutePointView'))
                  }
                  resetError={resetTitleError}
                  data-placeholder={'Название услуги'}
                />
              </div>
            </div>
            <div className="view-info__item-title edit-service__desc-title header1">
              Номер телефона организатора
            </div>
            <div className="edit-service__title-input" ref={phoneRef}>
              <IntlPhoneInput
                placeholder="900 000 00 00"
                onChange={onChangePhone}
                error={phoneError}
                value={phone}
                menuWidth={phoneFieldWidth}
              />
            </div>
            <div className="view-info__item-title edit-service__desc-title header1">
              Описание
            </div>
            <EditableDiv
              value={infoState.description}
              saveValue={setDescription}
              data-placeholder={'Описание'}
              error={descError || apiError?.key?.includes('description')}
              resetError={resetDescriptionError}
            />
            <div className="view-info__item-title edit-service__desc-title header1">
              Теги
            </div>
            {infoState.subCategories[0]?.tags?.map((item, index) => (
              <div key={index} className="edit-service__children step1__tag">
                <div className="edit-service__child-option-wrapper">
                  <div className="edit-service__child-option normal-text">
                    {item.name}
                  </div>
                </div>
                <div className="edit-service__child-switch">
                  <Switch
                    checked={
                      infoState.tags?.some(it => it.id === item.id) as boolean
                    }
                    tagId={item.id}
                    name={item.name}
                    handleChange={onChangeTags}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="view-info__item-title edit-service__images-title header1">
            Фото и видео
          </div>
          <ImagesArea
            infoState={infoState}
            setInfoState={setInfoState}
            error={imagesError}
            setError={setImagesError}
            resetError={resetImagesError}
            maxFilesQty={7}
            type={'PHOTOVIDEO'}
          />
          <div className="edit-service__content">
            {configuration.routePoints && (
              <>
                <div
                  className={classNames(
                    'view-info__item-title edit-service__desc-title header1',
                    apiError?.key?.includes('RoutePointView') && 'error',
                  )}
                >
                  Маршрут (Необязательно)
                </div>
                <Route infoState={infoState} setInfoState={setInfoState} />
              </>
            )}
            {configuration.expert && (
              <>
                <div className="view-info__item-title edit-service__desc-title header1">
                  Гид (Необязательно)
                </div>
                <div className="view-info__item-title edit-service__category-title header2 generic-service__guide-title">
                  ИФ гида
                </div>
                <div className="generic-service__guide-item">
                  <div className="edit-service__title-input instructor-name">
                    <EditableDiv
                      value={infoState?.expert?.firstName as string}
                      saveValue={setInstructorName}
                      singleLine={true}
                      data-placeholder={'Имя'}
                      error={
                        instructorFirstNameError ||
                        apiError?.key?.includes('Expert_firstName')
                      }
                      resetError={resetExpertFirstNameError}
                    />
                  </div>
                  <div className="edit-service__title-input instructor-surname">
                    <EditableDiv
                      value={infoState?.expert?.lastName as string}
                      saveValue={setInstructorSurname}
                      singleLine={true}
                      data-placeholder={'Фамилия'}
                      error={
                        instructorLastNameError ||
                        apiError?.key?.includes('Expert_lastName')
                      }
                      resetError={resetExpertLastNameError}
                    />
                  </div>
                  <div className="generic-service__under-text">
                    В приложении будут показаны только имя и&nbsp;первая буква
                    фамилии
                  </div>
                </div>
              </>
            )}
          </div>
          {configuration.expert && (
            <>
              <div className="view-info__item-title edit-service__category-title header2 generic-service__file-title">
                Фотография гида
              </div>
              <ImagesArea
                infoState={infoState}
                setInfoState={setInfoState}
                maxFilesQty={1}
                type={'PHOTO'}
              />
              <div className="view-info__item-title edit-service__category-title header2 generic-service__file-title">
                Голос гида
              </div>
              <ImagesArea
                infoState={infoState}
                setInfoState={setInfoState}
                maxFilesQty={1}
                error={audioError}
                setError={setAudioError}
                resetError={resetAudioError}
                type={'AUDIO'}
              />
            </>
          )}
          <div className="edit-service__content">
            {configuration.numberOfPeople && (
              <>
                <div className="view-info__item-title edit-service__desc-title header1">
                  Макс. кол-во человек
                </div>
                <div className="generic-service__people-amount">
                  <Input
                    value={infoState?.peopleAmount}
                    onChange={onChangePeopleAmount}
                    placeholder={'1'}
                    error={apiError?.key?.includes('numberOfPeople')}
                  />
                </div>
              </>
            )}
            {(configuration.durationInMinutes ||
              configuration.durationRange) && (
              <>
                <div className="view-info__item-title edit-service__desc-title generic-service__duration-range-title header1">
                  Длительность
                </div>
                <div className="edit-service__duration generic-service__duration">
                  <div className="edit-service__amount generic-service__amount">
                    {configuration.durationRange &&
                    infoState.summary?.priceType === 'PER_PERSON_PER_HOUR' ? (
                      <>
                        <div className="generic-service__label">Мин</div>
                        <Dropdown
                          placeholder="Выберите..."
                          options={durationFromOptions}
                          menuWidth={'100%'}
                          value={getDurationOptions().find(
                            item =>
                              item.value ===
                              (infoState.durationRange?.fromInMinutes !==
                                undefined &&
                                (
                                  infoState.durationRange?.fromInMinutes / 60
                                ).toString()),
                          )}
                          isSearchable={false}
                          isOptionDisabled={option => (option as any)?.disabled}
                          onChange={onChangeDurationMin}
                          error={rangeFromError}
                        />
                      </>
                    ) : (
                      configuration.durationInMinutes && (
                        <Input
                          value={infoState.duration?.days}
                          onChange={onChangeDurationHDays}
                          label="Дней"
                          placeholder={'0'}
                          error={
                            durationInMinutesError ||
                            apiError?.key?.includes('durationInMinutes')
                          }
                        />
                      )
                    )}
                  </div>
                  <div className="edit-service__amount generic-service__amount">
                    {configuration.durationRange &&
                    infoState.summary?.priceType === 'PER_PERSON_PER_HOUR' ? (
                      <>
                        <div className="generic-service__label">Макс</div>
                        <Dropdown
                          placeholder="Выберите..."
                          options={durationToOptions}
                          menuWidth={'100%'}
                          value={getDurationOptions().find(
                            item =>
                              item.value ===
                              (infoState.durationRange?.toInMinutes !==
                                undefined &&
                                (
                                  infoState.durationRange?.toInMinutes / 60
                                ).toString()),
                          )}
                          isSearchable={false}
                          isOptionDisabled={option => (option as any)?.disabled}
                          onChange={onChangeDurationMax}
                          error={rangeToError}
                        />
                      </>
                    ) : (
                      configuration.durationInMinutes && (
                        <Input
                          value={infoState.duration?.hours}
                          onChange={onChangeDurationHours}
                          label="Часов"
                          placeholder={'1'}
                          error={
                            durationInMinutesError ||
                            apiError?.key?.includes('durationInMinutes')
                          }
                        />
                      )
                    )}
                  </div>
                </div>
              </>
            )}
            {configuration.fitnessLevel && (
              <div className="edit-info__item">
                <div className="view-info__item-title edit-service__desc-title header1">
                  Уровень подготовки
                </div>
                <div className="edit-service__select">
                  <Dropdown
                    placeholder="Выберите..."
                    options={getFitnessLevelOptions()}
                    menuWidth={'100%'}
                    value={getFitnessLevelOptions().find(
                      item => item.value === infoState.summary?.fitnessLevel,
                    )}
                    isSearchable={false}
                    onChange={onChangeFitnessLevel}
                    error={fitnessLevelError}
                  />
                </div>
                <div className="generic-service__under-text fitness-level-under-text">
                  {getFitnessLevelDesc()}
                </div>
              </div>
            )}
            {configuration.meetingPoint && (
              <div className="view-info__item-title edit-service__desc-title header1">
                Местоположение/Место встречи
              </div>
            )}
          </div>
          {configuration.meetingPoint && (
            <div className="edit-info__item">
              <MeetingPoint infoState={infoState} setInfoState={setInfoState} />
            </div>
          )}
          {infoState.category?.type && (
            <>
              <div className="view-info__item-title header1">
                <>Стоимость</>
              </div>
              <Prices
                label="Взрослые (с 12 лет)"
                prices={infoState.pricing?.prices}
                customerType={'ADULT'}
                onChangePrice={onChangePrice}
                genericType={true}
                priceType={infoState.summary?.priceType}
              />
              <Prices
                label="Дети (до 12 лет)"
                prices={infoState.pricing?.prices}
                customerType={'CHILD'}
                onChangePrice={onChangePrice}
                genericType={true}
                priceType={infoState.summary?.priceType}
              />
              <Prices
                label="За всю услугу"
                prices={infoState.pricing?.prices}
                customerType={'GROUP'}
                onChangePrice={onChangePrice}
                genericType={true}
                priceType={infoState.summary?.priceType}
              />
            </>
          )}
          <div className="edit-service__content">
            <div className="view-info__item-title edit-service__desc-title header1">
              Дополнительная информация
            </div>
            <EditableDiv
              value={infoState.details}
              saveValue={setDetails}
              data-placeholder={'Необязательное поле'}
              error={apiError?.key?.includes('additionalInfo')}
              resetError={resetAdditionalInfoError}
            />
            <div className="edit-service__buttons">
              <Button
                className="edit-service__save-button"
                onClick={saveServiceInfo}
                disabled={requestInProgress}
              >
                {requestInProgress && (
                  <img className="edit-service__loader" src="/btn-loader.gif" />
                )}
                {requestInProgress ? 'Сохранение...' : 'Сохранить'}
              </Button>
              <Button
                className="edit-service__cancel-button"
                onClick={onCancelClick}
                mode="secondary"
              >
                Отменить
              </Button>
            </div>
            {apiError?.key?.includes('NotBlank') ||
            apiError?.key?.includes('Size') ||
            apiError?.key?.includes('Range') ||
            apiError?.key?.includes('Max') ? (
              <div className="login-page__error-message normal-text">
                {apiError?.key?.includes('durationInMinutes') &&
                  'Содержимое составного поля будет переведено в минуты. '}
                {apiError?.message}
              </div>
            ) : phoneError ? (
              <div className="login-page__error-message normal-text">
                Введите корректное значение номера телефона
              </div>
            ) : imagesError &&
              infoState.images.filter(
                item =>
                  item.view?.pictureUrl && item.view?.pictureUrl?.length > 0,
              )?.length === 0 ? (
              <div className="login-page__error-message normal-text">
                Добавьте хотя бы 1 фотографию
              </div>
            ) : nameSizeRouteError ? (
              <div className="login-page__error-message normal-text">
                Значение поля названия точки маршрута должно быть от 1 до 50
                символов.
              </div>
            ) : descriptionSizeRouteError ? (
              <div className="login-page__error-message normal-text">
                Значение поля описания точки маршрута должно быть от 0 до 200
                символов
              </div>
            ) : isErrors ? (
              <div className="login-page__error-message normal-text">
                Заполните все поля
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default withRouter(Index);
