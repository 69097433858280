import * as React from 'react';

import { addMinutes } from 'date-fns';
import differenceInSeconds from 'date-fns/differenceInSeconds';

interface IDeadlineTimer {
  deadlineInMin: number;
  setReadyToResendCode(val: boolean): void;
  reInit: boolean;
}

const DeadlineTimer: React.FC<IDeadlineTimer> = ({
  deadlineInMin,
  setReadyToResendCode,
  reInit,
}) => {
  const ONE_MINUTE = 60;
  const [diffInSeconds, setDiffInSeconds] = React.useState<number>(0);
  const timerId = React.useRef<ReturnType<typeof setTimeout>>();

  const getCountdown = () => {
    if (diffInSeconds && diffInSeconds < 1) {
      if (timerId.current) clearInterval(timerId.current);
      setReadyToResendCode(true);
      return {
        minutes: 0,
        seconds: 0,
      };
    }
    const minutes = Math.floor(diffInSeconds / ONE_MINUTE);
    const seconds = diffInSeconds - minutes * ONE_MINUTE;
    return {
      minutes,
      seconds,
    };
  };

  const countdown = React.useMemo(getCountdown, [diffInSeconds]);

  React.useEffect(() => {
    const currentTime = new Date().getTime();
    const deadline = addMinutes(new Date(), deadlineInMin);
    setDiffInSeconds(differenceInSeconds(deadline, currentTime));
    timerId.current = setInterval(() => {
      const now = new Date().getTime();
      setDiffInSeconds(differenceInSeconds(deadline, now));
    }, 1000);
  }, [reInit]);

  return (
    <>
      {countdown.minutes > 0 || countdown.seconds > 0 ? (
        <>
          {'Код действителен'}
          <span className="time-row-value">
            {countdown.minutes.toString()}:
            {countdown.seconds > 9
              ? countdown.seconds.toString()
              : `0${countdown.seconds}`}
          </span>
        </>
      ) : (
        <span className="time-row-expired-code">Код больше недействителен</span>
      )}
    </>
  );
};

export default DeadlineTimer;
