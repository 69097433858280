import * as React from 'react';
import { Fade } from 'react-slideshow-image';

import classNames from 'classnames';

import { components } from '../../../../generated/apiTypes';
import { ReactComponent as LeftArrow } from '../../../../images/Service/Info/icn-arrow-left.svg';
import { ReactComponent as RightArrow } from '../../../../images/Service/Info/icn-arrow-right.svg';
import Image from './Image';
import Video from './Video';

import 'react-slideshow-image/dist/styles.css';
import './style.scss';

interface ISlideshow {
  images: {
    view:
      | components['schemas']['ActivityPictureView']
      | components['schemas']['AttachmentView'];
    file?: Blob;
  }[];
  type?: 'AUDIO' | 'PHOTO';
}

const Slideshow: React.FC<ISlideshow> = ({ images, type }) => {
  const [properties, setProperties] = React.useState<any>();
  const [autoplay, setAutoplay] = React.useState<boolean>(true);

  React.useEffect(() => {
    const properties = {
      duration: 5000,
      transitionDuration: 700,
      infinite: true,
      autoplay: autoplay,
      prevArrow: (
        <div className="slideshow__arrow-icon left">
          <LeftArrow />
        </div>
      ),
      nextArrow: (
        <div className="slideshow__arrow-icon right">
          <RightArrow />
        </div>
      ),
    };
    setProperties(properties);
  }, [images.length, autoplay]);

  return (
    <div
      className={classNames(
        'slideshow__container label-text',
        images.length === 0 && 'no-images',
        type === 'AUDIO' && 'audio',
      )}
    >
      {images.length === 0 && 'Галерея фотографий недоступна'}
      {images.length == 1 &&
        images.map((each, index) => (
          <div
            key={index}
            className={classNames(
              'slideshow__each-fade',
              type === 'AUDIO' && 'audio',
            )}
          >
            {(each.view as components['schemas']['ActivityPictureView'])
              ?.pictureUrl && (
              <Image
                sourceImage={
                  (each?.view as components['schemas']['ActivityPictureView'])
                    .pictureUrl as string
                }
              />
            )}
            {(each.view as components['schemas']['AttachmentView']).url &&
              type === 'PHOTO' && (
                <Image
                  sourceImage={
                    (each.view as components['schemas']['AttachmentView'])
                      ?.url as string
                  }
                />
              )}
            {(each.view as components['schemas']['AttachmentView']).url &&
              type === 'AUDIO' && (
                <Video
                  sourceVideo={
                    (each.view as components['schemas']['AttachmentView'])
                      ?.url as string
                  }
                  setAutoplay={setAutoplay}
                />
              )}
            {(each.view as components['schemas']['ActivityPictureView'])
              .videoUrl && (
              <Video
                sourceVideo={
                  (each?.view as components['schemas']['ActivityPictureView'])
                    ?.videoUrl ||
                  ((each.view as components['schemas']['AttachmentView'])
                    ?.url as string)
                }
                setAutoplay={setAutoplay}
              />
            )}
          </div>
        ))}
      {images.length > 1 && (
        <Fade {...properties}>
          {images.map((each, index) => (
            <div key={index} className="slideshow__each-fade">
              {(each.view as components['schemas']['ActivityPictureView'])
                .pictureUrl && (
                <Image
                  sourceImage={
                    (each?.view as components['schemas']['ActivityPictureView'])
                      ?.pictureUrl ||
                    ((each.view as components['schemas']['AttachmentView'])
                      ?.url as string)
                  }
                />
              )}
              {(each.view as components['schemas']['ActivityPictureView'])
                .videoUrl && (
                <Video
                  sourceVideo={
                    (each?.view as components['schemas']['ActivityPictureView'])
                      ?.videoUrl ||
                    ((each.view as components['schemas']['AttachmentView'])
                      ?.url as string)
                  }
                  setAutoplay={setAutoplay}
                />
              )}
            </div>
          ))}
        </Fade>
      )}
    </div>
  );
};

export default Slideshow;
