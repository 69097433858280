/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Divider from '../../../components/Divider/Divider';
import NavigationIcon from '../../../images/LandingIcons/NavigationIcon';
import ShareIcon from '../../../images/LandingIcons/ShareIcon';
import Footer from '../../../components/Footer/Footer';
import pic from '../../MainPage/Usefull/travelPic.png';
import LandingLayout from '../../../components/LandingLayout/LandingLayout';
import { components } from '../../../generated/apiTypes';
import { getFeedPosts } from '../../../services/apiRequests';
import ArrowNavigationIcon from '../../../images/LandingIcons/ArrowNavigationIcon';
import ArrowIconLeft from '../../../images/LandingIcons/ArrowIconLeft';

function UsefullCategories(props: any) {
  const history = useHistory();

  const [maxResults, setMaxResults] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<
    components['schemas']['FeedPostView'][] | undefined
  >([]);

  const getAllPosts = async () => {
    const response = await getFeedPosts(maxResults * page, false);
    setData(response.data.results);
  };

  const goTo = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    getAllPosts();
    window.scrollTo(0, 0);
  }, []);

  return (
    <LandingLayout>
      <div className="usefullcategories__container">
        <Divider className="divider__horizontal" />
        <div className="usefullcategories__content">
          <div className="usefullcategories__navigation-links">
            <Link to="/">Главная</Link>
            <ArrowNavigationIcon />
            <p>Полезное для путешествий</p>
          </div>
          <div className="usefullcategories__navigation-links_1024">
            <ArrowIconLeft />
            <Link to="/">Главная</Link>
          </div>
          <h2 className="block__title-dark">
            Полезное для путешествий и новости
          </h2>
          <div className="usefullcategories__cards-page">
            {data?.map(post => (
              <button
                key={post.id}
                className="usefullcategories__card-item-page"
                onClick={() => goTo(`/blogs/article/${post.id}`)}
              >
                <div className="usefullcategories__card-item-content">
                  <div className="usefullcategories__card-item-pictire-container">
                    <div
                      className="usefullcategories__card-item-pictire"
                      style={{
                        background: `url(${post.picture?.url})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}
                    />
                  </div>
                  <div className="usefullcategories__card-description">
                    <div className="usefullcategories__card-description-top">
                      <p className="usefullcategories__card-description-title">
                        {post.title}
                      </p>
                    </div>
                    <div className="usefullcategories__card-description-bottom">
                      <p className="usefullcategories__card-description-text">
                        {post.content}
                      </p>
                    </div>
                  </div>
                </div>
              </button>
            ))}
          </div>
          <Divider height="20px" />
        </div>
        <Divider className="divider__horizontal" />
      </div>
    </LandingLayout>
  );
}

export default UsefullCategories;
