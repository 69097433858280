import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import classNames from 'classnames';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

interface ISlotsTable {
  slots: Date[];
  type:
    | 'ACTIVITY'
    | 'EXPERT'
    | 'RENTAL'
    | 'TRANSFER'
    | 'GENERIC'
    | 'PRIMITIVE'
    | undefined;
  loadingByPage: boolean;
  fetchMoreData?(): void;
  hasMore: boolean;
}

const SlotsTable: React.FC<ISlotsTable> = ({
  slots,
  type,
  loadingByPage,
  fetchMoreData,
  hasMore,
}) => {
  const fetch = () => {
    //
  };
  return (
    <div
      className={classNames(
        'edit-slot__table-wrapper',
        loadingByPage && 'all-slots-table-wrapper',
      )}
    >
      <table className="edit-slot__table thead-table button-text">
        <thead>
          <tr>
            <td>Дата</td>
            <td className="edit-slot__table-weekday-td">День недели</td>
            <td className="edit-slot__table-time-td">Время</td>
          </tr>
        </thead>
      </table>
      <div className="scroll-wrapper" id="scrollWrapper">
        <InfiniteScroll
          dataLength={slots.length}
          next={fetchMoreData ? fetchMoreData : fetch}
          hasMore={loadingByPage && hasMore}
          loader={
            <div className="infinite-scroll__loader normal-text">
              Идёт загрузка...
            </div>
          }
          scrollableTarget="scrollWrapper"
          scrollThreshold={0.95}
        >
          <table className="edit-slot__table normal-text">
            <tbody>
              {slots?.map((item, index, array) => {
                if (
                  array[index - 1] &&
                  array[index - 1].toDateString() !== item.toDateString()
                )
                  return (
                    <>
                      <tr key={index} className="edit-slot__table-separator">
                        <td />
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <td>{format(item, 'dd MMMM yyyy', { locale: ru })}</td>
                        <td className="edit-slot__table-weekday-td">
                          {format(item, 'EEEE', { locale: ru })}
                        </td>
                        <td className="edit-slot__table-time-td">
                          {!['RENTAL', 'TRANSFER', 'PRIMITIVE'].includes(
                            type as string,
                          )
                            ? format(item, 'HH:mm', { locale: ru })
                            : '-'}
                        </td>
                      </tr>
                    </>
                  );
                return (
                  <tr key={index}>
                    <td>{format(item, 'dd MMMM yyyy', { locale: ru })}</td>
                    <td className="edit-slot__table-weekday-td">
                      {format(item, 'EEEE', { locale: ru })}
                    </td>
                    <td className="edit-slot__table-time-td">
                      {!['RENTAL', 'TRANSFER', 'PRIMITIVE'].includes(
                        type as string,
                      )
                        ? format(item, 'HH:mm', { locale: ru })
                        : '-'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default SlotsTable;
