export const getGroupSizeOptions = () => {
  const groupSizeTypes: { value: string; label: string }[] = [
    { value: 'INDIVIDUAL', label: 'Индивидуально' },
    { value: 'GROUP', label: 'В группе' },
  ];
  return groupSizeTypes;
};

export const getPriceTypeOptions = () => {
  const priceTypes: { value: string; label: string; disabled: boolean }[] = [
    { value: 'PER_PERSON', label: 'За человека', disabled: false },
    {
      value: 'PER_PERSON_PER_HOUR',
      label: 'За человека в час',
      disabled: false,
    },
    { value: 'PER_GROUP', label: 'За всю услугу', disabled: false },
  ];
  return priceTypes;
};

export const getFitnessLevelOptions = () => {
  const priceTypes: { value: string; label: string }[] = [
    { value: 'BASIC', label: 'Базовый уровень' },
    {
      value: 'INTERMEDIATE',
      label: 'Средний уровень',
    },
    { value: 'ADVANCED', label: 'Продвинутый уровень' },
    { value: 'EXTREME', label: 'Высокий уровень (Экстрим)' },
  ];
  return priceTypes;
};

export const getDurationOptions = () => {
  const priceTypes: { value: string; label: string; disabled: boolean }[] = [
    { value: '1', label: '1 час', disabled: false },
    { value: '2', label: '2 часа', disabled: false },
    { value: '3', label: '3 часа', disabled: false },
    { value: '4', label: '4 часа', disabled: false },
    { value: '5', label: '5 часов', disabled: false },
    { value: '6', label: '6 часов', disabled: false },
    { value: '7', label: '7 часов', disabled: false },
    { value: '8', label: '8 часов', disabled: false },
    { value: '9', label: '9 часов', disabled: false },
    { value: '10', label: '10 часов', disabled: false },
    { value: '11', label: '11 часов', disabled: false },
    { value: '12', label: '12 часов', disabled: false },
    { value: '13', label: '13 часов', disabled: false },
    { value: '14', label: '14 часов', disabled: false },
    { value: '15', label: '15 часов', disabled: false },
    { value: '16', label: '16 часов', disabled: false },
    { value: '17', label: '17 часов', disabled: false },
    { value: '18', label: '18 часов', disabled: false },
    { value: '19', label: '19 часов', disabled: false },
    { value: '20', label: '20 часов', disabled: false },
    { value: '21', label: '21 час', disabled: false },
    { value: '22', label: '22 часа', disabled: false },
    { value: '23', label: '23 часа', disabled: false },
    { value: '24', label: '24 часа', disabled: false },
  ];
  return priceTypes;
};
