import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import Input from '../../components/Input';
import { ReactComponent as Logo } from '../../images/components/Layout/logo-lg.svg';
import { selectApiError, setApiError } from '../../store/commonSlice';

interface IChangePasswordForm {
  changePassword(password: string): void;
}

const ChangePasswordForm: React.FC<IChangePasswordForm> = ({
  changePassword,
}) => {
  const [password, setPassword] = React.useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = React.useState<string>('');
  const [passwordError, setPasswordError] = React.useState<boolean>(false);
  const [passwordRepeatError, setPasswordRepeatError] =
    React.useState<boolean>(false);
  const [passwordsEqualError, setPasswordsEqualError] =
    React.useState<boolean>(false);
  const [requestInProgress, setRequestInProgress] =
    React.useState<boolean>(false);
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      if (
        [
          'PasswordUpdate_password_Pattern',
          'PasswordUpdate_password_Size',
          'PasswordUpdate_oldPassword_Size',
          'ACCOUNT_PASSWORD_WEAK',
        ].includes(apiError?.key as string)
      )
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [apiError.key]);

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (passwordError) setPasswordError(false);
    if (passwordsEqualError) setPasswordsEqualError(false);
    if (
      [
        'AccountRegistration_password_Pattern',
        'PasswordUpdate_password_Size',
        'PasswordUpdate_oldPassword_Size',
        'ACCOUNT_PASSWORD_WEAK',
      ].includes(apiError?.key as string)
    )
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setPassword(e.target.value);
  };

  const onChangePasswordRepeat = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (passwordRepeatError) setPasswordRepeatError(false);
    if (passwordsEqualError) setPasswordsEqualError(false);
    setPasswordRepeat(e.target.value);
  };

  const onSaveClick = async () => {
    if (!password) setPasswordError(true);
    if (!passwordRepeat) setPasswordRepeatError(true);
    const passwordsEqual = password === passwordRepeat;
    if (!passwordsEqual) {
      setPasswordsEqualError(true);
    }
    if (password && passwordRepeat && passwordsEqual) {
      setPasswordError(false);
      setPasswordRepeatError(false);
      setRequestInProgress(true);
      try {
        await changePassword(password);
      } catch (e) {
        //
      }
      setRequestInProgress(false);
    }
  };

  return (
    <>
      <div className="restore-page__logo-wrapper">
        <div className="restore-text-wrapper title">
          <Logo className="restore-page__logo" />
          Изменение пароля
        </div>
      </div>
      <div className="restore-page__field">
        <Input
          placeholder="Новый пароль"
          value={password}
          onChange={onChangePassword}
          error={
            passwordError ||
            passwordsEqualError ||
            apiError?.key === 'PasswordUpdate_password_Pattern' ||
            apiError?.key === 'PasswordUpdate_password_Size' ||
            apiError.key === 'PasswordUpdate_oldPassword_Size' ||
            apiError.key === 'ACCOUNT_PASSWORD_WEAK'
          }
          type="password"
        />
      </div>
      <div className="restore-page__field">
        <Input
          placeholder="Повторить новый пароль"
          value={passwordRepeat}
          onChange={onChangePasswordRepeat}
          error={passwordRepeatError || passwordsEqualError}
          type="password"
          onEnter={onSaveClick}
        />
        <div className="login-page__post-message">
          {
            'Пароль должен быть от 8 символов и содержать только латинские буквы и цифры'
          }
        </div>
      </div>
      <Button
        className="restore-page__register-btn"
        onClick={onSaveClick}
        disabled={requestInProgress}
      >
        Сохранить
      </Button>
      {[
        'PasswordUpdate_password_Pattern',
        'PasswordUpdate_password_Size',
        'PasswordUpdate_oldPassword_Size',
        'ACCOUNT_PASSWORD_WEAK',
      ].includes(apiError?.key as string) && (
        <div className="login-page__error-message restore-page__error-message normal-text">
          {apiError?.message}
        </div>
      )}
      {passwordsEqualError && (
        <div className="login-page__error-message normal-text">
          Введенные пароли не совпадают
        </div>
      )}
    </>
  );
};

export default ChangePasswordForm;
