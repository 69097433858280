import React from 'react';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

function LandingLayout(props: any) {
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  );
}

export default LandingLayout;
