import Divider from '../../../components/Divider/Divider';
import HumanIcon from '../../../images/LandingIcons/HumanIcon';
import HumanIcon1 from '../../../images/LandingIcons/HumanIcon1';
import HumanIcon2 from '../../../images/LandingIcons/HumanIcon2';
import HumanIcon3 from '../../../images/LandingIcons/HumanIcon3';

function UserCategory(props: any) {
  return (
    <div className="category__container">
      <Divider className="divider__horizontal" />
      <div className="category__content">
        <div className="category__title">
          <h2 className="block__title-dark">Кому подходит ADVA</h2>
        </div>
        <div className="category__list">
          <div className="category__list-item">
            <HumanIcon width={62} height={62} />
            <div className="category__list-item__top">
              <p className="category__item-main-text">Начинающим и любителям</p>
            </div>
            <div className="category__list-item__bottom">
              <p className="category__item-secondary-text">
                Попробовать более активный отдых и найти новые увлечения
              </p>
            </div>
          </div>
          <div className="category__list-item">
            <HumanIcon1 width={62} height={62} />
            <div className="category__list-item__top">
              <p className="category__item-main-text">
                Профессионалам и экстремалам
              </p>
            </div>
            <div className="category__list-item__bottom">
              <p className="category__item-secondary-text">
                Мы подобрали услуги для разного уровня подготовки
              </p>
            </div>
          </div>
          <div className="category__list-item">
            <HumanIcon2 width={62} height={62} />
            <div className="category__list-item__top">
              <p className="category__item-main-text">
                Самостоятельным путешественникам
              </p>
            </div>
            <div className="category__list-item__bottom">
              <p className="category__item-secondary-text">
                Для тех, кто хочет&nbsp;личный подход вне больших групп и
                временных рамок
              </p>
            </div>
          </div>
          <div className="category__list-item">
            <HumanIcon3 width={62} height={62} />
            <div className="category__list-item__top">
              <p className="category__item-main-text">Родителям и детям</p>
            </div>
            <div className="category__list-item__bottom">
              <p className="category__item-secondary-text">
                Семейный отдых может быть интересным и заряжать бодростью
              </p>
            </div>
          </div>
        </div>
      </div>
      <Divider className="divider__horizontal" />
    </div>
  );
}

export default UserCategory;
