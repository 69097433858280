import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import classNames from 'classnames';

import Button from '../../../components/Button';
import { components } from '../../../generated/apiTypes';
import { ReactComponent as AddIcon } from '../../../images/Services/icn-add.svg';
import {
  getSlotConfigurations as getSlotConfigurationsRequest,
  getSlots,
} from '../../../services/apiRequests';
import { handleStorage } from '../../../services/handleStorage';
import SlotsTable from '../../EditSlot/SlotsTable';
import Slot from './Slot';

import './style.scss';

interface IRouterProps {
  provider_id?: string;
  service_id: string;
}

interface ISlots extends RouteComponentProps<IRouterProps> {
  type:
    | 'ACTIVITY'
    | 'EXPERT'
    | 'RENTAL'
    | 'TRANSFER'
    | 'GENERIC'
    | 'PRIMITIVE'
    | undefined;
}

const Slots: React.FC<ISlots> = ({ history, location, match, type }) => {
  const [slotConfigurations, setSlotConfigurations] = React.useState<
    components['schemas']['SlotConfigurationView'][]
  >([]);
  const [allSlots, setAllSlots] = React.useState<Date[]>([]);
  const [maxResults, setMaxResults] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(0);
  const [hasMoreData, setHasMoreData] = React.useState<boolean>(true);
  const role = handleStorage.getRole();
  const service_id = match.params.service_id;

  const getSlotsConfigurations = async () => {
    const response = await getSlotConfigurationsRequest(service_id);
    if (response?.data) {
      setSlotConfigurations(response?.data);
    }
  };

  const getSlotsTable = async (pageIx?: number) => {
    const response = await getSlots(
      service_id,
      maxResults * (pageIx ? pageIx : 0),
    );
    const receivedSlots: Date[] = [];
    if (response?.data) {
      if (response.data?.results) {
        for (const slot of response.data?.results) {
          receivedSlots.push(new Date(slot.dateTime as string));
        }
      }
      if (pageIx) setPage(pageIx);
      setMaxResults(response.data.maxResults as number);
      if ((response.data.totalPages as number) === 1) {
        setHasMoreData(false);
      } else {
        setHasMoreData(
          (response.data.page as number) < (response.data.totalPages as number),
        );
      }
    }
    if (allSlots?.length === 0) setAllSlots(receivedSlots);
    else {
      setAllSlots(allSlots.concat(receivedSlots));
    }
  };

  const fetchMoreData = () => {
    getSlotsTable(page + 1);
  };

  React.useEffect(() => {
    getSlotsConfigurations();
    getSlotsTable();
  }, [service_id]);

  const onAddSlotClick = () => {
    const path = `${location.pathname}/slot=new`;
    history.push({
      state: { tabIx: 2 },
    });
    history.push({
      pathname: path,
      state: {
        tabIx: (location.state as { tabIx: number }).tabIx,
      },
    });
  };

  return (
    <>
      <div className="slots__container-wrapper">
        <div className="slots__buttons">
          <Button className="slots__add-slots-btn" onClick={onAddSlotClick}>
            <AddIcon className="services__add-btn-icon" />
            Добавить информацию
          </Button>
        </div>
        <div
          className={classNames(
            'slots__container',
            role === 'OPERATOR' && 'operator',
          )}
        >
          {slotConfigurations?.length > 0 ? (
            slotConfigurations.map(item => (
              <Slot
                key={item?.id as number}
                id={item?.id as number}
                startDate={new Date(item?.startDate as string)}
                endDate={new Date(item?.endDate as string)}
                weekdays={item?.daysOfWeek}
                times={item?.times}
                type={type}
              />
            ))
          ) : (
            <div className="services__empty_message normal-text">
              Нет информации о расписании
            </div>
          )}
        </div>
        {allSlots?.length > 0 && (
          <>
            <div className="edit-slot__slots-list-title header1">
              Сводная таблица расписания
            </div>
            <SlotsTable
              slots={allSlots}
              type={type}
              loadingByPage={true}
              hasMore={hasMoreData}
              fetchMoreData={fetchMoreData}
            />
          </>
        )}
      </div>
      <div className="slots__buttons-xs">
        <Button className="slots__add-slots-btn" onClick={onAddSlotClick}>
          <AddIcon className="services__add-btn-icon" />
          Добавить информацию
        </Button>
      </div>
    </>
  );
};

export default withRouter(Slots);
