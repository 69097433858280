import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import moment from 'moment';

import Divider from '../../../components/Divider/Divider';
import LandingLayout from '../../../components/LandingLayout/LandingLayout';
import { components } from '../../../generated/apiTypes';
import ArrowIconLeft from '../../../images/LandingIcons/ArrowIconLeft';
import ArrowNavigationIcon from '../../../images/LandingIcons/ArrowNavigationIcon';
import NavigationIcon from '../../../images/LandingIcons/NavigationIcon';
import ShareIcon from '../../../images/LandingIcons/ShareIcon';
import { getFeedPostById } from '../../../services/apiRequests';
import CardList from '../../MainPage/CardList/CardList';
import QrCode from '../../MainPage/QrCode/QrCode';
import Usefull from '../../MainPage/Usefull/Usefull';
import snegohod from './snegohod.png';

type WebsiteItem = {
  id?: number;
  name?: string;
  hidden?: boolean;
  description?: string;
  date?: Date;
};

function UsefullArticle() {
  const { id } = useParams<any>();
  const { pathname } = useLocation();
  const [modalActive, setModalActive] = useState(false);
  const [image, setImage] = useState<any>();
  const [data, setData] = useState<
    components['schemas']['FeedPostView'] | null
  >(null);

  const getPost = async () => {
    if (id) {
      const response = await getFeedPostById(id);
      if (response.data) {
        setData(response.data);
      }
    }
  };

  const reserve = () => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      window
        .open(
          'https://play.google.com/store/apps/details?id=com.numdes.a2',
          '_blank',
        )
        ?.focus();
      return;
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window
        .open(
          'https://apps.apple.com/ru/app/adva-travel/id1600305963',
          '_blank',
        )
        ?.focus();
      return;
    }

    setModalActive(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    getPost();
  }, [id]);

  return (
    <LandingLayout>
      <div className="usefullArticle__container">
        <Divider className="divider__horizontal" />
        <div className="usefullArticle__content">
          <div className="usefullArticle__navigation-links">
            <Link to="/">Главная</Link>
            <ArrowNavigationIcon />
            <Link to="/blogs">Полезное для путешествий</Link>
            <ArrowNavigationIcon />
            <p>{data?.title}</p>
          </div>
          <div className="usefullArticle__navigation-links_1024">
            <ArrowIconLeft />
            <Link to="/blogs">Полезное для путешествий</Link>
          </div>
          <div className="usefullArticle__content-path">
            <div className="article__title">
              <p>{data?.title}</p>
            </div>
            <div className="usefullArticle__content-article">
              <div className="usefullArtcile__info-block">
                <img
                  src={data?.picture?.url}
                  className="usefullArtcile__info-img"
                />
                <p
                  id="usefullArticleContent"
                  className="usefullArtcile__info-text"
                >
                  {data?.content}
                </p>
              </div>
            </div>
            <div className="locationArtcile__info-date-container">
              <p className="locationArtcile__info-date">
                {moment(data?.publicationDate).format('DD.MM.YYYY')}
              </p>
            </div>
          </div>
          <button className="articleButton__reserve" onClick={() => reserve()}>
            Забронировать в приложении
          </button>
          <Divider height="10px" />
          <div className="usefullArticle__offer">
            <p>
              Хотите разместить услугу? Переходите{' '}
              <span>
                <Link
                  style={{ color: '#00BFB4', textDecoration: 'underline' }}
                  to="/provider"
                >
                  по ссылке
                </Link>
              </span>
            </p>
          </div>
        </div>
        <Divider className="divider__horizontal" />
      </div>
      <CardList />
      <Usefull />
      <QrCode modalActive={modalActive} setModalActive={setModalActive} />
    </LandingLayout>
  );
}

export default UsefullArticle;
