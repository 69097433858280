import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Divider from '../../../components/Divider/Divider';
import { components } from '../../../generated/apiTypes';
import { getFeedLocations } from '../../../services/apiRequests';
import altai from './images/altai.png';
import krasnodar from './images/krasnodar.png';
import leningrad from './images/leningrad.png';

function Locations(props: any) {
  const history = useHistory();

  const [maxResults, setMaxResults] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = React.useState<
    components['schemas']['LocationView'][] | undefined
  >([]);

  const goTo = (path: string) => {
    history.push(path);
  };

  const getAllPosts = async () => {
    const response = await getFeedLocations(maxResults * page, false);
    setData(response.data.results);
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  return (
    <div className="locations__container">
      <Divider className="divider__horizontal" />
      <div className="location__content">
        <div className="location__title">
          <h2 className="block__title-dark">ЛОКАЦИИ ADVA</h2>
        </div>
        <div className="location__cards">
          {data?.map(location => (
            <button
              key={location.id}
              className="location__cards-item"
              style={{
                background: `url(${location.feedPicture?.url})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              type="button"
              onClick={() => goTo(`/locations/article/${location.id}`)}
            >
              <div className="location__cards-item_bottom">
                <p className="location__cards-item_title">{location.name}</p>
              </div>
            </button>
          ))}
        </div>
      </div>
      <Divider className="divider__horizontal" />
    </div>
  );
}

export default Locations;
