import * as React from 'react';

import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import { components } from '../../../../generated/apiTypes';
import Booking from '../Booking';

import './style.scss';

interface IBookingsDateGroup {
  date: string;
  bookings: components['schemas']['ReservationView'][];
}

const BookingsDateGroup: React.FC<IBookingsDateGroup> = ({
  date,
  bookings,
}) => {
  return (
    <div className="booking-date-group__container">
      <div className="booking-date-group__date header1">
        {format(new Date(date), 'd MMMM', {
          locale: ru,
        })}
      </div>
      {bookings &&
        bookings.length > 0 &&
        bookings.map(item => (
          <Booking
            id={(item.id as number).toString()}
            serialNumber={item.serialNumber as string}
            duration={item.durationInHours}
            time={format(
              new Date(`${new Date().toDateString()} ${item.time}`),
              'HH:mm',
              {
                locale: ru,
              },
            )}
            subcategory={item.subcategory?.name}
            resort={`${item.activity?.location?.name as string}${
              item?.sublocation?.name ? `, ${item.sublocation.name}` : ''
            }`}
            cost={item.cost}
            amount={item.numberOfPeople as number}
            amountAdults={item.numberOfAdults as number}
            amountChildren={item.numberOfChildren as number}
            providerStatus={item.providerStatus}
            customerStatus={item.customerStatus}
            date={format(new Date(date), 'd MMMM yyyy', {
              locale: ru,
            })}
            type={item.activity?.category?.type}
            allDayReservation={item?.allDayReservation as boolean}
          />
        ))}
    </div>
  );
};

export default BookingsDateGroup;
