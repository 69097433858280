import * as React from 'react';

import { ReactComponent as ArrowIcon } from '../../images/components/DatePicker/left-arrow.svg';

import './style.scss';

interface IReturnLink {
  linkName: string;
  onClickHandler(): void;
}

const ReturnLink: React.FC<IReturnLink> = ({ linkName, onClickHandler }) => {
  return (
    <button
      className="return-link__container button-text"
      onClick={onClickHandler}
    >
      <ArrowIcon />
      <span className="return-link__text">{linkName}</span>
    </button>
  );
};

export default ReturnLink;
