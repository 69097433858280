import * as React from 'react';

import { ReactComponent as StarIcon } from '../../../../images/Service/Reviews/icn-star.svg';
import { ReactComponent as FilledStarIcon } from '../../../../images/Service/Reviews/icn-star-filled.svg';

import './style.scss';

interface IReview {
  id: string;
  name: string;
  text: string;
  rating: number;
  date: string;
}

const Review: React.FC<IReview> = ({ name, text, rating, date }) => {
  return (
    <div className="review__container">
      <div className="review__top-row">
        <div className="review__name header2">{name}</div>
        <div className="review__stars">
          {rating >= 1 ? <FilledStarIcon /> : <StarIcon />}
          {rating / 2 >= 1 ? <FilledStarIcon /> : <StarIcon />}
          {rating / 3 >= 1 ? <FilledStarIcon /> : <StarIcon />}
          {rating / 4 >= 1 ? <FilledStarIcon /> : <StarIcon />}
          {rating / 5 >= 1 ? <FilledStarIcon /> : <StarIcon />}
        </div>
        <div className="review__date label-text">{date}</div>
      </div>
      <div className="review__text normal-text">{text}</div>
    </div>
  );
};

export default Review;
