/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Divider from '../../../components/Divider/Divider';
import NavigationIcon from '../../../images/LandingIcons/NavigationIcon';
import ShareIcon from '../../../images/LandingIcons/ShareIcon';
import Footer from '../../../components/Footer/Footer';
import pic from '../../MainPage/Usefull/travelPic.png';
import LandingLayout from '../../../components/LandingLayout/LandingLayout';
import { components } from '../../../generated/apiTypes';
import {
  getFeedPosts,
  getFeedSubcategories,
} from '../../../services/apiRequests';
import ArrowNavigationIcon from '../../../images/LandingIcons/ArrowNavigationIcon';
import ArrowIconLeft from '../../../images/LandingIcons/ArrowIconLeft';
import declOfNum from '../../../utils/declOfNum';

function ServicesCategories(props: any) {
  const history = useHistory();

  const [maxResults, setMaxResults] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<
    components['schemas']['SubcategoryView'][] | undefined
  >([]);

  const getAllSubcategories = async () => {
    const response = await getFeedSubcategories(maxResults * page, false);
    console.log('RESPONSE', response);
    setData(response.data.results);
  };

  const goTo = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    getAllSubcategories();
    window.scrollTo(0, 0);
  }, []);

  return (
    <LandingLayout>
      <div className="services__container">
        <Divider className="divider__horizontal" />
        <div className="services__content">
          <div className="services__navigation-links">
            <Link to="/">Главная</Link>
            <ArrowNavigationIcon />
            <p>Что есть в ADVA?</p>
          </div>
          <div className="services__navigation-links_1024">
            <ArrowIconLeft />
            <Link to="/">Главная</Link>
          </div>
          <h2 className="block__title-dark">Что есть в ADVA?</h2>
          <div className="services__cards-page">
            {data?.map(subcategory => (
              <button
                key={subcategory.id}
                className="services__single-card"
                style={{
                  background: `url(${subcategory.feedPicture?.url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                onClick={() => goTo(`/subcategories/article/${subcategory.id}`)}
              >
                <div className="services__single-card-footer">
                  <p className="services__single-card-title">
                    {subcategory.name}
                  </p>
                  <p className="services__single-card-text">
                    {subcategory.activitiesCount +
                      ' ' +
                      declOfNum(
                        subcategory?.activitiesCount
                          ? subcategory?.activitiesCount
                          : 0,
                        ['предложение', 'предложения', 'предложений'],
                      )}
                  </p>
                </div>
              </button>
            ))}
          </div>
          <Divider height="20px" />
        </div>
        <Divider className="divider__horizontal" />
      </div>
    </LandingLayout>
  );
}

export default ServicesCategories;
