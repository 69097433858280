/* eslint-disable */
import React from 'react';
// import './style.scss';
import Number11 from '../../../images/LandingIcons/Number11';
import Number17 from '../../../images/LandingIcons/Number17';
import Number200 from '../../../images/LandingIcons/Number200';
import Divider from '../../../components/Divider/Divider';

const Nums = () => {
  return (
    <div className="nums__container">
      <Divider className="divider__horizontal" />
      <div className="nums__content">
        <div className="nums__number-item">
          <div className="nums__number-icon-200" />
          <div className="nums__number-title">Предложений</div>
        </div>
        <div className="nums__number-item">
          <div className="nums__number-icon-17" />
          <div className="nums__number-title">Видов услуг</div>
        </div>
        <div className="nums__number-item">
          <div className="nums__number-icon-11" />
          <div className="nums__number-title">Регионов</div>
        </div>
      </div>
      <Divider className="divider__horizontal" />
    </div>
  );
};

export default Nums;
