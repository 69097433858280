import * as React from 'react';

function ArrowIconLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.626 4l-8 8 8 8L10 21.626.374 12 10 2.374 11.626 4z"
        fill="#00BFB4"
      />
    </svg>
  );
}

export default ArrowIconLeft;
