import * as React from 'react';

import Input from '../../../../../components/Input';
import { ReactComponent as RubIcon } from '../../../../../images/EditService/icn-rub.svg';

interface IPrice {
  price: string;
  error?: boolean;
  setError?(val: boolean): void;
  label: string;
  paymentToProvider?: number;
  disabled?: boolean;
  arrayIx: number;
  onChangePrice?(price: number, index: number): void;
  serviceFee?: number;
  genericType?: boolean;
  priceType?: 'PER_PERSON' | 'PER_PERSON_PER_HOUR' | 'PER_GROUP';
}

const Price: React.FC<IPrice> = ({
  price,
  error,
  setError,
  label,
  disabled,
  arrayIx,
  onChangePrice,
  serviceFee,
  paymentToProvider,
  genericType,
  priceType,
}) => {
  const [inputVal, setInputVal] = React.useState<string>('');

  React.useEffect(() => {
    setInputVal(price);
  }, [price]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error && setError) setError(false);
    const newValue: number | string = parseInt(e.target.value, 10) || '';
    setInputVal(newValue.toString());
  };

  const onBlurHandler = () => {
    const newValue: number | string = parseInt(inputVal, 10) || '';
    if (newValue === '' && onChangePrice) {
      onChangePrice(1, arrayIx);
    } else if (newValue < 1000000 && onChangePrice) {
      onChangePrice(newValue as number, arrayIx);
    }
  };

  return (
    <div className="price__container">
      <Input
        value={inputVal}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        containerClassName="edit-service__cost-from-input"
        placeholder={''}
        error={error}
        label={label}
        disabled={disabled}
      >
        {!genericType && <RubIcon className="edit-service__rub-icon" />}
        {genericType && (
          <span className="edit-service__rub-unit">
            {priceType === 'PER_PERSON_PER_HOUR' ? 'руб/час' : 'руб'}
          </span>
        )}
      </Input>
      {serviceFee && (
        <div className="price__post-label">
          {!disabled
            ? Math.round((parseInt(inputVal) * (100 - serviceFee)) / 100 || 0)
            : paymentToProvider}
          {' руб'}
          <span className="price__sup">*</span>
        </div>
      )}
    </div>
  );
};

export default Price;
