import * as React from 'react';
import Select, { Props } from 'react-select';

import DropdownIndicator from './DropdownIndicator';
import handleStyles from './handleStyles';

const Dropdown: React.FC<Props> = props => {
  return (
    <Select
      {...props}
      styles={handleStyles}
      components={{
        DropdownIndicator: props.withoutIndicator
          ? () => null
          : DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
      noOptionsMessage={() => ''}
    />
  );
};

export default Dropdown;
