import * as React from 'react';

import classNames from 'classnames';

let containerRatio = 0.59;

interface IImage {
  sourceImage: string;
}

const Image: React.FC<IImage> = ({ sourceImage }) => {
  const imgRef = React.useRef<HTMLImageElement>(null);
  const [size, setSize] = React.useState<{ width: number; height: number }>();
  const [load, setLoad] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (document.body.clientWidth < 840 && document.body.clientWidth > 0)
      containerRatio = 375 / document.body.clientWidth;
    setSize({
      width: imgRef.current?.width as number,
      height: imgRef.current?.height as number,
    });
  }, [imgRef?.current, load]);

  return (
    <img
      ref={imgRef}
      alt=" "
      src={sourceImage}
      className={classNames(
        size?.width &&
          size?.width > 0 &&
          (size?.height as number) / (size?.width as number) < containerRatio
          ? 'fit-by-width'
          : 'fit-by-height',
        containerRatio,
      )}
      onLoad={() => setLoad(true)}
    />
  );
};

export default Image;
