import * as React from 'react';

import PaginationRow from '../../../components/PaginationRow';
import { components } from '../../../generated/apiTypes';
import { getFeedSubcategories } from '../../../services/apiRequests';
import Location from '../Locations/Location';
import SubCategory from './SubCategory';

import '../style.scss';

const SubCategories: React.FC = () => {
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [maxResults, setMaxResults] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(0);
  const [data, setData] = React.useState<
    components['schemas']['SubcategoryView'][] | undefined
  >([]);

  const getAllSubcategories = async () => {
    const response = await getFeedSubcategories(maxResults * page, true);
    if (response.data) {
      setData(response.data?.results);
      setMaxResults(response.data.maxResults as number);
      setTotalPages(response.data.totalPages as number);
    }
  };

  React.useEffect(() => {
    setData(undefined);
    setTotalPages(0);
    getAllSubcategories();
  }, [page]);

  return (
    <div className="website__locations-container">
      {data?.map(item => (
        <SubCategory
          subcategoryView={{
            id: item.id,
            name: item.name,
            hiddenFromFeed: item.hiddenFromFeed,
          }}
        />
      ))}
      {data && data.length === 0 && (
        <div className="services__empty_message normal-text">
          Список категорий пуст
        </div>
      )}
      <PaginationRow totalPages={totalPages} setPage={setPage} page={page} />
    </div>
  );
};

export default SubCategories;
