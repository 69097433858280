/* eslint-disable */
import React from 'react';
import Divider from '../../../components/Divider/Divider';
import QrCode from '../QrCode/QrCode';
import { useHistory } from 'react-router-dom';

const MainBlock = () => {
  const history = useHistory();

  const [modalActive, setModalActive] = React.useState(false);

  const goTo = (path: string, blank?: boolean) => {
    console.log('GO_TO');
    if (!blank) {
      history.push(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };

  const showQRModal = () => {
    window.scrollTo(0, 0);
    setModalActive(true);
  };

  return (
    <div className="main__container">
      <Divider className="divider__horizontal">
        <div className="main__info-social">
          <div className="main__info-icon">
            <button
              type="button"
              className="telegram__link-button"
              onClick={() => goTo('https://t.me/advatravel', true)}
            />
          </div>
          <div className="main__info-icon">
            <button
              type="button"
              className="vk__link-button"
              onClick={() => goTo('https://vk.com/advaapp', true)}
            />
          </div>
          <div className="main__info-icon">
            <button
              className="r__link-button"
              onClick={() => goTo('https://rutube.ru/channel/24261482/', true)}
            />
          </div>
        </div>
      </Divider>
      <div className="main__content">
        <div className="main__info">
          <div className="main__info-about">
            <div className="main__info-title">
              <h1>
                Найди и забронируй <br /> активности <br /> в приложении{' '}
                <span className="colortext">ADVA</span>
              </h1>
            </div>
            <div className="main__info-links">
              <button
                className="main__info-link-google"
                onClick={() =>
                  goTo(
                    'https://play.google.com/store/apps/details?id=com.numdes.a2',
                    true,
                  )
                }
              />
              <button
                className="main__info-link-apple"
                onClick={() =>
                  goTo(
                    'https://apps.apple.com/ru/app/adva-travel/id1600305963',
                    true,
                  )
                }
              />
              <button
                className="main__info-link-qr"
                onClick={() => showQRModal()}
              />
            </div>
            <div className="main__info-tags">
              <div className="main__info-tag">#Алтай</div>
              <div className="main__info-tag">#Краснодарский край</div>
              <div className="main__info-tag">#Ленинградская область </div>
            </div>
            <div className="main__info-social-375">
              <div className="main__info-icon">
                <button
                  type="button"
                  className="telegram__link-button"
                  onClick={() => goTo('https://t.me/advatravel', true)}
                />
              </div>
              <div className="main__info-icon">
                <button
                  type="button"
                  className="vk__link-button"
                  onClick={() => goTo('https://vk.com/advaapp', true)}
                />
              </div>
              <div className="main__info-icon">
                <button
                  className="r__link-button"
                  onClick={() =>
                    goTo('https://rutube.ru/channel/24261482/', true)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="main__phone">
          <div className="main__phone-picture" />
        </div>
      </div>
      <QrCode modalActive={modalActive} setModalActive={setModalActive} />
    </div>
  );
};

export default MainBlock;
