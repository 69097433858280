import * as React from 'react';

function HumanIcon2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={51}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.807 18.416a8.511 8.511 0 100-17.022 8.511 8.511 0 000 17.022zM16.158 17.945c-2.579 1.025-4.062 3.978-4.547 6.709M24.447 22.228c-13.03.9-18.547 12.49-21.347 16.759-1.04 1.58-1.594 3.66-.513 5.212v.013a3.62 3.62 0 005.018.915l10.854-7.499c1.441-.984 1.857-2.91.984-4.422h-.014c-.457-.804-1.15-1.317-2.038-1.538a3.193 3.193 0 00-2.536.36l-3.604 2.163M15.035 39.998v9.01M14.01 25.846c1.316.707 1.898 3.631 1.191 6.002M17.738 23.808c1.053.832 1.788 5.656 1.414 8.982M32.792 17.959c2.578 1.025 4.061 3.978 4.546 6.709M24.502 22.242c13.044.9 18.561 12.49 21.347 16.759 1.04 1.58 1.594 3.659.513 5.212v.013a3.62 3.62 0 01-5.018.915L30.49 37.642a3.323 3.323 0 01-.97-4.422 3.23 3.23 0 012.052-1.552c.887-.222 1.732-.097 2.536.374l3.604 2.162M33.914 40.012v9.01M34.94 25.86c-1.317.707-1.899 3.631-1.192 6.002M31.225 23.822c-1.067.832-1.788 5.655-1.428 8.982"
        stroke="#00BFB4"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HumanIcon2;
