import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from '../../../images/Service/BookingsList/icn-arrow.svg';
import { ReactComponent as BookingIcon } from '../../../images/Services/icn-booking.svg';
import { ReactComponent as ReviewIcon } from '../../../images/Services/icn-review.svg';
import { handleStorage } from '../../../services/handleStorage';

import './style.scss';

interface IService extends RouteComponentProps {
  id: string;
  name: string;
  bookings: number;
  numberOfWaitingForApprovalReservations: number;
  rating: string;
  newReviews: number;
  type:
    | 'ACTIVITY'
    | 'EXPERT'
    | 'RENTAL'
    | 'TRANSFER'
    | 'GENERIC'
    | 'PRIMITIVE'
    | undefined;
  status: 'ACTIVE' | 'INACTIVE' | 'VERIFICATION' | 'REJECTED' | 'BLOCKED';
}

const Index: React.FC<IService> = ({
  id,
  name,
  bookings,
  numberOfWaitingForApprovalReservations,
  newReviews,
  rating,
  history,
  status,
  type,
}) => {
  const role = handleStorage.getRole();

  const onServiceClick = () => {
    let path = '';
    let state = {};
    if (role === 'ACTIVITY_PROVIDER') {
      path = `/provider/service=${id}`;
    } else if (role === 'OPERATOR') {
      path = `${location.pathname}/service=${id}`;
      state = { tabIx: 1 };
    }
    if (path) history.push({ pathname: path, state: state });
  };

  const getStatus = (): string => {
    switch (status) {
      case 'ACTIVE':
        return 'Активна';
      case 'INACTIVE':
        return 'Неактивна';
      case 'BLOCKED':
        return 'Заблокирована';
      case 'REJECTED':
        return 'Отклонена';
      case 'VERIFICATION':
        return 'На модерации';
    }
  };

  return (
    <button className="service__container" onClick={onServiceClick}>
      <div className="service__name-row">
        <div className="service__name-row-title header2">{`${name}`}</div>
        <div className="service__status-container">
          <div
            className={classNames(
              'service__status list__status label-text',
              status.toLowerCase(),
            )}
          >
            {getStatus()}
          </div>
          <ArrowIcon />
        </div>
      </div>
      <div className="service__details-row">
        <div className="service__details-column">
          <>
            <BookingIcon />
            <div className="service__details-number normal-text">{`${bookings}`}</div>
            {numberOfWaitingForApprovalReservations > 0 && (
              <span className="service__details-new-number normal-text">{`+${numberOfWaitingForApprovalReservations}`}</span>
            )}
          </>
        </div>
        <div className="service__details-column">
          {rating && (
            <>
              <ReviewIcon />
              <span className="service__details-number normal-text">
                {rating}
              </span>
              {newReviews > 0 && (
                <span className="service__details-new-number normal-text">{`+${newReviews}`}</span>
              )}
            </>
          )}
        </div>
      </div>
      <div className="service__status-xs">
        <div
          className={classNames(
            'service__status list__status-xs label-text',
            status.toLowerCase(),
          )}
        >
          {getStatus()}
        </div>
      </div>
    </button>
  );
};

export default withRouter(Index);
