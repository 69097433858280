import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import QrCode from '../../pages/MainPage/QrCode/QrCode';
import Divider from '../Divider/Divider';

const Footer = () => {
  const history = useHistory();
  const [modalActive, setModalActive] = useState(false);

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      history.push(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };

  const showQRModal = () => {
    window.scrollTo(0, 0);
    setModalActive(true);
  };
  return (
    <div className="footer__container">
      <Divider className="divider__horizontal" />
      <div className="footer__content">
        <div className="footer__links-container">
          <div className="footer__links-container-navigation">
            <Link to="/" className="footer__links-container-navigation-link">
              Главная страница
            </Link>
            <Link
              to="/subcategories"
              className="footer__links-container-navigation-link"
            >
              Что есть в ADVA?
            </Link>
            <Link
              to="/locations"
              className="footer__links-container-navigation-link"
            >
              Локации
            </Link>
            <Link
              to="/blogs"
              className="footer__links-container-navigation-link"
            >
              Полезное для путешествий
            </Link>
          </div>
          <div className="footer__links-container-navigation-icons">
            <div className="footer__links-container-navigation-icons-container">
              <button
                className="footer__links-container-navigation-icon-google"
                onClick={() =>
                  goTo(
                    'https://play.google.com/store/apps/details?id=com.numdes.a2',
                    true,
                  )
                }
              />
              <button
                className="footer__links-container-navigation-icon-appstore"
                onClick={() =>
                  goTo(
                    'https://apps.apple.com/ru/app/adva-travel/id1600305963',
                    true,
                  )
                }
              />
              <button
                className="footer__links-container-navigation-icon-qr"
                onClick={() => showQRModal()}
              />
            </div>
            <div className="footer__links-container-navigation-button-container">
              <button
                className="footer__links-container-navigation-button"
                onClick={() => goTo('/provider')}
              >
                <p className="footer__links-container-navigation-button-text">
                  ЛК организатора
                </p>
              </button>
              <div className="footer__contacts-container-social-icons">
                <button
                  type="button"
                  className="footer__telegram__link-button"
                  onClick={() => goTo('https://t.me/advatravel', true)}
                />
                <button
                  type="button"
                  className="footer__vk__link-button"
                  onClick={() => goTo('https://vk.com/advaapp', true)}
                />
                <button
                  className="footer__r__link-button"
                  onClick={() =>
                    goTo('https://rutube.ru/channel/24261482/', true)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer__contacts-container">
          <div className="footer__contacts-container-contacts">
            <div className="footer__contacts-container-contacts-info-container">
              <div className="footer__contacts-container-contacts-info-questions">
                <p>По любым вопросам:</p>
              </div>
              <div className="footer__contacts-container-contacts-info-email">
                <p>info@adva.travel</p>
              </div>
            </div>
            <div className="footer__contacts-container-contacts-copyright">
              <p>(c) ADVA Travel 2022</p>
            </div>
          </div>
          <div className="footer__contacts-container-social">
            <button
              className="footer__contacts-container-social-item"
              onClick={() => goTo('https://t.me/advatravel', true)}
            >
              Telegram
            </button>
            <button
              className="footer__contacts-container-social-item"
              onClick={() => goTo('https://vk.com/advaapp', true)}
            >
              VKontakte
            </button>
            <button
              className="footer__contacts-container-social-item"
              onClick={() => goTo('https://rutube.ru/channel/24261482/', true)}
            >
              Rutube
            </button>
          </div>
        </div>
        <div className="footer__payment">
          <div className="footer__payment-card-visa" />
          <div className="footer__payment-card-mastercard" />
          <div className="footer__payment-card-mir" />
          <div className="footer__payment-card-paykeeper" />
        </div>
      </div>
      <Divider className="divider__horizontal" />
      <QrCode modalActive={modalActive} setModalActive={setModalActive} />
    </div>
  );
};

export default Footer;
