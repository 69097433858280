import * as React from 'react';

import classNames from 'classnames';

import PopupMenuLayout from '../../../../components/PopupMenuLayout';
import { ReactComponent as PointsIcon } from '../../../../images/Service/icn-points.svg';
import { handleStorage } from '../../../../services/handleStorage';

import './style.scss';

interface IPopupMenu {
  onBlockClick(): void;
  onDeleteService(): void;
  activeness: 'ACTIVE' | 'INACTIVE' | 'VERIFICATION' | 'REJECTED' | 'BLOCKED';
  verification: 'PENDING' | 'VERIFIED' | 'REJECTED' | 'BLOCKED';
  toggleVisibility(): void;
  visibilityRequestInProgress: boolean;
  deleteRequestInProgress: boolean;
  blockRequestInProgress: boolean;
}

const PopupMenu: React.FC<IPopupMenu> = ({
  onBlockClick,
  onDeleteService,
  activeness,
  verification,
  toggleVisibility,
  visibilityRequestInProgress,
  deleteRequestInProgress,
  blockRequestInProgress,
}) => {
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const controlRef = React.useRef<HTMLButtonElement>(null);

  const role = handleStorage.getRole();

  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };

  const closeMenu = () => {
    setActiveMenu(false);
  };

  const onMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleMenu();
  };
  return (
    <div className="popup-menu__container">
      <button
        className={classNames(
          'popup-menu__menu-button',
          activeMenu && 'active',
        )}
        type="button"
        ref={controlRef}
        onClick={onMenuClick}
      >
        <PointsIcon className="popup-menu__points-icon" />
      </button>
      {activeMenu && (
        <PopupMenuLayout
          className="service-menu"
          closeMenu={closeMenu}
          controlRef={controlRef}
        >
          {role === 'ACTIVITY_PROVIDER' && (
            <>
              <button
                type="button"
                className="popup-menu__list-item normal-text"
                onClick={() => {
                  onDeleteService();
                  closeMenu();
                }}
                disabled={true /*deleteRequestInProgress*/}
              >
                Удалить
              </button>
              <button
                type="button"
                className="popup-menu__list-item normal-text"
                onClick={() => {
                  toggleVisibility();
                  closeMenu();
                }}
                disabled={
                  !['ACTIVE', 'INACTIVE'].includes(activeness) ||
                  visibilityRequestInProgress
                }
              >
                {activeness === 'ACTIVE'
                  ? `Сделать неактивной`
                  : `Сделать активной`}
              </button>
            </>
          )}
          {role === 'OPERATOR' && (
            <>
              <button
                type="button"
                className="popup-menu__list-item normal-text"
                onClick={() => {
                  toggleVisibility();
                  closeMenu();
                }}
                disabled={
                  !['ACTIVE', 'INACTIVE'].includes(activeness) ||
                  visibilityRequestInProgress
                }
              >
                {activeness === 'ACTIVE'
                  ? `Сделать неактивной`
                  : `Сделать активной`}
              </button>
              <button
                type="button"
                className="popup-menu__list-item normal-text"
                onClick={() => {
                  onBlockClick();
                  closeMenu();
                }}
                disabled={
                  !['ACTIVE', 'INACTIVE', 'BLOCKED'].includes(activeness) ||
                  blockRequestInProgress
                }
              >
                {verification === 'BLOCKED'
                  ? `Разблокировать`
                  : `Заблокировать`}
              </button>
            </>
          )}
        </PopupMenuLayout>
      )}
    </div>
  );
};

export default PopupMenu;
