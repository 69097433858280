import * as React from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import Input from '../../components/Input';
import PhoneInput from '../../components/PhoneInput';
import ReturnLink from '../../components/ReturnLink';
import { components } from '../../generated/apiTypes';
import { selectApiError, setApiError } from '../../store/commonSlice';
import { accountState } from './types';

interface IEditAccount {
  accountData: components['schemas']['AccountView'];
  setAccountState(state: accountState): void;
  updateAccountData(account: components['schemas']['AccountUpdate']): void;
}

const EditAccount: React.FC<IEditAccount> = ({
  accountData,
  setAccountState,
  updateAccountData,
}) => {
  const [name, setName] = React.useState<string | undefined>(accountData?.name);
  const [phone, setPhone] = React.useState<string | undefined>(
    accountData?.phone && `+${accountData?.phone}`,
  );
  const [phoneError, setPhoneError] = React.useState<boolean>(false);
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [requestInProgress, setRequestInProgress] =
    React.useState<boolean>(false);
  const phoneRef = React.useRef<HTMLDivElement>(null);
  const [phoneFieldWidth, setPhoneFieldWidth] = React.useState<number>(0);
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      if (
        ['AccountUpdate_name_NotBlank', 'AccountUpdate_name_Size'].includes(
          apiError?.key as string,
        )
      )
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [apiError.key]);

  React.useEffect(() => {
    if (phoneRef?.current?.clientWidth)
      setPhoneFieldWidth(phoneRef?.current?.clientWidth);
  }, [phoneRef?.current]);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (nameError) setNameError(false);
    if (
      ['AccountUpdate_name_NotBlank', 'AccountUpdate_name_Size'].includes(
        apiError?.key as string,
      )
    )
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setName(e.target.value);
  };

  const onChangePhone = (value: any) => {
    if (phoneError) setPhoneError(false);
    setPhone(value);
  };

  const onSaveClick = async () => {
    const phoneIsCorrect = phone && isValidPhoneNumber(phone);
    if (!phoneIsCorrect) setPhoneError(true);
    if (!name) setNameError(true);
    if (phoneIsCorrect && name) {
      const phoneNumber = parseInt(phone as string, 10);
      setRequestInProgress(true);
      try {
        await updateAccountData({
          phone: phoneNumber.toString(),
          name: name as string,
        });
      } catch (e) {
        //
      }
      setRequestInProgress(false);
    }
  };

  return (
    <>
      <ReturnLink
        linkName={'К профилю'}
        onClickHandler={() => setAccountState('VIEW')}
      />
      <div className="page__title title edit-account__title">
        Редактирование данных профиля
      </div>
      <div className="edit-account__field">
        <Input
          placeholder="Название организации / ФИО"
          value={name}
          onChange={onChangeName}
          error={
            nameError ||
            apiError?.key === 'AccountUpdate_name_NotBlank' ||
            apiError?.key === 'AccountUpdate_name_Size'
          }
        />
      </div>
      <div className="edit-account__field" ref={phoneRef}>
        <PhoneInput
          placeholder="Номер телефона"
          setPhoneValue={onChangePhone}
          value={phone}
          error={phoneError}
          menuWidth={phoneFieldWidth}
        />
      </div>
      <div className="edit-account__buttons">
        <Button
          className="edit-account__button"
          onClick={onSaveClick}
          disabled={requestInProgress}
        >
          Сохранить
        </Button>
      </div>
      {['AccountUpdate_name_NotBlank', 'AccountUpdate_name_Size'].includes(
        apiError?.key as string,
      ) && (
        <div className="login-page__error-message normal-text">
          {apiError?.message}
        </div>
      )}
    </>
  );
};

export default EditAccount;
