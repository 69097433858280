import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import classNames from 'classnames';
import { divIcon } from 'leaflet';

import Button from '../../../components/Button';
import {
  disconnectSupervisedProviderBot,
  getConnectedSupervisedProviderBotChart,
  requestSupervisedProviderBotConnection,
} from '../../../services/apiRequests';
import { handleStorage } from '../../../services/handleStorage';

interface IRouterProps {
  provider_id?: string;
  service_id: string;
}

const Notifications: React.FC<RouteComponentProps<IRouterProps>> = ({
  match,
}) => {
  const service_id = match.params.service_id;
  const role = handleStorage.getRole();
  const [telegramState, setTelegramState] = React.useState<
    'CONNECTING' | 'CONNECTED' | 'NOT_CONNECTED'
  >();
  const [telegramAccountName, setTelegramAccountName] =
    React.useState<string>();
  const timerId = React.useRef<ReturnType<typeof setTimeout>>();
  const [telegramConnectionLink, setTelegramConnectionLink] =
    React.useState<string>();

  const getBotStatus = async () => {
    const response = await getConnectedSupervisedProviderBotChart(service_id);
    if (response?.data) {
      setTelegramAccountName(response.data?.name);
      setTelegramState('CONNECTED');
      if (timerId.current) clearInterval(timerId.current);
    } else {
      if (role === 'ACTIVITY_PROVIDER') {
        requestConnectionURL();
      } else {
        setTelegramState('NOT_CONNECTED');
      }
    }
  };

  const getBotStatusContinuously = async () => {
    const response = await getConnectedSupervisedProviderBotChart(service_id);
    if (response?.data) {
      setTelegramAccountName(response.data?.name);
      setTelegramState('CONNECTED');
      if (timerId.current) clearInterval(timerId.current);
    }
  };

  const requestConnectionURL = async () => {
    const response = await requestSupervisedProviderBotConnection(service_id);
    if (response?.data) {
      setTelegramConnectionLink(response.data?.url);
      setTelegramState('CONNECTING');
      timerId.current = setInterval(async () => {
        getBotStatusContinuously();
      }, 1000);
    }
  };

  const disconnectTelegramBot = async () => {
    await disconnectSupervisedProviderBot(service_id);
    getBotStatus();
  };

  React.useEffect(() => {
    getBotStatus();
    return () => {
      if (timerId.current) clearInterval(timerId.current);
    };
  }, []);

  return (
    <>
      {telegramState && (
        <>
          {telegramState === 'CONNECTING' && (
            <div className="account-view__telegram-panel">
              <div className="account-view__field-title header1">
                Подключение телеграм бота поставщика
              </div>
              <div className="account-view__telegram-value normal-text">
                Передайте данную ссылку инструктору для подключения бота
                персональных оповещений
              </div>
              <a
                className="login-page__policy-link button-text underlined"
                href={telegramConnectionLink}
                target="_blank"
                rel="noreferrer noopener"
              >
                {telegramConnectionLink}
              </a>
            </div>
          )}
          {telegramState === 'CONNECTED' && (
            <div
              className={classNames(
                'account-view__telegram-panel connected',
                role === 'OPERATOR' && 'operator',
              )}
            >
              <div className="account-view__field-title">
                <span className="account-view__field-value normal-text">
                  Подключены оповещения для телеграма поставщика
                </span>{' '}
                <span className="account-view__field-value bold-value normal-text">
                  {telegramAccountName}
                </span>
              </div>
              {role === 'ACTIVITY_PROVIDER' && (
                <Button
                  className="account-view__telegram-btn disconnect"
                  mode={'secondary'}
                  onClick={disconnectTelegramBot}
                >
                  Отключить оповещения для телеграма
                </Button>
              )}
            </div>
          )}
          {telegramState === 'NOT_CONNECTED' && (
            <div className="account-view__telegram-panel operator">
              <div className="account-view__telegram-value normal-text">
                Оповещения не подключены
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(Notifications);
