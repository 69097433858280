import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ValueType } from 'react-select';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import ApiErrorUI from '../../components/ApiErrorUI';
import Dropdown from '../../components/Dropdown';
import Layout from '../../components/Layout';
import PaginationRow from '../../components/PaginationRow';
import SearchInput from '../../components/SearchInput';
import { components } from '../../generated/apiTypes';
import { getLocations, getProviders } from '../../services/apiRequests';
import { handleStorage } from '../../services/handleStorage';
import { selectApiError, setApiError } from '../../store/commonSlice';
import Provider from './Provider';

import './style.scss';
import '../style.scss';

const Index: React.FC = () => {
  const [searchInputValue, setSearchInputValue] = React.useState<string>('');
  const [tabIndex, setTabIndex] = React.useState(0);
  const [data, setData] =
    React.useState<components['schemas']['ActivityProviderView'][]>();
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [maxResults, setMaxResults] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(0);
  const [resortSelectValue, setResortSelectValue] = React.useState<
    ValueType<any, false> | undefined
  >({ value: '0', label: 'Все локации' });
  const [resortOptions, setResortOptions] = React.useState<
    { value: string; label: string }[]
  >([]);
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();

  const role = handleStorage.getRole();
  const token = handleStorage.getToken();
  const history = useHistory();
  const { pathname } = useLocation();

  const getAllProviders = async () => {
    let group:
      | 'ALL'
      | 'ACTIVITY_VERIFICATION_PENDING'
      | 'REVIEW_MODERATION_PENDING' = 'ALL';
    if (tabIndex === 1) group = 'ACTIVITY_VERIFICATION_PENDING';
    else if (tabIndex === 2) group = 'REVIEW_MODERATION_PENDING';
    const response = await getProviders(
      group,
      maxResults * page,
      searchInputValue,
      resortSelectValue?.value > 0 ? resortSelectValue?.value : [],
    );
    if (response.data) {
      setData(response.data.results);
      setMaxResults(response.data.maxResults as number);
      setTotalPages(response.data.totalPages as number);
    }
  };

  const getOptions = async () => {
    const response = await getLocations();
    if (response?.data) {
      const resortOptions = [];
      resortOptions.push({
        value: '0',
        label: 'Все локации',
      });
      for (const item of response.data) {
        resortOptions.push({
          value: (item?.id as number).toString(),
          label: item?.name as string,
        });
      }
      setResortOptions(resortOptions);
    }
  };

  React.useEffect(() => {
    if (pathname.includes('/operator') && role === 'ACTIVITY_PROVIDER') {
      history.push('/provider');
    }
    if (!token) {
      history.push('/login');
    }
  }, []);

  React.useEffect(() => {
    setData(undefined);
    setTotalPages(0);
    getAllProviders();
  }, [page]);

  React.useEffect(() => {
    setPage(0);
    if (page === 0) {
      setData(undefined);
      setTotalPages(0);
      getAllProviders();
    }
  }, [tabIndex, searchInputValue, resortSelectValue?.value]);

  React.useEffect(() => {
    getOptions();
    return () => {
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    };
  }, []);

  const onChangeResort = (value: ValueType<any, false>) => {
    setResortSelectValue(value);
  };

  const onChangeSearchInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  const clearSearchInputValue = () => {
    setSearchInputValue('');
  };

  if (!token) {
    return null;
  }

  return (
    <Layout>
      {apiError?.isError ? (
        <ApiErrorUI />
      ) : (
        <>
          <div className="page__title title">Поставщики</div>
          <div className="all-providers__controls-row">
            <div className="all-providers__search-input-wrapper">
              <SearchInput
                onInput={onChangeSearchInputValue}
                value={searchInputValue}
                placeholder="Название организации"
                clearInputValue={clearSearchInputValue}
              />
            </div>
            <div className="all-providers__location-select">
              <Dropdown
                placeholder="Фильтр по локации"
                options={resortOptions}
                menuWidth={'100%'}
                value={resortSelectValue}
                isSearchable={false}
                onChange={onChangeResort}
              />
            </div>
          </div>
          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList className="page__tab-list">
              <Tab className="page__tab button-text">Все</Tab>
              <Tab className="page__tab button-text">
                Запрос&nbsp;на&nbsp;изменения
              </Tab>
              <Tab className="page__tab button-text">Новые&nbsp;отзывы</Tab>
            </TabList>
            <TabPanel>
              {data &&
                data?.length > 0 &&
                data?.map(item => (
                  <Provider
                    key={item?.id?.toString() as string}
                    id={item?.id?.toString() as string}
                    name={`${item?.account?.name}${
                      item?.supervisor ? ' (Супервайзер)' : ''
                    }`}
                    reviewsAvg={(item?.rating as number)?.toFixed(1)}
                    newReviews={0}
                  />
                ))}
              {data && data?.length === 0 && !searchInputValue && (
                <div className="services__empty_message normal-text">
                  Не создано ни одного поставщика
                </div>
              )}
              {data && data?.length === 0 && searchInputValue && (
                <div className="services__empty_message normal-text">
                  Не найдены поставщики соответствующие данному запросу
                </div>
              )}
              <PaginationRow
                totalPages={totalPages}
                setPage={setPage}
                page={page}
              />
            </TabPanel>
            <TabPanel>
              {data &&
                data?.length > 0 &&
                data?.map(item => (
                  <Provider
                    key={item?.id?.toString() as string}
                    id={item?.id?.toString() as string}
                    name={item?.account?.name as string}
                    reviewsAvg={(item?.rating as number)?.toFixed(1)}
                    newReviews={0}
                  />
                ))}
              {data && data?.length === 0 && (
                <div className="services__empty_message normal-text">
                  Не найдены поставщики соответствующие данному запросу
                </div>
              )}
              <PaginationRow
                totalPages={totalPages}
                setPage={setPage}
                page={page}
              />
            </TabPanel>
            <TabPanel>
              {data &&
                data?.length > 0 &&
                data?.map(item => (
                  <Provider
                    key={item?.id?.toString() as string}
                    id={item?.id?.toString() as string}
                    name={item?.account?.name as string}
                    reviewsAvg={(item?.rating as number)?.toFixed(1)}
                    newReviews={0}
                  />
                ))}
              {data && data?.length === 0 && (
                <div className="services__empty_message normal-text">
                  Не найдены поставщики соответствующие данному запросу
                </div>
              )}
              <PaginationRow
                totalPages={totalPages}
                setPage={setPage}
                page={page}
              />
            </TabPanel>
          </Tabs>
        </>
      )}
    </Layout>
  );
};

export default Index;
