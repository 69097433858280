import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import PaginationRow from '../../../components/PaginationRow';
import { components } from '../../../generated/apiTypes';
import { ReactComponent as WhiteStarIcon } from '../../../images/Service/Reviews/icn-star-white.svg';
import { getReviewsById } from '../../../services/apiRequests';
import { selectApiError, setApiError } from '../../../store/commonSlice';
import { sklonenie } from '../../../utils/sklonenie';
import Review from './Review';

import './style.scss';

interface IRouterProps {
  provider_id?: string;
  service_id: string;
}

interface IReview extends RouteComponentProps<IRouterProps> {
  rating?: number;
  numberOfReviews?: number;
}

const Index: React.FC<IReview> = ({ match, rating, numberOfReviews }) => {
  const [data, setData] =
    React.useState<components['schemas']['ReviewView'][]>();
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [maxResults, setMaxResults] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(0);
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();
  const service_id = match.params.service_id;

  const getReviews = async () => {
    const response = await getReviewsById(service_id, maxResults * page);
    if (response.data) {
      setData(response.data.results);
      setMaxResults(response.data.maxResults as number);
      setTotalPages(response.data.totalPages as number);
    }
  };

  React.useEffect(() => {
    setData(undefined);
    setTotalPages(0);
    getReviews();
    return () => {
      if (apiError.isError)
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [page]);

  return (
    <>
      {rating && rating > 0 && numberOfReviews && numberOfReviews > 0 && (
        <div className="reviews__rating-container label-text">
          <div className="reviews__rating label-text">
            <WhiteStarIcon />
            {rating.toFixed(1)}
          </div>
          {numberOfReviews}&nbsp;
          {sklonenie(numberOfReviews, ['отзыв', 'отзыва', 'отзывов'])}
        </div>
      )}
      <div>
        {data &&
          (data.length > 0 ? (
            data
              .filter(it => it.moderation === 'APPROVED')
              ?.map(item => (
                <Review
                  key={item.id}
                  name={item.reservation?.customer?.name as string}
                  rating={item.rating as number}
                  date={format(
                    new Date(item.dateTime as string),
                    'dd MMMM yyyy',
                    {
                      locale: ru,
                    },
                  )}
                  id={item.id?.toString() as string}
                  text={item.comment as string}
                />
              ))
          ) : (
            <div className="services__empty_message normal-text">
              Нет отзывов
            </div>
          ))}
      </div>
      <PaginationRow totalPages={totalPages} setPage={setPage} page={page} />
    </>
  );
};

export default withRouter(Index);
