import * as React from 'react';
import { DateRange, Range } from 'react-date-range';

import classNames from 'classnames';
import { format, subDays } from 'date-fns';
import ru from 'date-fns/locale/ru';

import { ReactComponent as CalendarIcon } from '../../images/components/DatePicker/calendar.svg';

import './style.scss';

interface IDatePicker {
  selection: Range[];
  setSelection(range: Range[]): void;
  inputClassName?: string;
  singleDate?: boolean;
  beforeDays?: boolean;
}

const DatePicker: React.FC<IDatePicker> = ({
  selection,
  setSelection,
  inputClassName,
  singleDate,
  beforeDays,
}) => {
  const [isShowCalendar, setIsShowCalendar] = React.useState<boolean>(false);
  const [focusedRange, setFocusedRange] = React.useState<number>(0);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const startInputRef = React.useRef<HTMLInputElement>(null);
  const endInputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        !wrapperRef?.current?.contains(event.target) &&
        !startInputRef?.current?.contains(event.target) &&
        !endInputRef?.current?.contains(event.target)
      ) {
        setIsShowCalendar(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    wrapperRef.current?.scrollIntoView();
  }, [isShowCalendar]);

  return (
    <>
      <button
        className={classNames('date-picker__input-wrapper', inputClassName)}
        onClick={() => {
          if (
            focusedRange === 1 &&
            selection[0]?.endDate?.toDateString() !==
              selection[0]?.startDate?.toDateString()
          )
            setIsShowCalendar(true);
          else setIsShowCalendar(!isShowCalendar);
          setFocusedRange(0);
        }}
      >
        <input
          type="text"
          className={classNames(
            'date-picker__input normal-text',
            inputClassName,
            selection[0]?.endDate?.toDateString() !==
              selection[0]?.startDate?.toDateString() && 'long',
          )}
          readOnly
          value={`${format(selection[0]?.startDate as Date, 'dd MMMM yyyy', {
            locale: ru,
          })} ${
            selection[0]?.endDate?.toDateString() !==
            selection[0]?.startDate?.toDateString()
              ? ` -  ${format(selection[0]?.endDate as Date, 'dd MMMM yyyy', {
                  locale: ru,
                })}`
              : ''
          }`}
          placeholder={'Не выбрано'}
          ref={startInputRef}
        />
        <CalendarIcon className="date-picker__calendar-icon" />
      </button>
      {isShowCalendar && (
        <div className="edit-slot__calendar-container" ref={wrapperRef}>
          <DateRange
            format="DD MMM YYYY"
            locale={ru}
            onChange={item => {
              setSelection([item.selection]);
              if (singleDate) setIsShowCalendar(false);
              if (focusedRange === 0) setFocusedRange(1);
              else if (focusedRange === 1) setFocusedRange(0);
            }}
            ranges={selection}
            weekdayDisplayFormat={'EEEEEE'}
            moveRangeOnFirstSelection={false}
            focusedRange={[0, focusedRange]}
            showDateDisplay={false}
            showMonthAndYearPickers={false}
            disabledDay={
              beforeDays
                ? date => date > new Date()
                : date => date < subDays(new Date(), 1)
            }
          />
        </div>
      )}
    </>
  );
};

export default DatePicker;
