import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import Input from '../../components/Input';
import ReturnLink from '../../components/ReturnLink';
import { components } from '../../generated/apiTypes';
import { selectApiError, setApiError } from '../../store/commonSlice';
import { accountState } from './types';

interface IEditAccount {
  setAccountState(state: accountState): void;
  updatePassword(account: components['schemas']['PasswordUpdate']): void;
}

const ChangePassword: React.FC<IEditAccount> = ({
  setAccountState,
  updatePassword,
}) => {
  const [oldPassword, setOldPassword] = React.useState<string>('');
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [newPasswordRepeat, setNewPasswordRepeat] = React.useState<string>('');
  const [oldPasswordError, setOldPasswordError] =
    React.useState<boolean>(false);
  const [newPasswordError, setNewPasswordError] =
    React.useState<boolean>(false);
  const [newPasswordRepeatError, setNewPasswordRepeatError] =
    React.useState<boolean>(false);
  const [passwordsEqualError, setPasswordsEqualError] =
    React.useState<boolean>(false);
  const [requestInProgress, setRequestInProgress] =
    React.useState<boolean>(false);
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      if (
        [
          'PasswordUpdate_password_Pattern',
          'PasswordUpdate_password_Size',
          'ACCOUNT_PASSWORD_INCORRECT',
          'PasswordUpdate_oldPassword_Size',
          'ACCOUNT_PASSWORD_WEAK',
        ].includes(apiError?.key as string)
      )
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [apiError.key]);

  const onChangeOldPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (oldPasswordError) setOldPasswordError(false);
    if (apiError.key === 'ACCOUNT_PASSWORD_INCORRECT')
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setOldPassword(e.target.value);
  };

  const onChangeNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (newPasswordError) setNewPasswordError(false);
    if (passwordsEqualError) setPasswordsEqualError(false);
    if (
      [
        'PasswordUpdate_password_Pattern',
        'PasswordUpdate_password_Size',
        'PasswordUpdate_oldPassword_Size',
        'ACCOUNT_PASSWORD_WEAK',
      ].includes(apiError?.key as string)
    )
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    setNewPassword(e.target.value);
  };

  const onChangeNewPasswordRepeat = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (newPasswordRepeatError) setNewPasswordRepeatError(false);
    if (passwordsEqualError) setPasswordsEqualError(false);
    setNewPasswordRepeat(e.target.value);
  };

  const onSaveClick = async () => {
    if (!oldPassword) setOldPasswordError(true);
    if (!newPassword) setNewPasswordError(true);
    if (!newPasswordRepeat) setNewPasswordRepeatError(true);
    if (newPassword !== newPasswordRepeat) {
      setPasswordsEqualError(true);
    }
    if (
      oldPassword &&
      newPassword &&
      newPasswordRepeat &&
      newPassword === newPasswordRepeat
    ) {
      setRequestInProgress(true);
      try {
        await updatePassword({
          password: newPassword,
          oldPassword: oldPassword,
        });
      } catch (e) {
        //
      }
      setRequestInProgress(false);
    }
  };

  return (
    <>
      <ReturnLink
        linkName={'К профилю'}
        onClickHandler={() => setAccountState('VIEW')}
      />
      <div className="page__title title edit-account__title">
        Изменение пароля
      </div>
      <div className="edit-account__field">
        <Input
          placeholder="Старый пароль"
          value={oldPassword}
          onChange={onChangeOldPassword}
          error={
            oldPasswordError || apiError.key === 'ACCOUNT_PASSWORD_INCORRECT'
          }
          type="password"
        />
      </div>
      <div className="edit-account__field">
        <Input
          placeholder="Новый пароль"
          value={newPassword}
          onChange={onChangeNewPassword}
          error={
            newPasswordError ||
            passwordsEqualError ||
            apiError.key === 'PasswordUpdate_password_Pattern' ||
            apiError.key === 'PasswordUpdate_password_Size' ||
            apiError.key === 'PasswordUpdate_oldPassword_Size' ||
            apiError.key === 'ACCOUNT_PASSWORD_WEAK'
          }
          type="password"
        />
      </div>
      <div className="edit-account__field">
        <Input
          placeholder="Повторить новый пароль"
          value={newPasswordRepeat}
          onChange={onChangeNewPasswordRepeat}
          error={newPasswordRepeatError || passwordsEqualError}
          type="password"
          onEnter={onSaveClick}
        />
        <div className="login-page__post-message">
          {
            'Пароль должен быть от 8 символов и содержать только латинские буквы и цифры'
          }
        </div>
      </div>
      <div className="edit-account__buttons">
        <Button
          className="edit-account__button"
          onClick={onSaveClick}
          disabled={requestInProgress}
        >
          Сохранить
        </Button>
      </div>
      {[
        'PasswordUpdate_password_Pattern',
        'PasswordUpdate_password_Size',
        'ACCOUNT_PASSWORD_INCORRECT',
        'PasswordUpdate_oldPassword_Size',
        'ACCOUNT_PASSWORD_WEAK',
      ].includes(apiError?.key as string) && (
        <div className="login-page__error-message normal-text">
          {apiError?.message}
        </div>
      )}
      {passwordsEqualError && (
        <div className="login-page__error-message normal-text">
          Введенные пароли не совпадают
        </div>
      )}
    </>
  );
};

export default ChangePassword;
