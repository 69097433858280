import * as React from 'react';
import { Pagination } from 'react-headless-pagination';

import classNames from 'classnames';

import './style.scss';

interface IPaginationRow {
  totalPages: number;
  setPage(pageIx: number): void;
  page: number;
}

const PaginationRow: React.FC<IPaginationRow> = ({
  totalPages,
  setPage,
  page,
}) => {
  return (
    <Pagination
      currentPage={page}
      setCurrentPage={setPage}
      totalPages={totalPages}
      edgePageCount={2}
      middlePagesSiblingCount={1}
      className="pagination-container"
      truncableText="..."
      truncableClassName="pagination-container__truncable-btn"
    >
      <div
        className={classNames(
          'pagination-container__page-btn',
          totalPages === 1 && 'hidden',
        )}
      >
        <Pagination.PageButton
          activeClassName="pagination-container__page-active"
          inactiveClassName=""
          className="pagination-container__page"
        />
      </div>
    </Pagination>
  );
};

export default PaginationRow;
