import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Button from '../../../components/Button';
import PaginationRow from '../../../components/PaginationRow';
import { components } from '../../../generated/apiTypes';
import { ReactComponent as AddIcon } from '../../../images/Services/icn-add.svg';
import { getFeedLocations, getFeedPosts } from '../../../services/apiRequests';
import Location from '../Locations/Location';
import Post from './Post';

import '../style.scss';

const Posts: React.FC<RouteComponentProps> = ({ history }) => {
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [maxResults, setMaxResults] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(0);
  const [data, setData] = React.useState<
    components['schemas']['FeedPostView'][] | undefined
  >([]);

  const getAllPosts = async () => {
    const response = await getFeedPosts(maxResults * page, true);
    if (response.data) {
      setData(response.data?.results);
      setMaxResults(response.data.maxResults as number);
      setTotalPages(response.data.totalPages as number);
    }
  };

  React.useEffect(() => {
    setData(undefined);
    setTotalPages(0);
    getAllPosts();
  }, [page]);

  return (
    <div className="website__locations-container posts-container">
      <Button
        className="services__add-btn website__add-post-btn"
        onClick={() => history.push('/operator/website/feedpost/new')}
      >
        <AddIcon className="services__add-btn-icon" />
        Добавить статью
      </Button>
      {data?.map(item => (
        <Post
          feedPostView={{
            id: item.id,
            title: item.title,
            hidden: item.hidden,
          }}
        />
      ))}
      {data && data.length === 0 && (
        <div className="services__empty_message normal-text">
          Список статей пуст
        </div>
      )}
      <PaginationRow totalPages={totalPages} setPage={setPage} page={page} />
      <div className="services__add-btn-wrapper-xs">
        <Button
          className="services__add-btn-xs"
          onClick={() => history.push('/operator/website/feedpost/new')}
        >
          <AddIcon className="services__add-btn-icon" />
          Добавить статью
        </Button>
      </div>
    </div>
  );
};

export default withRouter(Posts);
