/* eslint-disable */
import React from 'react';
import Divider from '../../../components/Divider/Divider';
import CloseIcon from '../../../images/LandingIcons/CloseIcon';
import QrCodeIcon from '../../../images/LandingIcons/QrCodeIcon';
interface modal {
  modalActive: boolean;
  setModalActive: (arg: boolean) => void;
}

const QrCode = ({ modalActive, setModalActive }: modal) => {
  return (
    <div className={modalActive ? 'qrcode__active' : 'qrcode__wrapper'}>
      <div className="qrcode__content">
        <div className="qrcode__container">
          <CloseIcon
            className="qrcode__close-icon"
            onClick={() => setModalActive(false)}
          />
          <Divider height="64px" />
          <div className="qrcode__image">
            <QrCodeIcon />
          </div>
          <Divider height="43px" />
          <p className="qrcode__title">Приложение ADVA</p>
          <Divider height="31px" />
          <p className="qrcode__text">
            Наведите камеру на QR-код, чтобы&nbsp; установить&nbsp;приложение
          </p>
        </div>
      </div>
    </div>
  );
};

export default QrCode;
