import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { components } from '../../../generated/apiTypes';
import { ReactComponent as ArrowIcon } from '../../../images/Service/BookingsList/icn-arrow.svg';

interface ISubLocation extends RouteComponentProps {
  subcategoryView: components['schemas']['SubcategoryView'];
}

const SubCategory: React.FC<ISubLocation> = ({
  subcategoryView,
  history,
  location,
}) => {
  const onLocationClick = () => {
    history.push({
      state: { tabIx: 2 },
    });
    history.push({
      pathname: `/operator/website/subcategory=${subcategoryView?.id}`,
      state: {
        tabIx: (location.state as { tabIx: number })?.tabIx,
      },
    });
  };

  return (
    <button
      key={subcategoryView?.id}
      className="booking__container"
      type="button"
      onClick={onLocationClick}
    >
      <div className="booking__id-row header2">
        {subcategoryView?.name}
        <div className="booking__status-container">
          <ArrowIcon />
        </div>
      </div>
      <div className="booking__details-container">
        <div className="booking__details-container-row">
          <div className="booking__details normal-text">
            {subcategoryView?.hiddenFromFeed ? 'Неактивна' : 'Активна'}
          </div>
        </div>
      </div>
      <div className="booking__details-container-xs">
        <div className="booking__details-xs-row">
          <div className="booking__details normal-text">
            {subcategoryView?.hiddenFromFeed ? 'Неактивна' : 'Активна'}
          </div>
        </div>
      </div>
    </button>
  );
};

export default withRouter(SubCategory);
