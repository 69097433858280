import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ApiErrorUI from '../../components/ApiErrorUI';
import Layout from '../../components/Layout';
import { components } from '../../generated/apiTypes';
import {
  getAccount,
  getMyActivityProvider,
  setAccount,
  setNewPassword,
} from '../../services/apiRequests';
import { handleStorage } from '../../services/handleStorage';
import { selectApiError, setApiError } from '../../store/commonSlice';
import updateAuthToken from '../../utils/updateAuthToken';
import AccountView from './AccountView';
import ChangePassword from './ChangePassword';
import EditAccount from './EditAccount';
import { accountState } from './types';

const Index: React.FC<RouteComponentProps> = ({ history }) => {
  const apiError = useSelector(selectApiError);
  const [accountState, setAccountState] = React.useState<accountState>('VIEW');
  const [accountData, setAccountData] = React.useState<
    components['schemas']['ActivityProviderView']
  >({});
  const [operatorAccountData, setOperatorAccountData] = React.useState<
    components['schemas']['AccountView']
  >({});
  const dispatch = useDispatch();
  const role = handleStorage.getRole();

  React.useEffect(() => {
    return () => {
      if (apiError.isError)
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          }),
        );
    };
  }, [apiError.isError]);

  const getAccountData = async () => {
    const response = await getMyActivityProvider();
    if (response?.data) {
      if (
        response.data?.account?.role &&
        handleStorage.getRole() !== response.data?.account.role
      )
        handleStorage.setRole(response.data?.account.role);
      setAccountData(response.data);
    }
  };

  const getOperatorAccountData = async () => {
    const response = await getAccount();
    if (response?.data) {
      if (
        response.data?.role &&
        handleStorage.getRole() !== response.data?.role
      )
        handleStorage.setRole(response.data?.role);
      setOperatorAccountData(response.data);
    }
  };

  const updateAccountData = async (
    account: components['schemas']['AccountUpdate'],
  ) => {
    const response = await setAccount(account);
    if (response?.data) {
      setAccountData({ ...accountData, account: response.data });
      if (handleStorage.getUserName() !== response.data?.name)
        handleStorage.setUsername(response.data?.name as string);
      if (
        response.data?.role &&
        handleStorage.getRole() !== response.data?.role
      )
        handleStorage.setRole(response.data?.role);
      setAccountState('VIEW');
    }
  };

  const updatePassword = async (
    password: components['schemas']['PasswordUpdate'],
  ) => {
    const response = await setNewPassword(password);
    if (response?.data) {
      setAccountData({ ...accountData, account: response.data });
      if (handleStorage.getUserName() !== response.data?.name)
        handleStorage.setUsername(response.data?.name as string);
      if (
        response.data?.role &&
        handleStorage.getRole() !== response.data?.role
      )
        handleStorage.setRole(response.data?.role);
      setAccountState('VIEW');
    }
  };

  const logout = () => {
    handleStorage.removeToken();
    handleStorage.removeUsername();
    handleStorage.removeRole();
    handleStorage.removeFeedAuthority();
    handleStorage.removeRefundAuthority();
    updateAuthToken(false);
    history.push('/login');
  };

  React.useEffect(() => {
    if (role === 'ACTIVITY_PROVIDER') {
      getAccountData().then();
    } else if (role === 'OPERATOR') {
      getOperatorAccountData().then();
    }
    return () => {
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        }),
      );
    };
  }, []);

  const errorToShow =
    apiError?.isError &&
    ![
      'AccountUpdate_name_NotBlank',
      'PasswordUpdate_password_Pattern',
      'PasswordUpdate_password_Size',
      'AccountUpdate_name_Size',
      'ACCOUNT_PASSWORD_INCORRECT',
      'ACCOUNT_PASSWORD_WEAK',
      'PasswordUpdate_oldPassword_Size',
    ].includes(apiError?.key as string);

  return (
    <Layout hideMobileMenu={['EDIT', 'CHANGE_PASSWORD'].includes(accountState)}>
      {errorToShow ? (
        <ApiErrorUI />
      ) : (
        <>
          {accountState === 'VIEW' &&
            role === 'ACTIVITY_PROVIDER' &&
            accountData?.account?.role && (
              <AccountView
                accountData={accountData.account}
                setAccountState={setAccountState}
                logout={logout}
                serviceChargePercentage={accountData.serviceChargePercentage}
                supervisor={accountData?.supervisor}
              />
            )}
          {accountState === 'VIEW' &&
            role === 'OPERATOR' &&
            operatorAccountData?.role && (
              <AccountView
                accountData={operatorAccountData}
                setAccountState={setAccountState}
                logout={logout}
                serviceChargePercentage={accountData.serviceChargePercentage}
              />
            )}
          {accountState === 'EDIT' && accountData?.account && (
            <EditAccount
              accountData={accountData.account}
              setAccountState={setAccountState}
              updateAccountData={updateAccountData}
            />
          )}
          {accountState === 'CHANGE_PASSWORD' && (
            <ChangePassword
              setAccountState={setAccountState}
              updatePassword={updatePassword}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default withRouter(Index);
