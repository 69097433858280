import * as React from 'react';
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents,
} from 'react-leaflet';

import L from 'leaflet';
import { LatLngExpression } from 'leaflet';

import marker from '../../../../images/Service/Info/icn-map-marker.svg';

import './style.scss';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';

interface IMap {
  mode: 'VIEW' | 'EDIT';
  coordinates: number[];
  setAddress(address: string): void;
  setNewLocation?(location: number[]): void;
}

interface ILocationMarker {
  position: LatLngExpression | undefined;
  setPosition(position: LatLngExpression): void;
  setAddress(address: string): void;
  setNewLocation?(location: number[]): void;
  mode: 'VIEW' | 'EDIT';
}

const LocationMarker: React.FC<ILocationMarker> = ({
  position,
  mode,
  setPosition,
  setAddress,
  setNewLocation,
}) => {
  const map = useMap();

  const markerIcon = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    iconSize: new L.Point(52, 72),
    iconAnchor: new L.Point(26, 72),
    className: 'map__icn-marker',
  });

  const geocoder = (L.Control as any).Geocoder.nominatim({
    htmlTemplate: (r: any) => {
      let resultAddress = `${r.address.country}`;
      if (r.address.state) resultAddress += `, ${r.address.state}`;
      if (r.address.town) resultAddress += `, ${r.address.town}`;
      if (r.address.village) resultAddress += `, ${r.address.village}`;
      else if (r.address.hamlet) resultAddress += `, ${r.address.hamlet}`;
      if (r.address.road) resultAddress += `, ${r.address.road}`;
      if (r.address.house_number)
        resultAddress += `, ${r.address.house_number}`;
      if (r.address.tourism) resultAddress += `, ${r.address.tourism}`;
      return resultAddress;
    },
  });

  let timer: NodeJS.Timer;
  const delay = 200;
  let prevent = false;

  const getAddress = (location: LatLngExpression) => {
    geocoder.reverse(
      location,
      map?.options?.crs?.scale(map?.getZoom()),
      (results: any) => {
        const r = results[0];
        if (r) {
          if (setAddress) setAddress(r?.html);
        }
      },
    );
  };

  React.useEffect(() => {
    if (position) getAddress(position);
  }, [position]);

  if (mode === 'EDIT')
    useMapEvents({
      click(e) {
        timer = setTimeout(function () {
          if (!prevent) {
            setPosition(e.latlng);
            if (setNewLocation) setNewLocation([e.latlng.lat, e.latlng.lng]);
          }
          prevent = false;
        }, delay);
      },
      dblclick() {
        clearTimeout(timer);
        prevent = true;
      },
    });

  return position ? (
    <Marker position={position} interactive={false} icon={markerIcon} />
  ) : null;
};

interface IChangeView {
  center: LatLngExpression;
  zoom: number;
}

const ChangeView: React.FC<IChangeView> = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return <></>;
};

const Map: React.FC<IMap> = ({
  mode,
  coordinates,
  setAddress,
  setNewLocation,
}) => {
  const [selectedPosition, setSelectedPosition] = React.useState<
    LatLngExpression | undefined
  >(L.latLng(coordinates[0], coordinates[1]));

  React.useEffect(() => {
    setSelectedPosition(L.latLng(coordinates[0], coordinates[1]));
  }, [coordinates[0], coordinates[1]]);

  return (
    <div className="map__container">
      <MapContainer
        center={selectedPosition}
        zoom={18}
        scrollWheelZoom={false}
        className="map__container"
      >
        <ChangeView center={selectedPosition as LatLngExpression} zoom={18} />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker
          position={selectedPosition}
          mode={mode}
          setPosition={setSelectedPosition}
          setAddress={setAddress}
          setNewLocation={setNewLocation}
        />
      </MapContainer>
    </div>
  );
};

export default Map;
