import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './style.scss';

const Index: React.FC<RouteComponentProps> = () => {
  return (
    <div className="tools__container">
      <div className="tools__content-container">
        <div className="tools__iphone-img" />
        <div className="tools__mac-book-img" />
        <div className="tools__info-title">Что такое ADVA?</div>
        <div className="tools__devices">
          <div className="tools__device iphone">
            <a className="login-page__policy-link" href="/">
              Приложение
            </a>{' '}
            для&nbsp;клиентов по поиску и&nbsp;бронированию туристических услуг
          </div>
          <div className="tools__device macbook">
            <span className="tools__device-text">
              <a className="login-page__policy-link" href="/provider">
                Личный кабинет
              </a>{' '}
              поставщика для&nbsp;управления услугами, расписанием
              и&nbsp;бронированиями
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Index);
